import mustache from 'mustache';

import moment from 'moment-timezone';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';

import { isCONRegion, isEXPRegion, isMXDRegion } from 'utils/misc';

import { AVAILABLE_LANGS } from '../constants';

const ext = process.env.REACT_APP_REGION_EXT;
const app = process.env.REACT_APP_BRAND_NAME;

export const loc = (field, lang = process.env.REACT_APP_REGION_LANGUAGE, dynamicText = {}) => {
    const translations = allTranslations[field];

    if (!translations) {
        return '';
    }

    let language = lang;
    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];
    if (!availableLangs.includes(lang)) {
        language = 'en';
    }

    const fieldTranslation = translations[language];
    if (!fieldTranslation) {
        return '';
    }

    // Rendering dynamic strings using mustache (needs testing)
    let dynamicTranslation = mustache.render(fieldTranslation, dynamicText);

    if (dynamicTranslation.includes('&amp;')) {
        dynamicTranslation = dynamicTranslation.replace(/&amp;/g, '&');
    }

    // START: Using consecutive string replacement from array

    // const splitTranslation = fieldTranslation.split('%%%');
    // if (splitTranslation.length <= 1) {
    //     // No dynamic text to be inserted
    //     return fieldTranslation || '!';
    // }

    // let dynamicTranslation = '';
    // for (let i = 0; i < splitTranslation.length; i++) {
    //     dynamicTranslation += splitTranslation;
    //     if (i >= dynamicText.length) {
    //         dynamicTranslation += '!';
    //         continue;
    //     }
    //     dynamicTranslation += dynamicText[i];
    // }

    // END: Using consecutive string replacement from array

    return String(dynamicTranslation) || '';
};

const getAdjustedFormat = (lang, format) => {
    // handle custom language formats...
    if (lang === 'fr') {
        switch (format) {
            case 'MMM Do':
                return 'D MMM';
            case 'MMM Do, YYYY':
                return 'D MMM, YYYY';
            default:
                return format;
        }
    }

    return format;
};

export const locDate = (
    date,
    format,
    lang = process.env.REACT_APP_REGION_LANGUAGE,
    timezone = process.env.REACT_APP_REGION_TIMEZONE
) => {
    let locale;
    switch (lang) {
        case 'en':
            locale = 'en-ca';
            break;
        case 'fr':
            locale = 'fr-ca';
            break;
        default:
            locale = 'en-ca';
    }

    const adjustedFormat = getAdjustedFormat(lang, format);

    return moment(date)
        .tz(timezone)
        .locale(locale)
        .format(adjustedFormat);
};

const terms = {
    AUS: {
        registration1: {
            en: 'ReCollect. It’s worth it.',
            fr: ''
        },
        registration7: {
            en: 'Home or unit pickups. Cash out or donate returns!',
            fr: 'Ramassages résidentiels, de condos ou dépôt de sacs. Encaissez ou donnez votre consigne!'
        },
        registration15: {
            en: 'Business pickups. Cash out or donate returns!',
            fr: 'Commerciaux ou dépôt de sacs. Encaissez ou donnez votre consigne!'
        },
        organization: {
            en: 'Organisation',
            fr: ''
        },
        charityNumName: {
            en: `Australian Business Number`,
            fr: `Numéro d'enregistrement de l'organisme de bienfaisance`
        },
        REGISTRATION_TAGLINE: {
            en: `${app} picks up and pays you for your bottles & cans. We also take used clothing and old electronics off your hands free of charge.`,
            fr: `${app} vient chercher et vous rembourse vos contenants consignés. Nous récupérons également gratuitement vos vêtements usagés et vos vieux appareils électroniques.`
        },
        ETRANSFERS_NAME: {
            en: 'Electronic Fund Transfer',
            fr: 'Electronic Fund Transfer'
        },
        ETRANSFERS_NAME_SHORT: {
            en: 'EFT',
            fr: 'EFT'
        }
    },
    STD: {
        registration1: {
            en: 'Bottles & Cans. Clothing. Electronics.',
            fr: ''
        },
        registration7: {
            en: 'Home, condo pickups or Drop&Go. Cash out or donate returns!',
            fr:
                'Ramassages résidentiels, de condos ou dépôts dans les dépôt de sacs. Encaissez ou donnez votre consigne!'
        },
        registration15: {
            en: 'Business pickups or Drop&Go. Cash out or donate returns!',
            fr: 'Commerciaux ou dépôts dans les dépôt de sacs. Encaissez ou donnez votre consigne!'
        },
        organization: {
            en: 'Organization',
            fr: ''
        },
        charityNumName: {
            en: `Charity Registration Number`,
            fr: `Numéro d'enregistrement de l'organisme de bienfaisance`
        },
        REGISTRATION_TAGLINE: {
            en: `${app} picks up and pays you for your bottles & cans. We also take used clothing and old electronics off your hands free of charge.`,
            fr: `${app} vient chercher et vous rembourse vos contenants consignés. Nous récupérons également gratuitement vos vêtements usagés et vos vieux appareils électroniques.`
        },
        ETRANSFERS_NAME: {
            en: 'Interac e-Transfer',
            fr: 'Virement électronique'
        },
        ETRANSFERS_NAME_SHORT: {
            en: 'e-Transfer',
            fr: 'virement électronique'
        }
    },
    EXP: {
        registration1: {
            en: '',
            fr: ''
        },
        registration7: {
            en: 'Individuals or households',
            fr: 'Particuliers ou ménages'
        },
        registration15: {
            en: 'Depot refund issued to registered business',
            fr: 'Remboursement de dépôt délivré aux entreprises enregistrées'
        },
        organization: {
            en: 'Organization',
            fr: ''
        },
        charityNumName: {
            en: `Charity Registration Number`,
            fr: `Numéro d'enregistrement de l'organisme de bienfaisance`
        },
        REGISTRATION_TAGLINE: {
            en: `A convenient and cashless way to recycle used beverage containers`,
            fr: `Un moyen pratique et sans espèces de recycler les contenants de boissons usagés`
        },
        ETRANSFERS_NAME: {
            en: 'Interac e-Transfer',
            fr: 'Virement électronique'
        },
        ETRANSFERS_NAME_SHORT: {
            en: 'e-Transfer',
            fr: 'virement électronique'
        }
    },
    MXD: {
        registration1: {
            en: 'Bottles & Cans. Clothing. Electronics.',
            fr: ''
        },
        registration7: !isMXDRegion()
            ? {
                  en: 'Home, condo units pickups or Bag Drop. Cash out or donate returns!',
                  fr: 'Dépôts dans les Dépôt de sacs '
              }
            : {
                  en: 'Bag deposit',
                  fr: 'Dépôt de sac'
              },
        registration15: !isMXDRegion()
            ? {
                  en: 'Business or Bag Drop. Cash out or donate returns!',
                  fr: 'Dépôts dans les Dépôt de sacs ou ramassages commerciaux (consommation sur place)'
              }
            : {
                  en: 'Commercial collection',
                  fr: 'Collecte commerciale'
              },
        organization: {
            en: 'Organization',
            fr: ''
        },
        charityNumName: {
            en: `Charity Registration Number`,
            fr: `Numéro d'enregistrement de l'organisme de bienfaisance`
        },
        REGISTRATION_TAGLINE: {
            en: `Sign up below and discover new ways to collect your returnable containers.`,
            fr: `Inscrivez-vous ci-dessous et découvrez de nouvelles façons de récupérer vos contenants consignés.`
        },
        ETRANSFERS_NAME: {
            en: 'Interac e-Transfer',
            fr: 'Virement électronique'
        },
        ETRANSFERS_NAME_SHORT: {
            en: 'e-Transfer',
            fr: 'virement électronique'
        }
    },
    CON: {
        registration1: {
            en: 'Bottles & Cans.',
            fr: ''
        },
        registration7: {
            en: 'Return point services',
            fr: 'Services points de retour'
        },
        registration15: {
            en: 'Business or Bag Drop. Cash out returns!',
            fr: 'Affaires ou dépôt de sacs. Retours encaissés'
        },
        organization: {
            en: 'Organization',
            fr: 'Organisation'
        },
        charityNumName: {
            en: `Charity Registration Number`,
            fr: `Numéro d'enregistrement de l'organisme de bienfaisance`
        },
        REGISTRATION_TAGLINE: {
            en: `Sign up below and discover new ways to collect your returnable containers.`,
            fr: `Inscrivez-vous ci-dessous et découvrez de nouvelles façons de récupérer vos contenants consignés.`
        },
        ETRANSFERS_NAME: {
            en: 'Interac e-Transfer',
            fr: 'Virement électronique'
        },
        ETRANSFERS_NAME_SHORT: {
            en: 'e-Transfer',
            fr: 'virement électronique'
        }
    }
}[ext];

const sideWidgetTranslations = {
    customerInfo: {
        en: 'Customer Info',
        fr: 'Customer Info'
    }
};

const confirmPickupTranslations = {
    confirmPickup1: {
        en: "We're coming to {{address}} on {{date}}!",
        fr: 'Nous arrivons à {{address}} le {{date}}!'
    },
    confirmPickup2a: {
        en: 'Enter the number of recycling bags we can expect, or click postpone to reschedule to the following week.',
        fr:
            'Saisissez le nombre de sacs de contenants consignés auxquels nous pouvons nous attendre ou cliquez sur reporter pour reporter à la semaine suivante.'
    },
    confirmPickup2b: {
        en: `Enter the number of recycling bags we can expect, or click postpone to reschedule {{frequencyWeeks}} weeks out.`,
        fr:
            'Entrez le nombre de sacs de contenants auxquels nous pouvons nous attendre, ou cliquez sur reporter pour replanifier {{frequencyWeeks}} semaines plus tard.'
    },
    confirmPickup3: {
        en: 'Confirm your pickup',
        fr: 'Confirmez votre ramassage'
    },
    confirmPickup4: {
        en: 'You can still make changes to your pickup up until 7 AM on the day of the pickup.',
        fr: "Vous pouvez toujours apporter des modifications à votre ramassage jusqu'à 7h le jour du ramassage."
    },
    confirmPickup5: {
        en: 'Close',
        fr: 'Fermé'
    },
    confirmPickup6: {
        en: 'Not right now',
        fr: 'Pas tout de suite'
    },
    confirmPickup7: {
        en: 'Postpone by {{frequencyWeeks}} week',
        fr: "Reporté d'une semaine"
    },
    confirmPickup8: {
        en: 'Yes, I want this pickup',
        fr: 'Oui, je veux ce ramassage'
    }
};

const cancelPickupTranslations = {
    cancelPickup1: {
        en: 'Cancel Your Pickup?',
        fr: 'Annuler votre collecte?'
    },
    cancelPickup2: {
        en: 'You can cancel your pickup up to one day before the date scheduled.',
        fr: "Vous pouvez annuler votre collecte jusqu'à un jour avant la date prévue."
    },
    cancelPickup3: {
        en: 'Why are you cancelling?',
        fr: 'Pourquoi annulez-vous?'
    },
    cancelPickup3a: {
        en: 'I need a pickup sooner',
        fr: "J'ai besoin d'un ramassage plus tôt"
    },
    cancelPickup3b: {
        en: 'I will re-book later',
        fr: 'Je réserverai plus tard'
    },
    cancelPickup3c: {
        en: 'I gave the bags away',
        fr: "J'ai donné les sacs"
    },
    cancelPickup3d: {
        en: 'I don’t like this service',
        fr: "Je n'aime pas ce service"
    },
    cancelPickup3e: {
        en: 'Other',
        fr: 'Autre'
    },
    cancelPickup4: {
        en: 'Reason',
        fr: 'Raison'
    },
    cancelPickup5: {
        en: 'How can we do better',
        fr: 'Comment pouvons-nous faire mieux'
    },
    cancelPickup6: {
        en: 'Who did you donate to',
        fr: 'A qui avez-vous fait un don'
    },
    cancelPickup7: {
        en: 'Wait! Does this date work?',
        fr: 'Attendez! Est-ce que cette date fonctionne?'
    },
    cancelPickup8: {
        en: 'Your area is serviced next on {{date}}',
        fr: 'Votre zone sera desservie le {{date}}'
    },
    cancelPickup9: {
        en: 'No, cancel pickup',
        fr: 'Non, annuler le ramassage'
    },
    cancelPickup10: {
        en: 'yes, use {{date}}',
        fr: 'oui, utilisez {{date}}'
    },
    cancelPickup11: {
        en: 'You have cancelled your pickup',
        fr: 'Vous avez annulé votre collecte'
    },
    cancelPickup12: {
        en: 'Wait! Can we leave the pickup reminder?',
        fr: 'Attendez! Pouvons-nous laisser le rappel de ramassage?'
    },
    cancelPickup13: {
        en: `{{brand}} works best with a recurring schedule. Leaving the pickup reminder, allows us to service your community better. There's no obligations or fees for leaving the reminder.`,
        fr:
            '{{brand}} fonctionne mieux avec un calendrier récurrent. Laisser le rappel de ramassage nous permettra de mieux servir votre communauté. Il n’y a pas d’obligations ou de frais pour laisser le rappel.'
    },
    cancelPickup14: { en: `Yes, But move reminder to {{date}}`, fr: 'Oui, mais déplacez le rappel vers {{date}}' },
    cancelPickup15: { en: 'Rescheduled pickup', fr: 'Ramassage reporté' },
    cancelPickup16: {
        en: 'Failed to reschedule pickups. Please try again in a moment.',
        fr: 'Échec du report des ramassages. Veuillez réessayer dans un instant.'
    }
};

const pickupWidgetTranslations = {
    pickupWidgets2: {
        en: 'Your customer ID is {{id}}',
        fr: 'Votre identifiant client est {{id}}'
    },
    pickupWidgets3: {
        en: 'We got your {{commodityUOM}}s!',
        fr: 'Nous avons vos sacs!'
    },
    pickupWidgets4: {
        en: '{{{type}}} waiting for counts',
        fr: 'En attente du décompte du/des sacs'
    },
    pickupWidgets5a: {
        en: 'Details for your last {{orderType}}',
        fr: 'Détails de votre dernier {{orderType}}'
    },

    pickupWidgets5b: {
        en: '{{orderType}} Alert',
        fr: '{{orderType}} Alerte'
    },

    pickupWidgets6: {
        en: 'Suggestions for your next {{orderType}}',
        fr: 'Suggestions pour votre prochain {{orderType}}'
    },
    pickupWidgets7: {
        en: 'Awaiting driver confirmation',
        fr: 'En attente de confirmation du chauffeur'
    },
    pickupWidgets8: {
        en: 'Your pickups',
        fr: 'Votre ramassage'
    },
    pickupWidgets9: {
        en: 'Your last pickup',
        fr: 'Votre dernier ramassage'
    },
    pickupWidgets10: {
        en: 'Your next pickup',
        fr: 'Votre prochain ramassage'
    },
    pickupWidgets11: {
        en: '{{commodityUOM}}s will be counted within a maximum of {{daysToCount}} days.',
        fr: 'Les sacs seront comptés dans un délai maximum de {{daysToCount}} jours.'
    },
    pickupWidgets12a: {
        en: 'We have suggestions for you based on your last {{orderType}}. Press the info icon for details.',
        fr:
            "Nous avons des suggestions pour vous en fonction de votre dernier {{orderType}}. Appuyez sur l'icône d'information pour plus de détails."
    },
    pickupWidgets12b: {
        en: 'Your last return has issues that may suspend your account. Press the info icon for details.',
        // 'Your last return did not meet {{orderType}} requirements and may result in your account {{penaltyDetails}}. Press the info icon for more details.',
        fr:
            "Votre dernière déclaration comporte des problèmes susceptibles de suspendre votre compte. Appuyez sur l'icône d'informations pour plus de détails."
    },
    pickupWidgets12EXP: {
        en: 'A recent return did not meet requirements and may suspend your account. Click "i" for details.',
        fr:
            'Un retour récent ne répond pas aux exigences et peut suspendre votre compte. Cliquez sur "i" pour plus de détails.'
    },
    pickupWidgets12c: {
        en: 'being suspended',
        fr: 'la suspension de votre compte'
    },
    pickupWidgets12d: {
        en: 'being placed under review',
        fr: 'la mise en examen de votre compte'
    },
    pickupWidgets12e: {
        en: 'status changing.',
        fr: 'une modification du statut de votre compte'
    },
    pickupWidgets13: {
        en: 'Press here to get started!',
        fr: 'Appuyez pour commencer!'
    },
    pickupWidgets14: {
        en: 'Click here to book a pickup!',
        fr: 'Cliquez ici pour réserver un ramassage!'
    },
    pickupWidgets15: {
        en: 'Affiliated with {{charityName}}',
        fr: 'Affilié à {{charityName}}'
    },
    pickupWidgets16: {
        en: 'Sorry we are not servicing this area at the moment, we will notify you when we are.',
        fr: "Restez à l'affût et nous vous informerons lorsqu'un chauffeur sera disponible!"
    },
    pickupWidgets17: {
        en: 'High Priority',
        fr: 'Haute priorité'
    },
    pickupWidgets18: {
        en: 'No donation preference',
        fr: 'Aucune préférence de don'
    },
    pickupWidgets19: {
        en: 'Donating to {{{charity}}}',
        fr: 'Faire un don à {{{charity}}}'
    },
    pickupWidgets20: {
        en: 'Write {{id}} on your bags!',
        fr: 'Écrivez {{id}} sur vos sacs !'
    },
    pickupWidgets21: {
        en: 'Action required: press here to confirm',
        fr: 'Action requise : appuyez ici pour confirmer'
    },
    pickupWidgets22: {
        en: 'You have {{numPickups}} pending pickups',
        fr: 'Vous avez {{numPickups}} ramassage en attente'
    },
    pickupWidgets23: {
        en: 'Your pickups',
        fr: 'Votre ramassage'
    },
    pickupWidgets24: {
        en: 'Your last {{{pickup}}} was completed on {{date}}. See history for more details.',
        fr: "Votre dernier {{{pickup}}} a été effectué le {{date}}. Voir l'historique pour plus de détails."
    },
    pickupWidgetsExpQuickDropAlt: {
        en: 'Drop-Off',
        fr: 'Déposer'
    },
    pickupWidgets25: {
        en: 'There was a problem with your last pickup scheduled for {{date}}. See history for more details',
        fr:
            "Il y a eu un problème avec votre dernier ramassage prévu pour le {{date}}. Voir l'historique pour plus de détails"
    },
    pickupWidgets26: {
        en: 'We got your pickup!',
        fr: 'Nous avons votre ramassage!'
    },
    pickupWidgets27: {
        en: 'We got your pickups!',
        fr: 'Nous avons votre ramassage!'
    },
    pickupWidgets28: {
        en: 'Picked up at {{time}} on {{date}}',
        fr: 'Récupéré à {{time}} le {{date}}'
    },
    pickupWidgets29: {
        en: 'Your items will be processed shortly and your account updated within 48 hours of pickup.',
        fr: 'Vos articles seront traités sous peu et votre compte mis à jour dans les 48 heures suivant le ramassage.'
    },
    pickupWidgets30: {
        en: 'Only certain beverage containers are eligible for a refund. For more information please follow the link',
        fr:
            "Seuls certains contenants sont admissibles à un remboursement de consigne. Pour plus d'informations, veuillez suivre le lien"
    },
    pickupWidgets31: {
        en: 'We have a few suggestions to help make the process smoother next time. We kindly ask that you:',
        fr:
            'Nous avons quelques suggestions pour faciliter le processus la prochaine fois. Nous vous prions de bien vouloir :'
    },
    pickupWidgets32: {
        en: 'Your Last {{{type}}}',
        fr: 'Votre dernier {{{type}}}'
    },
    pickupWidgets32b: {
        en: 'Your last drop-off',
        fr: 'Votre dernier dépôt'
    },
    pickupWidgets33: {
        en: 'Bags will be counted and your account updated within {{{days}}} days of the drop off date.',
        fr: 'Vos sacs seront comptés et votre compte client sera crédité dans les prochains {{{days}}} jours.'
    },
    pickupWidgets34: {
        en: 'Set donation preference',
        fr: 'Définir les préférences de don'
    },
    pickupWidgets34Exp: {
        en: 'Set Automatic Donation',
        fr: 'Définir le don Automatique'
    }
};

const managePickupsDialogTranslations = {
    managePickups1: {
        en: 'not donating',
        fr: 'ne pas faire de don'
    },
    managePickups2: {
        en: 'no promo',
        fr: 'pas de promo'
    },
    managePickups3: {
        en: 'edit',
        fr: 'modifier'
    },
    managePickups4: {
        en: 'cancel',
        fr: 'annuler'
    },
    managePickups5: {
        en: 'Book additional pickup',
        fr: 'Réserver une collecte supplémentaire'
    },
    managePickups6: {
        en: 'Multi-location Pickups',
        fr: 'Ramassages multi-emplacements'
    },
    managePickups7a: {
        en: 'Do you need to manage multiple locations?',
        fr: 'Avez-vous besoin de gérer plusieurs emplacements?'
    },
    managePickups7email: {
        en: 'Email us',
        fr: 'Envoyez-nous un courriel'
    },
    managePickups7b: {
        en: 'to enable this feature and increase your pickup limit!',
        fr: 'pour activer cette fonctionnalité et augmenter votre limite de ramassage!'
    },
    managePickups8: {
        en: 'Manage Your Pickups',
        fr: 'Modifiez vos collectes'
    }
};

const sidebarTranslations = {
    admin: {
        en: 'Administrator',
        fr: 'Administrateur'
    },
    dashboard: {
        en: 'Dashboard',
        fr: 'Tableau De Bord'
    },
    home: {
        en: 'Home',
        fr: 'Accueil'
    },
    history: {
        en: 'History',
        fr: 'Historique'
    },
    organisation: {
        en: 'Organization',
        fr: 'Organization'
    },
    donations: {
        en: 'Donations',
        fr: 'Dons'
    },
    taxReceipts: {
        en: 'Tax Receipts',
        fr: 'Reçus Fiscaux'
    },
    subdivisions: {
        en: 'Subdivisions',
        fr: 'Sous-divisions'
    },
    emailOutDirectly: {
        en: 'Email Out Directly',
        fr: 'Envoyer directement par courriel'
    },
    account: {
        en: 'Account',
        fr: 'Profil'
    },
    getStartedHere: {
        en: 'Get Started Here',
        fr: 'Commencer ici'
    },
    editPickup: {
        en: 'Edit Pickup',
        fr: 'Modifier le ramassage'
    },
    cashOut: {
        en: 'Cash Out',
        fr: 'Remboursement'
    },
    cashOutExp: {
        en: 'Redeem Funds',
        fr: 'Échanger des fonds'
    },
    cashOutAndDisable: {
        en: 'Cash Out and Close Account',
        fr: 'Remboursement et fermer le compte'
    },
    donateAndDisable: {
        en: 'Donate and Close Account',
        fr: 'Faire un don et fermer le compte'
    },
    cashOutAndDelete: {
        en: 'Cash Out and Delete Account',
        fr: 'Remboursement et supprimer le compte'
    },
    donateAndDelete: {
        en: 'Donate and Delete Account',
        fr: 'Faire un don et supprimer le compte'
    },
    redeem: {
        en: 'Cash Out',
        fr: 'Remboursement'
    },
    earnMoney: {
        en: 'Earn Money',
        fr: "Gagnez de l'argent"
    },
    growthPlan: {
        en: 'Growth Plan',
        fr: 'Plan De Croissance'
    },
    impactReport: {
        en: 'Impact Report',
        fr: "Rapport d'impact"
    },
    volumeReport: {
        en: 'Volume Report',
        fr: 'Rapport de volumes'
    },
    nightMode: {
        en: 'Night Mode',
        fr: 'Mode Nuit'
    },
    signOut: {
        en: 'Sign Out',
        fr: 'Se déconnecter'
    },
    signOutAll: {
        en: 'Sign Out All Devices',
        fr: 'Déconnectez tous les appareils'
    },
    removeDevice: {
        en: 'Remove Device',
        fr: `Enlevez l'appareil`
    },
    help: {
        en: 'Help',
        fr: 'Aide'
    },
    openDoor: {
        en: 'Unlock Door',
        fr: 'Ouvre la porte'
    },
    printLabels: {
        en: 'Print Labels',
        fr: 'Imprimer des auto-collants'
    },
    requestLabels: {
        en: 'Request Labels',
        fr: 'Demander des auto-collants'
    },
    donateExpressApp: {
        en: 'Donate Funds',
        fr: 'Faire un don de fonds'
    }
};

const dashboardTranslations = {
    dashboardFinancial1: {
        en: '{{organization}} Balance',
        fr: "Solde de l'organisation"
    },
    dashboardFinancial2: {
        en: 'Previous cash out of {{amount}} pending',
        fr: 'Remboursement précédent de {{amount}} en attente'
    },
    dashboardFinancial3: {
        en: 'Previous cash out of {{amount}} complete',
        fr: 'Remboursement précédent de {{amount}} terminé'
    },
    dashboardFinancial4: {
        en: '{{amount}} donated from your last return',
        fr: '{{amount}} en don depuis votre dernier retour'
    },
    dashboardFinancial4b: {
        en: '{{amount}} was donated to {{{charity}}}',
        fr: '{{amount}} reversé à {{{charity}}}'
    },
    dashboardFinancial5: {
        en: 'Outstanding Tax Receipts',
        fr: 'Reçus fiscaux impayés'
    },
    dashboardFinancial6: {
        en: 'Account Balance',
        fr: 'Solde du compte'
    },
    dashboardFinancial7: {
        en: 'You are donating to {{{charity}}}',
        fr: 'Vous faites un don à {{{charity}}}'
    },
    dashboardFinancial8: {
        en: 'days left to redeem',
        fr: 'jours restants pour racheter'
    },
    dashboardStats1: {
        en: 'Total revenue',
        fr: 'Revenu total'
    },
    dashboardStats2: {
        en: 'Total donations',
        fr: 'Total des dons'
    },
    dashboardStats3: {
        en: 'Total containers',
        fr: 'Total des contenants'
    },
    dashboardStats4: {
        en: 'Greenhouse gas saved',
        fr: 'Gaz à effet de serre économisé'
    },
    dashboardStats5: {
        en: 'Energy saved',
        fr: 'Énergie économisée'
    },
    dashboardStats6: {
        en: 'Spared from landfill',
        fr: "Détourné du site d'enfouissement"
    },
    dashboardStats7: {
        en: 'All Locations',
        fr: 'Tous les emplacements'
    },
    dashboardCharityInfo1: {
        en: 'Teams/Groups/Bottle drives:',
        fr: 'Equipes/Groupes/Collectes de contenants:'
    },
    dashboardCharityInfo2: {
        en: 'Website',
        fr: 'Site Internet'
    },
    dashboardCharityInfo3: {
        en: 'Referral link',
        fr: 'Lien de référence'
    },
    dashboardCharityInfo4: {
        en: 'E-Transfer email:',
        fr: 'Courriel de transfert électronique:'
    },
    dashboardCharityInfo5: {
        en: 'Referral link will be assigned when your application is approved.',
        fr: 'Le lien de référence sera attribué lorsque votre candidature sera approuvée.'
    },
    dashboardGrowthPlan1: {
        en: 'Your Growth Plan',
        fr: 'Votre plan de croissance'
    },
    dashboardGrowthPlan2: {
        en: 'Come here once approved to view your growth plan',
        fr: 'Venez ici une fois approuvé pour voir votre plan de croissance'
    },
    dashboardGrowthPlan3: {
        en: 'Growth Plan Information',
        fr: 'Renseignements sur le plan de croissance'
    },
    dashboardGrowthInfo1: {
        en: 'Current status',
        fr: 'Statut actuel'
    },
    dashboardGrowthInfo2: {
        en: 'Your growth plan will be available when your {{organization}} is approved.',
        fr: 'Votre plan de croissance sera disponible lorsque votre {{organization}} aura été approuvée.'
    },
    dashboardGrowthInfo3: {
        en: 'Waiting for approval',
        fr: "En attente d'approbation"
    },
    dashboardGrowthInfo4: {
        en:
            "We have compiled {{countCompletable}} tips and tricks to help you get your recycling program automated! Ranging from things like adding your donation link to your website to ordering custom bags! You don't have to do these in order.",
        fr:
            "Nous avons compilé {{countCompletable}} trucs et astuces pour vous aider à automatiser votre programme de recyclage! Cela va de l'ajout de votre lien de don à votre site Web à la commande de sacs personnalisés! Vous n'êtes pas obligé de les faire dans l'ordre."
    },
    dashboardGrowthInfo5: {
        en: 'You have {{countComplete}} out of {{countCompletable}} unexpired tasks in your growth plan completed!',
        fr:
            'Vous avez terminé {{countComplete}} des {{countCompletable}} tâches non expirées de votre plan de croissance !'
    },
    dashboardGrowthInfo6: {
        en: 'About Featured {{organization}}s',
        fr: 'À propos des Organisations Affiliés'
    },
    dashboardGrowthInfo7a: {
        en:
            'Complete and submit 5 or more Growth Plan items to qualify for a Featured {{organization}} position. Featured {{organization}}s are shown to {{brand}} customers when they register, book a pickup, and view their dashboard. ',
        fr:
            "Complétez et soumettez au moins 5 éléments du plan de croissance pour vous qualifier pour un poste d'Organisme Affilié. Les Organismes Affiliés sont présentées aux clients de {{brand}} lorsqu'ils s'inscrivent, réservent un ramassage et consultent leur tableau de bord. "
    },
    dashboardGrowthInfo7b: {
        en: 'Featured {{organization}} also qualify for a 100% refund promocode!',
        fr: "L'Organisme Affilié peut également bénéficier d'un code promotionnel de remboursement à 100 % !"
    },
    dashboardGrowthInfo8: {
        en: 'Your charity QR code',
        fr: 'Votre code QR caritatif'
    },
    dashboardGrowthInfo9: {
        en: 'Upload High Res. Image',
        fr: 'Télécharger une image haute résolution'
    },
    dashboardGrowthInfo10: {
        en: 'Upload Image',
        fr: "Télécharger l'image"
    },
    dashboardGrowthInfo11: {
        en: 'Not approved',
        fr: 'Non approuvé'
    },
    dashboardGrowthInfo12: {
        en: 'Logo succesfully uploaded',
        fr: 'Logo télécharger avec succès'
    },
    dashboardGrowthInfo13: {
        en: 'Preview Unavailable',
        fr: 'Aperçu indisponible'
    },
    dashboardCharityLogo1: {
        en: 'Upload High Resolution Logo',
        fr: 'Télécharger le logo haute résolution'
    },
    dashboardCharityLogo2: {
        en: 'Upload your logo! We prefer vector files of your logo (SVG, EPS, PDF or AI)',
        fr: 'Téléchargez votre logo! Nous privilégions les fichiers vectoriels de votre logo (SVG, EPS, PDF ou AI)'
    },
    dashboardCharityLogo3: {
        en:
            'WARNING: Logo is of low resolution and may result in poor quality of printed materials. If you can, reupload a higher resolution file (greater than 4000px).',
        fr:
            'AVERTISSEMENT : Le logo est de faible résolution et peut entraîner une mauvaise qualité des documents imprimés. Si vous le pouvez, téléchargez à nouveau un fichier de résolution supérieure (supérieure à 4 000 pixels).'
    },
    dashboardCharityLogo4: {
        en: 'Upload photo',
        fr: 'Télécharger la photo'
    },
    dashboardSetting1: {
        en: 'Organization',
        fr: 'Organisme'
    },
    dashboardSetting2: {
        en: 'Name',
        fr: 'Nom'
    },
    dashboardSetting3: {
        en: 'This {{organization}} issues tax receipts',
        fr: 'Cet organisme délivre des reçus fiscaux'
    },
    dashboardSetting4: {
        en: 'Charity Registration Number',
        fr: "Numéro d'enregistrement de l'organisme de bienfaisance"
    },
    dashboardSetting5: {
        en: '{{organization}} E-mail (financial)',
        fr: "Courriel de l'organisation (comptabilité)"
    },
    dashboardSetting6: {
        en: 'Website',
        fr: 'Site Internet'
    },
    dashboardSetting7: {
        en: 'Address',
        fr: 'Adresse'
    },
    dashboardSetting8: {
        en: 'GPS Override (Advanced)',
        fr: 'Remplacement GPS (avancé)'
    },
    dashboardSetting9: {
        en: 'Please do not change these values if you are unsure what they mean.',
        fr: "Veuillez ne pas modifier ces valeurs si vous n'êtes pas certain de leur signification."
    },
    dashboardSetting10: {
        en: `${terms.organization.en} Logo`,
        fr: "Logo de l'organisation"
    },
    dashboardSetting11: {
        en: 'Upload Image',
        fr: "Télécharger l'image"
    },
    dashboardSetting12: {
        en: 'Image to be uploaded:',
        fr: 'Image à importer :'
    },
    dashboardSetting12a: {
        en: 'Accepted file types: webp, heif, heic, gif, jpg, jpeg, png',
        fr: 'Types de fichiers acceptés : webp, heif, heic, gif, jpg, jpeg, png'
    },
    dashboardSetting13: {
        en: 'Mission & Vision',
        fr: 'Mission et vision'
    },
    dashboardSetting14: {
        en: 'Tax Receipts',
        fr: 'Reçus fiscaux'
    },
    dashboardSetting15: {
        en: 'Minimum Donation Cut Off',
        fr: 'Don minimum'
    },
    dashboardSetting16: {
        en:
            "The minimum donation amount required to issue a tax receipt. Donations will be summed over the year and carried over to the next year if they don't meet this requirement.",
        fr:
            "Le montant minimum de don requis pour délivrer un reçu fiscal. Les dons seront additionnés sur l'année et reportés à l'année suivante s'ils ne répondent pas à cette exigence."
    },
    dashboardSetting17: {
        en: 'Tax Receipt Year End Date',
        fr: "Date de fin d'année du reçu fiscal"
    },
    dashboardSetting18: {
        en: 'The date when you issue your tax receipts for the year.',
        fr: "La date à laquelle vous émettez vos reçus fiscaux pour l'année."
    },
    dashboardSetting19: {
        en: 'Subdivisions',
        fr: 'Sous-divisions'
    },
    dashboardSetting20: {
        en:
            'Enter your groups, teams, divisions, players, representatives, bottle drives, and companies for your organization below. Donors will be presented an option to specify which subdivision they want to donate to. Leave this empty and donors will only have a general donation option for your organization.',
        fr:
            "Entrez ci-dessous vos groupes, équipes, divisions, joueurs, représentants, services de collecte et entreprises pour votre organisation. Les donateurs auront la possibilité de spécifier à quelle sous-division ils souhaitent faire un don. Laissez ce champ vide et les donateurs n'auront qu'une option de don générale pour votre organisation."
    },
    dashboardSetting21: {
        en: 'You have no active subdivisions. Use the input above to create one.',
        fr: "Vous n'avez aucune sous-division active. Utilisez l'entrée ci-dessus pour en créer un."
    },
    dashboardSetting22: {
        en: 'Teams, Groups, Companies or Divisions',
        fr: 'Equipes, Groupes, Entreprises ou Divisions'
    },
    dashboardSetting23: {
        en: 'Referral link',
        fr: 'Lien de référence'
    },
    dashboardSetting24: {
        en: 'This field is required',
        fr: 'Ce champ est requis'
    },
    dashboardSetting25: {
        en: 'Invalid e-mail format',
        fr: 'Format de courriel invalide'
    },
    dashboardSetting26: {
        en: 'Must start with http:// or https://',
        fr: 'Doit commencer par http:// ou https://'
    },
    dashboardSetting27: {
        en: 'Submit changes?',
        fr: 'Soumettre des changements?'
    },
    dashboardSetting28: {
        en:
            'Your {{organization}} will require re-approval after you submit your changes. The approval process takes up to 2 business days.',
        fr:
            "Votre organisation devra obtenir une nouvelle approbation une fois que vous aurez soumis vos modifications. Le processus d'approbation prend jusqu'à 2 jours ouvrables."
    },
    dashboardSetting29: {
        en: 'Successfully updated your {{organization}}.',
        fr: 'Votre organisation a bien été mise à jour.'
    },
    dashboardSetting30: {
        en: 'Successfully updated your tax receipt settings.',
        fr: 'Les paramètres de votre reçu fiscal ont été mis à jour avec succés.'
    },
    dashboardSetting31: {
        en: 'Enter {{organization}} address',
        fr: "Entrer l'addresse de l'{{organization}}"
    },
    dashboardSetting32: {
        en: 'Click image to change',
        fr: "Cliquer sur l'image pour changer"
    },
    dashboardSetting33: {
        en: 'not selected',
        fr: 'non sélectionnée :'
    },
    dashboardSetting34: {
        en: `Account Owners`,
        fr: `Accès à la visualisation`
    },
    dashboardSubdivision1: {
        en: 'Are you sure you want to remove {{{charity}}}',
        fr: 'Êtes-vous sûr de vouloir supprimer {{{charity}}}'
    },
    dashboardSubdivision2: {
        en: 'Add/Remove Permissions',
        fr: 'Ajouter/supprimer des autorisations'
    },
    dashboardSubdivision3: {
        en: 'Users',
        fr: 'Utilisateurs'
    },
    dashboardSubdivision4: {
        en: 'Please enter a valid email address.',
        fr: 'Veuillez saisir une adresse courriel valide.'
    },
    dashboardSubdivision5: {
        en: 'You already entered {{value}}',
        fr: 'Vous avez déjà entré {{value}}'
    },
    dashboardSubdivision6: {
        en: 'No one can view this subdivision right now.',
        fr: 'Personne ne peut voir cette sous-division pour le moment.'
    },
    dashboardSubdivision7: {
        en: 'Click the edit button to manage permissions.',
        fr: 'Cliquez sur le bouton Modifier pour gérer les autorisations.'
    },
    done: {
        en: 'Done',
        fr: 'Fait'
    },
    clockIn: {
        en: 'Clock In',
        fr: "Démarrer l'itinéraire"
    },
    proceedTo: {
        en: 'Proceed to',
        fr: 'Dirigez-vous vers'
    },
    youAreToFarAway: {
        en: 'You are too far away!',
        fr: 'Vous êtes trop loin!'
    },
    moveCloserToStart: {
        en: 'Please move closer to the starting location.',
        fr: 'Veuillez vous rapprocher du point de départ.'
    },
    clockInMessage: {
        en: 'press Clock In once at starting location.',
        fr: "Appuyez sur 'Commencer' une fois arrivé au point de départ."
    },
    clockOut: {
        en: 'Clock Out',
        fr: 'Terminer le parcours'
    },
    startRoute: {
        en: 'Start Route',
        fr: "Démarrer l'itinéraire"
    },
    endRoute: {
        en: 'End Route',
        fr: 'Terminer le parcours'
    },
    finishTrip: {
        en: 'Finish Trip',
        fr: 'Terminer la journée'
    },
    nevermind: {
        en: 'Nevermind',
        fr: 'Annuler'
    },
    saved: {
        en: 'Saved.',
        fr: 'Enregistré.'
    },
    dashboardMain1: {
        en: 'Featured',
        fr: 'Affilié'
    },
    dashboardMain2: {
        en: 'Not approved',
        fr: 'Non approuvé'
    },
    dashboardMain3: {
        en: 'Approved',
        fr: 'Approuvé'
    },
    dashboardMain4a: {
        en: 'Need some help? Check out',
        fr: "Besoin d'aide? Consultez "
    },
    dashboardMain4b: {
        en: 'Your Donations Section ',
        fr: 'votre section de dons '
    },
    dashboardMain4c: {
        en: 'for reporting and tax receipts. Visit ',
        fr: 'pour les rapports et les reçus fiscaux. Visitez '
    },
    dashboardMain4d: {
        en: 'Your Growth Plan ',
        fr: 'votre Plan de croissance '
    },
    dashboardMain4e: {
        en: 'for tools to get more donations!',
        fr: "pour des outils permettant d'obtenir plus de dons!"
    },
    dashboardMain5: {
        en: 'Referral link and growth plan will be available once your application is approved.',
        fr: 'Le lien de référence et le Plan de croissance seront disponibles une fois votre candidature approuvée.'
    },
    dashboardMain6: {
        en: 'Your Referral Links',
        fr: 'Vos liens de référence'
    },
    dashboardMain7: {
        en: 'General Link',
        fr: 'Lien général'
    },
    dashboardMain8: {
        en: 'Teams/Groups/Bottle drive Specific Links',
        fr: 'Liens spécifiques vers Équipes/Groupes/Collecte de contenants'
    },
    dashboardMain9: {
        en: 'Close/open',
        fr: 'Fermé/ouvert'
    },
    dashboardMain10: {
        en: 'Donations',
        fr: 'Dons'
    },
    dashboardMain11: {
        en: 'Approval pending',
        fr: "En attente d'approbation"
    },
    dashboardMain12: {
        en: 'Not Featured',
        fr: 'Non présenté'
    },
    dashboardUpdate1: {
        en: 'Donate now',
        fr: 'Faire un don maintenant'
    },
    dashboardUpdate2: {
        en: 'Visit website',
        fr: 'Visitez le site Web'
    },
    dashboardUpdate3: {
        en: 'This charity issues tax receipts for your donations',
        fr: 'Cet organisme de bienfaisance délivre des reçus fiscaux pour vos dons'
    },
    dashboardUpdate4: {
        en: 'This {{organization}} does not issue tax receipts',
        fr: "Cet organisme de bienfaisance ne délivre pas de reçus d'impôt"
    },
    dashboardUpdate5: {
        en: 'Update preference',
        fr: 'Mettre à jour les préférences'
    },
    dashboardUpdate6: {
        en:
            'Your email address {{email}} has not been verified. Please check your spam/junk folder for verification or send again.',
        fr:
            "Votre adresse e-mail {{email}} n'a pas été vérifiée. Veuillez vérifier votre dossier spam / courrier indésirable pour vérification ou renvoyer."
    },
    dashboardUpdate7: {
        en: 'Send again',
        fr: 'Envoyer à nouveau'
    },
    dashboardUpdate8: {
        en: 'Note: tax receipts are not available for Quick Registration users.',
        fr: 'Remarque : les reçus fiscaux ne sont pas disponibles pour les utilisateurs de Quick Registration.'
    },
    dashboardUpdate9: {
        en:
            'Your account has not been verified. Please verify your email address to ensure we can process future cash outs. When you registered for Express, a verification email was sent to {{email}}. If you don’t see an email from us, please check your junk/spam folder. Alternately you can resend the email using the “send again” button or change the email in your profile below.',
        fr: `Votre compte n'a pas été vérifié. Veuillez vérifier votre adresse e-mail pour vous assurer que nous pouvons traiter les futurs rachats. Lors de votre inscription à Express, un e-mail de vérification a été envoyé à {{email}}. Si vous ne voyez pas d'e-mail de notre part, veuillez vérifier votre dossier de courrier indésirable/spam. Vous pouvez également renvoyer l'e-mail à l'aide du bouton "Renvoyer" ou modifier l'e-mail dans votre profil.`
    },
    dashboardUpdate10: {
        en: 'Email Verification Required',
        fr: "Vérification de l'e-mail requise"
    },
    dashboardUpdate11: {
        en:
            'Your email address {{email}} must be verified before redemptions can be processed. Please check your spam/junk folder for verification or send again.',
        fr:
            'Votre adresse e-mail {{email}} doit être vérifiée avant que les échanges puissent être traités. Veuillez vérifier votre dossier spam/indésirable pour vérification ou renvoyer.'
    },
    dashboardUpdate12: {
        en: 'Your account is currently pending approval for the {{{collectionProgram}}} collections program',
        fr:
            "Votre compte est actuellement en attente d'approbation pour le programme de collecte {{{collectionProgram}}}"
    },
    dashboardUpdate13: {
        en:
            'Your account has been rejected from the {{{collectionProgram}}} collections program for the following reasons: {{{reasons}}}',
        fr:
            'Votre compte a été rejeté du programme de collecte {{{collectionProgram}}} pour les raisons suivantes : {{{reasons}}}'
    },
    dashboardHistory1: {
        en: 'Pickups',
        fr: 'Collecte'
    },
    dashboardHistory2: {
        en: 'Walk-ins',
        fr: 'Sur place'
    },
    dashboardHistory3: {
        en: 'cash outs',
        fr: 'remboursements'
    },
    dashboardHistory4: {
        en: 'tax receipts',
        fr: 'reçus fiscaux'
    },
    dashboardHistory5: {
        en: 'Donation sent to',
        fr: 'Don envoyé à'
    },
    dashboardHistory6: {
        en: 'Donated',
        fr: 'Donné'
    },
    dashboardHistory7: {
        en: 'Counted',
        fr: 'Compté'
    },
    dashboardHistory8: {
        en: 'pending',
        fr: 'en attente'
    },
    dashboardHistory9: {
        en: 'bag',
        fr: 'sac'
    },
    dashboardHistory10: {
        en: 'Cancelled',
        fr: 'Annulé'
    },
    dashboardHistory11: {
        en: 'Scheduled',
        fr: 'Programmé'
    },
    dashboardHistory12: {
        en: 'Processing',
        fr: 'Traitement'
    },
    dashboardHistory13: {
        en: 'Collected',
        fr: 'Collecté'
    },
    dashboardHistory14: {
        en: 'Processed',
        fr: 'Traité'
    },
    dashboardHistory15: {
        en: 'Referral',
        fr: 'Référence'
    },
    dashboardHistory16: {
        en: 'Credited',
        fr: 'Remboursée'
    },
    dashboardHistory17: {
        en: 'Paid In Cash',
        fr: 'Payé en argent'
    },
    dashboardHistory18: {
        en: 'Counts Pending',
        fr: 'Décomptes en attente'
    },
    dashboardHistory19: {
        en: 'Customer Requesting More Information',
        fr: 'Client demande davantage d’informations'
    },
    dashboardHistory20: {
        en: 'Bagtag',
        fr: 'Auto-collant de sac'
    },
    dashboardHistory21: {
        en: 'We encountered the following issues with your order',
        fr: 'Nous avons rencontré les problèmes suivants avec votre commande'
    },
    dashboardHistory22: {
        en: 'View Details',
        fr: 'Voir détails'
    },
    dashboardHistory23: {
        en: 'Distance from drop location',
        fr: "Distance jusqu'à l’emplacement du dépôt"
    },
    dashboardHistory24: {
        en: 'Drop off date',
        fr: 'Date de dépôt'
    },
    dashboardHistory25: {
        en: 'This is from the lost & found',
        fr: 'Ceci provient des "perdus et retrouvés"'
    },
    dashboardHistory26: {
        en: 'Awaiting the driver',
        fr: 'En attente du chauffeur'
    },
    dashboardHistory27: {
        en: 'Cancelled by {{cancelledBy}}',
        fr: 'Annulé par {{cancelledBy}}'
    },
    dashboardHistory28: {
        en: 'Counting items',
        fr: 'Décompte des contenants'
    },
    dashboardHistory29: {
        en: 'Awaiting counts',
        fr: 'En attendes des décomptes'
    },
    dashboardHistory30: {
        en: 'We encountered the following issues with your pickup',
        fr: 'Nous avons rencontré les problèmes suivants avec votre collecte'
    },
    dashboardHistory31: {
        en: 'Receipt',
        fr: 'Reçu'
    },
    dashboardHistory32: {
        en: 'Created by',
        fr: 'Créer par'
    },
    dashboardHistory33: {
        en: 'Adjustments / {{{quickDropType}}} / {{walkInType}}',
        fr: 'Ajustements / {{{quickDropType}}} / {{walkInType}}'
    },
    dashboardHistory34: {
        en: 'Tax receipt will be issued by',
        fr: 'Le reçu fiscal sera émis par'
    },
    dashboardHistory35: {
        en: 'Tax receipt will be issued soon.',
        fr: 'Un reçu d’impôt sera bientôt émis.'
    },
    dashboardHistory36: {
        en: 'You are not eligible for a tax receipt until your total donation amount surpasses {{amount}}',
        fr:
            'Vous n’êtes pas admissible à un reçu fiscal tant que le montant total de votre don n’a pas dépassé {{amount}}'
    },
    dashboardHistory37: {
        en: 'Tax receipt issued for this donation',
        fr: 'Reçu fiscal émis pour ce don'
    },
    dashboardHistory38: {
        en: 'Please contact {{{charity}}} - {{charityRegistrationNumber}} for tax receipt info',
        fr:
            'Veuillez contacter {{{charity}}} - {{charityRegistrationNumber}} pour obtenir des informations sur les reçus fiscaux'
    },
    dashboardHistory39: {
        en: 'You did not request a tax receipt',
        fr: 'Vous n’avez pas demandé de reçu fiscal'
    },
    dashboardHistory40: {
        en: 'Tax receipt requested',
        fr: 'Reçu fiscal demandé'
    },
    dashboardHistory41: {
        en: 'Donation Breakdown',
        fr: 'Répartition des dons'
    },
    dashboardHistory42: {
        en: 'Tip left for driver',
        fr: 'Pourboire laissé pour le chauffeur'
    },

    dashboardHistory46: {
        en: 'Pickup booked for',
        fr: 'Ramassage réservé pour'
    },
    dashboardHistory47: {
        en: 'Recycling bags',
        fr: 'Sacs de contenants consignés'
    },
    dashboardHistory48: {
        en: 'Clothing bags',
        fr: 'Sacs de vêtements'
    },
    dashboardHistory49: {
        en: 'Electronic kgs',
        fr: "Kgs d'électronique"
    },
    dashboardHistory50: {
        en: 'To be picked up by {{name}} for {{collector}}',
        fr: 'À récupérer par {{name}} pour {{collector}}'
    },
    dashboardHistory51: {
        en: 'Aborted by {{name}} ({{collector}})',
        fr: 'Abandonné par {{name}} ({{collector}})'
    },
    dashboardHistory52: {
        en: 'Distance',
        fr: 'Distance'
    },
    dashboardHistory53: {
        en: 'Received by driver. Will be counted within 48 hours',
        fr: 'Reçu par le chauffeur. Sera compté dans les 48 heures'
    },
    dashboardHistory54: {
        en: 'Received by driver. Will be processed within 7 business days.',
        fr: 'Reçu par le chauffeur. Sera traité dans les 7 jours ouvrables.'
    },
    dashboardHistory55: {
        en: 'Please check back later for updated values.',
        fr: 'Veuillez vérifier plus tard pour les valeurs mises à jour.'
    },
    dashboardHistory56: {
        en: 'Dropped Off',
        fr: 'Déposé'
    },
    dashboardHistory56Exp: {
        en: 'Scanned',
        fr: 'Numérisé'
    },
    dashboardHistory57: {
        en: 'Rescheduled to {{date}}',
        fr: 'Reporté à {{date}}'
    },
    dashboardHistory58: {
        en: 'Pickup Details',
        fr: 'Détails du ramassage'
    },
    dashboardHistory59: {
        en: 'Processing Details',
        fr: 'Détails du traitement'
    },
    dashboardHistory60: {
        en: 'Deposited',
        fr: 'se déposer'
    },
    dashboardHistory61: {
        en: 'Cancelled',
        fr: 'Annulé'
    },
    dashboardHistory62: {
        en: 'Drop Off Details',
        fr: 'Détails du dépôt'
    },
    dashboardHistory63: {
        en: '{{taxName}} on deposits',
        fr: '{{taxName}} sur les dépôts'
    },
    dashboardHistory64: {
        en: '{{taxName}} on fees',
        fr: '{{taxName}} sur les frais'
    },
    dashboardHistory65: {
        en: 'Payment Processed',
        fr: 'Paiement traité'
    },
    dashboardHistory66: {
        en:
            'We had troubles completing your collection. Issue: {{reason}}. Please double check your address, if you suspect your bags have been stolen, please contact local authorities. For future collections hide your bags from walking traffic.',
        fr:
            'Nous avons eu du mal à terminer votre collection. Problème : {{reason}}. Veuillez vérifier votre adresse. Si vous pensez que vos sacs ont été volés, veuillez contacter les autorités locales. Pour les futures collections, cachez vos sacs du trafic piétonnier.'
    },
    dashboardHistory67: {
        en: `On {{date}}, at {{time}}, you dropped off`,
        fr: `Le {{date}}, à {{time}}, vous avez déposé`
    },
    dashboardHistory68: {
        en: 'On {{date}}, at {{time}}, we collected',
        fr: `Le {{date}}, à {{time}}, nous avons collecté`
    },
    dashboardHistory69: {
        en: ` you printed {{count}} labels at {{{location}}}`,
        fr: ` vous avez imprimé {{count}} auto-collants à {{{location}}}`
    },
    dashboardHistory70: {
        en: 'Total {{taxName}}',
        fr: '{{taxName}} totale'
    },
    dashboardHistory71: {
        en:
            'You are scheduled for collection. The driver will arrive between 8am and 5pm. For the exact time of arrival, please check the app or website on the morning of your collection.',
        fr: `Vous êtes programmé pour la collecte. Le chauffeur arrivera entre 8h et 17h. Pour connaître l'heure exacte d'arrivée, veuillez vérifier l'application ou le site web le matin de votre collecte.`
    },
    dashboardHistory72: {
        en: 'Your bags were processed on',
        fr: `Vos sacs ont été traités le`
    },
    dashboardHistory73: {
        en: 'Your account balance has been adjusted due to a {{adjustmentReason}}',
        fr: `Le solde de votre compte a été ajusté en raison d'un(e) {{adjustmentReason}}`
    },
    dashboardHistory73b: {
        en: 'Your account balance has been adjusted',
        fr: `Le solde de votre compte a été ajusté`
    },
    dashboardHistory74: {
        en: 'Total {{count}}',
        fr: '{{count}} totale'
    },
    dashboardHistory75: {
        en: 'Reference: {{referenceNote}}',
        fr: `Référence: {{referenceNote}}`
    },
    switchAcc1: {
        en: 'Heads up! You have multiple accounts',
        fr: 'Attention! Vous avez plusieurs comptes'
    },
    switchAcc2: {
        en:
            'All charity accounts must have a personal account linked to it. Your charity account is separate from your personal account. You can switch between your accounts from the side menu.',
        fr:
            "Tous les comptes de charité doivent être liés à un compte personnel. Votre compte de charité est distinct de votre compte personnel. Vous pouvez passer d'un compte à l'autre à partir du menu latéral."
    },
    charityType1: {
        en: 'Select Account Type',
        fr: 'Sélectionnez le type de compte'
    },
    charityType2: {
        en:
            'We require some additional information about your account. Please specify which type of account applies to you.',
        fr:
            "Nous avons besoin de quelques informations supplémentaires sur votre compte. Veuillez préciser quel type de compte s'applique à vous."
    },
    environmentalStats1: {
        en:
            "I've spared {{landfillStat}} kg from landfill, saved {{energyStat}} kWh of energy, and prevented {{emissionStat}} kg of greenhouse gas emissions by recycling with {{brand}}, and I think you should too!",
        fr:
            "J'ai épargné {{landfillStat}} kg du site d'enfouissement, économisé {{energyStat}} kWh d'énergie et évité {{emissionStat}} kg d'émissions de gaz à effet de serre en recyclant avec {{brand}}, et je pense que vous devriez aussi!"
    },
    locationFilter: {
        en: 'Filter By Location',
        fr: 'Filtrer par emplacement'
    },
    dateFilter: {
        en: 'Filter By Date',
        fr: 'Filtrer par date'
    },
    daily: {
        en: 'Daily',
        fr: 'tous les jours'
    },
    weekly: {
        en: 'Weekly',
        fr: 'hebdomadaire'
    },
    monthly: {
        en: 'Monthly',
        fr: 'tous les mois'
    },
    quarterly: {
        en: 'Quarterly',
        fr: 'tous les trois mois'
    },
    allTime: {
        en: 'All Time',
        fr: 'tout le temps'
    }
};

const charitySnackBarTranslations = {
    charitySnackbar1: {
        en: 'Image copied to clipboard',
        fr: 'Image copiée dans le presse-papiers'
    },
    charitySnackbar2: {
        en: 'Image saved to documents',
        fr: 'Image enregistrée dans des documents'
    },
    charitySnackbar3: {
        en: 'Error saving file',
        fr: 'Erreur lors de l’enregistrement du fichier'
    },
    charitySnackbar4: {
        en: 'Error while copying to clipboard',
        fr: 'Erreur lors de la copie dans le Presse-papiers'
    },
    charitySnackbar5: {
        en: 'Code copied to your clipboard.',
        fr: 'Code copié dans votre presse-papiers.'
    },
    charitySnackbar6: {
        en: 'Email sent successfully',
        fr: 'Courriel envoyé avec succès'
    },
    charitySnackbar7: {
        en: 'Promo code copied to your clipboard',
        fr: 'Code promotionnel copié dans votre presse-papiers'
    },
    charitySnackbar8: {
        en: 'Successfully applied for a promo code',
        fr: 'Demande de code promotionnel réussie'
    },
    charitySnackbar9: {
        en: 'Are you sure you want to apply for a promo code.',
        fr: 'Êtes-vous sûr de vouloir demander un code promotionnel?'
    },
    charitySnackbar10: {
        en: 'High resolution image successfully updated!',
        fr: 'Image haute résolution mise à jour avec succès!'
    },
    charitySnackbar11: {
        en: 'Successfully submitted task for verification',
        fr: 'Tâche soumise avec succès pour vérification'
    },
    charitySnackbar12: {
        en: 'Error submitting task for verification',
        fr: 'Erreur lors de l’envoi de la tâche pour vérification'
    },
    charitySnackbar13: {
        en: 'Are you sure you want to submit an order for {{type}}?',
        fr: 'Êtes-vous sûr de vouloir passer une commande pour {{type}}'
    },
    charitySnackbar14: {
        en: 'Minimum donation threshold cannot be negative.',
        fr: 'Le seuil minimum de don ne peut pas être négatif.'
    },
    charitySnackbar15: {
        en: '{{name}} saved to documents folder',
        fr: '{{name}} enregistré dans le dossier documents'
    },
    charitySnackbar16: {
        en: 'Cannot download file',
        fr: 'Impossible de télécharger le fichier'
    },
    charitySnackbar17: {
        en: 'Are you sure you want to {{customText}}?',
        fr: 'Êtes-vous sûr de vouloir {{customText}}?'
    },
    charitySnackbar18: {
        en: 'Error setting account type',
        fr: 'Erreur lors de la définition du type de compte'
    }
};

const charitySelectTranslations = {
    charitySelect1: {
        en: 'Enter your address',
        fr: 'Entrez votre adresse'
    },
    charitySelect2: {
        en:
            'Tax receipts can only be issued to conventionally registered customer accounts. Tax receipt preference can be modified in account settings',
        fr:
            'Les reçus fiscaux ne peuvent être émis que sur des comptes clients enregistrés de manière conventionnelle. La préférence de reçu fiscal peut être modifiée dans les paramètres du compte'
    },
    charitySelect3: {
        en:
            'Use the search bar below to find your cause of choice! Some {{organization}}s allow donations to a specific team or group.',
        fr:
            'Utilisez la barre de recherche ci-dessous pour trouver la cause de votre choix! Certains {{organisation}} autorisent les dons à une équipe ou à un groupe spécifique.'
    },
    charitySelect4: {
        en: 'Change donation preference?',
        fr: 'Changer la préférence de don?'
    },
    charitySelect5: {
        en: 'Would you like to set your donation preference to',
        fr: 'Souhaitez-vous définir votre préférence de don à'
    },
    charitySelect6: {
        en: 'Your current account balance will be unaffected.',
        fr: 'Le solde de votre compte courant ne sera pas affecté.'
    }
};

const charityStatsTranslations = {
    charityStats1: {
        en: '{{organization}} Statistics',
        fr: 'Statistiques {{organization}}'
    },
    charityStats2: {
        en: 'Current donors',
        fr: 'Donateurs actuels'
    },
    charityStats3: {
        en: '# of shares on social media',
        fr: 'nombre de partages sur les réseaux sociaux'
    },
    charityStats4: {
        en: 'Featured {{organization}}',
        fr: '{{organization}} Affilié'
    },
    charityStats5a: {
        en: 'Fundraising is a lot of work. We have created the ',
        fr: 'La collecte de fonds représente beaucoup de travail. Nous avons créé la section '
    },
    charityStats5b: {
        en: 'Featured {{organization}}',
        fr: '{{organization}} Affilié'
    },
    charityStats5c: {
        en:
            ' section to help with this. {{organization}} that are actively promoting themselves using {{brand}} will be showcased as a Featured {{organization}} on the dashboard of all users and on the registration page! Follow the steps below to become a Featured {{organization}}',
        fr:
            ' pour vous aider. {{organization}} qui font activement la promotion en utilisant {{brand}} sera présenté en tant que {{organization}} en vedette sur le tableau de bord de tous les utilisateurs et sur la page d’inscription! Suivez les étapes ci-dessous pour devenir un {{organization}} en vedette'
    },
    charityStats6a: {
        en: 'Add a link to ',
        fr: 'Ajouter un lien vers '
    },
    charityStats6b: {
        en: " on your website (bonus points if it's on the home page)",
        fr: ' sur votre site web (points bonus s’il est sur la page d’accueil)'
    },
    charityStats7: {
        en: 'Follow our social media accounts',
        fr: 'Suivez nous sur les médias sociaux'
    },
    charityStats8: {
        en: 'Share our posts',
        fr: 'Partager nos publications'
    },
    charityStats9: {
        en: 'Tag us in your posts',
        fr: 'Identifiez-nous dans vos publications'
    },
    charityStats10: {
        en: 'Give us a 5 star review on',
        fr: 'Donnez-nous un avis 5 étoiles sur'
    },
    charityStats11: {
        en: 'We will return the favor and like/share/rate your social media accounts!',
        fr: 'Nous vous rendrons la pareille et aimerons / partagerons / évaluerons vos comptes de médias sociaux!'
    },
    charityStats12: {
        en: 'Share your {{organization}} for more donations!',
        fr: 'Partagez votre {{organization}} pour plus de dons!'
    },
    charityStats13: {
        en: 'Start Virtual Bottle Drive',
        fr: 'Démarrer une collecte virtuelle de contenants'
    }
};

const growthPlanTranslations = {
    growthPlan1: {
        en: 'Your Growth Plan Progress',
        fr: 'Progression de votre plan de croissance'
    },
    growthPlan2: {
        en: 'You have {{count}} out of {{total}} unexpired tasks in your growth plan completed!',
        fr: 'Vous avez {{count}} sur {{total}} des tâches non expirées dans votre plan de croissance terminées !'
    },
    growthPlan3: {
        en: '{{count}} of your tasks are expired, please resubmit them.',
        fr: '{{count}} de vos tâches ont expiré, veuillez les soumettre à nouveau.'
    },
    growthPlan4: {
        en: 'Congratulations, You are currently a featured {{organization}}!',
        fr: 'Félicitations, vous êtes actuellement une {{organization}} en vedette !'
    },
    growthPlan5: {
        en: 'Complete {{count}} more task(s) to qualify as a featured {{organization}}!',
        fr: "Effectuez {{count}} tâche(s) supplémentaire(s) pour vous qualifier en tant qu'{{organization}} !"
    },
    growthPlan6: {
        en: 'How To Become Featured',
        fr: 'Comment devenir vedette'
    }
};

const growthTranslations = {
    growthGeneral1: {
        en: 'State',
        fr: 'État'
    },
    growthGeneral2: {
        en: 'Approved',
        fr: 'Approuvé'
    },
    growthGeneral3: {
        en: 'Rejected',
        fr: 'Rejeté'
    },
    growthGeneral4: {
        en: 'Expired',
        fr: 'Expiré'
    },
    growthGeneral5: {
        en: 'Expires',
        fr: 'Expire'
    },
    growthGeneral6: {
        en: 'Submitted',
        fr: 'Soumis'
    },
    growthGeneral7: {
        en: 'Your Donation Link',
        fr: 'Votre lien de don'
    },
    growthGeneral8: {
        en: 'Why It Works',
        fr: 'Pourquoi ça marche'
    },
    growthGeneral9: {
        en: 'What To Do',
        fr: 'Que faire'
    },
    growthGeneral10: {
        en: 'Being Reviewed',
        fr: "En cours d'examen"
    },
    growthGeneral11: {
        en: 'Order succesfully created!',
        fr: 'Commande créée avec succès !'
    },
    growthGeneral12: {
        en: 'Expired',
        fr: 'Expire'
    },
    growthGeneral13: {
        en: 'Put your donation link in your bio.',
        fr: 'Mettez votre lien de don dans votre bio.'
    },
    growthGeneral14: {
        en: 'Follow & Like our social media page:',
        fr: 'Suivez et aimez notre page de médias sociaux:'
    },
    growthGeneral15: {
        en: "Share an interesting post from our account (we'll return the favor!)",
        fr: 'Partagez une publication intéressante de notre compte (nous vous rendrons la pareille !)'
    },
    growthGeneral16: {
        en: 'Make your own posts using some of our examples:',
        fr: 'Créez vos propres publications en utilisant certains de nos exemples :'
    },
    growthGeneral17: {
        en: 'Once Approved',
        fr: 'Une fois approuvé'
    },
    growthGeneral18: {
        en: 'Your submission was rejected for the following reason(s):',
        fr: 'Votre souscription a été rejetée pour les raisons suivantes :'
    },
    growthGeneral19: {
        en: 'Your donation link will appear here once approved',
        fr: 'Votre lien de don apparaîtra ici une fois approuvé'
    },
    growthGeneral20: {
        en: 'Referral link copied to your clipboard.',
        fr: 'Lien de référence copié dans votre presse-papiers.'
    },
    growthGeneral21: {
        en: 'Send Email',
        fr: 'Envoyer courriel'
    },
    growthGeneral22: {
        en: 'you are no longer featured',
        fr: 'vous n’êtes plus en vedette'
    },
    growthGeneral23: {
        en: 'subscription ended/disabled by {{brand}}',
        fr: 'abonnement terminé/désactivé par {{brand}}'
    },
    growthGeneral24: {
        en: 'Click the button below to apply:',
        fr: 'Cliquez sur le bouton ci-dessous pour postuler :'
    },
    growthGeneral25: {
        en: 'You have completed {{count}} of 5 growth items! Please complete {{remaning}} more item your promocode.',
        fr:
            'Vous avez complété {{count}} de 5 éléments de croissance ! Veuillez compléter {{remaning}} plus d’article votre code promotionnel.'
    },
    growthGeneral26: {
        en: 'Active',
        fr: 'Actif'
    },
    growthGeneral27: {
        en: 'Not Active',
        fr: 'non actif'
    },
    growthGeneral28: {
        en: 'This promo code is active. Customers will be able to use it to donate to you.',
        fr: 'Ce code promotionnel est actif. Les clients pourront l’utiliser pour vous faire un don.'
    },
    growthGeneral29: {
        en:
            'This promo code is no longer active ({{inactivePromoReason}}). You will only receive the standard rate for deposits donated.',
        fr:
            'Ce code promotionnel n’est plus actif ({{inactivePromoReason}}). Vous ne recevrez que le taux standard pour les dépôts donnés.'
    },
    growthGeneral30: {
        en: "Send us an email using the form below to let us know you're interested:",
        fr:
            'Envoyez-nous un courriel en utilisant le formulaire ci-dessous pour nous faire savoir que vous êtes intéressé:'
    },
    growthGeneral31: {
        en: 'Order {{type}}',
        fr: 'Commande {{type}}'
    },
    growthGeneral32: {
        en: 'Resubmit Verification',
        fr: 'Resoumettre la vérification'
    },
    growthGeneral33: {
        en: 'Growth plan',
        fr: 'Plan de croissance'
    },
    growthGeneral34: {
        en: 'Please Verify Your Submission With A Link',
        fr: 'Veuillez vérifier votre souscription avec un lien'
    },
    growthGeneral35: {
        en: 'Please Upload A Photo',
        fr: 'Veuillez télécharger une photo'
    },
    growthGeneral36: {
        en: 'Photo',
        fr: 'Photo'
    },
    growthGeneral37: {
        en:
            'By registering through this referral form your returns will be donated to this {{organization}} by default (you can always change it later)',
        fr:
            'En vous inscrivant via ce formulaire de référence, vos retours seront donnés à cette {{organisation}} par défaut (vous pouvez toujours le modifier plus tard)'
    },
    growthGeneral38: {
        en: 'An address is required when requesting a tax receipt',
        fr: 'Une adresse est requise lors de la demande d’un reçu fiscal'
    },
    growthGeneral39: {
        en: 'Submit Verification',
        fr: 'Soumettre la vérification'
    },
    growthGeneral40: {
        en: 'Please provide the following information',
        fr: "S'il vous plaît fournir les informations suivantes"
    },
    submissionStatus: {
        en: 'Submission Status',
        fr: 'Statut de la soumission'
    },
    growthVerifyTask1: {
        en: 'Verify Task Complete',
        fr: 'Vérifier que la tâche est terminée'
    },
    growthVerifyTask2: {
        en: 'Provide proof of the completed task with a {{obj}}',
        fr: 'Fournir une preuve de la tâche terminée avec un lien'
    },
    growthVerifyTask3: {
        en: 'link',
        fr: 'lien'
    },
    growthVerifyTask4: {
        en: 'picture',
        fr: 'photo'
    },
    growthVerifyTask5: {
        en: 'link or picture',
        fr: 'lien ou photo'
    },
    growthVerifyTask6: {
        en: 'Please come back once approved',
        fr: 'Prière de revenir une fois approuvé'
    },
    growthPostInfo1: {
        en: 'Copy a variation of the code below and',
        fr: 'Copiez une variante du code ci-dessous et'
    },
    growthPostInfo2: {
        en: 'Put your link in your menu',
        fr: 'Mettez votre lien dans votre menu'
    },
    growthPostInfo3: {
        en: 'Put your link on your homepage',
        fr: "Mettez votre lien sur votre page d'accueil"
    },
    growthPostInfo4: {
        en: 'Code',
        fr: 'Code'
    },
    growthPostInfo5: {
        en: 'Preview',
        fr: 'Aperçu'
    },
    growthPostInfo6: {
        en: 'Image',
        fr: 'Image'
    },
    growthPostInfo7: {
        en: 'Text',
        fr: 'Texte'
    },
    growthPostInfo8: {
        en: 'DONATE YOUR RECYCLING (FREE PICKUP)',
        fr: 'DONNEZ VOS CONTENANTS (COLLECTE GRATUITE)'
    },
    growthPostInfo9: {
        en: 'Please come back once approved',
        fr: 'Veuillez revenir une fois approuvé'
    },
    growthNewsLetters1: {
        en: 'Make your own newsletter using this example:',
        fr: 'Créez votre propre infolettre en utilisant cet exemple :'
    },
    growthNewsLetters2: {
        en: "We've partnered with {{brand}}",
        fr: 'Nous avons établi un partenariat avec {{brand}}'
    },
    growthNewsLetters3: {
        en:
            'What is {{brand}}? A door-to-door bottle collection service that makes refundable recycling and fundraising easier than 1,2,3',
        fr:
            "Qu'est-ce que {{brand}}? Un service de dépôt volontaire et de collecte porte à porte de contenants consignés qui rend le remboursement de consigne et la collecte de fonds plus faciles que 1,2,3"
    },
    growthNewsLetters4: {
        en: 'Download the app and book a pickup using {{{website}}}',
        fr: "Téléchargez l'application et réservez un ramassage en utilisant {{{website}}}"
    },
    growthNewsLetters5: {
        en: 'Place your bags outside',
        fr: "Déposez vos sacs à l'extérieur"
    },
    growthNewsLetters6: {
        en: 'Receive a refund or donate to us!',
        fr: 'Recevez un remboursement ou faites-nous un don !'
    },
    growthNewsLetters7: {
        en:
            '{{brand}} will allow everyone to donate directly to our {{organization}} with ease. Spread the word by sharing our {{brand}} posts on social media!',
        fr:
            '{{brand}} permettra à chacun de faire un don directement à notre organisation en toute simplicité. Faites passer le mot en partageant nos publications {{brand}} sur les réseaux sociaux !'
    },
    growthTwitter1: {
        en:
            "Hey (insert city)! We've partnered with {{socialMediaHandle}}, a bottle collection service that will let you donate your bottle refund directly to us! They'll even pick them up for you.",
        fr:
            'Hey (insérer la ville)! Nous avons établi un partenariat avec {{socialMediaHandle}}, un service de collecte decontenants consignés qui vous permettra de nous faire directement don de votre remboursement consigne! Ils viendront même les chercher pour vous.'
    },
    growthTwitter2: {
        en: 'Download the app today and select our charity upon refund.',
        fr:
            "Téléchargez l'application aujourd'hui et sélectionnez notre organisme de bienfaisance lors du remboursement."
    },
    growthTwitter3: {
        en:
            'Want an easy way to give back to us? Download {{socialMediaHandle}} and donate your bottle refund to us! They even pick them up for you!',
        fr:
            'Vous voulez un moyen facile de nous redonner? Téléchargez {{socialMediaHandle}} et faites-nous don de votre remboursement de contenants consignés! Ils les récupèrent même pour vous !'
    },
    growthTwitter4: {
        en: 'We’ve partnered with {{socialMediaHandle}} to take your stinky bottles off your hands.',
        fr: 'Nous nous sommes associés à {{socialMediaHandle}} pour vous débarrasser de vos contenants tout collants!'
    },
    growthTwitter5: {
        en: 'Download the app and start donating your bottles to us today!',
        fr: "Téléchargez l'application et commencez à nous faire don de vos contenants consignés dès aujourd'hui!"
    },
    growthTwitter6: {
        en:
            "{{socialMediaHandle}} is an easy way to give! Place your bottles outside for a pickup and we'll receive the refund as a donation! Download the app today",
        fr:
            "{{socialMediaHandle}} est un moyen facile de donner! Placez vos contenants consigés à l'extérieur pour un ramassage et nous recevrons le remboursement de la consigne sous forme de don! Téléchargez l'application aujourd'hui"
    },
    growthInstagram1: {
        en:
            'Have bags of bottles lying around? Donate them to us using {{socialMediaHandle}}. Download the app using the link in our bio.',
        fr:
            "Vous avez des sacs de contenants consignés qui traînent? Faites-nous en don en utilisant {{socialMediaHandle}}. Téléchargez l'application en utilisant le lien dans notre bio."
    },
    growthInstagram2: {
        en:
            "Looking for ways to help us out? We've partnered with {{socialMediaHandle}}! Download the app, book a bottle pickup and donate your refund directly to us. Link in bio to download today!",
        fr:
            "Vous cherchez des moyens de nous aider? Nous avons établi un partenariat avec {{socialMediaHandle}}! Téléchargez l'application, réservez un ramassage de contenants consigés et faites-nous directement parvenir votre remboursement de consigne. Lien dans la bio à télécharger dès aujourd'hui!"
    },
    growthInstagram3: {
        en:
            "Looking for ways to help us out? We've partnered with {{socialMediaHandle}}! Download the app, book a bottle pickup and donate your refund directly to us. Link in bio to download today!",
        fr:
            "Vous cherchez des moyens de nous aider? Nous avons établi un partenariat avec {{socialMediaHandle}}! Téléchargez l'application, réservez un ramassage de contenants consignés et faites-nous directement parvenir votre remboursement de consigne. Lien dans la bio à télécharger dès aujourd'hui !"
    },
    growthInstagram4: {
        en:
            "We need you bottles! Download {{socialMediaHandle}} and start donating your bottles directly to us! They'll pick them up for you, make sure you select {{{charity}}} upon refund. Link in bio to download today.",
        fr:
            "Nous avons besoin de vos contenants consignés! Téléchargez {{socialMediaHandle}} et commencez à nous faire don de vos contenants directement! Ils les récupéreront pour vous, assurez-vous de sélectionner {{{charity}}} lors du remboursement. Lien dans la bio à télécharger aujourd'hui."
    },
    growthFacebook1: {
        en:
            "Looking for new ways to help us out? We've partnered with {{socialMediaHandle}}. Download the app, book a pickup, place your bags and donate your refund directly to {{{charity}}}.",
        fr:
            "Vous cherchez de nouvelles façons de nous aider? Nous avons établi un partenariat avec {{socialMediaHandle}}. Téléchargez l'application, réservez un ramassage, placez vos sacs et reversez votre remboursement directement à {{{charity}}}."
    },
    growthFacebook2: {
        en:
            'Do you have bottles piling up? Let {{socialMediaHandle}} take them off your hands! Download the app today and donate your bottle refund directly to us!',
        fr:
            "Vous avez des contenants consignés qui s'entassent? Laissez {{socialMediaHandle}} vous en débarrasser! Téléchargez l'application aujourd'hui et faites-nous directement don de votre remboursement de consigne!"
    },
    growthFacebook3: {
        en:
            "We've partnered with {{socialMediaHandle}}! Download the app and start donating your bottle refunds directly to us.",
        fr:
            "Nous avons établi un partenariat avec {{socialMediaHandle}}! Téléchargez l'application et commencez à nous faire directement don de vos remboursements de consigne."
    },
    growthFacebook4: {
        en:
            "We want your bottles! Use {{socialMediaHandle}} to start donating your bottles directly to us! You won't even have to leave the house. Download the app and select {{{charity}}} upon refund.",
        fr:
            "On veut vos contenants consignés! Utilisez {{socialMediaHandle}} pour commencer à nous faire don de vos contenants directement! Vous n'aurez même pas à quitter la maison. Téléchargez l'application et sélectionnez {{{charity}}} lors du remboursement."
    },
    growthLinkedIn1: {
        en: '{{{charity}}} is now accepting bottle donations through {{socialMediaHandle}}!',
        fr: '{{{charity}}} accepte désormais les dons de contenants consignés via {{socialMediaHandle}} !'
    },
    growthLinkedIn2: {
        en: 'You can support {{{charity}}} today by using our {{socialMediaHandle}} referral link ->',
        fr:
            "Vous pouvez soutenir {{{charity}}} aujourd'hui en utilisant notre lien de référence {{socialMediaHandle}} >"
    },
    growthLinkedIn3: {
        en:
            'When you sign up for {{socialMediaHandle}} with this referral link, this will pledge your bottles toward supporting our cause! The great part, you can either download the {{socialMediaHandle}} on your iPhone or Android or alternatively get started on your computer today at {{brand}}!',
        fr:
            "Lorsque vous vous inscrivez à {{socialMediaHandle}} avec ce lien de référence, cela engagera vos contenants à soutenir notre cause! La partie intéressante, vous pouvez soit télécharger {{socialMediaHandle}}App sur votre iPhone, Android ou sur votre ordinateur dès aujourd'hui sur {{brand}}!"
    },
    growthLinkedIn4: {
        en:
            '#consignaction #charity #nonprofit #giveback #donate #community #fundraising #recycle #reuse #environment #sustainability ',
        fr: '#consignaction'
    },
    growthLinkedIn4b: {
        en:
            '#consignaction #charity #nonprofit #giveback #donate #community #fundraising #recycle #reuse #environment #sustainability ',
        fr: '#consignaction'
    },
    growthLinkedIn5: {
        en:
            'Hey Hey! We have teamed up with {{socialMediaHandle}} and now you can support us by donating your bottles through their App!',
        fr:
            'Hey! Nous nous sommes associés à {{socialMediaHandle}} et vous pouvez maintenant nous soutenir en faisant don de vos contenants consignés via leur application !'
    },
    growthLinkedIn6: {
        en:
            'Get started today by downloading the App from the Apple or Android store and booking a pickup using our referral link ->',
        fr:
            "Commencez dès aujourd'hui en téléchargeant l'application depuis la boutique Apple ou Android et en réservant un ramassage à l'aide de notre lien de référence ->"
    },
    growthLinkedIn7: {
        en: 'By using our referral code, your bottle funds will go directly to supporting our cause!',
        fr:
            'En utilisant notre code de référence, les montants de consigne de vos contenants consignés serviront directement à soutenir notre cause!'
    },
    growthLinkedIn8: {
        en:
            'We need your bottles! Download the {{socialMediaHandle}} App today from the Apple App or Google Play Store and start donating your bottles directly to us!',
        fr:
            "Nous avons besoin de vos contenats consignés! Téléchargez l'application {{socialMediaHandle}} dès aujourd'hui depuis l'application Apple ou Google Play Store et commencez à nous faire directement don de vos contenants!"
    },
    growthLinkedIn9: {
        en:
            'The great part about {{socialMediaHandle}} is they will pickup your bottles and make sure the funds go toward supporting our cause!',
        fr:
            "L'avantage de {{socialMediaHandle}} est qu'ils viendront chercher vos contenants et s'assureront que les fonds serviront à soutenir notre cause!"
    },
    growthLinkedIn10: {
        en: 'Get started today and use the referral link here ->',
        fr: "Commencez dès aujourd'hui et utilisez le lien de référence ici ->"
    },
    growthLinkedIn11: {
        en:
            'When you use our referral link and book your first pickup, the funds will automatically be set to be donated to {{{charity}}}!',
        fr:
            'Lorsque vous utilisez notre lien de référence et réservez votre premier ramassage, les fonds seront automatiquement versés à {{{charity}}} !'
    },
    growthLinkedIn12: {
        en: "Did you know Albertan's helped raise $500,000 through {{{socialMediaHandle}}}?!",
        fr: 'Saviez-vous que les québécois ont aidé à amasser 500 000 $ grâce à {{{socialMediaHandle}}} ?!'
    },
    growthLinkedIn13: {
        en:
            '{{{charity}}} has partnered up with {{socialMediaHandle}} to give you the option to support us by donating your bottles!',
        fr:
            "{{{charity}}} s'est associé à {{socialMediaHandle}} pour vous donner la possibilité de nous soutenir en faisant don de vos contenants consignés!"
    },
    growthLinkedIn14: {
        en:
            'Getting signed up is easy! Just use our referral link below and your first pickup will be set to donate the funds toward supporting our cause!',
        fr:
            "S'inscrire, c'est facile! Utilisez simplement notre lien de référence ci-dessous et votre premier ramassage sera configuré pour faire don des fonds pour soutenir notre cause!"
    },
    growthLinkedIn15: {
        en: 'Here is our referral link! ->',
        fr: 'Voici notre lien de référence! ->'
    },
    growthLinkedIn16: {
        en: 'Get started today on your computer or download the {{brand}} App on your Apple or Android device!',
        fr:
            "Commencez dès aujourd'hui sur votre ordinateur ou téléchargez l'application {{brand}} sur votre appareil Apple ou Android !"
    },
    growBlog1: {
        en: 'Write a blog post using the examples given below:',
        fr: 'Rédigez un article de blog en utilisant les exemples ci-dessous:'
    },
    growBlog2: {
        en: '{{{charity}}} has partnered with {{brand}}',
        fr: "{{{charity}}} s'est associé à {{brand}}"
    },
    growBlog3: {
        en: 'Recycling Donations to {{{charity}}} with {{brand}}',
        fr: 'Dons de recyclage à {{{charity}}} avec {{brand}}'
    },
    growBlog4: {
        en: 'Donate to {{{charity}}} with {{brand}}',
        fr: 'Faites un don à {{{charity}}} avec {{brand}}'
    },
    growBlog5: {
        en: '{{{charity}}} wants your bottles & cans! {{brand}} Fundraising',
        fr: '{{{charity}}} veut vos contenants consignés! Collecte de fonds de {{brand}}'
    },
    growBlog6: {
        en: 'Make sure you put your link down on your page so people can easily donate',
        fr: 'Assurez-vous de mettre votre lien sur votre page afin que les gens puissent facilement faire un don'
    },
    growOrder1: {
        en:
            'Press the order button below. If prompted, upload a high resolution logo for us to use. We will create the proofs (for free!) and email you the results to be printed. Once printed, submit a picture below to verify it.',
        fr:
            'Appuyez sur le bouton de commande ci-dessous. Si vous y êtes invité, téléchargez un logo haute résolution que nous pourrons utiliser. Nous créerons les épreuves (gratuitement!) et vous enverrons par e-mail les résultats à imprimer. Une fois imprimé, soumettez une image ci-dessous pour le vérifier.'
    },
    growOrder2: {
        en:
            'Press the order button below. If prompted, upload a high resolution logo for us to use. We will generate and email you the proofs to be printed. Once printed, submit a picture below to verify.',
        fr:
            'Appuyez sur le bouton de commande ci-dessous. Si vous y êtes invité, téléchargez un logo haute résolution que nous utiliserons. Nous générerons et vous enverrons par e-mail les épreuves à imprimer. Une fois imprimé, soumettez une image ci-dessous pour vérifier.'
    },
    growOrder3: {
        en: 'Press "Order Bags" above and fill out the information.',
        fr: 'Appuyez sur "Commander des sacs" ci-dessus et remplissez les informations.'
    },
    growOrder4: {
        en:
            'Order eye catching door hangers about how people can book a free pickup for their recycling and donate it directly to you using {{brand}}. Once printed, submit to verify.',
        fr:
            "Commandez des accroches-porte expliquant sur la façon dont les gens peuvent réserver un ramassage gratuit pour leurs contenants consignés et vous en faire don directement à l'aide de {{brand}}. Une fois reçu, soumettez-le pour vérification."
    },
    growOrder5: {
        en:
            'Call / Email any of the news outlets in your area letting them know you are looking for recycling donations to help your cause.',
        fr:
            'Appelez / envoyez un courriel aux médias de votre région pour leur faire savoir que vous recherchez des dons de recyclage pour aider votre cause.'
    },
    growOrder6: {
        en: 'This is your promo code:',
        fr: 'Voici votre code promotionnel:'
    },
    growOrder7: {
        en: 'Copy Promo Code',
        fr: 'Copier le code promotionnel'
    },
    growOrder8: {
        en:
            'Distribute this to all your donors. Customers can only use this promocode when donating to you and you will recieve more deposits from them when they do.',
        fr:
            "Distribuez-le à tous vos donateurs. Les clients ne peuvent utiliser ce code promotionnel que lorsqu'ils vous font un don et vous recevrez plus de dépôts de leur part lorsqu'ils le feront."
    },
    growthEvent1: {
        en: 'Request Attendance',
        fr: 'Demande de Présence'
    },
    growthEvent2: {
        en:
            'Enter your event details below and request our attendance. Please allow for a minimum of 2 weeks notice for events. After the event is finished, submit a picture below to verify.',
        fr:
            "Entrez les détails de votre événement ci-dessous et demandez notre  présence à votre événement. Veuillez prévoir un préavis minimum de 2 semaines pour les événements. Une fois l'événement terminé, soumettez une photo ci-dessous pour vérifier."
    },
    tooltip1: {
        en: 'Upload New',
        fr: 'Télécharger nouveau'
    },
    tooltip2: {
        en: 'View Photo',
        fr: 'Voir la photo'
    },
    household1: {
        en: 'Your returns chart will be shown after your first bag is counted.',
        fr: 'Votre tableau des retours sera affiché après le comptage de votre premier sac.'
    }
};

const taxReceiptsTranslations = {
    taxReceipts1: {
        en: 'Issue Tax Receipts',
        fr: 'Émettre des reçus fiscaux'
    },
    taxReceipts2: {
        en: 'Issue {{count}} Receipts',
        fr: 'Émettre {{count}} reçus'
    },
    taxReceipts3: {
        en:
            'The system will notify donors that {{{charity}}} is preparing their tax receipts. The system cannot issue the actual tax receipts.',
        fr:
            'Le système informera les donateurs que {{{charity}}} est en train de préparer leurs reçus fiscaux. Le système ne peut pas émettre les reçus fiscaux physiques.'
    },
    taxReceipts4: {
        en: 'Continue',
        fr: 'Continuer'
    },
    taxReceipts5: {
        en: 'You have outstanding tax receipts to issue for {{yearsToIssue}}',
        fr: 'Vous avez des reçus fiscaux en suspens à émettre pour {{yearsToIssue}}'
    },
    taxReceipts6: {
        en:
            'Donations have been combined into a single receipt ({{minTaxReceiptAmount}} minimum per receipt). Issuing tax receipts will notify your donors that their tax receipt is being prepared.',
        fr:
            "Les dons ont été combinés en un seul reçu (minimum de {{minTaxReceiptAmount}} par reçu). L'émission de reçus fiscaux informera vos donateurs que leur reçu fiscal est en cours de préparation."
    },
    taxReceipts7a: {
        en:
            'Simply select the year you would like to issue your receipts for and press the issue button to notify the users that their tax receipts are being prepared. Your tax-receipt-year-end date is ',
        fr:
            "Sélectionnez simplement l'année pour laquelle vous souhaitez émettre vos reçus et appuyez sur le bouton d'émission pour informer les utilisateurs que leurs reçus fiscaux sont en cours de préparation. La date de fin d'année de votre reçu fiscal est le "
    },
    taxReceipts7b: {
        en: '. If you would like to change the year end date, contact {{brand}} to have this updated.',
        fr: ". Si vous souhaitez modifier la date de fin d'année, contactez {{brand}} pour la mettre à jour."
    },
    taxReceipts8: {
        en: 'In progress',
        fr: 'En cours'
    },
    taxReceipts9: {
        en:
            'Tax receipts for the current year can only be issued after your tax-receipt-year-end date, {{endOfTaxYear}}. Contact {{brand}} to update this date.',
        fr:
            "Les reçus fiscaux pour l'année en cours ne peuvent être délivrés qu'après la date de fin d'année de votre reçu fiscal, le {{endOfTaxYear}}. Contactez {{brand}} pour mettre à jour cette date."
    },
    taxReceipts10: {
        en: 'Tax Receipts for {{year}}',
        fr: 'Reçus fiscaux pour {{année}}'
    },
    taxReceipts11: {
        en: '{{count}} Issued',
        fr: '{{count}} Émis'
    },
    taxReceipts12: {
        en: '{{count}} Unissued',
        fr: '{{count}} Non émis'
    },
    taxReceipts13: {
        en: 'Information',
        fr: 'Information'
    },
    taxReceipts14: {
        en:
            'This table shows tax receipts that have been or are ready to be issued to customers. Every row in the table represents a tax receipt along with the total monetary value. By issuing tax receipts, we will notify the users by email and history that tax receipts are being prepared. We cannot issue the physical tax receipts, your charity must still do that.',
        fr:
            'Ce tableau présente les reçus fiscaux qui ont été ou sont prêts à être émis aux clients. Chaque ligne du tableau représente un reçu fiscal ainsi que la valeur monétaire totale. En émettant des reçus fiscaux, nous informerons les utilisateurs par courriel et historique que des reçus fiscaux sont en cours de préparation. Nous ne pouvons pas délivrer les reçus fiscaux physiques, votre organisme de bienfaisance doit toujours le faire.'
    },
    taxReceipts15: {
        en: 'Download All',
        fr: 'Tout télécharger'
    },
    taxReceipts16: {
        en: 'DEFERRED',
        fr: 'DIFFÉRÉ'
    },
    taxReceipts17: {
        en: 'Select a date period',
        fr: 'Sélectionner une date de période'
    },
    taxReceipts18: {
        en:
            'The system only notifies users by email and app history that tax receipts are being prepared. We cannot issue the physical tax receipts. You, the charity, must still do that.',
        fr:
            "Le système informe uniquement les utilisateurs par courriel et par l'historique de l'application que des reçus fiscaux sont en cours de préparation. Nous ne pouvons pas délivrer les reçus fiscaux physiques. Vous, l'organisme de charité, devez encore le faire."
    },
    taxReceipts19: {
        en: 'Select the end of your current tax year. By default, this date is set to December 31st.',
        fr: "Sélectionnez la fin de votre année d'imposition en cours. Par défaut, cette date est fixée au 31 décembre."
    },
    taxReceipts20: {
        en: 'ISSUED',
        fr: 'Émis'
    },
    taxReceipts21: {
        en: 'NOT ISSUED',
        fr: 'Non émis'
    },
    taxReceipts22: {
        en: 'Select date period',
        fr: 'Sélectionnez la période'
    },
    taxReceipts23: {
        en: 'Unredeemed donations',
        fr: 'Dons non remboursés'
    },
    taxReceipts24: {
        en:
            'This list shows donations which have not been redeemed. For reconciliation purposes when a redemption is executed this list will move to the redeemed donations list and a CSV will be generated matching the total redemption with donor information and amounts.',
        fr:
            "Cette liste répertorie les dons qui n'ont pas été remboursés. À des fins de centralisation, lorsqu'un remboursement est exécuté, cette liste passera à la liste des dons échangés et un fichier CSV sera généré correspondant au remboursement total avec les informations et les montants des donateurs."
    },
    taxReceipts25: {
        en: 'Download CSV',
        fr: 'Télécharger CSV'
    },
    taxReceipts26: {
        en: 'Redeemed donations',
        fr: 'Dons réclamés'
    },
    taxReceipts27: {
        en:
            "Use the drop-down list to select a redemption to display the associated donation information. If the user is muted or grayed out it's because the user selected not to receive a tax receipt. The exported CSV will only include people who want tax receipts.",
        fr:
            "Utilisez la liste déroulante pour sélectionner une réclamation afin d'afficher les informations de don associées. Si l'utilisateur est grisé, c'est parce qu'il a choisi de ne pas recevoir de reçu fiscal. Le CSV exporté n'inclura que les personnes qui souhaitent recevoir des reçus fiscaux."
    },
    taxReceipts28: {
        en: 'Select redemption',
        fr: 'Sélectionnez le remboursement'
    },
    taxReceipts29: {
        en: 'Monthly Container Breakdown',
        fr: 'Répartition mensuelle des contenants'
    },
    taxReceipts30: {
        en: 'Material',
        fr: 'Matière'
    },
    taxReceipts31: {
        en: 'Small Containers',
        fr: 'Petits contenants'
    },
    taxReceipts32: {
        en: 'Large Containers',
        fr: 'Grands contenants'
    },
    taxReceipts33: {
        en: 'Total Containers',
        fr: 'Nombre total de contenants'
    },
    taxReceipts34: {
        en: 'Rows per page',
        fr: 'Lignes par page'
    },
    taxReceiptCol1: {
        en: 'Name',
        fr: 'Nom'
    },
    taxReceiptCol2: {
        en: 'E-mail',
        fr: 'courriel'
    },
    taxReceiptCol3: {
        en: 'Phone',
        fr: 'Téléphone'
    },
    taxReceiptCol4: {
        en: 'Address',
        fr: 'Adresse'
    },
    taxReceiptCol5: {
        en: '# Donations',
        fr: '# dons'
    },
    taxReceiptCol6: {
        en: 'Total Amount',
        fr: 'Montant total'
    },
    taxReceiptCol7: {
        en: 'First Donation',
        fr: 'Premier don'
    },
    taxReceiptCol8: {
        en: 'Last Donation',
        fr: 'Dernier don'
    },
    taxReceiptCol9: {
        en: 'Date Issued',
        fr: 'Date de délivrance'
    },
    taxReceiptCol10: {
        en: 'Receipt Year',
        fr: 'Année de réception'
    },
    taxReceiptCol11: {
        en: 'Last Pickup Date',
        fr: 'Date du dernier ramassage'
    },
    taxReceiptCol12: {
        en: 'Grouping',
        fr: 'Regroupement'
    },
    taxReceiptCol13: {
        en: 'Amount',
        fr: 'Montant'
    },
    taxReceiptCol14: {
        en: 'Pickup Date',
        fr: 'Date de ramassage'
    },
    taxReceiptCol15: {
        en: 'Business Name',
        fr: "Nom de l'entreprise"
    }
};

const referralTranslations = {
    referal1: {
        en: 'Email Out Directly',
        fr: 'Envoyer directement par courriel'
    },
    referal2: {
        en:
            'Start by adding the email addresses of everyone who you want to participate. Enter them one by one or copy paste a comma/space delimited list!',
        fr:
            'Commencez par ajouter les adresses courriel de toutes les personnes dont la participation est souhaitée. Entrez-les un par un ou copiez-collez une liste délimitée par des virgules/espaces!'
    },
    referal3: {
        en: 'Emails',
        fr: 'Courriels'
    },
    referal4: {
        en: 'Add email',
        fr: 'Ajouter un courriel'
    },
    referal5: {
        en: 'Send',
        fr: 'Envoyer'
    },
    referal6: {
        en: 'Invalid email format',
        fr: 'Format de courriel invalide'
    },
    referal7: {
        en: 'Emails sent!',
        fr: 'Courriels envoyés!'
    },
    referal8: {
        en: 'Email is already added to the list',
        fr: 'Le courriel est déjà ajouté à la liste'
    },
    referralScreen1: {
        en: `If you love using ${app} give us a 5 star review! It only takes a moment and helps us expand to new areas!`,
        fr: ``
    },
    referralScreen2: {
        en: `Invite`,
        fr: `Inviter`
    },
    referralScreen3: {
        en: `Copy Link`,
        fr: `Copier le lien`
    },
    referralScreen4: {
        en: `Invite your friends!`,
        fr: `Invite tes amis!`
    },
    referralScreen5: {
        en: 'Press to Invite!',
        fr: 'Appuyer pour Inviter!'
    }
};

const historyTranslations = {
    history1: {
        en: 'Filters',
        fr: 'Filtres'
    },
    history2: {
        en: 'Donations',
        fr: 'Dons'
    },
    history3: {
        en: 'Redemptions',
        fr: 'Remboursements'
    },
    history4: {
        en: 'Donation by {{name}} to {{{charity}}}',
        fr: 'Don de par {{name}} à {{{charity}}}'
    },
    history5: {
        en: 'More',
        fr: 'Plus'
    },
    history6: {
        en: 'No history entries exist yet',
        fr: "Aucune entrée d'historique n'existe encore"
    },
    history7: {
        en: 'No items have been found. Check your filters to show more.',
        fr: 'Aucun élément n’a été trouvé. Vérifiez vos filtres pour en voir davantage.'
    },
    history8: {
        en: 'Account credited!',
        fr: 'Compte crédité!'
    },
    history9: {
        en: 'You have received a compensation from {{brand}}.',
        fr: 'Vous avez reçu une compensation de {{brand}}.'
    },
    history10: {
        en: 'Pickup photo',
        fr: 'Photo de ramassage'
    },
    history11: {
        en: 'The customer would like to know when their counts will be processed',
        fr: 'Le client souhaite savoir quand ses décomptes seront effectués'
    },
    history12: {
        en: 'Reason',
        fr: 'Raison'
    },
    history13: {
        en: 'Cash delivered to {{name}}',
        fr: 'Argent remis à {{name}}'
    },
    history14: {
        en: 'Tax Receipt issued by',
        fr: 'Reçu fiscal émis par'
    },
    history15: {
        en: 'Picked up by',
        fr: 'Récupérer par'
    },
    history16: {
        en: 'Printed at',
        fr: 'Imprimé à'
    },
    history17: {
        en: 'labels scanned at',
        fr: 'auto-collants numérisées à'
    },
    history18: {
        en: 'dropped off at',
        fr: 'déposé à'
    },
    history19: {
        en: 'Referral bonus from {{name}}',
        fr: 'Bonus de parrainage de {{name}}'
    },
    labels: {
        en: 'Labels',
        fr: 'Auto-collants'
    },
    labelsPrinted: {
        en: 'Labels Printed',
        fr: 'Auto-collants Imprimées'
    },
    labelsReprinted: {
        en: 'Labels Reprinted',
        fr: 'Auto-collants Réimprimées'
    },
    labelsScanned: {
        en: 'Labels Scanned',
        fr: 'Auto-collants Numérisées'
    }
};

const registrationTranslations = {
    registration1a: {
        en: `First time hearing about ${app}? Learn more through our website at`,
        fr: `C'est la première fois que vous entendez parler de ${app}? Apprenez-en plus sur`
    },
    registration1b: {
        en: `and the`,
        fr: `et`
    },
    registration2a: {
        en: `First time hearing about ${app}? Learn more through our`,
        fr: `C'est la première fois que vous entendez parler de ${app}? Apprenez-en plus grâce à notre`
    },
    registration2b: {
        en: 'and',
        fr: 'et'
    },
    registration2c: {
        en: 'You can also check out our website at',
        fr: "Vous y retrouverez plus d'information ici: "
    },
    registration2d: {
        en: 'to learn about the program and to see where the pickup service is currently available',
        fr: ''
    },
    registration3: {
        en: 'Personal',
        fr: 'Inscription personnelle'
    },
    registration4: {
        en: 'Fundraising & Group',
        fr: 'Collecte de fonds et inscription de groupe'
    },
    registration4a: {
        en: 'Group',
        fr: 'Inscription de groupe'
    },
    registration4b: {
        en: 'Charity',
        fr: 'Collecte de fonds'
    },
    registration5: {
        en: 'Start A Bottle Drive',
        fr: 'Démarrer une collecte de contenants'
    },
    registration6: {
        en: 'Become A Collector Partner',
        fr: 'Devenir un Récupérateur Partenaire'
    },
    registration8: {
        en: 'Accept donations for charities, schools, not-4-profit, sports teams, fundraisers',
        fr: 'Acceptez les dons pour votre organisme de bienfaisance, école, équipe sportive ou autre OBNL'
    },
    registration8a: {
        en: 'Bottle drives and fundraising for schools, community groups, or sports teams',
        fr:
            'Collectes de bouteilles et collectes de fonds pour les écoles, les groupes communautaires ou les équipes sportives'
    },
    registration8b: {
        en: 'Registered charitible organizations',
        fr: 'Organisations caritatives enregistrées'
    },
    registration9: {
        en: 'Request a trailer drop off or help with all things bottle drive related.',
        fr: `Demandez un dépôt de remorque ou de l'aide pour tout ce qui concerne la conduite de bouteilles.`
    },
    registration10: {
        en: `Expand to new areas, collect, and accept volume from ${app}.`,
        fr: `Développez-vous dans de nouveaux marchés, collectez et acceptez les contenants de ${app}`
    },
    registration11: {
        en: `Change account type`,
        fr: 'Changer le type de compte'
    },
    registration12: {
        en: `More information`,
        fr: `Plus d'information`
    },
    registration13: {
        en: `Different number for label printing?`,
        fr: `Numéro différent pour l'impression des auto-collants ?`
    },
    registration14: {
        en: 'Business',
        fr: "Inscription d'entreprise"
    },
    registration16: {
        en: '{{{ collectionProgram }}} Collections',
        fr: '{{{ collectionProgram }}} Collections'
    },
    registration17: {
        en: 'Industrial, Commercial & Institutional',
        fr: 'Industriel, Commercial et Institutionnel'
    },
    registrationInfo1: {
        en: `If you request a cheque, it will be issued to the first and last name on your ${app} account.`,
        fr: `Si vous demandez un chèque, il sera émis au nom et prénom de votre compte ${app}.`
    },
    registrationInfo2a: {
        en: `The phone number associated with your account will be used to print labels and for two-factor authentication during registration and login.`,
        fr: `Le numéro de téléphone associé à votre compte sera utilisé pour imprimer des auto-collants et pour l'authentification à deux facteurs lors de l'inscription et de la connexion.`
    },
    registrationInfo2b: {
        en: `The phone number associated with your account will be used for sms notifications and for two-factor authentication during registration and login.`,
        fr: `Le numéro de téléphone associé à votre compte sera utilisé pour les notifications par SMS et pour l'authentification à deux facteurs lors de l'inscription et de la connexion.`
    },
    registrationInfo3: {
        en: `The email associated with your ${app} account will be used to notify you of any updates to your account and to facilitate Interac e-Transfers.`,
        fr: `L'e-mail associé à votre compte ${app} sera utilisé pour vous informer de toute mise à jour de votre compte et pour faciliter les virements électroniques Interac.`
    },
    registrationInfo4: {
        en: 'The address provided during registration will be used to deliver cheques if requested.',
        fr: `L'adresse fournie lors de l'inscription sera utilisée pour délivrer les chèques sur demande.`
    },
    registrationInfo5a: {
        en: `Cheques will be issued to the registered business name and will appear when you print labels at an ${app} location.`,
        fr: `Les chèques seront émis au nom de l'entreprise enregistrée et apparaîtront lorsque vous imprimerez des auto-collants dans un emplacement ${app}.`
    },
    registrationInfo5b: {
        en: `Cheques will be issued to the registered business name.`,
        fr: `Les chèques seront émis au nom de l'entreprise enregistrée.`
    },
    registrationInfo6: {
        en:
            'A company is said to be "doing business as" when the name under which they operate their business differs from its legal, registered name.',
        fr: `On dit qu'une entreprise « fait affaire sous le nom » lorsque le nom sous lequel elle exploite son entreprise diffère de son nom légal enregistré.`
    },

    registrationType1: {
        en: `Sign Up for a Personal Account`,
        fr: `Inscrivez-vous pour un compte personnel`
    },
    registrationType2: {
        en: `Sign Up for a Group Account`,
        fr: `Inscrivez-vous pour un compte de groupe`
    },
    registrationType2a: {
        en: `Sign Up for a Charity Account`,
        fr: `Inscrivez-vous à un compte caritatif`
    },
    registrationType3: {
        en: `Sign Up For Depot Software`,
        fr: `S'enregistrer pour le logiciel de dépôt`
    },
    registrationType4: {
        en: 'Start A Bottle Drive',
        fr: 'Démarrer une collecte de contenants'
    },
    registrationType5: {
        en: 'Sign Up for a Business Account',
        fr: 'Inscrivez-vous pour un compte professionnel'
    },
    registrationType6: {
        en: 'Sign Up for the Collections Program',
        fr: 'Inscrivez-vous au Programme Collections'
    },
    registrationDescriptions1: {
        en: `Don't waste your day waiting at the bottle depot! Sign up below, schedule a pickup, and cash out or donate online!`,
        fr: `Inscrivez-vous ci-dessous et découvrez de nouvelles façons de récupérer vos contenants consignés.`
    },
    registrationDescriptions2: {
        en: `Use ${app} to promote your fundraising efforts. Sign up your ${terms.organization.en} below.`,
        fr: `Utilisez ${app} pour promouvoir vos efforts de collecte de fonds. Inscrivez votre organisme ci-dessous et laissez nos clients vous faire don de leurs contenants consignés!`
    },
    registrationDescriptions3: {
        en: `We have a variety of software systems that support Bottle Depots, including a Drop&Go system which eliminates lines and streamlines the payout process.`,
        fr: `Nous avons une variété de systèmes logiciels qui prennent en charge les lieux de retour de contenants consignés, y compris un système de conteneur extérieur POD qui permettent de déposer les sacs sans attente.`
    },
    registrationDescriptions4: {
        en: `Is your organization running a bottle drive, clothing drive, or electronics drive? Fill out the form below and we will confirm your booking. On the day of your bottle drive A SkipTheDepot truck will arrive at the end to collect your bags and have them processed. Your proceeds will be available via e-transfer with history recorded in your account.`,
        fr: `
        Votre organisation organise-t-elle une collecte de bouteilles, une collecte de vêtements ou une collecte d'appareils électroniques ? Remplissez le formulaire ci-dessous et nous confirmerons votre réservation. Le jour de votre collecte de bouteilles, un camion SkipTheDepot arrivera à la fin pour récupérer vos sacs et les faire traiter. Vos bénéfices seront disponibles par virement électronique avec un historique enregistré dans votre compte.`
    },
    registrationDescriptions5: {
        en: `{{brand}} allows business and community groups to donate their recyclables to a worthy cause. It's free to register and you choose who gets your donations! Company bottle drives are a great way for companies, corporations, businesses, and community groups to a local charity with ease.`,
        fr: `{{brand}} permet aux entreprises et aux groupes communautaires de faire don de leurs matières recyclables à une bonne cause. L'inscription est gratuite et vous choisissez qui reçoit vos dons ! Les collectes de bouteilles d'entreprise sont un excellent moyen pour les entreprises, les sociétés, les entreprises et les groupes communautaires de se rendre facilement à un organisme de bienfaisance local.`
    },
    registrationDescriptions6: {
        en: `A convenient and cashless way to recycle used beverage containers`,
        fr: `Un moyen pratique et sans espèces de recycler les contenants de boissons usagés`
    },
    registrationOrg1: {
        en: `Connect Account`,
        fr: `Connecter un compte`
    },
    registrationOrg2: {
        en: `Create Administrator Account`,
        fr: `Créer un Compte Administrateur`
    },
    registrationOrg3: {
        en: `Connect your existing account to manage the {{accountText}} you are about to register.`,
        fr: `Connectez votre compte existant pour gérer le {compte} que vous êtes sur le point d’enregistrer`
    },
    registrationOrg4: {
        en: `This account will be used to manage the {{accountText}} you are about to register.`,
        fr: `Ce compte sera utilisé pour gérer l'organisme que vous êtes sur le point d'enregistrer.`
    },
    registrationOrg5: {
        en: `I have a personal account already`,
        fr: `J'ai déjà un compte`
    },
    registrationOrg6: {
        en: `Fill out information about your ${terms.organization.en.toLowerCase()}. Remember that this information will be visible to all our customers.`,
        fr: `Remplissez les informations sur votre organisme. N'oubliez pas que ces informations seront visibles par tous nos clients.`
    },
    registrationOrg7: {
        en: `E-mail to receive donations/redemptions`,
        fr: `Courriel pour recevoir des dons/rachats`
    },
    registrationOrg8: {
        en: `Mission & Vision`,
        fr: `Mission et vision`
    },
    registrationOrg9: {
        en: `Enter ${terms.organization.en.toLowerCase()} address`,
        fr: ``
    },
    registrationOrg10: {
        en: `GPS Override (Advanced)`,
        fr: `Remplacement du GPS (avancé)`
    },
    registrationOrg11: {
        en: `Please do not change these values if you are unsure what they mean.`,
        fr: `Veuillez ne pas modifier ces valeurs si vous n'êtes pas certain de leur signification.`
    },
    registrationOrg12: {
        en: `My ${terms.organization.en.toLowerCase()} issues tax receipts`,
        fr: `Mon organisme émet des reçus fiscaux`
    },
    registrationOrg13: {
        en: `My ${terms.organization.en.toLowerCase()} has multiple groups/teams`,
        fr: `Mon organisme a plusieurs groupes/équipes`
    },
    registrationOrg14: {
        en: `teams, groups, companies or divisions`,
        fr: `équipes, groupes, sociétés ou divisions`
    },
    registrationOrg15: {
        en: `${terms.organization.en} Website`,
        fr: `Site de l'organisme`
    },
    registrationOrg16: {
        en: `${terms.organization.en} Name`,
        fr: `Nom de l'organisme`
    },
    registrationOrg17: {
        en: `${terms.organization.en} E-mail (financial)`,
        fr: `courriel de l'organisme (financier)`
    },
    registrationOrg18: {
        en: `Create ${terms.organization.en}`,
        fr: `Créer un organisme`
    },
    registrationOrg19: {
        en: `${terms.organization.en}'s Registered Name`,
        fr: `Nom enregistré de l'organisation`
    },
    registrationOrg20: {
        en: `Automatically close this ${terms.organization.en.toLowerCase()} after a specified date.`,
        fr: 'Fermez automatiquement cette organisation après une date spécifiée.'
    },
    registrationOrg21: {
        en: `${terms.organization.en} Scheme ID`,
        fr: `identifiant du schéma d'organisation`
    },

    registration2fa1: {
        en: `Please update your phone number.`,
        fr: `Veuillez mettre à jour votre numéro de téléphone.`
    },
    registration2fa2: {
        en: `Your account is protected with two-factor authentication. We've sent you a text message at {{formattedPhoneNumber}}. Please enter the code below.`,
        fr: `Votre compte est protégé par une authentification à deux facteurs. Nous vous avons envoyé un SMS à {{formattedPhoneNumber}}. Veuillez entrer le code ci-dessous.`
    },
    registration2fa3: {
        en: `*Warning: sms will be sent - do not use random phone numbers*`,
        fr: `* Attention: des sms seront envoyés - n’utilisez pas de numéros de téléphone aléatoires`
    },
    registration2fa4: {
        en: `Having trouble logging in? Contact us at`,
        fr: `Vous rencontrez des difficultés pour vous connecter ? Contactez-nous au`
    },
    registration2fa5: {
        en: `Retry available in`,
        fr: `Nouvelle tentative disponible dans`
    },
    registration2fa6: {
        en: `Authentication Code`,
        fr: `Code d’authentification`
    },
    registration2fa7: {
        en: `Trust this device`,
        fr: ``
    },
    registration2fa8: {
        en: 'I did not receive a SMS',
        fr: `Je n'ai pas reçu de SMS`
    },
    registration2fa9: {
        en: 'Is this your number? {{phone}}',
        fr: `Est-ce votre numéro? {{phone}}`
    },
    registration2fa10: {
        en: 'Customer Support has been contacted and will reach out shortly.',
        fr: 'Le service client a été contacté et vous contactera sous peu.'
    },
    registration2fa11: {
        en: 'Double Check Your Phone Number',
        fr: 'Vérifiez votre numéro de téléphone'
    },
    registration2fa12: {
        en: 'Please correct your phone number and click resend.',
        fr: 'Veuillez corriger votre numéro de téléphone et cliquer sur renvoyer.'
    },
    registration2fa13: {
        en: `An authentication code has been sent to`,
        fr: `Un code d'authentification a été envoyé à`
    },
    registration2fa14: {
        en: `Change Number`,
        fr: `Changer de numéro`
    },
    registration2fa15: {
        en: `I need to update my phone number`,
        fr: `Je dois mettre à jour mon numéro de téléphone`
    },
    resendViaText: {
        en: 'Resend code via text message',
        fr: 'Renvoyer le code par SMS'
    },
    resendViaVoice: {
        en: 'Resend code via voice call',
        fr: 'Renvoyer le code par appel vocal'
    },
    sendViaText: {
        en: 'Send code via text message',
        fr: 'Envoyer le code par SMS'
    },
    sendViaVoice: {
        en: 'Send code via voice call',
        fr: 'Envoyer le code par appel vocal'
    },
    verifyPhone: {
        en: 'Verify your phone number',
        fr: 'Vérifiez votre numéro de téléphone'
    },
    phoneNumberVerified: {
        en: 'Phone number verified',
        fr: 'Numéro de téléphone vérifié'
    },
    regBottleDrive1: {
        en: `Enter the name of your company bottle drive and select the charity that donations will go towards`,
        fr: `Entrez le nom de la collecte de contenants de votre entreprise et sélectionnez l'organisme de bienfaisance auquel les dons iront:`
    },
    regBottleDrive2: {
        en: `Bottle drive name`,
        fr: `Nom de la collecte de contenants`
    },
    regBottleDrive3: {
        en: `Can't find the organization you want? Contact us`,
        fr: `Vous ne trouvez pas l'organisme que vous souhaitez? Contactez-nous`
    },
    registrationOAuth1: {
        en: `Successfully authenticated with`,
        fr: `Authentifié avec succès avec:`
    },
    registrationOAuth2: {
        en: `To complete registration, please provide an email address.`,
        fr: `Pour terminer l’inscription, veuillez fournir une adresse courriel.`
    },
    registrationOAuth2a: {
        en: `To complete registration, please provide a phone number.`,
        fr: `Pour finaliser votre inscription, veuillez fournir un numéro de téléphone.`
    },
    registrationOAuth3: {
        en: `This will be your default e-transfer email.`,
        fr: `Il s’agira de votre courriel de transfert électronique par défaut.`
    },
    registrationOAuth4: {
        en: `It can be changed at any time.`,
        fr: ``
    },
    registrationOAuth5: {
        en: `By clicking "Continue", you agree to`,
        fr: `En cliquant sur « Continuer », vous acceptez`
    },
    registrationOAuth6: {
        en: `and acknowledge you have read the`,
        fr: `et reconnaissez que vous avez lu le`
    },

    registrationOAuth7: {
        en: `Sign up with {{platform}}`,
        fr: `S’inscrire avec {{platform}}`
    },
    registrationOAuth8: {
        en: `Continue with {{platform}}`,
        fr: `Continuer avec {{platform}}`
    },

    registrationOAuth9: {
        en: `Sign up for Mobile with {{platform}}`,
        fr: `S’inscrire avec {{platform}}`
    },
    registrationOAuth10: {
        en: `Continue on Mobile with {{platform}}`,
        fr: `Continuer avec {{platform}}`
    },
    registrationBottleDrive1: {
        en: 'Estimated number of bags',
        fr: 'Nombre estimé de sacs'
    },
    registrationBottleDrive2: {
        en: 'Referring Bottle Depot',
        fr: 'Dépôt de bouteilles référant'
    },
    registrationBottleDrive3: {
        en: 'Thank you, we will be in touch shortly.',
        fr: 'Merci, nous vous contacterons sous peu.'
    },
    registrationBottleDrive4: {
        en: 'Organization / Charity',
        fr: 'Organisation / Charité'
    },

    accountLink1: {
        en: `Account linked!`,
        fr: `Compte lié!`
    },
    accountLink2: {
        en: `You have successfully linked your account with {{accountType}}! To continue with your new account, please login with the account you have linked it to!`,
        fr: `Vous avez réussi à lier votre compte à {{accountType}} ! Pour continuer avec votre nouveau compte, veuillez vous connecter avec le compte auquel vous l’avez lié!`
    },
    accountLink3: {
        en: `go to login`,
        fr: `aller à la connexion`
    }
};

const loginTranslations = {
    login1a: {
        en: `Don't have an account?`,
        fr: `Besoin d'un compte?`
    },
    login1b: {
        en: `Sign up here`,
        fr: `Inscrivez-vous ici`
    },
    login1c: {
        en: `to book a pickup, {{{dropAndGo}}} start a bottle drive or register your ${terms.organization.en.toLowerCase()}`,
        fr: `pour réserver un ramassage, utiliser les conteneurs extérieurs POD, démarrer une collecte de contenants consignés ou enregistrer votre organisme.`
    },
    login2: {
        en: 'Logging In',
        fr: 'Connexion'
    },
    login3a: {
        en: 'Looking for more information?',
        fr: 'Vous cherchez plus d’informations ?'
    },
    login3b: {
        en: 'Help Center',
        fr: "Centre d'aide"
    },
    emailPasswordReset1: {
        en: `Reset password`,
        fr: `Réinitialiser le mot de passe`
    },
    emailPasswordReset2: {
        en: `Please enter the e-mail address connected with your account. If the e-mail matches, you will be sent a reset link. Please check your junk mail folder if you do not see the email.`,
        fr: `Veuillez saisir l’adresse courriel associée à votre compte. Si l'adresse correspond, vous recevrez un lien de réinitialisation. Veuillez vérifier votre dossier de courrier indésirable si vous ne voyez pas le courriel.`
    },
    emailPasswordReset3: {
        en: `Having trouble resetting your password? Contact us at`,
        fr: `Vous rencontrez des difficultés pour réinitialiser votre mot de passe ? Contactez-nous au`
    },
    failedLogin1: {
        en: `Multiple Failed Attempts`,
        fr: `Tentatives infructueuses multiples`
    },
    failedLogin2: {
        en: `Your account has been locked until {{lockAccountUntil}}. If you have concerns about your account or believe you have received this message in error, please contact us at`,
        fr: `Votre compte a été verrouillé jusqu’à {{lockAccountUntil}}. Si vous avez des préoccupations au sujet de votre compte ou si vous pensez avoir reçu ce message par erreur, veuillez nous contacter à l’adresse`
    },
    identityVerificationUnavailable: {
        en: `If you have concerns about your account or are unable to log in, please contact us at`,
        fr: `Si vous avez des inquiétudes concernant votre compte ou si vous ne parvenez pas à vous connecter, veuillez nous contacter à`
    },

    loginMessage1: {
        en: `Could not apply promo, {{reason}}`,
        fr: `Impossible d’appliquer promo, {{reason}}`
    },
    loginMessage2: {
        en: `Promo applied!`,
        fr: `Promotion appliquée!`
    },
    loginMessage3: {
        en: `Error applying promo.`,
        fr: `Erreur lors de l’application de la promotion.`
    },
    fbDiscontinued1: {
        en: `Support for Facebook sign in has been discontinued, please use the following link to create a password for your account.`,
        fr: `La prise en charge de la connexion Facebook a été interrompue, veuillez utiliser le lien suivant pour créer un mot de passe pour votre compte.`
    }
};

const words = {
    issued: {
        en: 'issued',
        fr: 'émis'
    },
    request: {
        en: 'request',
        fr: 'demander'
    },
    order: {
        en: 'order',
        fr: 'commande'
    },
    suspended: {
        en: 'Suspended',
        fr: 'Suspendue'
    },
    underReview: {
        en: 'Under Review',
        fr: 'En cours de révision'
    },
    refunded: {
        en: 'refunded',
        fr: 'remboursé'
    },
    remove: {
        en: 'remove',
        fr: 'retirer'
    },
    information: {
        en: 'information',
        fr: 'information'
    },
    large: {
        en: 'large',
        fr: ''
    },
    hours: {
        en: 'Hours',
        fr: 'Heures'
    },
    closed: {
        en: 'Closed',
        fr: 'Fermé'
    },
    or: {
        en: 'or',
        fr: 'ou'
    },
    for: {
        en: 'for',
        fr: 'pour'
    },
    receipt: {
        en: 'Receipt',
        fr: 'Reçu'
    },
    adjustment: {
        en: 'Adjustment',
        fr: 'Ajustement'
    },
    pickup: {
        en: 'Pickup',
        fr: 'Ramassage'
    },
    cheque: {
        en: 'Cheque',
        fr: 'Chèque'
    },
    to: {
        en: 'to',
        fr: 'à'
    },
    requested: {
        en: 'requested',
        fr: 'demandé'
    },
    value: {
        en: 'Value',
        fr: 'Valeur'
    },
    sent: {
        en: 'sent',
        fr: 'expédié'
    },
    submit: {
        en: 'Submit',
        fr: 'Soumettre'
    },
    submit2: {
        en: 'Submit',
        fr: 'Terminé'
    },
    status: {
        en: 'Status',
        fr: 'Statut'
    },
    none: {
        en: 'None',
        fr: 'Aucun'
    },
    morning: {
        en: 'Morning',
        fr: 'Matin'
    },
    afternoon: {
        en: 'Afternoon',
        fr: 'Après midi'
    },
    add: {
        en: 'Add',
        fr: 'Ajouter'
    },
    use: {
        en: 'Use',
        fr: 'Utilisez'
    },
    search: {
        en: 'Search',
        fr: 'Rechercher'
    },
    verifying: {
        en: `Verifying`,
        fr: `en vérification`
    },
    location: {
        en: 'Location',
        fr: 'Emplacement'
    },
    locations: {
        en: 'Locations',
        fr: 'Emplacements'
    },
    change: {
        en: 'Change',
        fr: 'Modifier'
    },
    confirm: {
        en: 'Confirm',
        fr: 'Confirmer'
    },
    no: {
        en: 'No',
        fr: 'Non'
    },
    yes: {
        en: 'Yes',
        fr: 'Oui'
    },
    reason: {
        en: 'Reason',
        fr: 'Raison'
    },
    save: {
        en: 'Save',
        fr: 'Enregistrer'
    },
    close: {
        en: 'Close',
        fr: 'Fermer'
    },
    revert: {
        en: 'Revert',
        fr: 'Revenir'
    },
    dismiss: {
        en: 'Dismiss',
        fr: 'Rejeter'
    },
    send: {
        en: 'Send',
        fr: 'Envoyer'
    },
    authenticating: {
        en: 'Authenticating',
        fr: 'Authentification'
    },
    registration: {
        en: 'Registration',
        fr: 'Inscription'
    },
    okay: {
        en: 'Okay',
        fr: 'Okay'
    },
    cancel: {
        en: 'Cancel',
        fr: 'Annuler'
    },
    download: {
        en: 'Download',
        fr: 'Télécharger'
    },
    reset: {
        en: 'Reset',
        fr: 'Réinitialiser'
    },
    continue: {
        en: 'Continue',
        fr: 'Continuer'
    },
    with: {
        en: 'with',
        fr: 'avec'
    },
    website: {
        en: 'website',
        fr: 'site web'
    },
    create: {
        en: 'Create',
        fr: 'Créer'
    },
    name: {
        en: 'name',
        fr: 'nom'
    },
    Name: {
        en: 'Name',
        fr: 'Nom'
    },
    next: {
        en: 'Next',
        fr: 'Suivant'
    },
    back: {
        en: 'Back',
        fr: 'Retour'
    },
    rates: {
        en: 'Rates',
        fr: 'Taux'
    },
    verify: {
        en: 'Verify',
        fr: 'Vérifier'
    },
    financial: {
        en: 'Financial',
        fr: 'Financier'
    },
    email: {
        en: `E-mail`,
        fr: `Courriel`
    },
    address: {
        en: 'Address',
        fr: 'Adresse'
    },
    companyAddress: {
        en: 'Address',
        fr: 'Adresse'
    },
    latitude: {
        en: `Latitude`,
        fr: `Latitude`
    },
    longitude: {
        en: `Longitude`,
        fr: `Longitude`
    },
    password: {
        en: `Password`,
        fr: `Mot de passe`
    },
    refresh: {
        en: 'refresh',
        fr: 'rafraîchir'
    },

    approved: {
        en: 'Approved',
        fr: 'Approuvé'
    },
    donate: {
        en: 'Donate',
        fr: 'Faire un don'
    },
    time: {
        en: 'Time',
        fr: 'Temps'
    },
    details: {
        en: 'Details',
        fr: 'Détails'
    },
    approve: {
        en: 'Approve',
        fr: 'Approuver'
    },
    reject: {
        en: 'Reject',
        fr: 'Rejeter'
    },
    other: {
        en: 'Other',
        fr: 'Autre'
    },
    type: {
        en: 'Type',
        fr: 'Taper'
    },
    types: {
        en: 'Types',
        fr: 'les types'
    },
    high: {
        en: 'High',
        fr: 'Haute'
    },
    beverage: {
        en: 'beverage',
        fr: 'contenants de boissons'
    },
    electronics: {
        en: 'electronics',
        fr: 'électronique'
    },
    clothing: {
        en: 'clothing',
        fr: 'vêtements'
    },
    depot: {
        en: 'depot',
        fr: 'dépôt'
    },
    driver: {
        en: 'Driver',
        fr: 'Conducteur'
    },
    items: {
        en: 'Items',
        fr: 'articles'
    },
    clerk: {
        en: 'Clerk',
        fr: 'le greffier'
    },
    containers: {
        en: 'Containers',
        fr: 'conteneurs'
    },
    all: {
        en: 'All',
        fr: 'Toutes'
    },
    fees: {
        en: 'Fees',
        fr: 'frais'
    },
    discount: {
        en: 'discount',
        fr: 'rabais'
    },
    ea: {
        en: 'ea',
        fr: 'chaque'
    },
    bag: {
        en: 'bag',
        fr: 'sac'
    },
    call: {
        en: 'Call',
        fr: 'Appeler'
    },
    unavailable: {
        en: 'Unavailable',
        fr: 'Indisponible'
    },
    deposits: {
        en: 'Deposits',
        fr: 'Dépôts'
    },
    complaints: {
        en: 'Complaints',
        fr: 'Plaintes'
    },
    of: {
        en: 'of',
        fr: 'de'
    },
    at: {
        en: 'at',
        fr: 'à'
    },
    printed: {
        en: 'Printed',
        fr: 'Imprimé'
    },
    on: {
        en: 'On',
        fr: 'Le'
    },
    book: {
        en: 'book',
        fr: 'réserver'
    },
    Frequency: {
        en: 'Frequency',
        fr: 'Fréquence'
    },
    skip: {
        en: 'skip',
        fr: 'ignorer'
    },
    trip: {
        en: 'trip',
        fr: 'Voyage'
    },
    routes: {
        en: 'routes',
        fr: 'routes'
    },
    bags: {
        en: 'bags',
        fr: 'sacs'
    },
    completed: {
        en: 'completed',
        fr: 'complété'
    },
    import: {
        en: 'import',
        fr: 'importer'
    },
    priority: {
        en: 'priority',
        fr: 'priorité'
    },
    comment: {
        en: 'Comment',
        fr: 'Commentaire'
    },
    abort: {
        en: 'Abort',
        fr: 'Annulé' //Avorter - to get an abortion - dont use
    },
    skipped: {
        en: 'Skipped',
        fr: 'Ignoré'
    },
    aborted: {
        en: 'Aborted',
        fr: 'Annulé'
    },
    accuracy: {
        en: 'Accuracy',
        fr: 'Précision'
    },
    complete: {
        en: 'Complete',
        fr: 'Complété'
    },
    pickups: {
        en: 'Pickups',
        fr: 'Ramassages'
    },
    left: {
        en: 'left',
        fr: 'restant'
    },
    today: {
        en: 'Today',
        fr: `Aujourd'hui`
    },
    compliments: {
        en: 'Compliments',
        fr: 'Compliments'
    },
    day: {
        en: 'Day',
        fr: 'Jour'
    },
    distance: {
        en: 'Distance',
        fr: 'Distance'
    },
    phone: {
        en: 'Phone',
        fr: 'Téléphone'
    },
    directions: {
        en: 'Directions',
        fr: 'Directives'
    },
    bagtag: {
        en: 'Bagtag',
        fr: 'Auto-collant de sac'
    },
    early: {
        en: 'Early',
        fr: 'tôt'
    },
    late: {
        en: 'Late',
        fr: 'En retard'
    },
    guest: {
        en: 'Guest',
        fr: 'Invité'
    },
    notes: {
        en: 'Notes',
        fr: 'Notes'
    },
    processing: {
        en: 'Processing',
        fr: 'Traitement'
    },
    rescheduled: {
        en: 'Rescheduled',
        fr: 'Reporté'
    },
    duration: {
        en: 'Duration',
        fr: 'Durée'
    },
    odometer: {
        en: 'Odometer',
        fr: 'Odomètre.'
    },
    undo: { en: 'undo', fr: 'défaire' },
    pay: { en: 'Pay', fr: 'Paiement' },
    tips: { en: 'Tips', fr: 'Pourboires' },
    units: { en: 'Units', fr: 'Unités' },
    worked: { en: 'Worked', fr: 'Travaillé' },
    approximately: { en: 'Approximately', fr: 'Approximativement' },
    professional: { en: 'Professional', fr: 'Professionnel' }
};

const generalTranslations = {
    actionDisabled: {
        en: 'Action disabled',
        fr: 'Action désactivée'
    },
    collectionsDialog1: {
        en:
            'You cannot perform this action as your account is not approved. Please contact a system administrator for more details.',
        fr:
            "Vous ne pouvez pas effectuer cette action car votre compte n'est pas approuvé. Veuillez contacter un administrateur système pour plus de détails."
    },
    resendVerification: {
        en: `Resend verification link`,
        fr: `Renvoyer le lien de vérification`
    },
    resendAuthorization: {
        en: `Resend authorization link`,
        fr: `Renvoyer le lien d'autorisation`
    },
    confirmPickup: {
        en: `Confirm Pickup`,
        fr: `Confirmer le ramassage`
    },
    goBack: {
        en: `Go back`,
        fr: `Retourner`
    },
    noThanks: {
        en: 'No thanks',
        fr: 'Non merci'
    },
    areYouSure: {
        en: 'Are you sure?',
        fr: 'Êtes-vous sûr'
    },
    confirmDeletion: {
        en: 'Are you sure you want to delete this?',
        fr: 'Êtes-vous sûr de vouloir supprimer ceci ?'
    },
    networkError: {
        en: 'Network Error',
        fr: 'Erreur Réseau'
    },
    signUp: {
        en: 'Sign Up',
        fr: `S'inscrire`
    },
    accountSignUp: {
        en: 'Account Sign Up',
        fr: `S'inscrire`
    },
    homeAndBusinessSignUp: {
        en: 'Home & Business Sign Up',
        fr: `S'inscrire`
    },
    signIn: {
        en: 'Sign In',
        fr: `Se connecter`
    },
    logIn: {
        en: 'Log In',
        fr: `Se connecter`
    },
    twoFactorAuthentication: {
        en: 'Two-Factor Authentication',
        fr: `Authentification à deux facteurs`
    },
    contactUs: {
        en: 'Contact Us',
        fr: `Nous contacter`
    },
    accountType: {
        en: 'Account Type',
        fr: 'Type de compte'
    },
    addPromoCode: {
        en: 'Add promo code',
        fr: 'Ajouter un code promotionnel'
    },
    forgotPassword: {
        en: 'Forgot Password?',
        fr: 'Mot de passe oublié?'
    },
    repeatPassword: {
        en: 'Repeat Password',
        fr: 'Répéter le mot de passe'
    },
    alreadyHaveAccount: {
        en: `Already have an account?`,
        fr: 'Vous avez déjà un compte?'
    },
    ifDifferent: {
        en: 'if different',
        fr: 'si différent'
    },
    companyAddress: {
        en: 'Company Address',
        fr: 'Adresse de la société'
    },
    viewInOtherLanguage: {
        en: `View this app in another language`,
        fr: 'Afficher dans une autre langue'
    },
    language: {
        en: `Language`,
        fr: 'Langue'
    },
    termAgreement1: {
        en: `By clicking "Sign up" or "Sign in", you agree to ${app}`,
        fr: `En cliquant sur "Inscrivez-vous" ou "Connexion", vous adhérez aux`
    },
    termsAndConditions: {
        en: `Terms and Conditions`,
        fr: `Termes et Conditions`
    },
    termAgreement2: {
        en: `and acknowledge you have read the`,
        fr: `de ${app} et reconnaissez avoir lu`
    },
    privacyNotice: {
        en: `Privacy Notice`,
        fr: `L'avis de confidentialité`
    },
    howItWorks: {
        en: `How It Works`,
        fr: `Comment ça marche`
    },
    howItWorksUnavailable: {
        en: `No information currently configured, please try again later.`,
        fr: `Aucune information actuellement configurée, veuillez réessayer plus tard.`
    },
    frequentQuestions: {
        en: 'Frequent questions',
        fr: 'Questions fréquentes'
    },
    phoneNumber: {
        en: `Phone Number`,
        fr: `Numéro de Téléphone`
    },
    businessPhoneNumber: {
        en: `Business Phone Number`,
        fr: `Numéro de téléphone professionnel`
    },
    firstName: {
        en: `First Name`,
        fr: `Prénom`
    },
    lastName: {
        en: `Last Name`,
        fr: `Nom`
    },
    contactFirstName: {
        en: `Contact First Name`,
        fr: `Prénom du contact`
    },
    contactLastName: {
        en: `Contact Last Name`,
        fr: `Nom du contact`
    },
    companybottledrive: {
        en: `company bottle drive`,
        fr: ``
    },
    textMessage: {
        en: `Text Message`,
        fr: `Message texte`
    },
    voiceCall: {
        en: `Voice Call`,
        fr: `Appel vocal`
    },
    bottleDrive: {
        en: `Bottle Drive`,
        fr: ``
    },
    referAppa: {
        en: `Use the website or download the ${app} app from the`,
        fr: `Utilisez le site Web ou téléchargez l'application ${app} à partir de `
    },
    referAppb: {
        en: 'or',
        fr: 'ou de'
    },
    confirmationDialog1: {
        en: 'Please type {{word}} to confirm',
        fr: 'Veuillez saisir {{word}} pour confirmer'
    },
    confirmationDialog2: {
        en: 'WARNING',
        fr: 'AVERTISSEMENT'
    },
    approvalPending: {
        en: 'Approval pending',
        fr: "En attente d'approbation"
    },
    pending: {
        en: 'Pending',
        fr: 'En attente'
    },
    notApproved: {
        en: 'Not approved',
        fr: 'Non approuvé'
    },
    qrDownload: {
        en: 'QRCode',
        fr: 'QRCode'
    },
    comingSoon: {
        en: 'Coming Soon...',
        fr: 'À venir...'
    },
    collector: {
        en: 'Collector',
        fr: 'Récupérateur'
    },
    resend: {
        en: 'resend',
        fr: 'renvoyer'
    },
    update: {
        en: 'Update',
        fr: 'Mise à jour'
    },
    cancelLogin: {
        en: 'Cancel Login',
        fr: 'Annuler Connexion'
    },
    pleaseSpecify: {
        en: `Please Specify`,
        fr: 'veuillez préciser'
    },
    grossAmount: {
        en: 'Gross Amount',
        fr: 'Montant brut'
    },
    serviceFee: {
        en: 'Service Fee',
        fr: 'frais de service'
    },
    totalRefund: {
        en: 'Total Refund',
        fr: 'Remboursement total'
    },
    TotalOwing: {
        en: 'Total Owing',
        fr: 'Total dû'
    },
    subTotal: {
        en: 'Sub Total',
        fr: 'Sous-total'
    },
    balancePaid: {
        en: 'Balance Paid',
        fr: 'Solde payé'
    },
    loggedOut: {
        en: 'Logged Out',
        fr: 'Déconnecté'
    },
    inactiveLogOut: {
        en: 'You were logged out for inactivity',
        fr: 'Vous avez été déconnecté pour inactivité'
    },
    securityQuestion: {
        en: 'Security Question',
        fr: 'Question de sécurité'
    },
    currentSecurityQuestion: {
        en: 'Current Security Question',
        fr: 'Question de sécurité actuelle'
    },
    scanQRCode: {
        en: 'Scan QR Code',
        fr: 'Scanner le code QR'
    },
    QRTitle: {
        en: 'QR Code',
        fr: 'Code QR'
    },
    showQRCodeSubTitle: {
        en: 'Scan your QR code below using a scanning device',
        fr: "Scannez votre code QR ci-dessous à l'aide d'un appareil de numérisation"
    },
    scanQRCodeSubTitle: {
        en: 'Scan a QR code using your phone',
        fr: "Scannez un code QR à l'aide de votre téléphone"
    },
    scanQRCodeButton: {
        en: 'Press here to scan a QR code',
        fr: 'Appuyez ici pour scanner un code QR'
    },
    scanQRSuccessTitle: {
        en: "You're all set!",
        fr: 'Vous êtes prêt !'
    },
    scanQRSuccess: {
        en:
            'The QR code has been successfully validated. Complete your transaction by placing your containers in the machine. Thank you for using our QR scanning feature. If you need any further assistance or have any questions, please feel free to reach out.',
        fr:
            "Le code QR a été validé avec succès. Complétez votre transaction en plaçant vos contenants dans la machine. Merci d'utiliser notre fonctionnalité de scan de QR code. Si vous avez besoin d'assistance supplémentaire ou si vous avez des questions, n'hésitez pas à nous contacter."
    },
    scanQRFail: {
        en:
            "The QR code you've scanned is invalid. Please ensure it's well-lit and fully visible for a clear scan. If the problem continues, try refreshing or restarting the app. Contact support for further help. Thank you for your understanding.",
        fr:
            "Le code QR scanné est invalide. Assurez-vous qu'il est bien éclairé et totalement visible pour un scan claire. Si le problème persiste, essayez de rafraîchir ou de redémarrer l'application. Contactez le support pour plus d'aide. Merci de votre compréhension."
    },
    noPickupBooked: {
        en: 'No Pickup Booked',
        fr: 'Aucun ramassage réservé'
    },
    netTax: {
        en: 'Net Tax',
        fr: 'taxe nette'
    },
    taxBreakdown: {
        en: 'Tax Breakdown',
        fr: 'Répartition des impôts'
    },
    accountAdjustment: {
        en: 'Account Adjustment',
        fr: 'Ajustement de compte'
    },
    openingBalanceOn: {
        en: 'Opening Balance on {{date}}',
        fr: `Solde d'ouverture le {{date}}`
    },
    rvm: {
        en: 'Reverse Vending Machine',
        fr: 'Machine récupératrice à l’unité'
    },
    bagDrop: {
        en: 'Bag Drop',
        fr: 'Dépôt De Sacs'
    },
    openNow: {
        en: 'Open Now',
        fr: 'Ouvrez maintenant'
    },
    noLocationFound: {
        en: 'No Location Found',
        fr: 'Aucune localisation trouvée'
    },
    processedOn: {
        en: 'Processed on',
        fr: 'Traité sur'
    },
    printedOn: {
        en: 'Printed On',
        fr: `Imprimé sur`
    },
    showAll: {
        en: 'Show all',
        fr: 'Afficher tout'
    },
    showLess: {
        en: 'Show less',
        fr: 'Afficher moins'
    },
    showMore: {
        en: 'Show More',
        fr: 'Montre plus'
    },
    technologyFilter: {
        en: 'Filters',
        fr: 'Filtres'
    },
    noTechnology: {
        en: 'No Technology',
        fr: 'Aucune technologie'
    },
    resolvedComplaints: {
        en: 'Resolved Complaints',
        fr: `Plaintes résolues`
    },
    unresolvedComplaints: {
        en: 'Unresolved Complaints',
        fr: 'Plaintes non résolues'
    },
    paymentReceived: {
        en: 'Payment received',
        fr: `Paiement reçu`
    },
    dateAtTime: {
        en: `{{date}}, at {{time}},`,
        fr: `{{date}}, à {{time}},`
    },
    weCollected: {
        en: 'we collected',
        fr: `nous avons collecté`
    },
    youDroppedOff: {
        en: `you dropped off`,
        fr: `vous avez déposé`
    },
    ReceiptNumber: {
        en: `Receipt Number`,
        fr: `Numéro de reçu`
    },
    Quantity: {
        en: `Quantity`,
        fr: `Quantité`
    },
    Unitprice: {
        en: `Unit price`,
        fr: `Prix unitaire`
    },
    Amount: {
        en: `Amount`,
        fr: `Montant`
    },
    RefundTo: {
        en: `Refund To`,
        fr: `Remboursement à`
    },
    BillTo: {
        en: `Bill To`,
        fr: `Facturer à`
    },
    Invoice: {
        en: 'Invoice',
        fr: 'Facture'
    }
};

const errors = {
    errorDialog1: {
        en: `Not authenticated. You are being redirected to login page.`,
        fr: `Non authentifié. Vous êtes redirigé vers la page de connexion.`
    },
    errorDialog2: {
        en: `You do not have permissions to perform this action.`,
        fr: `Vous n'êtes pas autorisé à effectuer cette action.`
    },
    errorDialog3: {
        en: `The file you tried to upload is too large. Please check the size restrictions of the uploader.`,
        fr: `Le fichier que vous avez essayé de télécharger est trop volumineux. Veuillez vérifier les restrictions de taille du téléchargeur.`
    },
    errorDialog4: {
        en: `Unrecoverable error occurred. Please try again in a moment.`,
        fr: `Une erreur irrécupérable s'est produite. Veuillez réessayer dans un instant.`
    },
    errorDialog5: {
        en: `The file you tried to upload is too large {{size}}. Please check the size restrictions of the uploader.`,
        fr: `Le fichier que vous avez essayé de télécharger est trop volumineux. Veuillez vérifier les restrictions de taille du téléchargeur.`
    },
    errorDialog6: {
        en: `Please check the size restrictions of the uploader.`,
        fr: `Veuillez vérifier les restrictions de taille du téléchargeur.`
    },
    errorDialog7: {
        en: `Update required`,
        fr: `Mise à jour requise`
    },
    errorDialog8: {
        en: `Your application is out of date. Please update before retrying.`,
        fr: `Votre application n'est plus à jour. Veuillez mettre à jour avant de réessayer.`
    },
    errorDialog9: {
        en: `An error occurred due to version mismatch. Please re-open your browser before retrying.`,
        fr: `Une erreur s'est produite en raison d'une incompatibilité de version. Veuillez rouvrir votre navigateur avant de réessayer.`
    },
    errorDialog10: {
        en: `Server maintenance`,
        fr: `Maintenance du serveur`
    },
    errorDialog11: {
        en: `We are performing quick server maintenance. Please check back in a few minutes!`,
        fr: `Nous effectuons une maintenance rapide du serveur. Veuillez réessayer dans quelques minutes!`
    },
    errorDialog12: {
        en: `Sorry for the inconvenience.`,
        fr: `Désolé pour le dérangement.`
    },
    errorDialog13: {
        en: `Account Banned`,
        fr: `Compte banni`
    },
    errorDialog14: {
        en: `Your account has been banned, please contact us at`,
        fr: `Votre compte a été banni, veuillez nous contacter à {link} pour plus d'informations.`
    },
    errorDialog15: {
        en: `for more information.`,
        fr: ``
    },
    errorDialog16: {
        en: `Too many requests made. Please try again in a moment.`,
        fr: `Trop de demandes. Veuillez réessayer dans un instant.`
    }
};

const snackbar = {
    verificationLinkSent: {
        en: `Sent verification link successfully, check your email.`,
        fr: `Lien de vérification envoyé avec succès, vérifiez votre courrier électronique.`
    },
    verificationLinkFailed: {
        en: `Error sending verification link.`,
        fr: `Erreur lors de l'envoi du lien de vérification.`
    },
    authorizationEmailFailed: {
        en: `Error sending authentication link.`,
        fr: ``
    },
    oneStepPickupFail: {
        en: `Could not confirm your pickup. Please try again or log in to confirm your pickup from your account.`,
        fr: `Impossible de confirmer votre prise en charge. Veuillez réessayer ou vous connecter pour confirmer votre retrait depuis votre compte.`
    },
    errorSubmittingTip: {
        en: `Error submitting tip.`,
        fr: `Erreur lors de l'envoi du conseil.`
    },
    thanksForFeedback: {
        en: `Thank you for the feedback. We will get back to you as soon as we can.`,
        fr: `Merci pour votre retour. Nous vous répondrons dès que possible.`
    },
    copiedReferraLink: {
        en: `Referral link copied to your clipboard.`,
        fr: `Lien de parrainage copié dans votre presse-papiers.`
    },
    copiedToClipboard: {
        en: `Copied {{str}} to your clipboard!`,
        fr: `Copié {{str}} dans votre presse-papier !`
    },
    copyFailed: {
        en: `Unable to copy value to clipboard`,
        fr: `Impossible de copier la valeur dans le presse-papiers`
    },
    refererNotQualified: {
        en: 'Referer not qualified for referrals',
        fr: 'Référent non qualifié pour les références'
    },
    unableToApplyPromo: {
        en: 'Unable to apply promo',
        fr: 'Impossible d’appliquer la promotion'
    },
    orgLinkInvalid: {
        en: `${terms.organization.en} link is invalid.`,
        fr: `Le lien ${terms.organization.en} n’est pas valide.`
    },
    orgLinkExpired: {
        en: `This campaign is no longer accepting donations. We appreciate your interest and support. Please adjust your donation preference accordingly. Thank you!`,
        fr: `Cette campagne n'accepte plus de dons. Nous apprécions votre intérêt et votre soutien. Veuillez ajuster vos préférences de don en conséquence. Merci!`
    },
    campaignClosed: {
        en: `Campaign Closed`,
        fr: `Campagne clôturée`
    },
    fileTooLarge: {
        en: `Image file size too large. Max file size of 1MB`,
        fr: `Taille du fichier image trop grande. Taille maximale du fichier de 1 Mo`
    },
    uploadLogoFirst: {
        en: `Please upload your logo first.`,
        fr: `Veuillez d’abord télécharger votre logo.`
    },
    invalidImageFormat: {
        en: `Invalid image format.`,
        fr: `Format d'image invalide.`
    },
    badRequest: {
        en: `Bad request.`,
        fr: `Mauvais demande`
    },
    somethingWentWrong: {
        en: `Something went wrong. Please try again in a moment.`,
        fr: `Quelque chose a mal tourné. Veuillez réessayer dans un instant.`
    },
    authCodeSent: {
        en: `Authentication Code Sent`,
        fr: `Code d’authentification envoyé`
    },
    identityVerified: {
        en: `Identity Verified`,
        fr: `Identité Vérifiée`
    },
    unauthorized: {
        en: `You do not have permissions to perform that action`,
        fr: `Vous n'avez pas les autorisations pour effectuer cette action`
    },
    tooManyAttempts: {
        en: `Too many failed attempts, try again later`,
        fr: `Trop de tentatives infructueuses, réessayez plus tard`
    },
    connectionErr: {
        en: `Connection error occurred`,
        fr: `Une erreur de connexion s’est produite`
    },
    invalidAuthCode: {
        en: `Invalid authentication code`,
        fr: `Code d’authentification non valide`
    },
    errorVerifyingCreds: {
        en: `Error verifying user credentials`,
        fr: `Erreur lors de la vérification des informations d’identification de l’utilisateur`
    },
    serverUnreachable: {
        en: `Server is unreachable`,
        fr: `Le serveur est inaccessible`
    },
    serverError: {
        en: `Server error occurred`,
        fr: `Une erreur de serveur s’est produite`
    },
    contactFormSuccess: {
        en: `Thank you for reaching out! We will get back to you within a few days.`,
        fr: `Merci d’avoir tendu la main! Nous reviendrons vers vous dans quelques jours.`
    },
    emailMatchCheck: {
        en: 'If the e-mail matches, you will receive a reset link shortly.',
        fr: 'Si le courriel correspond, vous recevrez un lien de réinitialisation sous peu.'
    },
    enterAuthCode: {
        en: 'Please enter authentication code',
        fr: 'Veuillez saisir le code d’authentification'
    },
    authInterrupted: {
        en: 'Authentication popup process was interrupted or request timed out. Please try again.',
        fr:
            'Le processus de fenêtre contextuelle d’authentification a été interrompu ou la demande a expiré. Veuillez réessayer.'
    },
    invalidCreds: {
        en: 'Invalid email or password',
        fr: 'Adresse courriel ou mot de passe non valide'
    },
    emailOAuthAlreadyRegistered: {
        en: 'Email already registered using {{provider}} provider',
        fr: 'Courriel déjà enregistré à l’aide du fournisseur {{provider}}'
    },
    authCodeFailed: {
        en: 'Authentication code is incorrect or may have expired',
        fr: 'Le code d’authentification est incorrect ou a peut-être expiré'
    },
    identityVerificationFailed: {
        en: 'We could not verify your identity based on the information provided',
        fr: `Nous n'avons pas pu vérifier votre identité sur la base des informations fournies`
    },
    verificationSuccessful: {
        en: 'Verification Successful',
        fr: `Vérification réussie`
    },
    invalidOAuthProvider: {
        en: 'Invalid auth provider supplied',
        fr: "Fournisseur d'authentification non valide fourni"
    },
    OAuthOtherApp: {
        en: 'Unable to use OAuth inside of another application',
        fr: "Impossible d'utiliser OAuth dans une autre application"
    },
    errorOAuthVia: {
        en: 'Error authenticating via {{providerType}}',
        fr: "Erreur d'authentification via {{providerType}}"
    },
    googleAlreadyLinked: {
        en:
            'It appears you already have an account linked with your Google account. Please sign in with Google authentication. If you are unable to access your account, please contact us at {{serviceEmailAddress}}',
        fr:
            "Il semble que vous ayez déjà un compte lié à votre compte Google. Veuillez vous connecter avec l'authentification Google. Si vous ne parvenez pas à accéder à votre compte, veuillez nous contacter à {{serviceEmailAddress}}"
    },
    facebookAlreadyLinked: {
        en:
            'It appears you already have an account linked with your Facebook account. Please sign in with Facebook authentication. If you are unable to access your account, please contact us at {{serviceEmailAddress}}',
        fr:
            "Il semble que vous ayez déjà un compte lié à votre compte Facebook. Veuillez vous connecter avec l'authentification Facebook. Si vous ne parvenez pas à accéder à votre compte, veuillez nous contacter à {{serviceEmailAddress}}"
    },
    errorAuthViaOAuth: {
        en: 'Unable to authenticate with oAuth, please use a different method or try again.',
        fr: 'Impossible de vous authentifier avec oAuth, veuillez utiliser une autre méthode ou réessayer.'
    },
    authProcessInterrupted: {
        en: 'Authentication popup process was interrupted. Please try again.',
        fr: "Le processus contextuel d'authentification a été interrompu. Veuillez réessayer."
    },
    authPopupBlocked: {
        en: 'Popup was blocked by the browser. Unable to complete authentication process',
        fr: "La popup a été bloquée par le navigateur. Impossible de terminer le processus d'authentification"
    },
    oAuthGenericError: {
        en: 'An error occurred during the authentication process',
        fr: "Une erreur s'est produite lors du processus d'authentification"
    },
    unexpectedAccountType: {
        en: 'Unexpected account type.',
        fr: 'Type de compte inattendu.'
    },
    updatedNotificationSettings: {
        en: 'Successfully updated your notification settings.',
        fr: 'Vos paramètres de notification ont bien été mis à jour.'
    },
    clearTrustedDevices: {
        en: 'Successfully cleared list of trusted devices.',
        fr: 'Effacer avec succès la liste des appareils approuvés.'
    },

    changedPassword: {
        en: 'Successfully changed your password.',
        fr: 'Votre mot de passe a été modifié avec succès.'
    },
    changePasswordFailed: {
        en: 'Error! Check your current password and try again.',
        fr: 'Erreur! Vérifiez votre mot de passe actuel et réessayez.'
    },
    verificationEmailSent: {
        en: 'Verification email has been sent.',
        fr: 'Un courriel de vérification a été envoyé.'
    },
    emailVerified: {
        en: 'Email verified.',
        fr: 'E-mail vérifié.'
    },
    authorizationEmailSent: {
        en: 'A link to authorize {{email}} has been sent to {{accountEmail}}.',
        fr: `Un lien pour autoriser {{email}} a été envoyé à {{accountEmail}}..`
    },
    emailAuthorizationRemoved: {
        en: 'Authorization for {{email}} has been removed.',
        fr: `L'autorisation pour {{email}} a été supprimée.`
    },
    duplicateAuthorizationEmail: {
        en: 'You have already request authorization for {{email}}',
        fr: 'Vous avez déjà demandé une autorisation pour {{email}}'
    },
    accountDeleteFailed: {
        en: 'Unable to delete account. Please Try again in a moment.',
        fr: 'Impossible de supprimer le compte. Veuillez réessayer dans un instant.'
    },
    accountDeleteSucceeded: {
        en: 'Your account has been deleted.',
        fr: 'Impossible de supprimer le compte. Veuillez réessayer dans un instant.'
    },
    accountDeleteSucceededWithCashOut: {
        en: 'Your account has been deleted and your cashout request is being processed.',
        fr: 'Impossible de supprimer le compte. Veuillez réessayer dans un instant.'
    },
    updatedProfile: {
        en: 'Successfully updated your profile.',
        fr: 'Votre profil a été mis à jour avec succès.'
    },
    submissionInProgress: {
        en: 'Submission in progress. Please try again in a moment.',
        fr: 'Soumission en cours. Veuillez réessayer dans un instant.'
    },
    accountClosed: {
        en: 'Your account is closed. Please contact us at {{servicePhone}}',
        fr: 'Votre compte est fermé. Veuillez nous contacter au {{servicePhone}}.'
    },
    accountSuspended: {
        en: 'Your account is suspended. Please contact us at {{servicePhone}}.',
        fr: 'Votre compte est suspendu. Veuillez nous contacter au {{servicePhone}}.'
    },
    accountDeleted: {
        en: 'Your account is deleted. Please contact us at {{servicePhone}}.',
        fr: 'Votre compte est supprimé. Veuillez nous contacter au {{servicePhone}}.'
    },
    updatedProfileWithEmailAuthentication: {
        en: 'Please check {{email}} to confirm these changes.',
        fr: 'Veuillez vérifier {{email}} pour confirmer ces modifications'
    },
    resetLinkSent: {
        en: 'Password reset link sent, check your email.',
        fr: 'Lien de réinitialisation de mot de passe envoyé, vérifiez vos courriels.'
    },
    resetLinkNotSent: {
        en: 'Error sending password reset link.',
        fr: 'Erreur lors de l’envoi du lien de réinitialisation du mot de passe.'
    },
    deviceSignOut: {
        en: 'Device successfully signed out',
        fr: `L'appareil s'est déconnecté avec succès`
    },
    deviceRemoved: {
        en: 'Device successfully removed',
        fr: `Appareil supprimé avec succès`
    },
    uploadPermissionError: {
        en: 'You must give the app permission to access your images',
        fr: `Vous devez autoriser l'application à accéder à vos images`
    },
    cameraPermissionError: {
        en: 'You must give the app permission to access your camera',
        fr: `Vous devez autoriser l'application à accéder à votre caméra`
    },
    photoProcessingError: {
        en: 'An error occurred while processing your photo. Please try again.',
        fr: `Une erreur s'est produite lors du traitement de votre photo. Veuillez réessayer.`
    },
    pendingApproval: {
        en: 'Pending Approval',
        fr: 'En attente de validation'
    }
};

const redemptionTranslations = {
    redemption1: {
        en: 'Cash Out',
        fr: 'Remboursement'
    },
    redemption1Exp: {
        en: 'Redemption Options',
        fr: 'Options de Rachat'
    },
    redemption2: {
        en: 'How would you like to cash out?',
        fr: 'Comment souhaitez-vous retirer votre argent?'
    },
    redemption3: {
        en: 'Please select one of the available cash out options.',
        fr: "Veuillez sélectionner l'une des options de retrait disponibles."
    },
    redemption4: {
        en: 'Interac e-Transfer',
        fr: 'Virement Interac'
    },
    redemption5: {
        en: 'Cheque',
        fr: 'Chèque'
    },
    redemption6: {
        en: 'Bank Transfer',
        fr: 'Virement bancaire'
    },
    redemption7: {
        en: 'Cash',
        fr: 'En espèces'
    },
    redemption8: {
        en: 'E-Transfer information',
        fr: 'Informations sur le virement électronique'
    },
    redemption8a: {
        en: 'E-Transfer security',
        fr: 'Sécurité des virement électronique'
    },
    redemption9: {
        en:
            'Enter the email address to send the {{name}} to. You will have to enter your passphrase to accept the transfer.',
        fr:
            "Entrez l'adresse courriel à laquelle envoyer le {{name}}. Vous devrez entrer votre mot de passe pour accepter le transfert."
    },
    redemption10: {
        en: 'Enter a passphrase you will use to accept the e-transfer email.',
        fr: 'Entrez une phrase que vous utiliserez pour accepter le transfert électronique reçu par courriel.'
    },
    redemption11: {
        en: 'Do NOT use your account password as your passphrase.',
        fr: "N'utilisez PAS le mot de passe de votre compte comme phrase."
    },
    redemption12: {
        en:
            'You need to enter your security answer (below) to accept the e-Transfer. Please remember (write it down) your security answer.',
        fr:
            'Vous devez saisir votre réponse de sécurité (ci-dessous) pour accepter le virement électronique. Veuillez vous souvenir (notez-le) de votre réponse de sécurité.'
    },
    redemption13: {
        en: 'Security question',
        fr: 'Question de sécurité'
    },
    redemption14: {
        en: 'who is my favourite sports star?',
        fr: 'quelle est ma star sportive préférée?'
    },
    redemption15: {
        en: 'what is my favourite tv show?',
        fr: 'quelle est mon émission de télévision préférée ?'
    },
    redemption16: {
        en: 'what is my favourite food?',
        fr: 'quelle est mon plat préféré?'
    },
    redemption17: {
        en: 'who is my favourite movie star?',
        fr: 'quelle est ma star de cinéma préférée?'
    },
    redemption18: {
        en: 'what is my favourite movie?',
        fr: 'quel est mon film préféré?'
    },
    redemption19: {
        en: 'Answer',
        fr: 'réponse'
    },
    redemption20: {
        en: 'Answer should be a combination of 6-15 letters or numbers.',
        fr: 'La réponse doit être une combinaison de 6 à 15 lettres ou chiffres.'
    },
    redemption21: {
        en: 'Answer should only contain letters and numbers with no spaces.',
        fr: 'La réponse ne doit contenir que des lettres et des chiffres sans espaces.'
    },
    redemption22: {
        en: 'Summary',
        fr: 'Sommaire'
    },
    redemption23a: {
        en: 'There is {{method}} fee of {{amount}}.',
        fr: "Des frais de {{method}} de {{amount}} s'appliquent."
    },
    redemption23b: {
        en: 'You should receive an {{method}} in 1 business day.',
        fr: 'Vous devriez recevoir un {{method}} dans un délai de 1 jour ouvrable.'
    },
    redemption24: {
        en: 'Write down your security answer',
        fr: 'Notez votre réponse de sécurité'
    },
    redemption25a: {
        en: 'Your security answer is ',
        fr: 'Votre réponse de sécurité est '
    },
    redemption25b: {
        en: '. Please write this down as you will need to provide this to your bank to complete the e-Transfer',
        fr: ". Veuillez l'écrire car vous devrez le fournir à votre banque pour effectuer le virement électronique."
    },
    redemption26: {
        en: 'Fee',
        fr: 'Frais'
    },
    redemption27: {
        en: 'Net amount',
        fr: 'Montant net'
    },
    redemption28: {
        en: 'Interac e-Transfer (${{limit}} yearly limit)',
        fr: 'Virement Interac (limite annuelle de {{limit}} $)'
    },
    redemption29: {
        en: 'Interac e-Transfer (Balance needs to be over ${{limit}} to cash out via Interac e-Transfer)',
        fr: 'Virement Interac (le solde doit être supérieur à {{limit}}  $ pour être échangé avec le virement Interac)'
    },
    redemption30: {
        en: 'Cheque information',
        fr: 'Information du chèque'
    },
    redemption31: {
        en: 'Enter the name on the account you would like the cheque to be made out to.',
        fr: 'Entrez le nom du compte sur lequel vous souhaitez que le chèque soit libellé.'
    },
    redemption32: {
        en: 'Account Name',
        fr: 'nom du compte'
    },
    redemption33: {
        en: 'Street Address',
        fr: 'adresse'
    },
    redemption34: {
        en: 'City',
        fr: 'ville'
    },
    redemption35: {
        en: 'Postal Code',
        fr: 'code postal'
    },
    redemption36: {
        en: 'Country',
        fr: 'pays'
    },
    redemption37: {
        en: 'This field is required',
        fr: 'Ce champ est requis'
    },
    redemption38: {
        en: 'There is a cheque fee of $1.50.You should receive a cheque in 3-5 business days.',
        fr:
            "Des frais de chèque de 1,50 $ s'appliquent. Vous devriez recevoir un chèque dans un délai de 3 à 5 jours ouvrables."
    },
    redemption39: {
        en: 'Cheque (Balance needs to be over ${{minimum}} to receive a cheque)',
        fr: 'Chèque (le solde doit être supérieur à {{minimum}} $ pour être échangé avec un chèque)'
    },
    redemption40: {
        en: 'Bank transfer information',
        fr: 'Informations sur le virement bancaire'
    },
    redemption41: {
        en: 'Account Number',
        fr: 'numéro de compte'
    },
    redemption42: {
        en: 'should be 7-12 digits',
        fr: 'devrait être un numéro de 7 à 12 chiffres'
    },
    redemption43: {
        en: 'Transit Number',
        fr: 'Numéro de transit'
    },
    redemption44: {
        en: 'should be 5 digits',
        fr: 'devrait être de 5 chiffres'
    },
    redemption45: {
        en: 'institution number',
        fr: "numéro d'établissement"
    },
    redemption46: {
        en: 'should be 3 digits',
        fr: 'devrait être de 3 chiffres'
    },
    redemption47: {
        en: 'should only contain digits',
        fr: 'ne doit contenir que des chiffres'
    },
    redemption48: {
        en: 'There is a Bank Transfer fee of $1.50.You should receive a Bank Transfer in 3-5 business days.',
        fr:
            "Des frais de virement bancaire de 1,50 $ s'appliquent. Vous devriez recevoir un virement bancaire dans un délai de 3 à 5 jours ouvrés."
    },
    redemption49: {
        en: 'Cash (Balance needs to be over ${{minimum}} to cash out)',
        fr: 'Espèces (le solde doit être supérieur à {{minimum}} $ pour pouvoir être échangé en espèces)'
    },
    redemption50: {
        en: 'You are about to cash out {{balance}} to {{{charity}}} via {{method}}. Is this correct?',
        fr: "Vous êtes sur le point d'échanger {{balance}} avec {{{charity}}} via {{method}}. Est-ce correct?"
    },
    redemption51: {
        en: 'You have requested a cash out!',
        fr: 'Vous avez demandé un remboursement!'
    },
    redemption52: {
        en: 'No cash out methods available at this moment.',
        fr: 'Aucune méthode de retrait disponible pour le moment.'
    },
    redemption53: {
        en: 'Cannot cash out an amount less than $0',
        fr: "Impossible d'échanger un montant inférieur à 0 $"
    },
    redemption54a: {
        en: 'A minimum of ',
        fr: 'Un minimum de '
    },
    redemption54b: {
        en: ' is required to cash out.',
        fr: ' est requis pour effectuer un remboursement.'
    },
    redemption55: {
        en: 'You may donate regardless of your balance.',
        fr: 'Vous pouvez faire un don quel que soit votre solde.'
    },
    redemption56: {
        en: 'You currently do not have a balance to cash out.',
        fr: "Vous n'avez actuellement aucun solde à remboursé."
    },
    redemption57: {
        en: 'Current balance:',
        fr: 'Solde actuel:'
    },
    redemption58: {
        en: 'sent',
        fr: 'expédié'
    },
    redemption59: {
        en: 'requested  to',
        fr: 'demandé à'
    },
    redemption60: {
        en: 'Your charity must be approved before cashing out.',
        fr: 'Votre organisme de bienfaisance doit être approuvé avant de procéder à un remboursement'
    },
    redemption61: {
        // eslint-disable-next-line no-template-curly-in-string
        en: 'Bank Transfer (Balance needs to be over ${{minimum}} to receive a bank transfer)',
        // eslint-disable-next-line no-template-curly-in-string
        fr: 'Virement bancaire (le solde doit être supérieur à ${{minimum}} pour être échangé par virement bancaire)'
    },
    redemption62: {
        en: 'You are about to donate {{balance}} to {{{charity}}}. Is this correct?',
        fr: 'Vous êtes sur le point de faire un don {{balance}} à {{{charity}}}. Est-ce exact?'
    },
    redemption63: {
        en: 'You have donated your returns to {{{charity}}}!',
        fr: 'Vous avez fait don de votre consigne à {{{charity}}}!'
    },
    redemption64: {
        en: 'Bank transfer information from previous bank transfer:',
        fr: 'Informations sur le virement bancaire du remboursement précédent:'
    },
    redemption65: {
        en: 'Cash out method',
        fr: 'Méthode de retrait'
    },
    redemption66: {
        en: 'Redeem Outstanding Balance',
        fr: 'Échanger le solde impayé'
    },
    redemption67: {
        en: 'Province',
        fr: 'Province'
    },
    redemption68: {
        en: 'You may redeem with a cheque if you are over your e-Transfer limit.',
        fr: 'Vous pouvez échanger avec un chèque si vous dépassez votre limite de transfert électronique.'
    },
    redemption69: {
        en: `The following {{userAmount}} users have pending redemptions linked to the infractions you resolved. If you believe there's no foul play, leave the requests selected to approve them. If you suspect any users of bad intentions, unselect those and their redemption requests will be reversed.`,
        fr: `Les {{userAmount}} utilisateurs suivants ont des rachats en attente liés aux infractions que vous avez résolues. Si vous pensez qu'il n'y a pas d'acte criminel, laissez les demandes sélectionnées pour les approuver. Si vous soupçonnez des utilisateurs de mauvaises intentions, désélectionnez-les et leurs demandes de rachat seront annulées.`
    },
    directDeposit: {
        en: 'Direct Deposit',
        fr: 'Dépôt direct'
    },
    customerSubscription1: {
        en: 'Customer Subscriptions',
        fr: 'Abonnements clients'
    },
    customerSubscription2: {
        en: 'Currently no fees on this customer',
        fr: 'Actuellement aucun frais sur ce client'
    },
    customerSubscription3: {
        en:
            'Outstanding subscriptions will be charged onto your next pickup. If you have any questions or concerns about any of your subscriptions, please contact us at',
        fr:
            "Les abonnements en cours seront facturés sur votre prochain ramassage. Si vous avez des questions ou des préoccupations concernant l'un de vos abonnements, veuillez nous contacter à"
    },
    apiStatusSent: { en: 'Sent: Awaiting confirmation from API', fr: "Envoyé: en attente de confirmation de l'API" },
    apiStatusReceived: {
        en: 'Received: Awaiting acceptance by customer',
        fr: "Reçu : En attente d'acceptation par le client"
    },
    apiStatusRejected: { en: 'Rejected', fr: 'Rejeté' },
    apiStatusAccepted: { en: 'Accepted: Deposited in customer account', fr: 'Accepté : Déposé sur le compte client' },
    timeSpanHours: {
        en: '{{time1}} to {{time2}} hours',
        fr: '{{time1}} à {{time2}} heures'
    },
    timeSpanBusinessHours: {
        en: '{{time1}} to {{time2}} business hours',
        fr: '{{time1}} à {{time2}} heures ouvrables'
    },
    timeSpanDays: {
        en: '{{time1}} to {{time2}} days',
        fr: '{{time1}} à {{time2}} jours'
    },
    timeSpanBusinessDays: {
        en: '{{time1}} to {{time2}} business days',
        fr: '{{time1}} à {{time2}} jours ouvrables'
    },
    timeSpanWeeks: {
        en: '{{time1}} to {{time2}} weeks',
        fr: '{{time1}} à {{time2}} semaines'
    },
    maxTimeHours: {
        en: '{{time2}} hours',
        fr: '{{time2}} heures'
    },
    maxTimeDays: {
        en: '{{time2}} days',
        fr: '{{time2}} jours'
    },
    maxTimeWeeks: {
        en: '{{time2}} weeks',
        fr: '{{time2}} semaines'
    }
};

const impactReportTranslations = {
    impactReport1: {
        en: 'Environmental Impact Report',
        fr: "Rapport d'impact environnemental"
    },
    impactReport2a: {
        en: 'Keep it up {{user}}!',
        fr: 'Continuez comme ça {{user}}!'
    },
    impactReport2b: {
        en:
            ' Recycling helps keep our landfills clear, reduces greenhouse gas emissions, and lowers your carbon footprint.',
        fr:
            " Le recyclage aide à détourner la matière des sites d'enfouissement, à réduire les émissions de gaz à effet de serre et à réduire votre empreinte carbone."
    },
    impactReport3a: {
        en: 'As of ',
        fr: 'Au '
    },
    impactReport3b: {
        en: ' you have:',
        fr: ' vous avez :'
    },
    impactReport4: {
        en: 'Carbon Emissions Avoided',
        fr: 'Émissions de carbone évitées'
    },
    impactReport5: {
        en: 'Energy saved',
        fr: 'Énergie économisée'
    },
    impactReport6: {
        en: 'Spared from landfill',
        fr: "Détourné du site d'enfouissement"
    },
    impactReport7: {
        en: 'Get Others To Participate - Share Now!',
        fr: 'Invitez les autres à participer - Partagez maintenant!'
    },
    impactReport8: {
        en: 'Active Bins',
        fr: 'Bacs actifs'
    },
    share: {
        en: 'Share',
        fr: 'Partager'
    },
    impactReport9: {
        en: 'No thanks',
        fr: 'Non merci'
    },
    impactReport10: {
        en: 'Continue',
        fr: 'Continuer'
    },
    impactReport11: {
        en: 'That is equivalent to {{count}} cars off the road this month!',
        fr: ''
    },
    impactReport12: {
        en: 'Total returns',
        fr: 'Montants cummulatif'
    },
    impactReport13: {
        en: 'Total donated',
        fr: 'Nombre de dons'
    },
    impactReport14: {
        en: 'Total containers',
        fr: 'Nombre de contenants'
    },
    impactReport15: {
        en: 'Total bags',
        fr: 'Nombre de sacs'
    },
    impactReport16: {
        en: 'Total electronics',
        fr: "Nombre d'appareils électroniques"
    },
    impactReport17: {
        en: 'Water saved',
        fr: 'Eau économisée'
    }
};

const account = {
    accountDisabled1: {
        en: 'Account Closed',
        fr: 'Compte désactivé'
    },
    accountDisabled2: {
        en: 'Your account is closed. Click the button below to reopen it.',
        fr: 'Votre compte est désactivé. Cliquez sur le bouton ci-dessous pour le réactiver.'
    },
    accountDisabled3: {
        en: 'Reopen Account',
        fr: 'Réactiver le compte'
    },
    accountDisabled4: {
        en: 'Your account has been closed.\nIf this is an error, contact {{brandname}} at {{contact}}.',
        fr: "Votre compte a été fermé.\nS'il s'agit d'une erreur, contactez {{brandname}} à {{contact}}."
    },

    disableAccount1: {
        en: `Are you sure you want to close your account?`,
        fr: `Voulez-vous vraiment désactiver votre compte?`
    },
    disableAccount2: {
        en: `You still have pending pickups for today. Closing your account will cancel all pending pickups.`,
        fr: `Vous avez encore des ramassages en attente pour aujourd'hui. La désactivation de votre compte annulera tous les ramassages en attente.`
    },
    disableAccount3: {
        en: `Why are you closing your account?`,
        fr: `Pourquoi désactivez-vous votre compte ?`
    },
    disableAccount4: {
        en: `I already have an account`,
        fr: `J'ai déjà un compte`
    },
    disableAccount5: {
        en: `I don't like this service`,
        fr: `Je n'aime pas ce service`
    },
    disableAccount6: {
        en: `I'm recycling a different way`,
        fr: `Je recycle autrement`
    },
    disableAccount7: {
        en: `Other`,
        fr: `Autre`
    },
    disableAccount8: {
        en: `What can we do better?`,
        fr: `Que peut-on ameliorer?`
    },
    disableAccount9: {
        en: `What is your preferred method?`,
        fr: `Quelle est votre méthode préférée`
    },

    twoFactorAuthentication1: {
        en: `This action requires additional verification`,
        fr: `Cette action nécessite une vérification supplémentaire`
    },
    twoFactorAuthentication2: {
        en: `Please select your preferred method to receive your authentication code at {{formattedPhoneNumber}} and then enter the code below.`,
        fr: `Veuillez sélectionner votre méthode préférée pour recevoir votre code d'authentification à {{formattedPhoneNumber}}, puis entrez le code ci-dessous.`
    },
    twoFactorAuthentication3: {
        en: 'Send code via text message',
        fr: 'Envoyer le code par SMS'
    },
    twoFactorAuthentication4: {
        en: 'Send code via voice call',
        fr: 'Envoyer le code par appel vocal'
    },

    deleteAccount1: {
        en: `Are you sure you want to delete your account?`,
        fr: `Êtes-vous sûr de vouloir supprimer votre compte?`
    },
    deleteAccount2a: {
        en: `You have a remaining account balance of {{balance}}. Before deleting your account`,
        fr: `Il vous reste un solde de compte de {{balance}}. Avant de supprimer votre compte`
    },
    deleteAccount2b: {
        en: `you must redeem your remaining balance!`,
        fr: `vous devez utiliser votre solde restant!`
    },
    deleteAccount3a: {
        en: `All of your account data will be deleted.`,
        fr: `Toutes les données de votre compte seront supprimées.`
    },
    deleteAccount3b: {
        en: `THIS CANNOT BE UNDONE.`,
        fr: `ÇA NE PEUT PAS ÊTRE ANNULÉ.`
    },
    deleteAccount3c: {
        en: `To proceed with deleting your account, enter the word delete in the text box below and then press the confirmation button.`,
        fr: `Pour procéder à la suppression de votre compte, saisissez le mot supprimer dans la zone de texte ci-dessous, puis appuyez sur le bouton de confirmation.`
    },

    accountSettings1: {
        en: "I'd like to receive a tax receipt when donating",
        fr: "Je souhaite recevoir un reçu fiscal lors d'un Don"
    },
    accountSettings2: {
        en: 'Use two-factor authentication',
        fr: "Utiliser l'authentification à deux facteurs"
    },
    accountSettings2a: {
        en:
            'Authentication codes will be sent - users will be unable to sign in if the number associated with their account is invalid',
        fr:
            "Les codes d'authentification seront envoyés - les utilisateurs ne pourront pas se connecter si le numéro associé à leur compte est invalide"
    },
    accountSettings3: {
        en: 'Current password',
        fr: 'Mot de passe actuel'
    },
    accountSettings4: {
        en: 'New password',
        fr: 'Nouveau mot de passe'
    },
    accountSettings5: {
        en: 'Repeat new password',
        fr: 'Répété le nouveau mot de passe'
    },
    accountSettings6: {
        en: 'Passwords should match',
        fr: 'Les mots de passe doivent correspondre'
    },
    accountSettings7: {
        en: 'Notification Settings',
        fr: 'Paramètres de Notification'
    },
    accountSettings8: {
        en: 'Email Notifications',
        fr: 'Notifications par e-mail'
    },
    accountSettings8a: {
        en: 'Essential Email Notifications',
        fr: 'Notifications essentielles par e-mail'
    },
    accountSettings8b: {
        en:
            'Essential emails are emails that still get sent when a user disables their email notifications. These include pickup, confirmation, and other warning emails.',
        fr:
            "Les e-mails essentiels sont des e-mails qui sont toujours envoyés lorsqu'un utilisateur désactive ses notifications par e-mail. Ceux-ci incluent le ramassage, la confirmation et d’autres e-mails d’avertissement."
    },
    accountSettings9: {
        en: 'SMS Notifications',
        fr: 'Notifications SMS'
    },
    accountSettings10: {
        en: 'Push Notifications',
        fr: 'Notifications App'
    },
    accountSettings11: {
        en: 'Push Notifications (App not installed)',
        fr: 'Notifications App (App non installée)'
    },
    accountSettings12: {
        en: 'Donation Notifications',
        fr: 'Notifications de dons'
    },
    accountSettings13: {
        en: 'Reopen Account',
        fr: 'Réactiver le compte'
    },
    accountSettings14: {
        en: 'Close Account',
        fr: 'Désactiver le Compte'
    },
    accountSettings15: {
        en: `Reopen Account to start receiving notifications from ${app}`,
        fr: `Réactiver le compte pour commencer à recevoir des notifications de ${app}`
    },
    accountSettings16a: {
        en: `Close your account to stop receiving notifications from ${app}.`,
        fr: `Désactivez votre compte pour ne plus recevoir de notifications de ${app}.`
    },
    accountSettings16b: {
        en: `This will cancel all of your unfinished pickups.`,
        fr: `Cela annulera tous vos ramassages incomplets.`
    },

    accountSettings17: {
        en: `Delete Account`,
        fr: `Supprimer le Compte`
    },

    accountSettings18: {
        en: `Action required`,
        fr: `Action requise`
    },
    accountSettings19: {
        en: `Please fill out missing information in your profile.`,
        fr: `Veuillez remplir les informations manquantes dans votre profil.`
    },
    accountSettings20: {
        en: 'Profile Incomplete',
        fr: 'Profil non complété'
    },
    accountSettings21: {
        en: 'The following information needs to be updated:',
        fr: 'Les informations suivantes doivent être mises à jour:'
    },
    accountSettings22: {
        en: `Please update this information on the Account page to complete your registration.`,
        fr: `Veuillez mettre à jour ces informations sur la page Compte pour terminer votre inscription.`
    },
    accountSettings23: {
        en: `Please verify your email before proceeding. You should have received an email at {{email}}. You can change your email in the form below and get a verification letter sent to a new address.`,
        fr: `Veuillez vérifier votre adresse courriel avant de continuer. Vous devriez avoir reçu un courriel à l’adresse {{email}}. Vous pouvez modifier votre adresse courriel dans le formulaire ci-dessous et recevoir une lettre de vérification envoyée à une nouvelle adresse.`
    },
    accountSettings24: {
        en: `Send again`,
        fr: `Envoyer à nouveau`
    },
    accountSettings25: {
        en: `Clear Trusted Devices`,
        fr: `Oublier les appareils jumelés`
    },
    accountSettings26: {
        en: `Viewing Access`,
        fr: `Accès à la visualisation`
    },
    accountSettings27: {
        en: `The following users have viewing access to your account.`,
        fr: `Les utilisateurs suivants ont un accès visuel à votre compte.`
    },
    accountSettings28: {
        en: 'Doing Business As',
        fr: 'Faire des affaires comme'
    },
    accountSettings28a: {
        en: 'Trading Name',
        fr: 'Faire des affaires comme'
    },
    accountSettings29: {
        en: 'Registered Business Name',
        fr: "Nom enregistré de l'entreprise"
    },
    accountSettings30: {
        en: 'Business Number',
        fr: 'Numéro d’entreprise'
    },
    accountSettings31: {
        en: 'If you need your account type changed, please contact us.',
        fr: 'Si vous avez besoin de modifier votre type de compte, veuillez nous contacter.'
    },
    accountSettings32: {
        en: 'Authorized Emails',
        fr: 'E-mails autorisés'
    },
    accountSettings33: {
        en: 'Welcome to the New Return-It Platform.',
        fr: 'Bienvenue sur la Nouvelle Plateforme Return-It'
    },
    accountSettings34: {
        en:
            'Please check your old email account ({{oldEmail}}) to approve the change to your new email account ({{newEmail}}).',
        fr:
            'Veuillez vérifier votre ancien compte de messagerie ({{oldEmail}}) pour approuver la modification de votre nouveau compte de messagerie ({{newEmail}}).'
    },
    accountSettings35a: {
        en: 'Approval will expire in {{minutes}} minute(s).',
        fr: "L'approbation expirera dans {{minutes}} minute(s)."
    },
    accountSettings35b: {
        en: 'Approval will expire in less than 1 minute.',
        fr: "L'approbation expirera dans moins d'une minute"
    },
    accountSettings35c: {
        en: 'Approval will expire in {{hours}} hour(s).',
        fr: "L'approbation expirera dans {{hours}} heure(s)."
    },
    accountSettings36: {
        en: 'Cancel Request',
        fr: "Demande d'annulation"
    },
    accountSettings36a: {
        en: 'Are you sure you want to cancel your pending email change request?',
        fr: 'Êtes-vous sûr de vouloir annuler votre demande de modification d’e-mail en attente ?'
    },
    accountSettings37: {
        en: 'Please confirm your password below',
        fr: 'Veuillez confirmer votre mot de passe ci-dessous'
    },
    accountSettings38: {
        en: 'To complete the transfer of your account to the new platform we need to confirm your password.',
        fr:
            'Pour finaliser le transfert de votre compte vers la nouvelle plateforme, nous devons confirmer votre mot de passe.'
    },
    accountSettings39: {
        en: 'Password',
        fr: 'Mot de passe'
    },
    accountSettings40: {
        en: 'Confirm Password',
        fr: 'Confirmez le mot de passe'
    },
    accountSettings41: {
        en: 'Business Type (select all that apply)',
        fr: `Type d'entreprise (sélectionnez tout ce qui s'applique)`
    },
    passwordReset1: {
        en: `Your password has been successfully changed! You can sign into your account with your new password.`,
        fr: `Votre mot de passe a été modifié avec succès! Vous pouvez vous connecter à votre compte avec votre nouveau mot de passe.`
    },
    passwordReset2: {
        en: `Change password`,
        fr: `Changer le mot de passe`
    },
    passwordReset3: {
        en: `New password`,
        fr: `Nouveau mot de passe`
    },
    passwordReset4: {
        en: `Repeat new password`,
        fr: `Répéter le nouveau mot de passe`
    },
    passwordReset5: {
        en: `Resend password reset link`,
        fr: `Renvoyer le lien de réinitialisation du mot de passe`
    },
    passwordReset6: {
        en: `Back to login`,
        fr: `Retour à la connexion`
    },
    passwordReset7: {
        en: `Passwords should match`,
        fr: `Les mots de passe doivent correspondre`
    },
    devices: {
        en: 'Devices',
        fr: `Dispositifs`
    }
};

const volumnReportTranslations = {
    volumeReport1: {
        en: 'Please select the date range for the report to be downloaded',
        fr: 'Veuillez sélectionner la plage de dates pour le rapport à télécharger'
    }
};

const pickupDialogTranslations = {
    appServiceType1: {
        en: 'Residential',
        fr: 'Résidentiel'
    },
    appServiceType2: {
        en: 'Residential Pickup',
        fr: 'Ramassage résidentiel'
    },
    appServiceType3: {
        en: 'Condo',
        fr: 'Condo'
    },
    appServiceType4: {
        en: 'Condo Pickup',
        fr: 'Ramassage en condo'
    },
    appServiceType5: {
        en: 'Commercial',
        fr: 'Commercial'
    },
    appServiceType6: {
        en: 'Commercial Pickup',
        fr: 'Ramassage commercial'
    },
    appServiceType7: {
        en: 'Drop&Go',
        fr: 'Dépôt de sacs'
    },
    appServiceType8: {
        en: 'Bottle Drive',
        fr: 'Collecte de contenants consignés'
    },
    appServiceType9: {
        en: 'refund',
        fr: 'remboursement'
    },
    pickupDialog1: {
        en: 'Select Service',
        fr: 'Sélectionnez le service'
    },
    pickupDialog2: !isMXDRegion()
        ? {
              en: 'What do you need?',
              fr: 'De quoi avez-vous besoin?'
          }
        : {
              en: 'Select the desired service',
              fr: 'Sélectionnez le service voulu'
          },
    pickupDialog3: {
        en:
            "Select this if you want a pickup from your house! A driver will arrive between 8 AM and 5 PM on the day you select. Don't worry, you will be able to see an estimated time of arrival in the app on the morning of your pickup. If you can't wait around, hide your bags outside and tell the driver where to look in the directions. Once picked up, we'll count your containers and credit your account, where you can keep or donate your refund. If possible, please place everything into large bags and label them with",
        fr:
            "Sélectionnez ceci si vous voulez un ramassage à la maison ! Un chauffeur arrivera entre 8h et 17h le jour sélectionnez. Ne vous inquiétez pas, vous pourrez voir une heure d'arrivée estimée dans l'application le matin de votre ramassage. Si vous ne pouvez pas attendre, cachez vos sacs à l'extérieur et dites au chauffeur où regarder dans les directives. Une fois ramassés, nous compterons vos contenants et créditerons votre compte, où vous pourrez conserver ou donner votre remboursement. Si possible, veuillez tout placer dans de grands sacs et les étiqueter avec"
    },
    pickupDialog4: {
        en: 'Residential Location',
        fr: 'Emplacement résidentiel'
    },
    pickupDialog5: {
        en: 'Where is the pickup?',
        fr: 'Où est le ramassage?'
    },
    pickupDialog6: {
        en: 'Where is the pickup?',
        fr: 'Où est le ramassage?'
    },
    pickupDialog7: {
        en: 'Unit number',
        fr: 'Numéro de local'
    },
    pickupDialog8: {
        en: 'Location',
        fr: 'Emplacement'
    },
    pickupDialog9: !isMXDRegion()
        ? {
              en:
                  'Your address will determine your available pickup days. As our customer base increases, the more availability we will be able to offer, so tell your friends about {{brand}}!',
              fr:
                  "Votre adresse déterminera vos jours de ramassage disponibles. Plus notre clientèle augmente, plus nous serons en mesure d'offrir de disponibilité, alors parlez de {{brand}} à vos amis !"
          }
        : {
              en: 'Your address will determine your available pickup days.',
              fr: 'Votre adresse déterminera les jours de collecte disponibles.'
          },

    pickupDialog10: isMXDRegion()
        ? {
              en: 'Specify in which premises your establishment is located',
              fr: 'Précisez dans quel local votre établissement se situe'
          }
        : isCONRegion()
        ? {
              en:
                  'We serve the commercial premises of on-site consumption establishments. Use the unit # field and comments area on the last page to specify where your business is located and where to find your bags.',
              fr:
                  'Nous desservons les locaux commerciaux des établissements de consommation sur place. Utilisez le champ unité # et la zone commentaires sur la dernière page pour spécifier où se trouve votre commerce et où trouver vos sacs.'
          }
        : {
              en:
                  'We service {{condoName}}, multifamily complexes, and apartments. Use the Unit # field and the comments box on the last page to specify where your home is located and where your bags can be found.',
              fr:
                  'Nous desservons des {{condoName}}, des complexes multifamiliaux et des appartements. Utilisez le champ Unit # et la zone de commentaires sur la dernière page pour spécifier où se trouve votre maison et où trouver vos sacs.'
          },

    date: {
        en: 'Date',
        fr: 'Date'
    },
    pickupDialog12: {
        en: 'Your community is picked up on {{days}}',
        fr: 'Votre secteur est ramassé le {{days}}'
    },
    pickupDialog13: {
        en: 'Time Window',
        fr: 'Plage Horaire'
    },
    pickupDialog14: {
        en: 'Estimated time of arrival will be shown on the morning of your pickup',
        fr: "L'heure d'arrivée estimée sera indiquée le matin de votre ramassage"
    },
    pickupDialog15: {
        en: 'How often?',
        fr: 'À quelle fréquence?'
    },
    pickupDialog16: {
        en: 'Frequency',
        fr: 'Fréquence'
    },
    pickupDialog17: {
        en: 'One time pickup',
        fr: 'Ramassage unique'
    },
    pickupDialog18: {
        en: 'Every 1 week',
        fr: 'Toutes les semaines'
    },
    pickupDialog19: {
        en: 'Every {{frequencyWeeks}} weeks',
        fr: 'Toutes les {{frequencyWeeks}} semaines'
    },
    pickupDialog20: {
        en: '(busy restaurant)',
        fr: '(restaurant achalandé)'
    },
    pickupDialog21: {
        en: '(regular household)',
        fr: '(ménage régulier)'
    },
    pickupDialog22: {
        en: '(low volume household)',
        fr: '(ménage à faible volume)'
    },
    pickupDialog23: {
        en: '(very low volume household)',
        fr: '(ménage à très faible volume)'
    },
    recyclables: !isMXDRegion()
        ? {
              en: 'Recyclables',
              fr: 'Recyclables'
          }
        : {
              en: 'Quantity of containers to pick up',
              fr: 'Quantité de contenants à récupérer'
          },
    adminOptions: {
        en: 'Administrator Options',
        fr: "Options de l'administrateur"
    },
    pickupDialog25: {
        en: 'How much are we picking up?',
        fr: 'Combien de sacs collectons-nous?'
    },
    pickupDialog26: {
        en: 'Number of garbage-sized bags.',
        fr: 'Nombre de sacs à poubelle'
    },
    bottleRecycling: {
        en: 'Bottle Recycling',
        fr: 'Recyclage de Contenants'
    },
    accepted: {
        en: 'Accepted',
        fr: 'Accepté'
    },
    acceptedMaterials: {
        en: 'Accepted Materials',
        fr: 'Matériaux acceptés'
    },
    description: {
        en: 'Description',
        fr: 'Description'
    },
    lowVolumeFeeWarning: {
        en:
            'Please ensure you have {{lowVolumeContainerThreshold}} or more containers per pickup. A small fee may be applied for consistent low-volume collections.',
        fr:
            'Veuillez vous assurer que vous disposez de {{lowVolumeContainerThreshold}} conteneurs ou plus par collecte. Des frais minimes peuvent être appliqués pour les collectes constantes de faible volume.'
    },
    pickupDialog27: {
        en: 'customer requires {{{binCount}}} bin',
        fr: 'le client a besoin de {{{binCount}}} bac'
    },
    // pickupDialog28a: {
    //     en: "Don't use {{{commodityUnits}}}s? Press here for other options",
    //     fr: "Vous n'utilisez pas des {{{commodityUnits}}}s? Appuyez ici pour d'autres options"
    // },
    pickupDialog28a: {
        en: 'Other options',
        fr: "Autres d'options"
    },
    // pickupDialog28b: {
    //     en: 'Press here to hide other options',
    //     fr: 'Appuyez ici pour masquer les autres options'
    // },
    pickupDialog28b: {
        en: 'Hide other options',
        fr: 'Masquer les autres options'
    },
    pickupDialog29: {
        en: 'Please use large bags for future pickups if possible. Use the inputs below for any non bagged items.',
        fr:
            'Veuillez utiliser de grands sacs pour les futurs ramassages si possible. Utilisez les entrées ci-dessous pour tous les articles non emballés.'
    },
    pickupDialog30: {
        en: 'Clothing Donation',
        fr: 'Don de Vêtements'
    },
    pickupDialog31: {
        en: 'Garbage-sized bags',
        fr: 'Sacs à poubelle'
    },
    electronics: {
        en: 'Electronics',
        fr: 'Électronique'
    },
    pickupDialog33: {
        en: 'Number of items',
        fr: "Nombre d'objets"
    },
    pickupDialog34a: {
        en: 'Your ID is ',
        fr: 'Votre identifiant est '
    },
    pickupDialog34b: !isMXDRegion()
        ? {
              en: '. Please write this on your bag(s) if you can.',
              fr: ". Veuillez l'écrire sur votre (vos) sac (s) si vous le pouvez."
          }
        : {
              en: '. Please write this on your bag(s) or place the sticker with your QR code',
              fr: ". Veuillez l'écrire sur votre (vos) sac(s) ou apposez l'autocollant avec votre code QR"
          },
    pickupDialog35a: {
        en: 'When should we pickup?',
        fr: 'Quand souhaitez-vous planifier la collecte?'
    },
    pickupDialog35b: {
        en:
            'The applied promo code is restricted to the date below. If you would like to change the date of your pickup, please remove the promo code',
        fr:
            'Le code promotionnel que vous avez appliqué est valable uniquement pour la date ci-dessous. Si vous souhaitez modifier la date de votre ramassage, veuillez supprimer le code promotionnel'
    },
    confirmation: {
        en: 'Confirmation',
        fr: 'Confirmation'
    },
    pickupDialog36: {
        en: 'Pickup picture confirmation',
        fr: 'Confirmation de la photo de ramassage'
    },
    pickupDialog37: {
        en: 'Large volumes pickups need to be verified with a picture. Please take a picture of your pickup.',
        fr:
            'Les ramassage gros volumes doivent être vérifiés avec une photo. Veuillez prendre une photo de votre ramassage.'
    },
    pickupDialog38: {
        en: 'If you do not believe this is a large volume pickup please check the number of items you have entered.',
        fr:
            "Si vous ne pensez pas qu'il s'agit d'un ramassage gros volume, veuillez vérifier le nombre d'articles que vous avez saisis."
    },
    pickupDialog39: {
        en:
            'Please take a picture of your containers for verification. Not required, but helpful if something goes missing.',
        fr:
            'Veuillez prendre une photo de vos conteneurs pour vérification. Pas obligatoire, mais utile en cas de disparition de quelque chose.'
    },
    pickupDialog40: {
        en: 'Where will the bags be?',
        fr: 'Où seront les sacs ?'
    },
    pickupDialog41: {
        en: 'Directions/Comments​',
        fr: 'Instructions/Commentaires​'
    },
    specialInstructions: {
        en: 'Special instructions',
        fr: 'Instructions spéciales'
    },
    pickupDialog43: {
        en:
            'Your personal information is kept private and will only be relayed to the driver. If you need to be contacted by the driver (ie. call on arrival), please specify.',
        fr:
            "Vos informations personnelles sont gardées privées et ne seront transmises qu'au chauffeur. Si vous avez besoin d'être contacté par le chauffeur (c'est-à-dire appeler à l'arrivée), veuillez le préciser."
    },
    pickupDialog44aResidential: !isMXDRegion()
        ? {
              en:
                  "Log in after 8 AM on your pickup day for an estimated time of arrival. If you can't wait around, hide your bags in a secure location outside and update your directions above.",
              fr:
                  "Connectez-vous à l'application après 8h le jour de votre ramassage pour une heure d'arrivée estimée plus précise. Si vous ne pouvez pas attendre, cachez vos sacs à l'extérieur et dites au chauffeur où regarder dans les directives ci-dessus."
          }
        : {
              en:
                  'Make sure your bags of containers and crates of glass containers are ready and accessible on collection day.',
              fr:
                  'Assurez-vous que vos sacs de contenants et caisses de contenants de verre sont prêts et accessibles le jour de la collecte.'
          },
    pickupDialog44aCondo: !isMXDRegion()
        ? {
              en:
                  "Log in after 8 AM on your pickup day for an estimated time of arrival. If you can't wait around, place your bags on ground floor in a secure location and update your directions above.",
              fr:
                  "Connectez-vous à l'application après 8h le jour de votre ramassage pour une heure d'arrivée estimée plus précise. Si vous ne pouvez pas attendre, cachez vos sacs à l'extérieur et dites au chauffeur où regarder dans les directives ci-dessus."
          }
        : {
              en:
                  'Make sure your bags of containers and crates of glass containers are ready and accessible on collection day.',
              fr:
                  'Assurez-vous que vos sacs de contenants et caisses de contenants de verre sont prêts et accessibles le jour de la collecte.'
          },
    pickupDialog44aCommercial: !isMXDRegion()
        ? {
              en:
                  'Log in after 8 AM on your pickup day for an estimated time of arrival. Please make sure the driver can access your recycling / recycling room and knows who to talk to using the directions above.',
              fr:
                  "Connectez-vous à l'application après 8h le jour de votre ramassage pour une heure d'arrivée estimée plus précise. Si vous ne pouvez pas attendre, cachez vos sacs à l'extérieur et dites au chauffeur où regarder dans les directives ci-dessus."
          }
        : {
              en:
                  'Make sure your bags of containers and crates of glass containers are ready and accessible on collection day.',
              fr:
                  'Assurez-vous que vos sacs de contenants et caisses de contenants de verre sont prêts et accessibles le jour de la collecte.'
          },
    pickupDialog44bResidential: {
        en:
            'Please DO NOT leave your bags in plain sight of walking traffic, in the alley, or inside your home/{{condoName}}. We cannot enter private residences.',
        fr:
            "Veuillez NE PAS laisser vos sacs à la vue de la circulation piétonne, dans la ruelle ou à l'intérieur de votre maison/{{condoName}}. Nous ne pouvons pas entrer dans les résidences privées."
    },
    pickupDialog44bCondo: {
        en:
            'Please DO NOT leave your bags in plain sight of walking traffic, outside of your main building, or inside your home/{{condoName}}. We cannot enter private residences.',
        fr:
            "Veuillez NE PAS laisser vos sacs à la vue de la circulation piétonne, dans la ruelle ou à l'intérieur de votre maison/{{condoName}}. Nous ne pouvons pas entrer dans les résidences privées."
    },
    pickupDialog45: {
        en: 'What number to contact?',
        fr: 'Quel numéro contacter?'
    },
    pickupDialog46: {
        en: "We'll use {{number}} to contact you if needed.",
        fr: 'Nous utiliserons {{number}} pour vous contacter si nécessaire.'
    },
    pickupDialog47: {
        en: 'Use a different number for this pickup',
        fr: 'Utiliser un numéro différent pour cette collecte'
    },
    phoneNumber: {
        en: 'Phone Number',
        fr: 'Numéro de Téléphone'
    },
    pickupDialog49: {
        en: 'Want to donate?',
        fr: 'Faire un don'
    },
    organizationName: {
        en: '{{organizationName}}',
        fr: 'Organisme'
    },
    pickupDialog50: {
        en: 'Select {{organizationName}}',
        fr: "Sélectionner l'organisme"
    },
    pickupDialog50Exp: {
        en: 'Select a Charity or Group',
        fr: 'Sélectionnez un Organisme de Bienfaisance ou un Groupe'
    },
    pickupDialog51: {
        en: 'Search for a Charity or Group',
        fr: 'Rechercher une œuvre de charité ou un groupe'
    },
    pickupDialog52: {
        en: 'This charity issues tax receipts for your donations',
        fr: 'Cet organisme de bienfaisance délivre des reçus fiscaux pour vos dons'
    },
    pickupDialog52a: {
        en: 'Registered Canadian Charities',
        fr: 'Organismes de bienfaisance canadiens enregistrés'
    },
    pickupDialog53: {
        en: 'You will not receive tax receipts for donating to this organization.',
        fr: 'Vous ne recevrez pas de reçus fiscaux pour avoir fait un don à cette organisme.'
    },
    featuredOrganizations: {
        en: 'Featured {{organizationName}}',
        fr: 'Organisme Affilié'
    },
    pickupDialog55: {
        en:
            'These are our featured {{organizationName}}s. There are over {{num}} {{organizationName}}s registered on {{brand}}, use the search bar above if you cannot find the one your looking for.',
        fr:
            'Voici nos organismes vedettes. Il y a plus de {{num}} organismes enregistrées sur {{brand}}, utilisez la barre de recherche ci-dessus si vous ne trouvez pas celle que vous recherchez.'
    },
    pickupDialog56: {
        en:
            'These are our featured {{organizationName}}s. There are {{num}} {{organizationName}}s registered on {{brand}}, use the search bar above if you cannot find the one your looking for.',
        fr:
            'Voici nos organismes vedettes. Il y a {{num}} organismes enregistrées sur {{brand}}, utilisez la barre de recherche ci-dessus si vous ne trouvez pas celle que vous recherchez.'
    },
    pickupDialog57: {
        en:
            'These are our featured {{organizationName}}s. There is one {{organizationName}} registered on {{brand}}, use the search bar above if you cannot find the one your looking for.',
        fr:
            'Voici nos organismes vedettes. Il y a un organisme enregistrées sur {{brand}}, utilisez la barre de recherche ci-dessus si vous ne trouvez pas celle que vous recherchez.'
    },
    pickupDialog58: {
        en: 'Any promo codes?',
        fr: 'Des codes promotionnels?'
    },
    pickupDialog59: {
        en: 'Promo code is invalid:',
        fr: "Le code promotionnel n'est pas valide :"
    },
    pickupDialog60: {
        en: 'Use Previous Address',
        fr: "Utiliser l'adresse précédente"
    },
    pickupDialog61: {
        en: 'conditions apply',
        fr: "des conditions s'appliquent"
    },
    pickupDialog62: {
        en: 'team/group/bottle drive',
        fr: 'équipe/groupe/collecte de contenants'
    },
    pickupDialog63: {
        en: 'Request tax receipt (if available)',
        fr: 'Demander un reçu fiscal (si disponible)'
    },
    pickupDialog64: {
        en: 'Tax receipts',
        fr: 'Reçus fiscaux'
    },
    pickupDialog65: {
        en:
            'By selecting this option, the charity will get access to your full name, current address, and e-mail in order to send you a tax receipt. Tax receipts are issued independently from {{appName}}. For details, including whether a tax receipt will be provided, please contact the charity directly.',
        fr:
            "En sélectionnant cette option, l'organisme de bienfaisance aura accès à votre nom complet, votre adresse actuelle et votre adresse e-mail afin de vous envoyer un reçu fiscal. Les reçus fiscaux sont émis indépendamment de {{appName}}. Pour plus de détails, notamment si un reçu fiscal sera fourni, veuillez contacter directement l'organisme de bienfaisance."
    },
    pickupDialog66: {
        en: "Heads up! We don't have an available driver for this date. Please select another day",
        fr:
            "Nous n'avons malheureusement pas de chauffeur disponible pour cette date. Veuillez sélectionner un autre jour"
    },
    pickupDialog67: {
        en: 'We do not service your area yet',
        fr: 'Nous ne desservons pas encore votre région'
    },
    pickupDialog68: {
        en: 'Next booking date',
        fr: 'Prochaine date de réservation'
    },
    pickupDialog69: {
        en: 'No Bookings',
        fr: 'Aucune réservation'
    },
    inactive: {
        en: 'INACTIVE',
        fr: 'INACTIF'
    },
    promoCode: {
        en: 'Promo Code',
        fr: 'Code Promotionnel'
    },
    apply: {
        en: 'apply',
        fr: 'appliquer'
    },
    code: {
        en: 'Code',
        fr: 'Code'
    },
    required: {
        en: 'required',
        fr: 'requise'
    },
    noDonation: {
        en: 'No donation',
        fr: 'Aucun don'
    },
    noDonationExp: {
        en: 'Redeem funds without donating',
        fr: 'Échanger des fonds sans faire de don'
    },
    donationPreferenceLocked: {
        en: 'Donation Preference Locked',
        fr: 'Préférence de don verrouillée'
    },
    donationPreferenceLockedMessage: {
        en:
            'You are not allowed to change your donation preference. Please contact {{brandname}} if you need to change this.',
        fr:
            "Vous n'êtes pas autorisé à modifier vos préférences de don. Veuillez contacter {{brandname}} si vous avez besoin de changer cela."
    },
    allowedTypes: {
        en: 'Insert the accepted formats (For commercial collection = BGE bags)',
        fr: 'Insérer les formats acceptés (Pour collecte commerciale = sacs BGE)'
    }
};

const promoErrors = {
    doesNotExist: {
        en: 'does not exist',
        fr: `n'existe pas`
    },
    promotionEnded: {
        en: 'promotion ended',
        fr: 'promotion terminée'
    },
    noLongerActive: {
        en: 'no longer active',
        fr: "n'est plus actif"
    },
    internalUse: {
        en: 'reserved for internal use only',
        fr: 'réservé à un usage interne uniquement'
    },
    ownCode: {
        en: 'cannot use your own code',
        fr: 'vous ne pouvez pas utiliser votre propre code'
    },
    notStarted: {
        en: 'not available for use until {{date}}',
        fr: "indisponible jusqu'au {{date}}"
    },
    expired: {
        en: 'expired',
        fr: 'expiré'
    },
    noUsesLeft: {
        en: 'all codes used up',
        fr: 'tous les codes utilisés'
    },
    newCustomerOnly: {
        en: 'reserved for new customers only',
        fr: 'réservé aux nouveaux clients uniquement'
    },
    alreadyUsed: {
        en: 'a similar promo code has been used already',
        fr: 'un code promotionnel similaire a déjà été utilisé'
    },
    charityDonationOnly: {
        en: 'reserved for donations to the affiliated charity',
        fr: "réservé aux dons à l'organisme de bienfaisance affilié"
    }
};

const pickupDialogSelectTranslations = {
    pickupDialogSelect1: {
        en: '{{name}} pickup',
        fr: 'ramassage en condo'
    },
    pickupDialogSelect2: {
        en:
            "Select this if you want pickups from your {{name}}! Enter your unit number, location, and date. A driver will arrive between 8 AM and 5 PM on the day you select. Don't worry, you will be able to see an estimated time of arrival in the app on the morning of your pickup. If you can't wait around to meet the driver, use your building's recycling room and talk to your {{name}} management board about setting up a designated area. Once picked up, we'll count your containers and credit your account, where you can keep or donate your refund. Please place everything into large bags and label them with ",
        fr:
            "Sélectionnez cette option si vous voulez des ramassages depuis votre condo ou appartement! Entrez votre numéro d'appartement, l'emplacement et la date. Un chauffeur arrivera entre 8h et 17h le jour sélectionnez. Ne vous inquiétez pas, vous pourrez voir une heure d'arrivée estimée dans l'application le matin de votre ramassage. Si vous ne pouvez pas attendre pour rencontrer le chauffeur, utilisez la salle de recyclage de votre immeuble et discutez avec le gestionnaire de la mise en place d'une zone désignée. Une fois ramassés, nous compterons vos contenants et créditerons votre compte, où vous pourrez conserver ou donner votre remboursement. Veuillez tout placer dans de grands sacs et les étiqueter avec "
    },
    pickupDialogSelect3: {
        en: 'Buzzer Code',
        fr: 'Code de Sonnerie'
    },
    pickupDialogSelect4: {
        en:
            'We service {{name}}s, multifamily complexes, and apartments. Use the Buzzer Code field and the comments box on the last page to specify how the driver can contact you once they arrive.',
        fr:
            'Nous desservons des condos, des complexes multifamiliaux et des appartements. Utilisez le champ code de sonnerie et la zone de commentaires sur la dernière page pour spécifier comment le chauffeur peut vous contacter une fois arrivé.'
    },
    pickupDialogSelect5: {
        en:
            'Please leave your bags on the ground floor in a safe spot or run them down when the driver arrives. You will be notified 15 minutes before arrival.',
        fr:
            "Veuillez laisser vos sacs au rez-de-chaussée dans un endroit sûr ou les déposer à l'arrivée du chauffeur. Vous serez prévenu 15 minutes avant votre arrivée."
    },
    pickupDialogSelect6: {
        en: 'commercial pickup',
        fr: 'ramassage commercial'
    },
    pickupDialogSelect7: !isMXDRegion()
        ? {
              en:
                  "Select this if you want pickups from your high-volume business! Enter your business location, date and pickup frequency (weekly, monthly, etc). A driver will arrive between 8 AM and 5 PM on the day you select. Don't worry, you will be able to see an estimated time of arrival in the app on the morning of your pickup. In the directions section, please specify where the driver can find your bags, any security codes and hours of operation they should be aware of. Once picked up, we'll count your containers and credit your account, where you can keep or donate your refund. If possible, please place everything into large bags and label them with ",
              fr:
                  "Sélectionnez cette option si vous voulez des ramassages de votre entreprise à volume élevé! Entrez le lieu de votre entreprise, la date et la fréquence de ramassage (hebdomadaire, mensuelle, etc.). Un chauffeur arrivera entre 8h et 17h le jour sélectionnez. Ne vous inquiétez pas, vous pourrez voir une heure d'arrivée estimée dans l'application le matin de votre ramassage. Dans la section des directives, veuillez préciser où le chauffeur peut trouver vos sacs, les codes de sécurité et les heures d'ouverture qu'il doit connaître. Une fois ramassés, nous compterons vos contenants et créditerons votre compte, où vous pourrez conserver ou donner votre remboursement. Si possible, veuillez tout placer dans de grands sacs et les étiqueter avec "
          }
        : {
              en:
                  'Select this option if you want to get a pickup for your business. Please enter your contact details and instructions for retrieving your bags. Your bags will be counted and your customer account credited. Remember to properly identify your bags with your identification tags.',
              fr:
                  "Sélectionnez cette option si vous voulez obtenir une cueillette pour votre entreprise.  Veuillez entrer vos coordonnées et les directives pour la récupération de vos sacs.  Vos sacs seront comptés et votre compte client crédité.  Important de bien identifier vos sacs avec vos auto-collants d'identification."
          },

    pickupDialogSelect9: {
        en: 'Commercial/business pickup',
        fr: 'Collecte commerciale'
    },
    pickupDialogSelect8: {
        en: 'Doing Business As',
        fr: 'Faire des affaires comme'
    },
    pickupDialogSelect8a: {
        en: 'Trading Name',
        fr: 'Faire des affaires comme'
    },
    pickupDialogSelect: {
        en: 'Enter your business name to help our drivers navigate to your pickup faster.',
        fr:
            'Entrez le nom de votre entreprise pour aider nos chauffeurs à naviguer plus rapidement vers votre ramassage.'
    },
    pending1: {
        en: 'Your cash out of {{amount}} is being processed. You should receive a transfer no later than {{date}}.',
        fr:
            'Votre remboursement de {{amount}} est en cours de traitement. Vous devriez recevoir un virement au plus tard le {{date}}.'
    }
};

const pickupDialogDropAndGoTranslations = {
    pickupDialogDrop1: {
        en: 'drop&go',
        fr: 'dépôt de sacs'
    },
    pickupDialogDrop2:
        !isMXDRegion() && !isCONRegion() && !isEXPRegion()
            ? {
                  en:
                      'Select this if you want to drop off your bags and skip the line at a valid {{{type}}} location. Please label your bags with',
                  fr:
                      "Sélectionnez cette option si vous souhaitez déposer vos sacs dans un conteneur extérieur 'POD' et éviter les files d'attente! Veuillez étiqueter vos sacs avec"
              }
            : {
                  en: 'Select this option and follow the instructions to locate bag depots in your area.',
                  fr:
                      'Sélectionnez cette option et suivez les instructions pour repérer les dépôts de sacs de votre région.'
              },

    pickupDialogDrop3: {
        en: 'How to recycle with {{{type}}}',
        fr: 'Dépôt de sacs'
    },
    pickupDialogDrop4: !isMXDRegion()
        ? {
              en: 'Write this on your bags:',
              fr: 'Écrivez ceci sur vos sacs:'
          }
        : {
              en: 'This is your customer code:',
              fr: 'Ceci est votre code client:'
          },
    pickupDialogDrop5:
        !isMXDRegion() && !isEXPRegion()
            ? {
                  en:
                      'Why wait? {{{type}}} lets you skip the line for your empties refund. Select the closest {{{type}}} location and bring your recyclables in during their hours of operation. When you arrive at the depot, use the app to snap a picture and confirm your drop off. Leave the bags in the designated {{{type}}} area or bin (inside the depot). Your bags will be counted and account credited within 48 hours.',
                  fr:
                      "Pourquoi attendre? Dépôt de sacs vous permet d'éviter les files d'attente et les machines en panne pour le remboursement de vos contenants consignés. Sélectionnez le dépôt de sacs le plus proche et apportez vos contenants consignés pendant leurs heures d'ouverture. Lorsque vous arrivez au dépôt de sacs, utilisez l'application pour afficher votre code-client QR et passer le sous un scanneur afin de débarrer la porte. Déposez les sacs à l'intérieur du conteneur puis refermer bien la porte. Vos sacs seront comptés et votre compte crédité dans les 48 heures."
              }
            : isMXDRegion()
            ? {
                  en:
                      'Clearly identify your bags and place them inside the container. Your bags will be sorted and counted and your customer account will be credited with the value of the deposit in the next few days.',
                  fr:
                      "Identifiez bien vos sacs et déposez-les à l'intérieur du conteneur.  Vos sacs seront triés et comptés et votre compte client sera crédité de la valeur de la consigne dans les prochains jours."
              }
            : {
                  en:
                      'Bring your bagged containers the following location. Tag each bag with an express label and then drop the bags in the designated Express drop off area. Express labels can be shipped to your home (for a fee) or printed on location from the Express Kiosk.',
                  fr:
                      'Apportez vos contenants en sac à l’endroit suivant. Étiquetez chaque sac avec une auto-collant express, puis déposez les sacs dans la zone de dépôt express désignée. Les auto-collants express peuvent être expédiées à votre domicile (moyennant des frais) ou imprimées sur place à partir du kiosque express.'
              },
    pickupDialogDrop6: {
        en: 'location',
        fr: 'emplacement'
    },
    pickupDialogDrop7: {
        en: 'open',
        fr: 'ouvert'
    },
    pickupDialogDrop7b: {
        en: 'closing soon',
        fr: 'fermeture bientôt'
    },
    pickupDialogDrop8: {
        en: 'closed',
        fr: 'fermé'
    },
    pickupDialogDrop8b: {
        en: 'opening soon',
        fr: 'ouverture prochaine'
    },
    pickupDialogDrop9: {
        en: 'get directions',
        fr: 'obtenir des directions'
    },
    pickupDialogDrop9b: {
        en: 'request labels',
        fr: 'demander des auto-collants'
    },
    pickupDialogDrop9c: {
        en: 'Call Location',
        fr: 'Appelez cet endroit'
    },
    pickupDialogDrop9d: {
        en: `Continue with ${app} drop off`,
        fr: `Continuer avec le dépôt de ${app}`
    },
    pickupDialogDrop9e: {
        en: `Continue with`,
        fr: `Continuez avec`
    },
    pickupDialogDrop10: {
        en: 'hours of operation',
        fr: "heures d'ouverture"
    },
    pickupDialogDrop11: {
        en: 'How much are you dropping off?',
        fr: 'Combien de sacs déposer vous?'
    },
    pickupDialogDrop12: {
        en: 'GPS Not registering.',
        fr: 'GPS non enregistré.'
    },
    pickupDialogDrop13: {
        en: 'Please turn your location services ON and press the refresh button.',
        fr: "Veuillez activer vos services de localisation et appuyer sur le bouton d'actualisation."
    },
    pickupDialogDrop14: {
        en: 'Click here if your GPS is not working',
        fr: 'Cliquez ici si votre GPS ne fonctionne pas'
    },
    pickupDialogDrop15: {
        en: 'refresh',
        fr: 'rafraîchir'
    },
    pickupDialogDrop16: {
        en: 'back',
        fr: 'retour'
    },
    new: {
        en: 'New',
        fr: 'NOUVEAU'
    },
    pickupDialogDrop17: {
        en: 'Please ask the front counter for the override code',
        fr: 'Veuillez demander le code de dérogation au comptoir'
    },
    pickupDialogDrop18: {
        en: 'override code',
        fr: 'remplacer le code'
    },
    pickupDialogDrop19: {
        en: 'submit code',
        fr: 'soumettre le code'
    },
    pickupDialogDrop20: !isMXDRegion()
        ? {
              en: '{{{type}}} picture confirmation',
              fr: "Confirmation de l'image {{{type}}}"
          }
        : {
              en: 'Take a photo',
              fr: 'Prenez une photo'
          },
    pickupDialogDrop21: !isMXDRegion()
        ? {
              en:
                  'Please take a picture of your bags and then leave them in the designated Drop&Go area or bin (inside the depot). The picture is for security and gives us a record of your bags.',
              fr:
                  "Veuillez prendre une photo de vos sacs et les laisser ensuite dans la zone Drop&Go désignée ou dans la poubelle (à l'intérieur du dépôt). La photo est pour la sécurité et nous donne un enregistrement de vos sacs."
          }
        : {
              en:
                  'Please take a picture of all the bags you put in the container. To open the door, follow the steps to display your QR code which you can scan under the door. The photo gives us additional proof of your deposit.',
              fr:
                  'Veuillez prendre une photo de tous les sacs que vous déposez dans le conteneur.  Pour ouvrir la porte, poursuivez les étapes pour afficher votre code QR que vous pourrez balayer sous la porte.  La photo nous donne une preuve additionnelle de votre dépôt.'
          },
    pickupDialogDrop22: {
        en: 'Please take a photo of your bags.',
        fr: 'Veuillez prendre une photo de vos sacs.'
    },
    pickupDialogDrop23a: {
        en: 'upload photo',
        fr: 'Télécharger la photo'
    },
    pickupDialogDrop23b: {
        en: 'take photo',
        fr: 'prendre une photo'
    },
    pickupDialogDrop24: {
        en: 'This is the closest {{{type}}} location',
        fr: "Il s'agit de l'emplacement {{{type}}} le plus proche"
    },
    pickupDialogDrop24b: {
        en: 'Closest Location',
        fr: 'Emplacement le plus proche'
    },
    pickupDialogDrop25: {
        en: 'This is not the closest {{{type}}} location',
        fr: "Ce n'est pas l'emplacement {{{type}}} le plus proche"
    },
    pickupDialogDrop25b: {
        en: 'Not Closest Location',
        fr: "Pas l'emplacement le plus proche"
    },
    pickupDialogDrop26: {
        en:
            'You currently have a {{{type}}} being processed. Please wait to receive your counts before submitting another one.',
        fr:
            "Vous avez actuellement un {{{type}}} en cours de traitement. Veuillez attendre de recevoir vos décomptes avant d'en soumettre un autre."
    },
    pickupDialogDrop27: {
        en: 'There are currently no {{{type}}} locations available.',
        fr: "Il n'y a actuellement aucun emplacement {{{type}}} disponible."
    },

    pickupDialogDrop28: {
        en: 'QR Scan',
        fr: 'Balayez le code QR'
    },
    pickupDialogDrop28a: {
        en: 'Please scan the following qr code to deposit your bags.',
        fr: 'Veuillez balayer le code QR pour déposer vos sacs.'
    },

    pickupDialogDrop29: {
        en: 'Label printing',
        fr: 'Impression des auto-collants'
    },
    pickupDialogDrop29a: {
        en:
            'If you have not already printed your labels, go inside one of these two shops and scan the code below on the printing module:',
        fr:
            "Si vous n'avez pas déja imprimé vos auto-collants, rendez-vous à l'intérieur de l'un de ces deux commerces et scanner le code ci-dessous sur le module d'impression:"
    },
    pickupDialogDrop29b: {
        en: '41 boul. Charest Est (Familiprix)',
        fr: '41 boul. Charest Est (Familiprix)'
    },
    pickupDialogDrop29c: {
        en: '1805 1ère Avenue (Couche-Tard)',
        fr: '1805 1ère Avenue (Couche-Tard)'
    },
    pickupDialogDrop30: {
        en: 'Scan your QR code on the print booth to print ID labels',
        fr: "Balayez votre code QR sur le module d'impression pour imprimer des auto-collants d'identification"
    },
    pickupDialogDrop31: {
        en: 'The printing booth is located in the store next to the bag deposit site.',
        fr: `Le module d'impression est situé dans le commerce voisin du site de dépôt de sacs.`
    },
    pickupDialogDrop32: {
        en:
            'Identify each of your bags of containers using the self-adhesive labels on which is your QR code and your 4-character code.',
        fr: `Identifiez chacun de vos sacs de contenants à l’aide des auto-collants autocollantes sur lesquelles se trouve votre code QR et votre code à 4 caractères.`
    },
    pickupDialogDrop33: {
        en:
            'Scan your QR code on the scanner under the container door to drop your bags or follow the steps for a bag drop in the app.',
        fr: `Balayez votre code QR sur le lecteur optique situé sous la porte du conteneur pour déposer vos sacs ou suivez les étapes d'un dépôt de sacs dans l'application.`
    },
    pickupDialogDrop34: {
        en: 'Print Booth Information',
        fr: `Informations sur le module d'impression`
    },
    pickupDialogDrop35: {
        en: 'Were all bags deposited?',
        fr: `Tous les sacs ont-ils été déposés?`
    },
    pickupDialogDrop36: {
        en: 'Are you sure you want to cancel your {{{type}}}?',
        fr: `Êtes-vous sûr de vouloir annuler votre {{{type}}}?`
    },
    pickupDialogDrop37: {
        en: `Login at the ${app} kiosk to print your labels.`,
        fr: `Connectez-vous au kiosque ${app} pour imprimer vos auto-collants.`
    },
    pickupDialogDrop38: {
        en: 'Place your unsorted containers in a clear or transparent plastic bag and tag your bags, one tag per bag.',
        fr:
            'Placez vos contenants non triés dans un sac en plastique transparent ou transparent et étiquetez vos sacs, une auto-collants par sac.'
    },
    pickupDialogDrop39: {
        en:
            'At the Express and GO station, text your Express & Go Passcode to 778-400-5253, receive your one-time PIN to open the door.',
        fr: `À la gare Express et GO, envoyez votre code d'accès Express & Go au 778 400-5253 et recevez votre code PIN à usage unique pour ouvrir la porte.`
    },
    pickupDialogDrop40: {
        en: 'Scan the label before dropping off the bag(s).',
        fr: `Scannez l'auto-collants avant de déposer le(s) sac(s).`
    },
    pickupDialogDrop41: {
        en: 'Drop off your bag(s)!',
        fr: 'Déposez votre(vos) sac(s) !'
    },
    pickupDialogDrop42: {
        en: 'Complete your return',
        fr: 'Complétez votre déclaration'
    },
    pickupDialogDrop43: {
        en: 'Refer to hours of operation',
        fr: "Se référer aux heures d'ouverture"
    },
    pickupDialogDrop44: {
        en: 'Request one-time PIN',
        fr: 'Demander un code PIN à usage unique'
    },
    pickupDialogDrop45: {
        en: 'Text {{kantechPasscode}} to {{kantechPhoneNumber}} to receive your one-time PIN',
        fr: 'Envoyez {{kantechPasscode}} à {{kantechPhoneNumber}} pour recevoir votre code PIN à usage unique'
    },
    pickupDialogDrop46: {
        en: 'Your one-time PIN',
        fr: 'Votre code PIN à usage unique'
    },
    pickupDialogDrop47: {
        en: 'Code expires in ',
        fr: 'Le code expire dans '
    },
    pickupDialogDrop48: {
        en: 'Select this if you want to drop off your bags at a valid Reverse Vending Machine location.',
        fr:
            'Sélectionnez cette option si vous souhaitez déposer vos sacs dans un emplacement de machine récupératrice à l’unité valide.'
    },
    pickupDialogDrop49: {
        en: 'Set {{{name}}} as your preferred location?',
        fr: 'Définir {{{name}}} comme emplacement préféré ?'
    },
    pickupDialogDrop50: {
        en: 'Remove {{{name}}} as your preferred location?',
        fr: 'Supprimer {{{name}}} comme emplacement préféré ?'
    },
    pickupDialogDrop51: {
        en: 'preferred location',
        fr: 'emplacement préféré'
    }
};

const pickupDialogBottleDriveTranslations = {
    pickupDialogBottleDrive1: {
        en: 'Bottle drives',
        fr: 'Collecte de contenants'
    },
    pickupDialogBottleDrive2: {
        en:
            'Select this if you are organizing a high volume (over 200 bags) bottle drive and want a trailer or truck to pickup your pile of bags.',
        fr:
            "Sélectionnez cette option si vous organisez une collecte de bouteilles à volume élevé (plus de 200 sacs) et que vous souhaitez qu'une remorque ou un camion récupère votre pile de sacs."
    },
    pickupDialogBottleDrive3: {
        en: 'Want to donate to a bottle drive?',
        fr: 'Vous souhaitez faire un don pour une collecte de conmtenants consignés?'
    },
    pickupDialogBottleDrive4: {
        en: 'Select Bottle Drive to Donate to:',
        fr: 'Sélectionnez Collecte de Contenants pour faire un don à :'
    },
    pickupDialogBottleDrive5: {
        en: 'donate now',
        fr: 'faite un don maintenant'
    },
    pickupDialogBottleDrive6: {
        en: 'Want to start a bottle drive?',
        fr: 'Vous souhaitez démarrer une collecte de contenants consigés?'
    },
    pickupDialogBottleDrive7: {
        en:
            'Want a better way to run a Bottle Drive? We have trucks on the road five days a week, year round, ready to pick up your donations!',
        fr:
            "Vous voulez une meilleure façon d'amasser des contenants consignés? Nous avons des camions sur la route cinq jours par semaine, toute l'année, prêts à ramasser vos dons!"
    },
    pickupDialogBottleDrive8: {
        en: 'Indicate the full name of your company',
        fr: 'Indiquer le nom complet de votre entreprise'
    },

    pickupDialogBottleDrive9: {
        en: '',
        fr: '' // Leave blank
    },
    pickupDialogBottleDrive10: {
        en:
            'Is your organization running a bottle drive, clothing drive, or electronics drive? Fill out the form below and we will confirm your booking. On the day of your bottle drive A SkipTheDepot truck will arrive at the end to collect your bags and have them processed. Your proceeds will be available via e-transfer with history recorded in your account.',
        fr: `
        Votre organisation organise-t-elle une collecte de bouteilles, une collecte de vêtements ou une collecte d'appareils électroniques ? Remplissez le formulaire ci-dessous et nous confirmerons votre réservation. Le jour de votre collecte de bouteilles, un camion SkipTheDepot arrivera à la fin pour récupérer vos sacs et les faire traiter. Vos bénéfices seront disponibles par virement électronique avec un historique enregistré dans votre compte.`
    },
    pickupDialogBottleDrive11: {
        en: 'You must have over 40 bags to book.',
        fr: 'Vous devez avoir plus de 40 sacs à réserver.'
    },
    pickupDialogBottleDrive12: {
        en: 'You need to book your bottle drive at least three days ahead.',
        fr: "Vous devez réserver votre collecte de bouteilles au moins trois jours à l'avance."
    },
    pickupDialogError1: {
        en:
            "Looks like we weren't able to find you a pickup date! Don't worry, we've been notified about it already and will contact you soon about your pickup!",
        fr:
            "Il semble que nous n'ayons pas trouvé de date de ramassage pour vous ! Ne vous inquiétez pas, nous avons déjà été informés et nous vous contacterons bientôt au sujet de votre ramassage !"
    },
    pickupDialogError2: {
        en:
            "This area isn't open for public bookings until {{date}}. Please contact {{email}} if you need an exception.",
        fr:
            "Cette zone n'est pas ouverte aux réservations publiques avant le {{date}}. Veuillez contacter {{email}} si vous avez besoin d'une exception."
    },
    startTime: {
        en: 'Start Time',
        fr: 'Heure de début'
    },
    endTime: {
        en: 'End Time',
        fr: 'Heure de fin'
    },
    processor: {
        en: 'Processor',
        fr: 'Processeur'
    }
};

const validateErrorTranslations = {
    validate1: {
        en: 'Phone number should consist of 10 digits',
        fr: 'Le numéro de téléphone doit être composé de 10 chiffres'
    },
    validate2: {
        en: 'Invalid e-mail format',
        fr: 'Format de courriel invalide'
    },
    validate3: {
        en: 'Invalid currency format',
        fr: 'Format de devise non valide'
    },
    validate4: {
        en: 'This field is required',
        fr: 'Ce champ est requis'
    },
    validate5: {
        en: 'Password should be at least 6 characters long',
        fr: 'Le mot de passe doit comporter au moins 6 caractères'
    },
    validate6: {
        en: 'Password should not exceed 127 characters',
        fr: 'Le mot de passe ne doit pas dépasser 127 caractères'
    },
    validate7: {
        en: 'Answer should be a combination of 3-25 letters (case insensitive), numbers and/or hyphens.',
        fr:
            'La réponse doit être une combinaison de 3 à 25 lettres (insensible à la case), de chiffres et / ou de traits d’union.'
    },
    validate8: {
        en: 'Answer should only contain letters (case insensitive), numbers and/or hyphens with no spaces.',
        fr:
            'La réponse ne doit contenir que des lettres (insensibles à la case), des chiffres et/ou des traits d’union sans espaces.'
    },
    validate9: {
        en: 'Answer should be a combination of 6-15 letters or numbers.',
        fr: 'La réponse doit être une combinaison de 6 à 15 lettres ou chiffres.'
    },
    validate10: {
        en: 'Answer should only contain letters and numbers with no spaces.',
        fr: 'La réponse ne doit contenir que des lettres et des chiffres sans espaces.'
    },
    validate11: {
        en: 'Must be greater than 0',
        fr: 'Doit être supérieur à 0'
    },
    validate12: {
        en: 'We admire your perseverance, but the maximum number allowed is',
        fr: 'Nous admirons votre persévérance, mais le nombre maximum autorisé est'
    },
    validate13: {
        en: 'The maximum number allowed is',
        fr: 'Le nombre maximum autorisé est'
    },
    validate14: {
        en: 'Invalid number of items',
        fr: 'Nombre d’éléments non valide'
    },
    validate15: {
        en: 'You must be really bored or something. The maximum number allowed is 99',
        fr: 'Vous devez vraiment vous ennuyer. Le nombre maximum autorisé est de 99'
    },
    validate16a: {
        en: 'The maximum number allowed is 99',
        fr: 'Le nombre maximum autorisé est 99'
    },
    validate16b: {
        en: 'Invalid number of bags',
        fr: 'Nombre de sacs non valide'
    },
    validate17: {
        en: 'Invalid weight',
        fr: 'Poids non valide'
    },
    validate18: {
        en: 'Too heavy. Maximum weight is 99 kgs',
        fr: 'Trop lourd. Le poids maximum est de 99 kgs'
    },
    validate19: {
        en: 'Invalid number of units',
        fr: 'Quantité non valide'
    },
    validate20: {
        en: 'Must start with http:// or https://',
        fr: 'Doit commencer par http:// ou https://'
    },
    validate21: {
        en: 'Should be a secure URL starting with https://',
        fr: 'Doit être une URL sécurisée commençant par https://'
    },
    validate22: {
        en: 'Should be at least 1 character long',
        fr: 'Doit comporter au moins 1 caractère'
    },
    validate23: {
        en: 'Should not exceed {{arg}} characters',
        fr: 'Ne doit pas dépasser les caractères {{arg}}'
    },
    validate24: {
        en: 'Should be alphanumeric',
        fr: 'Doit être alphanumérique'
    },
    validate25: {
        en: 'Invalid or missing coordinate',
        fr: 'Coordonnées non valides ou manquantes'
    },
    validate26: {
        en: 'Invalid coordinate',
        fr: 'Corrdonnées invalides'
    },
    validate27: {
        en: 'Should be exactly {{num}} characters',
        fr: 'Doit être exactement {{num}} caractères'
    },
    validate28: {
        en: 'Should be exactly {{num}} digits',
        fr: 'devrait être de {{num}} chiffres'
    },
    validate29: {
        en: 'should only contain digits',
        fr: 'ne doit contenir que des chiffres'
    },
    validate30: {
        en: 'Should be {{num}} digits',
        fr: 'Devrait être {{num}} chiffres'
    },
    validate31: {
        en: 'Limit should be a non-negative numeric value',
        fr: 'La limite doit être une valeur numérique non négative'
    },
    validate32: {
        en: 'Entity not supported',
        fr: 'Entité non prise en charge'
    },
    validate33: {
        en: 'Should be {{numA}}-{{numB}} digits',
        fr: 'Devrait être {{numA}}-{{numB}} chiffres'
    },
    validate34: {
        en: 'Name contains invalid characters',
        fr: 'Le nom contient des caractères invalides'
    },
    validate35: {
        en: 'This must contain a number (e.g. 0-9)',
        fr: 'Celui-ci doit contenir un chiffre (par exemple 0-9)'
    },
    validate36: {
        en: 'This must contain both alphabetic uppercase and lowercase letters (e.g. A-Z, a-z)',
        fr: 'Celui-ci doit contenir à la fois des lettres alphabétiques majuscules et minuscules (par exemple A-Z, a-z)'
    },
    validate37: {
        en: 'This must contain at least one of these special characters ({{{charsStr}}})',
        fr: 'Celui-ci doit contenir au moins un de ces caractères spéciaux({{{charsStr}}})'
    },
    validate38: {
        en: 'Password should be between {{charsMin}} and {{charsMax}} characters long',
        fr: 'Le mot de passe doit contenir entre {{charsMin}} et {{charsMax}} caractères.'
    },
    validate39: {
        en: 'Passwords must match',
        fr: 'Les mots de passe doivent correspondre'
    },
    validate40: {
        en: 'Private relay email address ({{examples}}) are not compatible with e-Transfer services.',
        fr:
            'Les adresses e-mail de relais privées ({{examples}}) ne sont pas compatibles avec les services de transfert électronique.'
    },
    validate41: {
        en: 'Please double-check to make sure your email address is spelled correctly',
        fr: 'Veuillez revérifier pour vous assurer que votre adresse e-mail est correctement orthographiée'
    }
};

const labelTranslations = {
    labels1: {
        en: 'Questions/Comments',
        fr: 'Questions/Commentaires'
    },
    labels2: {
        en: 'What describes your issue best?',
        fr: "Qu'est-ce qui décrit le mieux votre problème ?"
    },
    labels3: {
        en: 'Full name',
        fr: 'Nom et prénom'
    },
    labels4: {
        en: 'Question, comment, or concern',
        fr: 'Question, commentaire ou préoccupation'
    },
    labels5: {
        en: 'Shares',
        fr: 'partages'
    },
    labels6: {
        en: "Share {{{charity}}}'s donation link",
        fr: 'Partager le lien de don de {{{charity}}}'
    },
    labels7: {
        en: 'Tips',
        fr: 'Astuces'
    },
    labels8: {
        en: 'Please type {{string}} to confirm',
        fr: 'Veuillez saisir {{string}} pour confirmer'
    },
    labels9: {
        en: 'Unit #',
        fr: '# App'
    },
    labels10: {
        en: '{{organization}} to collect for',
        fr: '{{organisation}} à collecter pour'
    },
    labels11: {
        en: 'Directions/Comments',
        fr: 'Instructions/Commentaires'
    },
    labels12: {
        en: 'New Campaign Name',
        fr: 'Nouveau nom de campagne'
    },
    labels13: {
        en: 'Tax year end date',
        fr: "Date de fin d'année d'imposition"
    },
    labels14: {
        en: 'Confirm Date',
        fr: 'Confirmer la date'
    },
    labels15: {
        en: 'Phone number invalid',
        fr: 'Numéro de téléphone invalide'
    },
    labels16: {
        en: 'First name is required',
        fr: 'Le prénom est requis'
    },
    labels17: {
        en: 'Last name is required',
        fr: 'Le nom de famille est requis'
    },
    labels18: {
        en: 'Bypass Two-Factor Authentication',
        fr: "Contourner l'authentification à deux facteurs"
    },
    labels19: {
        en: 'What area best describes what you are looking for?',
        fr: 'Quel domaine décrit le mieux ce que vous recherchez?'
    },
    labels20: {
        en: 'Any additional details',
        fr: 'Tout détail supplémentaire'
    },
    labels21: {
        en: 'P.O. Box',
        fr: 'Boîte Postale'
    },
    labels22: {
        en: 'Growth Tasks',
        fr: 'Tâches de croissance'
    },
    labels23: {
        en: 'I have a different Trading Name',
        fr: "J'ai un nom commercial différent"
    },
    labels24: {
        en: 'I have a unit number',
        fr: "J'ai un numéro d'unité"
    },
    labels25: {
        en: 'I have a PO Box',
        fr: "J'ai une boîte postale"
    },
    placeholder1: {
        en: 'Enter your address',
        fr: 'Entrez votre adresse'
    },
    placeholder2: {
        en: 'optional',
        fr: 'optionnel'
    },
    helperText1: {
        en:
            'Your pickup is scheduled for today and our driver is on their way. If you would like to change your address, please cancel this pickup and reschedule a new one.',
        fr:
            "Votre ramassage est prévue pour aujourd'hui et notre chauffeur est en route. Si vous souhaitez changer d'adresse, veuillez annuler ce ramassage et en reprogrammer un nouveau."
    },
    helperText2: {
        en: 'We pickup from different communities on different days of the week.',
        fr: 'Nous ramassons dans différentes communautés à différents jours de la semaine.'
    },
    helperText3: {
        en:
            "We will automatically schedule your next pickup for you. Don't worry, we'll remind you again when it comes around! There's no obligation - you can cancel at any time! NOTE: Bottle drives must be booked manually each time.",
        fr:
            "Nous planifierons automatiquement votre prochain ramassage pour vous. Ne vous inquiétez pas, nous vous rappellerons à nouveau quand cela arrivera! Il n'y a aucune obligation - vous pouvez annuler à tout moment! REMARQUE : Les collectes de contenants doivent être réservées manuellement à chaque fois."
    },
    helperText4: {
        en:
            'The following are eligible containers. Please place all containers in large garbage bags and record the number of bags.',
        fr:
            'Les contenants suivants sont éligibles. Veuillez placer tous les contenants dans de grands sacs à ordures et noter le nombre de sacs.'
    },
    helperText5: {
        en: 'Not offered in your area yet.',
        fr: 'Pas encore offert dans votre région.'
    },
    helperText6: {
        en: 'Enter the name of the group or {{organization}} to collect bottles for',
        fr: 'Entrez le nom du groupe ou {{organization}} pour collecter les contenants pour'
    },
    helperText7: {
        en:
            'Looking to fundraise for your charity or sports team? Select this option to upgrade your account into an {{organization}} to start collecting donations from any {{brand}} user!',
        fr:
            "Vous cherchez à collecter des fonds pour votre association caritative ou votre équipe sportive ? Sélectionnez cette option pour mettre à niveau votre compte vers une {{organisation}} afin de commencer à collecter des dons auprès de n'importe quel utilisateur de {{brand}}!"
    }
};

const otherDialogTranslations = {
    confirmDialog1: {
        en: 'Are you sure?',
        fr: 'Êtes-vous certain?'
    },
    confirmDialog2: {
        en: 'Warning: ',
        fr: 'Avertissement:'
    },
    unservicedDialog1: {
        en: 'Pickup request error',
        fr: 'Erreur de demande de ramassage'
    },
    unservicedDialog2: {
        en:
            'Unfortunately we are not servicing your area yet! We will be expanding shortly and will inform you when we do.',
        fr:
            'Malheureusement, nous ne desservons pas encore votre région! Nous nous étendrons sous peu et vous informerons lorsque nous le ferons.'
    },
    addressDialog1: {
        en: 'What type of building?',
        fr: 'Quel type de bâtiment?'
    },
    promoCampaign1: {
        en: 'New Campaign',
        fr: 'Nouvelle campagne'
    },
    promoCampaign2: {
        en: 'A campaign with this name already exists',
        fr: 'Une campagne portant ce nom existe déjà'
    },
    promoCampaign3: {
        en: 'Campaign',
        fr: 'Campagne'
    },
    promoInput1: {
        en: 'Only one promo code can be applied at a time.',
        fr: 'Un seul code promotionnel peut être appliqué à la fois.'
    },
    promoInput2: {
        en: 'Select promo to apply',
        fr: 'Sélectionnez la promotion à appliquer'
    },
    promoInput3: {
        en: 'The {{organization}} associated with this promo code must remain featured for it to apply.',
        fr: "L'{{organization}} associée à ce code promotionnel doit rester en vedette pour qu'il s'applique."
    },
    promoInput4: {
        en: `Please update your donation preference`,
        fr: `Veuillez mettre à jour votre préférence d'organisation`
    },
    promoInput5: {
        en: `Using this promo code will change your donation preference to {{{charityName}}}.`,
        fr: `L'utilisation de ce code promotionnel changera votre préférence de don à {{{charityName}}}.`
    },
    promoInput6: {
        en: `This promo code can only be used when donating to {{{charityName}}}. To use this promo code, please update your ${terms.organization.en.toLowerCase()} preference when booking a pickup.`,
        fr: `Ce code promotionnel ne peut être utilisé que lors d'un don à {{{charityName}}}. Pour utiliser ce code promotionnel, veuillez mettre à jour les préférences de votre organisation lors de la réservation d'un ramassage.`
    },
    promoInput7: {
        en: `Donate to {{{charityName}}}`,
        fr: `Faire un don à {{{charityName}}}`
    },
    promoInput8: {
        en: `Apply one day event promo code`,
        fr: `Appliquer un code promotionnel d'événement d'un jour`
    },
    promoInput9: {
        en: `This promo code can only be used for pickups scheduled on {{{date}}}. Applying this promo code will automatically adjust your pickup date (if applicable). Would you like to apply this promo code?`,
        fr: `Ce code promotionnel ne peut être utilisé que pour les ramassages prévus le {{{date}}}. L'application de ce code promotionnel ajustera automatiquement votre date de ramassage. Vous souhaitez appliquer ce code promo?`
    },
    promoInput10: {
        en: `Remove one day event promo code`,
        fr: `Supprimer le code promotionnel de l'événement d'un jour`
    },
    promoInput11: {
        en: `Would you like to remove your promo code for {{{date}}}. If you are in the process of booking a pickup, you will be taken back to the date select screen.`,
        fr: `Souhaitez-vous supprimer votre code promotionnel pour le {{{date}}} ? Si vous êtes en train de réserver un ramassage, vous serez redirigé vers l'écran de sélection de la date.`
    },
    promoWarning1: {
        en: `Warning: This promo is not recurring.`,
        fr: `Attention : Cette promotion n'est pas récurrente.`
    },
    promoWarning2: {
        en: `Warning: This promo will change user's donation preference.`,
        fr: `Attention : Cette promotion modifiera la préférence de don de l'utilisateur.`
    },
    locationSelection1: {
        en: 'Confirm pickup location',
        fr: 'Confirmer le lieu de prise en charge'
    },
    locationSelection2: {
        en: 'Drag/Zoom map to adjust red pin',
        fr: "Faites glisser/zoomer la carte pour ajuster l'épingle rouge"
    },
    locationSelection3: {
        en: 'Original Address (Blue pin)',
        fr: "Adresse d'origine (épingle bleue)"
    },
    locationSelection4: {
        en: 'None Selected',
        fr: 'Aucune sélection'
    }
};

const feedbackTranslations = {
    complaints1: {
        en: 'How can we help?',
        fr: 'Comment pouvons nous aider?'
    },
    complaints2a: {
        en: 'Please choose a category. Select ',
        fr: 'Veuillez choisir une catégorie. Sélectionnez '
    },
    complaints2b: {
        en: 'pickup ',
        fr: 'ramassage '
    },
    complaints2c: {
        en: 'to report about the pickup and/or driver, or select ',
        fr: 'pour signaler le ramassage et/ou le conducteur, ou sélectionnez '
    },
    complaints2d: {
        en: 'refund ',
        fr: 'remboursement '
    },
    complaints2e: {
        en: 'to report about your refund value.',
        fr: 'pour signaler la valeur de votre remboursement.'
    },
    complaints3: {
        en: 'Select a category',
        fr: 'Choisissez une catégorie'
    },
    complaints4: {
        en: 'Pickup/Driver problem',
        fr: 'Problème de ramassage/chauffeur'
    },
    complaints5: {
        en: 'Refund problem',
        fr: 'Problème de remboursement'
    },
    complaints6: {
        en: 'Generic problem',
        fr: 'Problème général'
    },
    complaints7: {
        en: 'Comment',
        fr: 'Commentaire'
    },
    complaints8: {
        en: 'Go Back',
        fr: 'Retourner'
    },
    complaints9: {
        en: 'Thank you for the feedback. We will get back to you as soon as we can.',
        fr: 'Merci pour votre retour. Nous vous répondrons dès que possible.'
    },
    summary1: {
        en: 'You have a donation!',
        fr: 'Vous avez un don!'
    },
    summary2: {
        en: 'You have donated!',
        fr: 'Vous avez fait un don!'
    },
    summary3: {
        en: 'Your refund is ready!',
        fr: 'Votre remboursement est prêt!'
    },
    summary4: {
        en: "Here's what we processed!",
        fr: 'Voici ce que nous avons traité!'
    },
    summary5: {
        en: "Here's what we picked up:",
        fr: 'Voici ce que nous avons ramassé:'
    },
    summary6: {
        en: 'bags of containers',
        fr: 'sacs de contenants'
    },
    summary7: {
        en: 'eligible containers',
        fr: 'contenants éligibles'
    },
    summary8: {
        en: 'non-eligible containers',
        fr: 'contenants non éligibles'
    },
    summary9: {
        en: 'Containers (>1L)',
        fr: 'Contenants (>1L)'
    },
    summary10: {
        en: 'Containers (0-1L)',
        fr: 'Contenants (0-1L)'
    },
    summary11: {
        en: 'Does this look right?',
        fr: 'Cela vous semble-t-il correct ?'
    },
    summary12: {
        en: 'Processed',
        fr: 'Traité'
    },
    summary13: {
        en: 'Donated',
        fr: 'Donné'
    },
    summary14: {
        en: 'Fees:',
        fr: 'Frais:'
    },
    summary15: {
        en: 'Fee',
        fr: 'frais'
    },
    summary16: {
        en: 'Payment processed!',
        fr: 'Paiement traité!'
    },
    summary17: {
        en: 'Balance owing cleared!',
        fr: 'Solde dû réglé!'
    },
    feedback1: {
        en: 'How did the driver do?',
        fr: 'Comment était le chauffeur?'
    },
    feedback2: {
        en: 'Was the driver on time?',
        fr: "Le chauffeur était-il à l'heure?"
    },
    feedback3: {
        en: 'Did the driver leave bags?',
        fr: 'Le chauffeur a-t-il laissé des sacs?'
    },
    feedback3a: {
        en: 'Did the driver swap bins?',
        fr: 'Le conducteur a-t-il échangé les bacs?'
    },
    feedback4: {
        en: 'Was the driver professional?',
        fr: 'Le chauffeur était-il professionnel?'
    },
    feedback5: {
        en: 'Want to leave a comment for your driver?',
        fr: 'Vous souhaitez laisser un commentaire à votre chauffeur?'
    },
    feedback6: {
        en: 'Select feedback type',
        fr: 'Sélectionnez le type de commentaires'
    },
    feedback7: {
        en: 'Compliment',
        fr: 'Compliment'
    },
    feedback8: {
        en: 'Complaint',
        fr: 'Plainte'
    },
    feedback9: {
        en: 'Say thanks with a tip!',
        fr: 'Dites merci avec un pourboire!'
    },
    feedback10: {
        en: 'Submit Feedback',
        fr: 'Soumettre des commentaires'
    },
    feedback11: {
        en: 'Submit Feedback With Tip',
        fr: 'Soumettre des commentaires avec un pourboire'
    },
    feedback12: {
        en: 'Tips are not mandatory, simply a way to extend gratitude.',
        fr: "Les pourboires ne sont pas obligatoires, simplement un moyen d'exprimer sa gratitude."
    },
    feedback13: {
        en: 'Other',
        fr: 'Autre'
    },
    feedback14: {
        en: 'Tip Amount',
        fr: 'Montant du pourboire'
    },
    feedback15: {
        en: 'Leave a compliment',
        fr: 'Laisser un compliment'
    },
    feedback16: {
        en: 'Leave a complaint',
        fr: 'Laisser une plainte'
    }
};

const FAQTranslations = {
    'faq-residential': !isMXDRegion()
        ? {
              en: 'Residential, Business & Commercial',
              fr: 'Résidentiel, commercial ou institutionnel'
          }
        : {
              en: 'Bag Drops and Commercial pickups',
              fr: 'Dépôt de sacs et Collecte commerciale'
          },
    'faq-charity': {
        en: 'Charity & {{organizationName}} Fundraising',
        fr: 'Collecte de fonds pour organismes de bienfaisance'
    },
    'faq-bottleDepot': {
        en: 'Bottle Depots & Mobile Recycling',
        fr: 'Récupérateur de contenants consignés & autres matières recyclabes'
    },
    'faq-tab-residential': !isMXDRegion()
        ? {
              en: 'Residential',
              fr: 'Résidentiel'
          }
        : {
              en: 'Bag Drop',
              fr: 'Dépôts de sacs'
          },
    'faq-tab-commercial-business': {
        en: 'Commercial & Business',
        fr: 'Collecte Commerciale'
    },
    'faq-tab-charities': {
        en: 'Charities',
        fr: 'Organisme de bienfaisance'
    },
    'faq-tab-fundraising-bottle-drives': {
        en: 'Fundraising & Bottle Drives',
        fr: 'Collecte de fonds et collecte de contenants'
    },
    'faq-tab-bottle-depot': {
        en: 'Bottle Depot',
        fr: 'Récupérateur de contenants consignés'
    },
    'faq-tab-mobile-recycling': {
        en: 'Mobile Recycling',
        fr: 'Recyclage mobile'
    }
};

const customerSiteSnackbarTranslations = {
    customerSnackBar1: {
        en: 'You do not have permissions to view customer accounts',
        fr: "Vous n'êtes pas autorisé à afficher les comptes clients"
    },
    customerSnackBar2: {
        en: 'You do not have permissions to view {{organization}} accounts',
        fr: "Vous n'êtes pas autorisé à afficher les comptes {{organization}}"
    },
    customerSnackBar3: {
        en: 'Your donation preference has been set to {{{charity}}}.',
        fr: 'Votre préférence de don a été définie sur {{{charity}}}.'
    },
    customerSnackBar4: {
        en: 'Something went wrong, please try again.',
        fr: "Une erreur s'est produite. Veuillez réessayer."
    },
    customerSnackBar5: {
        en: 'You have postponed your pickup.',
        fr: 'Vous avez reporté votre ramassage.'
    },
    customerSnackBar6: {
        en: 'Your pickup was successfully edited!',
        fr: 'Votre ramassage a été modifié avec succès!'
    },
    customerSnackBar7: {
        en: 'You have requested a pickup!',
        fr: 'Vous avez demandé un ramassage!'
    },
    customerSnackBar8: {
        en: 'Thank you for your feedback!',
        fr: 'Merci pour votre avis!'
    },
    customerSnackBar9: {
        en: 'Your pickup has been confirmed!',
        fr: 'Votre ramassage a été confirmé!'
    },
    customerSnackBar10: {
        en: 'Your donation preference has been updated!',
        fr: 'Votre préférence de don a été mise à jour!'
    },
    customerSnackBar11: {
        en: 'Thanks for giving us a share!',
        fr: 'Merci de partager!'
    },
    customerSnackBar12: {
        en: 'You are not authorized to view this page. Parameters:',
        fr: "Vous n'êtes pas autorisé à afficher cette page. Paramètres:"
    },
    customerSnackBar13: {
        en:
            'Thank you for submitting a ticket. A member of our Customer Relations team will get back to you within two business days.',
        fr:
            "Merci d'avoir soumis un ticket. Un membre de notre équipe des relations avec la clientèle vous répondra dans les deux jours ouvrables."
    },
    quickDropSnackBar1: {
        en: `Successfully submitted {{{type}}}`,
        fr: `Soumis`
    },
    quickDropSnackBar2: {
        en: `Error submitting {{{type}}}`,
        fr: `Erreur`
    },
    quickDropSnackBar3: {
        en: `Successfully created booking.`,
        fr: `Soumis`
    },
    quickDropSnackBar4: {
        en: `Error submitting booking.`,
        fr: `Erreur`
    },
    quickDropSnackBar5: {
        en: `Successfully updated return site preference.`,
        fr: `Soumis`
    },
    quickDropSnackBar6: {
        en: `Error updating return site preference.`,
        fr: `Erreur`
    },
    quickdropInstructionsStep: {
        en: `Instructions`,
        fr: `Instructions`
    },
    quickdropInstructionsStep1: {
        en: `Please follow the instructions below to use the`,
        fr: `Veuillez suivre les instructions ci-dessous pour utiliser la`
    },

    openAccessDoorStep: {
        en: 'Open Access Door',
        fr: `Ouvrir la porte d'accès`
    },
    quickdropPayloadStep: {
        en: `Recyclables`,
        fr: `Produits recyclables`
    },
    quickdropPictureConfirmationStep: {
        en: `Confirmation`,
        fr: `Confirmation`
    },
    quickdropDonationStep: {
        en: `Donation`,
        fr: `Don`
    },
    quickdropQRPrintStep: {
        en: `Print Label`,
        fr: `Imprimer l'auto-collant`
    },
    quickdropQRScanStepStep: {
        en: `Scan Label`,
        fr: `Numériser l'auto-collant`
    },
    quickdropNoLocationFound: {
        en: `No {{technology}} Location Available`,
        fr: `Aucun emplacement {{technology}} disponible`
    },
    copyClipSnackBar: {
        en: `{{href}} copied to your clipboard.`,
        fr: `Copié`
    },
    appOnly: {
        en: 'App',
        fr: 'Application'
    },
    kiosk: { en: 'Kiosk', fr: 'Lieux de retour' },
    kioskAndScanner: { en: 'Kiosk & Scanner', fr: 'Lieux de retour et scanner' },
    accessDoor: { en: 'Access Door', fr: `Porte d'acces` },
    reverseVendingMachine: { en: 'Reverse Vending Machine', fr: 'Machine récupératrice à l’unité' },
    primaryReturnSite: { en: 'Primary Return Site', fr: 'Site de retour principal' },
    returnSiteLookup: { en: 'Return Site Lookup', fr: 'Sites de retour' }
};

const binRequestDialogTranslations = {
    binRequest1: {
        en: 'Looking to order rolling bins?',
        fr: 'Vous souhaitez commander des bacs roulants ?'
    },
    binRequest2: {
        en:
            'Let us know what type of bins and how many you need below. Depending on the bins there may be additional charges applied for the service.',
        fr:
            "Faites-nous savoir ci-dessous quel type de bacs et combien vous avez besoin. Selon les bacs, des frais supplémentaires peuvent s'appliquer pour le service."
    },
    binRequest3: {
        en: "No, Don't need bins",
        fr: 'Non, pas besoin de bacs'
    },
    binRequest4: {
        en: 'Yes, I want bins',
        fr: 'Oui, je veux des bacs'
    },
    binRequest5: {
        en: 'Request Bins',
        fr: 'Demander des bacs'
    },
    binRequest6: {
        en: 'Request for bins attached',
        fr: 'Demande de bacs ci-jointe'
    }
};

const commodityRequestDialogTranslations = {
    commodityRequestDialog1: {
        en: 'Need bags or bins?',
        fr: 'Besoin de sacs ou de poubelles ?'
    },
    commodityRequestDialog2: {
        en: 'Certain equipment may incur one-time and recurring fees',
        fr: 'Certains équipements peuvent entraîner des frais ponctuels et récurrents'
    },
    commodityRequestDialog3: {
        en: 'You have a request pending',
        fr: 'Vous avez une demande en attente'
    },
    commodityRequestDialog4: {
        en: 'Update Request',
        fr: 'Mettre à jour la demande'
    },
    commodityRequestDialog5: {
        en: 'Your request has been submitted',
        fr: 'Votre demande a été soumise'
    },
    commodityRequestDialog6: {
        en: 'Request attached',
        fr: 'La demande est jointe'
    },
    commodityRequestDialog7: {
        en: 'Request pending',
        fr: 'La demande est en attente'
    },
    commodityRequestDialogError1: {
        en: 'Please enter a value from {{min}}-{{max}}',
        fr: 'Veuillez saisir une valeur comprise entre {{min}} et {{max}}'
    },
    commodityRequestDialogError2: {
        en: 'Error submitting request',
        fr: "Erreur lors de l'envoi de la demande"
    }
};

const updateDialogTranslations = {
    updateDialog1: {
        en: 'We have introduced important changes that require you to update to the latest version of the app.',
        fr:
            "Nous avons introduit des changements importants qui vous obligent à mettre à jour la dernière version de l'application."
    },
    updateDialog2: {
        en: 'Please refresh the page to continue.',
        fr: 'Veuillez actualiser la page pour continuer.'
    },
    updateDialog3: {
        en: 'Please update through Google Play to continue.',
        fr: 'Veuillez mettre à jour via Google Play pour continuer'
    },
    updateDialog4: {
        en: 'Please update through the App Store to continue.',
        fr: "Veuillez mettre à jour via l'App Store pour continuer."
    },
    updateDialog5: {
        en: "If that doesn't work you can use our web app at",
        fr: "Si cela ne fonctionne pas, vous pouvez utiliser notre application Web à l'adresse {{{url}}}"
    },
    updateDialog6: {
        en: 'Your current version: {{version}}',
        fr: 'Votre version actuelle : {{version}}'
    },
    updateDialog7: {
        en: 'Update required',
        fr: 'Mise à jour requise'
    },
    redeemError: {
        en: 'Cash out type invalid',
        fr: ''
    }
};

const mapMarkerTranslations = {
    mapMarker1: {
        en: 'Check back later for driver ETA',
        fr: "Revenez plus tard pour l'estimation du temps d'arrivée du conducteur"
    },
    mapMarker2: {
        en: "You're next! {{name}} will arrive around {{time}}",
        fr: 'Vous êtes le prochain! {{name}} arrivera vers {{time}}'
    },
    mapMarker3: {
        en: "You're next! {{name}} will pick up your bags soon!",
        fr: 'Vous êtes le prochain! {{name}} va bientôt récupérer vos contenants!'
    },
    mapMarker4: {
        en: '{{name}} will arrive between 8:00 AM and 5:30 PM',
        fr: '{{name}} arrivera entre 8h00 et 17h30'
    },
    mapMarker5: {
        en: '{{name}} will arrive at {{time}}',
        fr: '{{name}} arrivera à {{time}}'
    },
    mapMarker6: {
        en: '{{name}} will arrive between {{minEtaTime}} and {{maxEtaTime}}',
        fr: '{{name}} arrivera entre {{minEtaTime}} et {{maxEtaTime}}'
    }
};
const OOSPresetTranslations = {
    OOSPresetSave: {
        en: 'Save Preset',
        fr: 'Save Preset'
    },
    OOSPresetLoad: {
        en: 'Use Preset',
        fr: 'Use Preset'
    },
    OOSNoPreset: {
        en: 'No Preset Saved',
        fr: 'No Preset Saved'
    }
};

const informationFormsTranslations = {
    icon: {
        en: 'Icon',
        fr: 'Icône'
    },
    question: {
        en: 'Question',
        fr: 'Question'
    },
    answer: {
        en: 'Answer',
        fr: 'Réponse'
    },
    title: {
        en: 'Title',
        fr: 'Titre'
    },
    contactEmail: {
        en: 'Contact Email',
        fr: 'Email du Contact'
    },
    terms: {
        en: 'Terms',
        fr: 'Termes'
    },
    privacy: {
        en: 'Privacy',
        fr: 'Confidentialité'
    },
    issueType: {
        en: 'Issue Type',
        fr: 'Type de Probleme'
    },
    primaryText: {
        en: 'Primary Text',
        fr: 'Texte Principal'
    },
    secondaryText: {
        en: 'Secondary Text',
        fr: 'Texte Secondaire'
    }
};
const reviewUsersTranslations = {
    AUDIT_TYPE_BAGCOUNT: {
        en: 'Bag Difference',
        fr: 'Bag Difference'
    },
    AUDIT_TYPE_DRIVERCOMPLAINT: {
        en: 'Driver Complaint',
        fr: 'Driver Complaint'
    },
    AUDIT_TYPE_COUNTERCOMPLAINT: {
        en: 'Counter Complaint',
        fr: 'Counter Complaint'
    },
    AUDIT_TYPE_AVGCONTAINERCOUNT: {
        en: 'Abnormal Container Numbers',
        fr: 'Abnormal Container Numbers'
    },
    AUDIT_TYPE_AMOUNTDIFFERENCE: {
        en: 'Counting Threshold',
        fr: 'Counting Threshold'
    },
    AUDIT_TYPE_REDEMPTIONAMOUNT: {
        en: 'Redemption Threshold',
        fr: 'Redemption Threshold'
    },
    AUDIT_TYPE_REDEMPTIONEMAIL: {
        en: 'Redemption Email',
        fr: 'Redemption Email'
    },
    AUDIT_TYPE_SAMEIPADDRESS: {
        en: 'IP Address',
        fr: 'IP Address'
    },
    AUDIT_TYPE_HAZARDOUS: {
        en: 'Hazardous Complaint',
        fr: 'Hazardous Complaint'
    },
    AUDIT_TYPE_GARBAGE: {
        en: 'Garbage Complaint',
        fr: 'Garbage Complaint'
    },
    resolve: {
        en: 'Resolve',
        fr: 'Resolve'
    },
    unresolve: {
        en: 'Unresolve',
        fr: 'Unresolve'
    },
    resolveAll: {
        en: 'Resolve All',
        fr: 'Resolve All'
    },
    unresolveAll: {
        en: 'Unresolve All',
        fr: ''
    },
    resolved: {
        en: 'Resolved',
        fr: 'Une plainte réglée'
    },
    unresolved: {
        en: 'Unresolved',
        fr: 'Une plainte non réglée'
    }
};
const PORScreenTranslations = {
    no_dont_exist: {
        en: "No, they don't exist",
        fr: "No, they don't exist"
    },
    delete_image: {
        en: 'Are you sure you want to remove this image?',
        fr: 'Êtes-vous sûr de vouloir supprimer cette image?'
    }
};

const verificationLinkTranslations = {
    verificationLink1: {
        en: 'Invalid link, please send again.',
        fr: 'Lien invalide, veuillez le renvoyer.'
    },
    verificationLink2: {
        en: 'Link has expired, please send again.',
        fr: 'Le lien a expiré, veuillez le renvoyer.'
    },
    verificationLink3: {
        en: 'Verification successful. You can sign into your account now.',
        fr: `Vérification réussie.  Vous pouvez maintenant utiliser les services de ${app}`
    },
    verificationLink4: {
        en: 'Your e-mail is verified already.',
        fr: 'Votre e-mail est déjà vérifié.'
    },
    verificationLink5: {
        en: 'Link is invalid or has expired.',
        fr: "Le lien n'est pas valide ou a expiré"
    }
};

const emailAuthorizationTranslations = {
    authorizationLink1: {
        en: 'Invalid link, please send again.',
        fr: 'Lien invalide, veuillez le renvoyer.'
    },
    authorizationLink2: {
        en: 'Link has expired, please send again.',
        fr: 'Le lien a expiré, veuillez le renvoyer.'
    },
    authorizationLink3: {
        en: 'Email authorized. It may now be used to request e-Transfers.',
        fr: `Email autorisé. Il peut désormais être utilisé pour demander des virements électroniques`
    },
    authorizationLink4: {
        en: 'This email is already authorized.',
        fr: 'Cet email est déjà autorisé.'
    },
    addAuthorizedEmail: {
        en: 'Add Authorized Email',
        fr: 'Ajouter un e-mail autorisé'
    },
    resendAuthorizationEmail: {
        en: 'Resend Authorization Email',
        fr: `Renvoyer l'e-mail d'autorisation`
    },
    emailAuthorization1: {
        en: `The email address {{email}} has not been authorized to receive funds from ${app}. To authorize it, please follow the link in the corresponding authorization email.`,
        fr: `L'adresse e-mail {{email}} n'a pas été autorisée à recevoir des fonds de ${app}. Pour l'autoriser, veuillez suivre le lien dans l'e-mail d'autorisation correspondant.`
    },
    emailAuthorization2: {
        en: `The email address {{email}} has been authorized to receive funds from ${app}.`,
        fr: `L'adresse e-mail {{email}} a été autorisée à recevoir des fonds de ${app}.`
    },
    emailAuthorization3: {
        en: `No email addresses have been authorized to receive funds for this account.`,
        fr: `Aucune adresse e-mail n'a été autorisée à recevoir des fonds pour ce compte.`
    },
    emailAuthorization4: {
        en: `Want to send to a different email? Add it in your `,
        fr: `Vous souhaitez envoyer vers une autre adresse e-mail ? Ajoutez-le dans votre `
    },
    emailAuthorization5: {
        en: `account settings`,
        fr: `paramètres du compte`
    },
    privateRelayText: {
        en:
            "To receive an Interac e-Transfer, please provide an alternative email address. The private relay email address associated with your account, due to using 'Sign in with Apple,' is not compatible with e-Transfer services.",
        fr:
            "Pour recevoir un virement électronique Interac, veuillez fournir une autre adresse électronique. L'adresse e-mail de relais privée associée à votre compte, en raison de l'utilisation de « Connectez-vous avec Apple », n'est pas compatible avec les services de transfert électronique."
    }
};

const downloadPageTranslations = {
    downloadPage1a: {
        en: 'Oops!',
        fr: 'Oups!'
    },
    downloadPage1b: {
        en: "That's not how it works.",
        fr: "Ce n'est pas comme ça que ça marche."
    },
    downloadPage2: {
        en: `The QR code you scanned needs to be scanned using the ${app} app. Download the ${app} app below and re-scan the QR code using the app.`,
        fr: `Le code QR que vous avez scanné doit être scanné avec l'application ${app}. Téléchargez l'application ${app} ci-dessous et scannez à nouveau le code QR avec l'application.`
    }
};

const biometricsTranslations = {
    biometrics1: {
        en: 'Allow this device to sign in using biometrics',
        fr: "Autoriser cet appareil à se connecter à l'aide de la biométrie"
    },
    biometrics2: {
        en: 'Continue with biometrics',
        fr: 'Continuer avec la biométrie'
    },
    biometrics3: {
        en: 'Error with authentication, please use regular email and password sign in.',
        fr: `Erreur d'authentification, veuillez utiliser votre adresse e-mail et votre mot de passe habituels pour vous connecter.`
    },
    biometrics4: {
        en: 'Error verifying your identity, please use regular email and password sign in.',
        fr: `Erreur lors de la vérification de votre identité, veuillez utiliser votre adresse e-mail et votre mot de passe habituels pour vous connecter.`
    },
    biometrics5: {
        en: 'The credentials saved to this device are incorrect, please use regular email and password sign in.',
        fr: `Les informations d'identification enregistrées sur cet appareil sont incorrectes. Veuillez utiliser votre adresse e-mail et votre mot de passe habituels.`
    },
    biometrics6: {
        en: 'Delete saved biometric credentials',
        fr: `Supprimer les informations d'identification biométriques enregistrées`
    },
    biometrics7: {
        en:
            'Are you sure, you will no longer be able to sign in using fingerprint/face scan on this device? Any other devices will not be affected.',
        fr: `Êtes-vous sûr de ne plus pouvoir vous connecter à l'aide de la numérisation d'empreintes digitales/visage sur cet appareil ? Aucun autre appareil ne sera affecté.`
    },
    biometrics8: {
        en:
            'If you use a device that uses biometrics, changing your password will require you to sign in using your email and password before you can use the fingerprint/face scan again.',
        fr: `Si vous utilisez un appareil qui utilise la biométrie, pour modifier votre mot de passe, vous devrez vous connecter à l'aide de votre adresse e-mail et de votre mot de passe avant de pouvoir utiliser à nouveau la numérisation d'empreintes digitales/visage.`
    },
    biometrics9: {
        en:
            'Your password will be updated on this device, any other devices using biometrics will require you to sign in using email and password before you can use fingerprint/face scan again.',
        fr: `Votre mot de passe sera mis à jour sur cet appareil, tout autre appareil utilisant la biométrie vous demandera de vous connecter en utilisant votre e-mail et votre mot de passe avant de pouvoir utiliser à nouveau l'analyse d'empreintes digitales/visage.`
    }
};

const passwordRestLinkTranslations = {
    passwordResetLink1: {
        en: 'Link already used, please send again.',
        fr: 'Lien déjà utilisé, merci de le renvoyer.'
    },
    passwordResetLink2: {
        en: 'Invalid link, please send again.',
        fr: 'Lien invalide, veuillez le renvoyer.'
    },
    passwordResetLink3: {
        en: 'Link has expired, please send again.',
        fr: `Le lien a expiré, veuillez le renvoyer.`
    },
    passwordResetLink4: {
        en: 'Your password has been changed.',
        fr: 'Votre mot de passe a été changé.'
    }
};

const daysOfWeek = {
    monday: {
        en: 'Monday',
        fr: 'Lundi'
    },
    tuesday: {
        en: 'Tuesday',
        fr: 'Mardi'
    },
    wednesday: {
        en: 'Wednesday',
        fr: 'Mercredi'
    },
    thursday: {
        en: 'Thursday',
        fr: 'Jeudi'
    },
    friday: {
        en: 'Friday',
        fr: 'Vendredi'
    },
    saturday: {
        en: 'Saturday',
        fr: 'Samedi'
    },
    sunday: {
        en: 'Sunday',
        fr: 'Dimanche'
    }
};
const externalOTC = {
    OTCPortalTitle: {
        en: 'External Booking',
        fr: 'Réservation externe'
    },
    OTCInstruction: {
        en: 'Please enter your access code. If you forgot, or do not have an access code please contact',
        fr: "Veuillez saisir votre code d'accès. Si vous avez oublié ou n'avez pas de code d'accès, veuillez contacter"
    },
    OTCHelpMessage: {
        en: 'If you need to make changes to your location details please contact',
        fr: 'Si vous devez apporter des modifications aux détails de votre emplacement, veuillez contacter'
    },
    OTCLocationDetails: {
        en: 'Location Details',
        fr: 'Détails de localisation'
    },
    OTCCancelBooking: {
        en: 'cancel booking',
        fr: 'annuler la réservation'
    },
    OTCSelectDate: {
        en: 'Select Pickup Date',
        fr: 'Sélectionnez la date de ramassage'
    },
    OTCPickupDate: {
        en: 'Pickup Date',
        fr: 'Date de ramassage'
    },
    OTCCancelConfirmation: {
        en: 'Are you sure you want to cancel this pickup?',
        fr: 'Etes-vous sûr de vouloir annuler ce ramassage ?'
    },
    OTCPickupSuccess: {
        en: 'Pickup booked',
        fr: 'Le ramassage a été réservé'
    },
    OTCCancelSuccess: {
        en: 'Pickup cancelled',
        fr: 'Le ramassage a été annulé'
    },
    OTCGroupDetails: {
        en: 'Group Details',
        fr: 'Détails du groupe'
    },
    OTCNoLocations: {
        en: 'This group does not have any locations',
        fr: "Ce groupe n'a aucun emplacement"
    },
    OTCBookingTitle: {
        en: 'Booking Pickup',
        fr: "Réservation d'un ramassage"
    },
    OTCInvalidCode: {
        en: 'Invalid access code',
        fr: "Code d'accès invalide"
    },
    OTCMessageSent: {
        en: 'Message sent',
        fr: 'Message envoyé'
    },
    OTCFrequencyOnce: { en: 'Only Once', fr: 'Une seule fois' },
    OTCFrequencyWeekly: { en: 'Weekly', fr: 'Hebdomadaire' },
    OTCFrequencyOnce2Week: { en: 'Every 2 Weeks', fr: 'Toutes les 2 semaines' },
    OTCFrequencyOnce3Week: { en: 'Every 3 Weeks', fr: 'Toutes les 3 semaines' },
    OTCFrequencyOnce4Week: { en: 'Every 4 Weeks', fr: 'Toutes les 4 semaines' },
    OTCFrequencyOnce5Week: { en: 'Every 5 Weeks', fr: 'Toutes les 5 semaines' }
};

const driverDashboardTranslations = {
    pickupStats: {
        en: 'Pickup Statistics',
        fr: 'Statistiques de ramassage'
    },
    combinedStats: {
        en: 'Weekly Statistics',
        fr: 'Statistiques hebdomadaires'
    },
    driverCodeTitle: {
        en: 'Your driver code is',
        fr: 'Votre code de conducteur est'
    },
    upcomingTrips: {
        en: 'Upcoming Trips',
        fr: 'Voyages à venir'
    },
    completeTrips: {
        en: 'Completed Trips',
        fr: 'Voyages terminés'
    },
    notrips: {
        en: 'No Trips',
        fr: 'Aucun voyage'
    },
    noCompliments: {
        en: 'No Compliments',
        fr: 'Aucun compliment'
    },
    noComplaints: {
        en: 'No Complaints',
        fr: 'Aucune plainte'
    },
    noDriverIncidents: {
        en: 'No Driver Incidents',
        fr: 'Aucun incident de conducteur'
    },
    noOptions: {
        en: 'No Options',
        fr: 'Aucune option'
    },
    totalTime: {
        en: 'Total Time',
        fr: 'Temps total'
    },
    timeSlashPickup: {
        en: 'Time/Pickup',
        fr: 'Temps/Ramassage'
    },
    timeSlashBag: {
        en: 'Time/Bag',
        fr: 'Temps/Sac'
    },
    tripIs: {
        en: 'Trip is',
        fr: 'Voyage est'
    },
    goToTrip: {
        en: 'Goto Trip',
        fr: 'Aller au voyage'
    },
    proceedToYourStartingLocation: {
        en: 'Proceed to your starting location',
        fr: 'Dirigez-vous vers votre point de départ'
    },
    tripComplete: {
        en: 'Trip Complete',
        fr: 'Voyage terminé.'
    },
    onlyUndoClockedIn: {
        en: 'Can only undo pickups when clocked in.',
        fr: `Tu peux seulement annuler les ramassages lorsque tu es pointé(e).`
    },
    customerFeedback: {
        en: 'Customer Feedback',
        fr: `Commentaires des clients`
    },
    onTime: {
        en: 'On Time',
        fr: "À l'heure"
    },
    leftBags: {
        en: 'Left Bags',
        fr: 'Bagages laissés'
    },
    pickupCompliment: {
        en: 'Pickup compliment',
        fr: 'Compliment sur la cueillette'
    },
    pickupComplaint: {
        en: 'Pickup complaint',
        fr: 'Plainte concernant la cueillette'
    },
    systemComplaint: {
        en: 'System complaint',
        fr: 'Plainte concernant le système'
    },
    sortingComplaint: {
        en: 'Sorting complaint',
        fr: 'Plainte concernant le tri'
    },
    viewComplaintImages: {
        en: 'View Complaint Images',
        fr: 'Voir les images de la plainte'
    }
};

const driverPickupTranslations = {
    proceedToStart: {
        en: 'Proceed to your starting location',
        fr: 'Dirigez-vous vers votre point de départ'
    },
    youHaveClockedIn: {
        en: "You've clocked in!",
        fr: 'Vous avez pointé!'
    },
    rememberTo: {
        en: 'Please remember to:',
        fr: 'Veuillez vous rappeler de:'
    },
    shouldLeave: {
        en: 'You should leave for your first pickup at',
        fr: 'Vous devriez partir pour votre premier ramassage à'
    },
    shouldHaveLeft: {
        en: 'You should have left for your first pickup at',
        fr: 'Vous devriez être parti pour votre premier ramassage à'
    },
    lateReason: {
        en: 'Reason for being late',
        fr: 'Raison du retard'
    },
    completePreTrip: {
        en: 'Complete Pre Trip',
        fr: "Complétez l'inspection avant départ"
    },
    proceedToFirstPickup: {
        en: 'Proceed to First Pickup',
        fr: 'Dirigez-vous vers le premier ramassage'
    },
    goNextPickup: {
        en: 'Go to next pickup',
        fr: 'Prochaine collecte'
    },
    undoPickups: {
        en: 'Undo pickups',
        fr: 'Revenir à la collecte'
    },
    selectNextActions: {
        en: 'Select your next action:',
        fr: 'Sélectionnez votre prochaine action:'
    },
    sendSMS: {
        en: 'Send SMS?',
        fr: 'Envoyer un SMS?'
    },
    confirmSendSMSMessage1: {
        en: "We'll send an SMS to the next customer ",
        fr: 'Nous enverrons un SMS au prochain client. '
    },
    confirmSendSMSMessage2: {
        en: " that you're on your way. Confirm?",
        fr: ' Que vous êtes en route. Confirmez?'
    },
    customerComments: {
        en: 'Customer comments:',
        fr: 'Commentaires du client :'
    },
    finishPickup: {
        en: 'Finish Pickup',
        fr: 'Terminer le ramassage'
    },
    customerNotes: {
        en: 'Customer Notes',
        fr: 'Notes du client'
    },
    completeFoundBags: {
        en: 'Complete (Found the bags)',
        fr: 'Terminé (Sacs trouvés)'
    },
    skipBackLater: {
        en: 'Skip (Come back later today)',
        fr: `Sauter (Revenir plus tard)`
    },
    abortCantFind: {
        en: `Abort (Can't find the bags)`,
        fr: `Abandonner (Sacs introuvables)`
    },
    whyAreYouSkipping: {
        en: 'Why are you skipping?',
        fr: 'Pourquoi passez-vous?'
    },
    updateTimeOfDayPreference: {
        en: 'Update time-of-day preference for pickup?',
        fr: "Mettre à jour la préférence de l'heure de la journée pour le ramassage?"
    },
    explanation: {
        en: 'Explanation',
        fr: 'Explication'
    },
    whyAreYouAborting: {
        en: 'Why are you aborting?',
        fr: 'Pourquoi avortez-vous ?'
    },
    pleaseProvideReason: {
        en: 'Please provide an explanation',
        fr: 'Veuillez fournir une explication'
    },
    haveYouDoneTheFollowing: {
        en: 'Have you done the following?',
        fr: ' Avez-vous fait ce qui suit?'
    },
    beforeAbortDoSteps: {
        en: 'Before aborting, please follow these steps:',
        fr: `Avant d'annuler, veuillez suivre ces étapes:`
    },
    confirmThePlayload: {
        en: 'Confirm the payload',
        fr: 'Confirmez la cargaison'
    },
    pictureRequired: {
        en: 'Picture Required',
        fr: 'Photo requise'
    },
    recordBagIdentifier: {
        en: 'Record bag identifier',
        fr: `Enregistrer l'identifiant du sac`
    },
    bagTagMustNotBeUsed: {
        en: 'Bagtag must not be currently used',
        fr: `L'auto-collant de sac ne doit pas être actuellement utilisée`
    },
    apply: {
        en: 'Apply',
        fr: 'Appliquer'
    },
    makeSureWriteOnBag: {
        en: 'Make sure to write this on the bags!',
        fr: `Assurez-vous de l'écrire sur les sacs!`
    },
    anyIssuesWithBags: {
        en: 'Any issues with the pickup?',
        fr: 'Y a-t-il des problèmes avec le ramassage?'
    },
    additionalDetails: {
        en: 'Additional Details',
        fr: 'Détails supplémentaires'
    },
    customer: {
        en: 'Customer',
        fr: 'Client'
    },
    confirmBags: {
        en: 'Confirm Bags',
        fr: 'Confirmer les sacs'
    },
    recordBagtag: {
        en: 'Record Bagtag',
        fr: `Enregistrer l'auto-collant de sac`
    },
    completePickup: {
        en: 'Complete Pickup',
        fr: 'Compléter le ramassage'
    },
    take: {
        en: 'Take',
        fr: 'Prendre'
    },
    picture: {
        en: 'Picture',
        fr: 'Photo'
    },
    addNote: {
        en: 'Add Note',
        fr: 'Ajouter une note'
    },
    enterReason: {
        en: 'Enter Reason',
        fr: 'Entrer la raison'
    },
    captureBags: {
        en: 'Capture Bags',
        fr: 'Capturer les sacs'
    },
    followUp: {
        en: 'Follow Up',
        fr: 'Suivi'
    },
    timePreference: {
        en: 'Time Preference',
        fr: 'Préférence de temps'
    },
    dropOffPoint: {
        en: 'Drop-off point',
        fr: 'Point de dépôt'
    },
    offLoadAndPrepare: {
        en: 'offload truck, prepare for next trip. Press',
        fr: 'déchargez le camion, préparez-vous pour le prochain voyage. Appuyez sur'
    },
    clockOut: {
        en: 'Clock Out',
        fr: 'Pointer la sortie'
    },
    whenDone: {
        en: 'when done.',
        fr: 'Quand vous avez terminé.'
    },
    yourNextPickup: {
        en: 'Your next pickup:',
        fr: 'Votre prochain ramassage:'
    },
    proceedToYourNextDropZone: {
        en: 'Proceed to your drop zone:',
        fr: 'Dirigez-vous vers votre zone de dépôt:'
    },
    selectHowToManage: {
        en: 'Select how to handle this pickup:',
        fr: 'Sélectionnez comment traiter ce ramassage:'
    },
    proceedToNextPickup: {
        en: 'Proceed to your next pickup:',
        fr: 'Passez à votre prochain ramassage:'
    },
    timeRemainingForLunch: {
        en: 'Time remaining for lunch:',
        fr: `Temps restant pour le dîner:`
    },
    signOut: {
        en: 'Sign out',
        fr: 'Se déconnecter'
    },
    skippedReason: {
        en: 'Skipped Reason',
        fr: 'Raison sautée'
    },
    noDirections: {
        en: 'No Directions Provided',
        fr: 'Aucune indication fournie'
    },
    pickupInfo: {
        en: 'Pickup info',
        fr: 'Informations de ramassage'
    },
    driverNote: {
        en: 'Driver note',
        fr: 'Note du chauffeur'
    },
    reAddPickupRouteMessage: {
        en: `This will put the pickup back on today's route.`,
        fr: `Cela remettra le ramassage sur la route d'aujourd'hui.`
    },
    undoPickup: {
        en: 'Undo pickup',
        fr: 'Annuler le ramassage'
    },
    additionalInformation: {
        en: 'Enter additional information',
        fr: 'Entrez des informations supplémentaires'
    },
    proceedToNextTrip: {
        en: 'Proceed to Next Trip',
        fr: 'Passer au prochain voyage'
    },
    yourDroveFor1: {
        en: 'You drove for',
        fr: 'Vous avez conduit pendant'
    },
    yourDroveFor2: {
        en: 'and completed',
        fr: 'et complété.'
    },
    finished: {
        en: 'Finished',
        fr: 'Complété'
    },
    perPickup: {
        en: 'Per Pickup',
        fr: 'Par ramassage.'
    },
    perBag: {
        en: 'Per Bag',
        fr: 'Par sac'
    },
    importPickups: {
        en: 'Import Pickups',
        fr: 'Importer des collectes'
    },
    uploadFile: {
        en: 'Upload File',
        fr: 'Télécharger le fichier'
    },
    customerName: {
        en: 'Customer Name',
        fr: 'Nom du client'
    },
    importUnsuccesful: {
        en: 'Import Unsuccessful',
        fr: `Échec de l'importation`
    },
    noPickupsThisDate: {
        en: 'No pickups on this date.',
        fr: `Il n'y a pas de ramassage à cette date.`
    },
    youArrivedToEarly: {
        en: 'You arrived too early',
        fr: `T'es arrivé(e) trop tôt.`
    },
    noEarlierThan: {
        en: 'Please arrive no earlier than {{maxEarlyStartRouteTime}} min before the scheduled starting time.',
        fr: `S'il te plaît, n'arrive pas plus tôt que {{maxEarlyStartRouteTime}} minutes avant l'heure prévue du début.`
    },
    gpsNotRegistered: {
        en: 'GPS not registered',
        fr: 'GPS non enregistré.'
    },
    turnLocationsOn: {
        en: 'Please turn your location services ON',
        fr: `S'il te plaît, active tes services de localisation.`
    },
    scheduledStartTimeIs: {
        en: 'Scheduled starting time is',
        fr: "L'heure prévue de début est."
    },
    truckNumber: {
        en: 'Truck Number',
        fr: 'Numéro du camion'
    },
    captureStop: {
        en: 'Capture Stop',
        fr: "Capturez l'arrêt."
    },
    tripsRerouted: {
        en: `Today's Route Has Been Updated`,
        fr: `L'itinéraire d'aujourd'hui a été mis à jour.`
    },
    reviewTripUpdate: {
        en: 'There was an update to your route. Please review before continuing.',
        fr: `"Il y a eu une mise à jour de votre itinéraire. Veuillez vérifier avant de continuer.`
    },
    canOnlyClockOutWhenDone: {
        en:
            'You can only clock out once all your pickups are completed. If you cannot complete your pickups contact SkipTheDepot support',
        fr:
            'Vous pouvez pointer votre départ uniquement une fois que tous vos ramassages sont terminés. Si vous ne pouvez pas terminer vos ramassages, contactez le support de SkipTheDepot.'
    },
    permittedLocations: {
        en:
            'Accessing hallways, recycling rooms, lobbies, elevators, and garages is permitted. Entering the residence is NOT permitted.',
        fr: `L'accès aux couloirs, salles de recyclage, halls d'entrée, ascenseurs et garages est autorisé. L'entrée dans la résidence n'est PAS autorisée.`
    },
    pickupAbortedRecalculating: {
        en: 'Pickup aborted. Recalculating trip...',
        fr: 'Ramassage annulé. Recalcul en cours du trajet...'
    },
    tripRecalculationComplete: {
        en: 'Trip recalculation complete.',
        fr: 'Recalcul du trajet terminé'
    },
    failedToRecalculate: {
        en: 'Failed to recalculate trip. Please manually trigger trip recalculation',
        fr: `Échec du recalcul du trajet. Veuillez déclencher manuellement le recalcul du trajet.`
    },
    unableToCompletePickup: {
        en: 'Unable to complete pickup. Please contact support.',
        fr: 'Impossible de terminer le ramassage. Veuillez contacter le support.'
    },
    truckInformation: {
        en: 'Truck information',
        fr: 'Informations sur le camion'
    },
    locationAccess: {
        en: 'Location Access',
        fr: 'Accès au lieu'
    },
    thisAppCollectionLocation: {
        en:
            'This app collects location data to enable pickup routing, driver location updates, and pickups statistics even when the app is closed or not in use.',
        fr:
            "Cette application collecte des données de localisation pour permettre l'acheminement des ramassages, les mises à jour de la position des conducteurs, et les statistiques des ramassages, même lorsque l'application est fermée ou non utilisée."
    },
    doNotShowThisAgain: {
        en: 'Do not show this again',
        fr: 'Ne plus afficher ceci'
    },
    toSaveTime1: {
        en:
            'To save time, only use the customer 4 digit code if the bags are pre-labeled and secure. Otherwise use your ',
        fr:
            'Pour gagner du temps, utilisez seulement le code à 4 chiffres du client si les sacs sont déjà étiquetés et bien fermés. Sinon, utilisez votre propre code. '
    },
    toSaveTime2: {
        en: ' driver code + next available number',
        fr: ' Code du chauffeur + prochain numéro disponible'
    },
    toSaveTime3: {
        en:
            ' Boxes are accepted! To save time, place boxes directly into {{container}} and move multiple boxes at the same time.',
        fr:
            ' Les boîtes sont acceptées! Pour gagner du temps, placez les boîtes directement dans le {{container}} et déplacez plusieurs boîtes en même temps.'
    },
    contactForDriverCode: {
        en: 'Please contact {{brandName}} for your Driver Code',
        fr: 'Veuillez contacter {{brandName}} pour obtenir votre code de chauffeur.'
    },
    gasReimbursment: {
        en: 'Gas Reimbursement',
        fr: 'Remboursement d’essence'
    },
    stopPayment: {
        en: 'Stop Payment',
        fr: 'Arrêt de paiement'
    },
    earningsThisWeek: {
        en: 'Earnings this week',
        fr: 'Revenus cette semaine'
    },
    areYouSureClockout: {
        en: 'Are you sure you want to clock out right now?',
        fr: 'Êtes-vous sûr de vouloir pointer votre départ maintenant?'
    },
    pleaseLoadBins: {
        en: 'Please load {{numOfBinsForTrip}} bins',
        fr: 'Veuillez charger {{numOfBinsForTrip}} bacs'
    },
    lunchBreakAt: {
        en: 'lunch break at',
        fr: 'pause dîner à'
    },
    labelMustBe: {
        en: 'Label must be exactly {{length}} characters long',
        fr: "L'étiquette doit comporter exactement {{length}} caractères."
    }
};

const abortReminders = {
    ringTheDoorBell: {
        en: '1. Ring the doorbell',
        fr: '1. Sonnez à la porte'
    },
    callTheCustomer: {
        en: '2. Call the customer - {{customerName}} - {{customerPhone}}',
        fr: '2. Appelez le client - {{customerName}} - {{customerPhone}}'
    },
    textCustomer: {
        en: '3. If no answer - Text Customer',
        fr: '3. Si pas de réponse - Envoyez un message texte au client'
    },
    wait5Mins: {
        en: '4. If no answer - Wait 5 Minutes',
        fr: '4. Si pas de réponse - Attendez 5 minutes.'
    },
    callAgain: {
        en: '5. If no answer - Call Customer Again',
        fr: '5. Si pas de réponse - Appelez à nouveau le client.'
    }
};

const cameraDialog = {
    cameraDialogText1: {
        en: 'Upload or take a photo to help provide information for skipping this pickup.',
        fr: "Téléchargez ou prenez une photo pour fournir des informations concernant l'annulation de ce ramassage."
    },
    cameraDialogText2: {
        en: 'This will be displayed to the customer.',
        fr: 'Ceci sera affiché au client.'
    },
    abortNote: {
        en: 'Note: You can press the buttons above to perform the associated action',
        fr: `Remarque : Vous pouvez appuyer sur les boutons ci-dessus pour effectuer l'action associée.`
    },
    deleteImage: {
        en: 'Delete Image',
        fr: `Supprimer l'image`
    },
    noImageToDisplay: {
        en: 'No images to display',
        fr: 'Aucune image à afficher'
    }
};

const allTranslations = {
    ...account,
    ...words,
    ...confirmPickupTranslations,
    ...cancelPickupTranslations,
    ...pickupWidgetTranslations,
    ...managePickupsDialogTranslations,
    ...sidebarTranslations,
    ...sideWidgetTranslations,
    ...dashboardTranslations,
    ...growthTranslations,
    ...taxReceiptsTranslations,
    ...referralTranslations,
    ...historyTranslations,
    ...redemptionTranslations,
    ...impactReportTranslations,
    ...pickupDialogDropAndGoTranslations,
    ...pickupDialogBottleDriveTranslations,
    ...registrationTranslations,
    ...loginTranslations,
    ...generalTranslations,
    ...errors,
    ...snackbar,
    ...terms,
    ...pickupDialogTranslations,
    ...promoErrors,
    ...FAQTranslations,
    ...validateErrorTranslations,
    ...charitySnackBarTranslations,
    ...charitySelectTranslations,
    ...charityStatsTranslations,
    ...growthPlanTranslations,
    ...labelTranslations,
    ...otherDialogTranslations,
    ...feedbackTranslations,
    ...customerSiteSnackbarTranslations,
    ...updateDialogTranslations,
    ...OOSPresetTranslations,
    ...pickupDialogSelectTranslations,
    ...mapMarkerTranslations,
    ...informationFormsTranslations,
    ...binRequestDialogTranslations,
    ...commodityRequestDialogTranslations,
    ...volumnReportTranslations,
    ...reviewUsersTranslations,
    ...verificationLinkTranslations,
    ...emailAuthorizationTranslations,
    ...passwordRestLinkTranslations,
    ...PORScreenTranslations,
    ...biometricsTranslations,
    ...downloadPageTranslations,
    ...daysOfWeek,
    ...externalOTC,
    ...driverDashboardTranslations,
    ...driverPickupTranslations,
    ...abortReminders,
    ...cameraDialog
};
