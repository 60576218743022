import React, { useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Switch, Route } from 'react-router-dom';

import { deviceHelper, getAppBarHeight, toggleFullScreen, getEnvName, isProductionEnv } from 'utils/misc';

import { Icon as MDIcon } from '@mdi/react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import Error from 'components/Error/Error';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

// Operator:
import Dashboard from 'containers/Operators/Dashboard/Dashboard';
import BulkCounter from 'containers/Operators/BulkCounter';
import BulksHistory from 'containers/Operators/BulksHistory';
import BulkStats from 'containers/Operators/BulkStats';
import PORScreen from 'containers/Operators/PORScreen';
import { PickupsDriver, PickupsAdmin } from 'containers/Operators/Pickups';
import Redemptions from 'containers/Operators/Redemptions/Redemptions';
import Accounting from 'containers/Operators/Accounting/Accounting';
import Users from 'containers/Operators/Users';
import CollectionUsers from 'containers/Operators/CollectionUsers';
import Charities from 'containers/Operators/Charities/Charities';
import CharityDonations from 'containers/Operators/CharityDonations';
import Employees from 'containers/Operators/Employees/Employees';
import GoogleCalls from 'containers/Operators/GoogleCalls/GoogleCalls';
import Promos from 'containers/Operators/Promos/Promos';
import PromoUsage from 'containers/Operators/PromoUsage';
import FeesUsage from 'containers/Operators/FeesUsage';
import Collectors from 'containers/Operators/Collectors/Collectors';
import QuickRegistration from 'containers/Operators/QuickRegistration/QuickRegistration';
import Options from 'containers/Operators/Options';
import Debug from 'containers/Operators/Debug';
import Logs from 'containers/Operators/Logs';
import QuickBooks from 'containers/Operators/Quickbooks';
import JournalEntries from 'containers/Operators/JournalEntries/JournalEntries';
import Reconciliation from 'containers/Operators/Reconciliation';
import AgingBalancesReport from 'containers/Operators/AgingBalancesReport';
import Regions from 'containers/Operators/Regions';
import Commodities from 'containers/Operators/Commodities';
import Announcements from 'containers/Operators/Announcements';
import LiveCounts from 'containers/Operators/LiveCounts';
import RatingPlatforms from 'containers/Operators/RatingPlatforms';
import Integrations from 'containers/Operators/Integrations';
import Rates from 'containers/Operators/Rates';
import DropLocations from 'containers/Operators/DropLocations';
import CommodityPickups from 'containers/Operators/CommodityPickups';
import Configuration from 'containers/Operators/Configuration';
import Trends from 'containers/Operators/Trends';
import Complaints from 'containers/Operators/Complaints';
import Stops from 'containers/Operators/Stops';
import Fees from 'containers/Operators/Fees';
import Taxes from 'containers/Operators/Taxes';
import SavedNotifications from 'containers/Operators/SavedNotifications';
import Review from 'containers/Operators/Review/Review';
import IPBlocking from 'containers/Operators/IPBlocking';
import ReqLogs from 'containers/Operators/ReqLogs';
import MsgLogs from 'containers/Operators/MsgLogs';
import Infractions from 'containers/Operators/Review/Infractions';
import Trucks from 'containers/Operators/Trucks/Trucks';
import Profile from 'containers/Operators/Profile';
import Questionnaires from 'containers/Operators/Questionnaires';
import AssetTracking from 'containers/Operators/AssetTracking';
import SendOuts from 'containers/Operators/SendOuts';
import ImgCaptureStatus from 'containers/Operators/ImgCaptureStatus';
import OTC from 'containers/Operators/OTC/OTC';
import LocalizationContext from 'utils/contexts/LocalizationContext';

// dialogs
import RefreshRequiredDialog from 'components/BlockingDialogs/RefreshRequiredDialog';
import EmailReceiptResultDialog from 'containers/Operators/PORScreen/EmailReceiptResultDialog';
import ContactForm from 'components/ContactForm/ContactForm';
import HowItWorks from 'components/InfoDialogs/HowItWorks';
import FAQ from 'components/InfoDialogs/FAQ';

// drawer
import OperatorDrawerContents from './OperatorDrawerContents';

// Driver:
import DriverDashboard from 'containers/Operators/DriverDashboard/DriverDashboard';
import DriverHistory from 'containers/Operators/DriverHistory/DriverHistory';
import PickupsCalendar from 'containers/Operators/PickupsCalendar';
import DriverComplete from 'containers/Operators/DriverComplete';
// Collector:
import CollectorDashboard from 'containers/Operators/CollectorDashboard/CollectorDashboard';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';

// hooks
import useOperatorSite from './useOperatorSite';
import useLiveCountsCounterSocket from 'components/LiveCounts/hooks/useLiveCountsCounterSocket';
import useWindowSize from 'utils/hooks/useWindowSize';

// providers
import { OperatorProvider } from 'utils/contexts/OperatorContext';
import { mdiBackburger, mdiMenu, mdiArrowDownDropCircle } from '@mdi/js';
import InformationForms from './InformationForms';
import Settings from './Settings';
import { _user } from 'std';
import useFetchBulks from './BulkCounter/hooks/useFetchBulks';
import Tips from './Tips';
import BulkImport from './BulkPickupImport';
import BottleDrives from './BottleDrives/BottleDrives';
import AudienceCapture from './Trends/AudienceCapture';
import Admins from './Admins/Admins';
import ConfigurationV2 from './Configuration/ConfigurationV2';
import InformationFormsV2 from './InformationForms/InformationFormsV2';
import RolePermissions from './RolePermissions';
import { ROLES } from 'constants.js';
import Services from './Services';
import DbLogs from './DbLogs';
import Emails from './Emails';
import AssetManagement from './AssetManagement';
import Inventory from './Inventory';
import { AVAILABLE_LANGS } from '../../constants';

const logo = require(process.env.REACT_APP_LOGO);
const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];

const drawerWidth = 280;

const styles = theme => ({
    root: {
        display: 'flex'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        overflowX: 'hidden'
    },
    drawerPaper: {
        width: drawerWidth,
        overflowX: 'hidden'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing.unit * 2, // TODO: bring this bacK
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('lg')]: {
            marginLeft: -drawerWidth
        },
        maxWidth: '100%',
        height: '100vh'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
            maxWidth: `calc(100% - ${drawerWidth}px)`
        }
    }
});

function OperatorSite(props) {
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext); // TODO: REMOVE THIS ONCE ALL SCREENS NO LONGER USE SNACKBAR AS PROP
    const { lang } = useContext(LocalizationContext);

    useMemo(() => {
        // all moment objects created after this is called with have there language set to this locale
        // moment objects that were created before such as ones stored in a components state will not be updated
        // if you call this again with a different language after the initial render then the languages on all of
        // the dates may not be updated
        if (lang === 'fr') {
            moment.locale('fr-ca');
        } else {
            moment.locale('en-ca');
        }
    }, [lang]);

    const {
        theme,
        width,
        auth,
        match,
        classes,
        location,
        history,
        toggleNightMode,
        onAdminTheme,
        google,
        logOut,
        logOutAll,
        refreshRequired,
        pickupsEnabled,
        charityEnabled,
        collectionProgramEnabled,
        helpCenterFunctions
    } = props;

    const [refreshUnresolvedInfractionCount, setRefreshUnresolvedInfractionCount] = React.useState(true);
    const [refreshPendingCharityCount, setRefreshPendingCharityCount] = React.useState(true);
    const [refreshPendingBottleDriveCount, setRefreshPendingBottleDriveCount] = React.useState(true);
    const [refreshPendingMobileCollectionCount, setRefreshPendingMobileCollectionCount] = React.useState(true);
    const [refreshProblemAssetCount, setRefreshProblemAssetCount] = React.useState(true);

    const {
        redemptionPending,
        backButtonShown,
        pages,
        homePath,
        loading,
        operator,
        pickups,
        skus,
        receivers,
        redemptions,
        redemptionOptions,
        minRedemption,
        redemptionFee,
        collectors,
        processors,
        regions,
        customHeader,
        drawerOpen,
        drawerWidth,
        drawerPickupOpen,
        helpDialogEl,
        langDialogEl,
        contactDialogOpen,
        howItWorksOpen,
        faqOpen,
        collapseOpen,
        isLiveCountsPage,
        twoFactorAuthenticationWarning,
        pendingCount,
        overdueComplaintCount,
        unresolvedInfractionCount,
        pendingBottleDriveCount,
        pendingMobileCollectionCount,
        problemAssetCount,
        menuOptions,
        handleCustomHeader,
        handleDrawer,
        handlePickupDrawer,
        handleNightModeToggle,
        handleSaveRedemptionOptions,
        handleHelpClick,
        handleHelpClose,
        handleLangClick,
        handleLangClose,
        handleFAQ,
        handleHowItWorks,
        handleContactDialog,
        handleContactSubmitSuccess,
        handleGoBack,
        handleExitView,
        handleExpandCollapse,
        reloadOperator,
        rolePermissions,
        tippingEnabled,
        handleSelectLanguage
    } = useOperatorSite({
        match,
        auth,
        location,
        history,
        width,
        onSnackbar,
        onToggleNightMode: toggleNightMode,
        onAdminTheme,
        refreshUnresolvedInfractionCount,
        setRefreshUnresolvedInfractionCount,
        refreshPendingCharityCount,
        setRefreshPendingCharityCount,
        refreshPendingBottleDriveCount,
        setRefreshPendingBottleDriveCount,
        refreshPendingMobileCollectionCount,
        setRefreshPendingMobileCollectionCount,
        refreshProblemAssetCount,
        setRefreshProblemAssetCount
    });

    const { handleEmitCountsUpdated, handleEmitSwitchedBulk } = useLiveCountsCounterSocket();

    const handleToggleFullscreenMode = () => {
        toggleFullScreen(document.body);
        if (drawerOpen) {
            handleDrawer(false)();
        }
    };

    let mainContentPadding = CSS.supports(
        'padding-top',
        `calc(var(--custom-safe-area-top) + ${getAppBarHeight(theme.breakpoints)}px)`
    )
        ? `calc(var(--custom-safe-area-top) + ${getAppBarHeight(theme.breakpoints)}px)`
        : getAppBarHeight(theme.breakpoints);
    if (isLiveCountsPage) {
        mainContentPadding = 0;
    }

    const envColorIndex = theme.palette.type === 'light' ? 500 : 700;

    const accountTypesAuthorized = ['Collector Employee', 'Collector Administrator', 'System Administrator', ...ROLES];

    useEffect(() => {
        //fetchNotifications(); // TODO: implement this later
    }, [operator, location]);

    const [accountsAnchorEl, setAccountsAnchorEl] = React.useState(null);
    const [accountSelectedIndex, setAccountSelectedIndex] = React.useState(0);
    const [timeout, setTimeout] = React.useState(0);
    const [collector, setCollector] = React.useState(null);
    const [isDepotLogin, setIsDepotLogin] = React.useState(false);
    const [timeoutStarted, setTimeoutStarted] = React.useState(false);
    const [timeoutCount, setTimeoutCount] = React.useState(0);
    const [timeoutSVCID, setTimeoutSVCID] = React.useState(null);
    const [deactivateMenuEl, setDeactivateMenuEl] = React.useState(null);
    const isOnOrderPage = window.location.pathname.split('/').includes('bulks');
    const [windowWidth, windowHeight] = useWindowSize();
    const [showResultDialog, setShowResultDialog] = React.useState(false);
    const [resultDialogText, setResultDialogText] = React.useState('');

    let screenIsVerySmall = windowWidth <= 400;

    const handleAccountsMenu = () => e => {
        if (_user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length > 1) {
            setAccountsAnchorEl(_.isNil(accountsAnchorEl) ? e.currentTarget : undefined);
        }
    };

    const handleAccountsChange = index => () => {
        if (_user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length > 1) {
            setAccountSelectedIndex(index);
            setAccountsAnchorEl(undefined);
            setCollector(operator.collectors[index]);
            reloadOperator();
        }
    };

    useEffect(() => {
        if (!_.isNil(operator)) {
            setCollector(
                _.isEmpty(operator.collectors) ? operator.collector : operator.collectors[accountSelectedIndex]
            );
        }
    }, [operator]);

    useEffect(() => {
        if (!_.isNil(operator) && !_.isNil(collector)) {
            getDepotTimeout();
            getIsDepotLogin();
        }
    }, [operator, collector]);

    useEffect(() => {
        if (
            !timeoutStarted &&
            isDepotLogin &&
            timeout > 0 &&
            auth.accountType !== 'System Administrator' &&
            auth.accountType !== 'Collector Administrator'
        ) {
            setTimeoutStarted(true);
            startTimeoutSVC();
        }
    }, [timeout, isDepotLogin]);

    useEffect(() => {
        if (timeoutCount > timeout) {
            handleDepotLogout();
        }
    }, [timeoutCount]);

    function startTimeoutSVC() {
        let timeoutSVCID = setInterval(() => {
            setTimeoutCount(v => v + 1);
        }, 1000);
        setTimeoutSVCID(timeoutSVCID);
    }

    function resetTimeoutCount() {
        setTimeoutCount(0);
    }

    async function handleDepotLogout() {
        await updateRecentPage();
        clearInterval(timeoutSVCID);
        logOut(`/${collector._id}/login`);
    }

    const {
        incompleteBulks,
        overdueBulks,
        whinyBulks,
        completedBulks,
        lostAndFoundBulks,
        incompleteBulksLoading,
        overdueBulksLoading,
        whinyBulksLoading,
        completedBulksLoading,
        lostAndFoundBulksLoading,
        loading: bulksLoading,
        fetchAllBulks,
        commodityColors,
        fetchCommodityColors,
        commodities,
        fetchCommodities
    } = useFetchBulks(operator);

    useEffect(() => {
        fetchCommodities();
    }, []);

    let id_match = true;
    // NOTE: Administrators skip IDs comparison
    if (
        _.includes(
            _.filter(
                accountTypesAuthorized,
                a => a !== 'System Administrator' && a !== 'Collector Administrator' && !ROLES.includes(a)
            ),
            auth.accountType
        )
    ) {
        id_match = auth._id.toLowerCase() === props.match.params._id.toLowerCase();
    }
    if (!_.includes(accountTypesAuthorized, auth.accountType) || !id_match) {
        return <Error header="Error 403" description="You are not authorized to view this page." />;
    }

    if (loading) {
        return <LoadingScreen />;
    }

    const drawerContents = index => {
        return (
            <OperatorDrawerContents
                homePath={homePath}
                location={location}
                pages={pages}
                loading={loading}
                operator={operator}
                drawerWidth={drawerWidth}
                collapseOpen={collapseOpen}
                collector={collector}
                index={index}
                onHandleDrawer={handleDrawer}
                onExitView={handleExitView}
                onCollapse={handleExpandCollapse}
                onNightModeToggle={handleNightModeToggle}
                onLogOut={logOut}
                history={history}
                resetTimeoutCount={resetTimeoutCount}
                rolePermissions={rolePermissions}
                handleDepotLogout={handleDepotLogout}
                onAccountsMenu={handleAccountsMenu}
                onAccountsChange={handleAccountsChange}
                accountSelectedIndex={accountSelectedIndex}
                accountsAnchorEl={accountsAnchorEl}
                pendingCount={pendingCount}
                overdueComplaintCount={overdueComplaintCount}
                unresolvedInfractionCount={unresolvedInfractionCount}
                pendingBottleDriveCount={pendingBottleDriveCount}
                pendingMobileCollectionCount={pendingMobileCollectionCount}
                problemAssetCount={problemAssetCount}
                menuOptions={menuOptions}
                pickupsEnabled={props.pickupsEnabled}
                promosEnabled={props.promosEnabled}
                charityEnabled={charityEnabled}
                collectionProgramEnabled={collectionProgramEnabled}
                tippingEnabled={tippingEnabled}
                commodities={commodities}
            />
        );
    };

    const showHelpButton = Object.values(helpCenterFunctions).includes(true);
    const showLanguageButton = _user.isDriver(operator) && availableLangs.length > 1;
    const showFullscreenButton = !deviceHelper.isNativeApp() && window.screen.height !== window.innerHeight;
    const showRefreshButton = window.screen.height !== window.innerHeight;
    const showDepotLogoutButton =
        auth.accountType !== 'System Administrator' &&
        !ROLES.includes(auth.accountType) &&
        _user.isClerk(operator) &&
        isOnOrderPage;

    const hideNavButtons =
        !showHelpButton && !showFullscreenButton && !showRefreshButton && !showDepotLogoutButton && !showLanguageButton;

    return (
        <OperatorProvider value={operator}>
            <div id="OperatorSite" className={classes.root}>
                <CssBaseline />

                {/* Mobile View */}
                {!isLiveCountsPage && (
                    <Hidden lgUp>
                        <SwipeableDrawer open={drawerOpen} onOpen={handleDrawer(true)} onClose={handleDrawer(false)}>
                            {drawerContents(0)}
                        </SwipeableDrawer>
                        <AppBar
                            position="fixed"
                            className={`${classes.appBar} appbar`}
                            style={{ backgroundColor: theme.palette.envColor[envColorIndex] }}
                        >
                            <Toolbar>
                                {backButtonShown ? (
                                    <IconButton
                                        data-cy="operatorsite-back-button"
                                        color="inherit"
                                        onClick={handleGoBack}
                                    >
                                        <Icon>arrow_back</Icon>
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        data-cy="operatorsite-drawer-button"
                                        color="inherit"
                                        onClick={handleDrawer(true)}
                                    >
                                        <Icon>menu</Icon>
                                    </IconButton>
                                )}
                                <Typography
                                    variant="h6"
                                    color="inherit"
                                    style={{ flex: 1, marginLeft: 16, fontWeight: 400 }}
                                >
                                    {!_.isNil(customHeader) &&
                                    location.pathname === `/operators/${operator._id}/pickups`
                                        ? customHeader
                                        : pages[location.pathname]}
                                </Typography>
                                {!isProductionEnv && (
                                    <Typography
                                        variant={screenIsVerySmall ? 'body1' : 'h4'}
                                        style={{ flex: 1 }}
                                        color="inherit"
                                    >
                                        <b>{getEnvName()}</b>
                                    </Typography>
                                )}
                                {screenIsVerySmall ? (
                                    <>
                                        {!hideNavButtons && (
                                            <>
                                                <IconButton
                                                    onClick={event => {
                                                        setDeactivateMenuEl(event.currentTarget);
                                                    }}
                                                >
                                                    <MDIcon path={mdiArrowDownDropCircle} size={1} color={'white'} />
                                                </IconButton>
                                                <Menu
                                                    data-cy="help-menu"
                                                    anchorEl={deactivateMenuEl}
                                                    open={Boolean(deactivateMenuEl)}
                                                    onClose={() => {
                                                        setDeactivateMenuEl(null);
                                                    }}
                                                >
                                                    {showFullscreenButton && (
                                                        <MenuItem
                                                            style={{ justifyContent: 'center' }}
                                                            onClick={handleToggleFullscreenMode}
                                                        >
                                                            <Icon>fullscreen</Icon>
                                                        </MenuItem>
                                                    )}
                                                    {showRefreshButton && (
                                                        <MenuItem
                                                            style={{ justifyContent: 'center' }}
                                                            onClick={() => window.location.reload(false)}
                                                        >
                                                            <Icon>refresh</Icon>
                                                        </MenuItem>
                                                    )}
                                                    {showHelpButton && (
                                                        <MenuItem
                                                            style={{ justifyContent: 'center' }}
                                                            onClick={handleHelpClick}
                                                            data-cy="help-menu-button"
                                                        >
                                                            <Icon>help</Icon>
                                                        </MenuItem>
                                                    )}
                                                    {showLanguageButton && (
                                                        <IconButton
                                                            color="inherit"
                                                            aria-owns={langDialogEl ? 'lang-menu' : null}
                                                            aria-haspopup="true"
                                                            data-cy="operator-lang-select-button"
                                                            onClick={handleLangClick}
                                                        >
                                                            <Icon>language</Icon>
                                                        </IconButton>
                                                    )}
                                                    {showDepotLogoutButton && (
                                                        <MenuItem
                                                            style={{ justifyContent: 'center' }}
                                                            onClick={() => {
                                                                handleDepotLogout();
                                                            }}
                                                        >
                                                            <Icon>lock</Icon>
                                                            <Typography
                                                                variant="h6"
                                                                color="inherit"
                                                                style={{
                                                                    flex: 1,
                                                                    marginLeft: theme.spacing.unit,
                                                                    fontWeight: 300
                                                                }}
                                                            >
                                                                Lock
                                                            </Typography>
                                                        </MenuItem>
                                                    )}
                                                </Menu>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {showFullscreenButton && (
                                            <IconButton
                                                color="inherit"
                                                aria-owns={helpDialogEl ? 'help-menu' : null}
                                                aria-haspopup="true"
                                                onClick={handleToggleFullscreenMode}
                                            >
                                                <Icon>fullscreen</Icon>
                                            </IconButton>
                                        )}
                                        {showRefreshButton && (
                                            <IconButton color="inherit" onClick={() => window.location.reload(false)}>
                                                <Icon>refresh</Icon>
                                            </IconButton>
                                        )}
                                        {showLanguageButton && (
                                            <IconButton
                                                color="inherit"
                                                aria-owns={langDialogEl ? 'lang-menu' : null}
                                                aria-haspopup="true"
                                                data-cy="operator-lang-select-button"
                                                onClick={handleLangClick}
                                            >
                                                <Icon>language</Icon>
                                            </IconButton>
                                        )}
                                        {showHelpButton && (
                                            <IconButton
                                                color="inherit"
                                                aria-owns={helpDialogEl ? 'help-menu' : null}
                                                aria-haspopup="true"
                                                onClick={handleHelpClick}
                                                data-cy="help-menu-button"
                                            >
                                                <Icon>help</Icon>
                                            </IconButton>
                                        )}
                                        {showDepotLogoutButton && (
                                            <IconButton
                                                color="inherit"
                                                onClick={() => {
                                                    handleDepotLogout();
                                                }}
                                            >
                                                <Icon>lock</Icon>
                                                <Typography
                                                    variant="h6"
                                                    color="inherit"
                                                    style={{
                                                        flex: 1,
                                                        marginLeft: theme.spacing.unit,
                                                        fontWeight: 300
                                                    }}
                                                >
                                                    Lock
                                                </Typography>
                                            </IconButton>
                                        )}
                                        <Hidden lgUp>
                                            {location.pathname === `/operators/${operator._id}/pickups` &&
                                                (operator.accountType === 'System Administrator' ||
                                                    ROLES.includes(operator.accountType)) && (
                                                    <IconButton
                                                        data-cy="operatorsite-pickupdrawer-button"
                                                        color="inherit"
                                                        onClick={handlePickupDrawer(true)}
                                                    >
                                                        <Icon>local_shipping</Icon>
                                                    </IconButton>
                                                )}
                                        </Hidden>
                                    </>
                                )}
                            </Toolbar>
                        </AppBar>
                    </Hidden>
                )}

                {/* Desktop View */}

                <Hidden mdDown>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={drawerOpen}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        {drawerContents(1)}
                    </Drawer>
                    {!isLiveCountsPage && (
                        <AppBar
                            position="fixed"
                            style={{
                                width: `calc(100% - ${drawerWidth}px)`,
                                marginLeft: drawerWidth,
                                backgroundColor: theme.palette.envColor[envColorIndex]
                            }}
                        >
                            <Toolbar>
                                <IconButton
                                    data-cy="operatorsite-drawer-button"
                                    color="inherit"
                                    onClick={handleDrawer(!drawerOpen)}
                                >
                                    <MDIcon
                                        path={drawerOpen ? mdiBackburger : mdiMenu}
                                        size={1}
                                        color={theme.palette.common.white}
                                    />
                                </IconButton>
                                <Typography
                                    variant="h6"
                                    color="inherit"
                                    style={{
                                        flex: 1,
                                        marginLeft: theme.spacing.unit * 2,
                                        fontFamily: 'Oswald',
                                        fontWeight: 300,
                                        letterSpacing: 1
                                    }}
                                >
                                    <img
                                        src={_.get(props, 'brandLogoOverride.url', logo)}
                                        alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
                                        style={{
                                            position: 'relative',
                                            top: 4,
                                            height: 42
                                        }}
                                    />
                                </Typography>
                                {!isProductionEnv && (
                                    <Typography variant="h4" style={{ flex: 1 }} color="inherit">
                                        <b>{getEnvName()}</b>
                                    </Typography>
                                )}
                                {showFullscreenButton && (
                                    <IconButton
                                        color="inherit"
                                        aria-owns={helpDialogEl ? 'help-menu' : null}
                                        aria-haspopup="true"
                                        onClick={handleToggleFullscreenMode}
                                    >
                                        <Icon>fullscreen</Icon>
                                    </IconButton>
                                )}
                                {showRefreshButton && (
                                    <IconButton color="inherit" onClick={() => window.location.reload(false)}>
                                        <Icon>refresh</Icon>
                                    </IconButton>
                                )}
                                {showLanguageButton && (
                                    <IconButton
                                        color="inherit"
                                        aria-owns={langDialogEl ? 'lang-menu' : null}
                                        aria-haspopup="true"
                                        data-cy="operator-lang-select-button"
                                        onClick={handleLangClick}
                                    >
                                        <Icon>language</Icon>
                                    </IconButton>
                                )}
                                {showHelpButton && (
                                    <IconButton
                                        color="inherit"
                                        aria-owns={helpDialogEl ? 'help-menu' : null}
                                        aria-haspopup="true"
                                        data-cy="help-menu-button"
                                        onClick={handleHelpClick}
                                    >
                                        <Icon>help</Icon>
                                    </IconButton>
                                )}
                                {showDepotLogoutButton && (
                                    <IconButton
                                        color="inherit"
                                        onClick={() => {
                                            handleDepotLogout();
                                        }}
                                    >
                                        <Icon>lock</Icon>
                                        <Typography
                                            variant="h6"
                                            color="inherit"
                                            style={{
                                                flex: 1,
                                                marginLeft: theme.spacing.unit,
                                                fontWeight: 300
                                            }}
                                        >
                                            Lock
                                        </Typography>
                                    </IconButton>
                                )}
                                <Hidden lgUp>
                                    {location.pathname === `/operators/${operator._id}/pickups` &&
                                        (operator.accountType === 'System Administrator' ||
                                            ROLES.includes(operator.accountType)) && (
                                            <IconButton color="inherit" onClick={handlePickupDrawer(true)}>
                                                <Icon>local_shipping</Icon>
                                            </IconButton>
                                        )}
                                </Hidden>
                            </Toolbar>
                        </AppBar>
                    )}
                </Hidden>

                <main
                    className={classNames(classes.content, {
                        [classes.contentShift]: drawerOpen
                    })}
                >
                    <Grid
                        container
                        spacing={0}
                        // id="main-grid"
                        style={{
                            height: `100%`,
                            paddingTop: mainContentPadding
                        }}
                    >
                        <Grid item xs={12} style={{ height: '100%' }}>
                            {loading ? (
                                <LoadingScreen />
                            ) : (
                                <Switch>
                                    <Route
                                        exact
                                        path="/operators/:_id"
                                        render={props => (
                                            <Dashboard
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collectors={collectors}
                                                collector={collector}
                                                onSnackbar={onSnackbar}
                                                regions={regions}
                                                pickupsEnabled={pickupsEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/bulks"
                                        render={props => (
                                            <BulkCounter
                                                {...props}
                                                skus={skus}
                                                receivers={receivers}
                                                collectors={collectors}
                                                collector={collector}
                                                auth={auth}
                                                resetTimeoutCount={resetTimeoutCount}
                                                incompleteBulks={incompleteBulks}
                                                overdueBulks={overdueBulks}
                                                whinyBulks={whinyBulks}
                                                completedBulks={completedBulks}
                                                lostAndFoundBulks={lostAndFoundBulks}
                                                incompleteBulksLoading={incompleteBulksLoading}
                                                overdueBulksLoading={overdueBulksLoading}
                                                whinyBulksLoading={whinyBulksLoading}
                                                completedBulksLoading={completedBulksLoading}
                                                lostAndFoundBulksLoading={lostAndFoundBulksLoading}
                                                bulksLoading={bulksLoading}
                                                fetchAllBulks={fetchAllBulks}
                                                commodityColors={commodityColors}
                                                fetchCommodityColors={fetchCommodityColors}
                                                commodities={commodities}
                                                fetchCommodities={fetchCommodities}
                                                pickupsEnabled={pickupsEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/bulks-history"
                                        render={props => (
                                            <BulksHistory
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collectors={collectors}
                                                initialCollector={collector}
                                                skus={skus}
                                                receivers={receivers}
                                                auth={auth}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/bulks-stats"
                                        render={props => (
                                            <BulkStats
                                                {...props}
                                                operator={operator}
                                                collectors={collectors}
                                                initialCollector={collector}
                                                skus={skus}
                                                receivers={receivers}
                                                auth={auth}
                                                onSnackbar={onSnackbar}
                                                pickupsEnabled={pickupsEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/bulks/:bulk_id"
                                        render={props => (
                                            <PORScreen
                                                {...props}
                                                onEmitCountsUpdated={handleEmitCountsUpdated}
                                                onEmitSwitchedBulk={handleEmitSwitchedBulk}
                                                onSnackbar={onSnackbar}
                                                resetTimeoutCount={resetTimeoutCount}
                                                rolePermissions={rolePermissions}
                                                setShowResultDialog={setShowResultDialog}
                                                setResultDialogText={setResultDialogText}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/live-counts"
                                        render={props => <LiveCounts {...props} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/pickups"
                                        render={props => (
                                            <PickupsDriver
                                                {...props}
                                                google={google}
                                                http={http}
                                                showDirections={
                                                    location.pathname === homePath + '/pickups' ? true : false
                                                }
                                                operator={operator}
                                                collector={collector}
                                                driverPickups={pickups}
                                                auth={auth}
                                                drawerPickupOpen={drawerPickupOpen}
                                                onPickupDrawerState={handlePickupDrawer}
                                                reloadOperator={reloadOperator}
                                                onSnackbar={onSnackbar}
                                                onCustomHeader={handleCustomHeader}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/pickups-manager"
                                        render={props => (
                                            <PickupsAdmin
                                                {...props}
                                                google={google}
                                                collectors={collectors}
                                                collector={collector}
                                                reloadOperator={reloadOperator}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/redemptions"
                                        render={props => (
                                            <Redemptions
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collector={collector}
                                                collectors={collectors}
                                                auth={auth}
                                                onSnackbar={onSnackbar}
                                                redemptionOptions={redemptionOptions}
                                                handleSaveRedemptionOptions={handleSaveRedemptionOptions}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/accounting"
                                        render={props => (
                                            <Accounting
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collector={collector}
                                                auth={auth}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/aging-balances-report"
                                        render={props => <AgingBalancesReport {...props} operator={operator} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/users"
                                        render={props => (
                                            <Users
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                collector={collector}
                                                auth={auth}
                                                onSnackbar={onSnackbar}
                                                minRedemption={minRedemption}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/collection-users"
                                        render={props => (
                                            <CollectionUsers
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                collector={collector}
                                                auth={auth}
                                                onSnackbar={onSnackbar}
                                                minRedemption={minRedemption}
                                                setRefreshPendingMobileCollectionCount={
                                                    setRefreshPendingMobileCollectionCount
                                                }
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/charities"
                                        render={props => (
                                            <Charities
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collector={collector}
                                                auth={auth}
                                                onSnackbar={onSnackbar}
                                                setRefreshPendingCharityCount={setRefreshPendingCharityCount}
                                                rolePermissions={rolePermissions}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/trends"
                                        render={props => (
                                            <Trends
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collectors={collectors}
                                                collector={collector}
                                                onSnackbar={onSnackbar}
                                                regions={regions}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/audience-capture"
                                        render={props => (
                                            <AudienceCapture
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collectors={collectors}
                                                collector={collector}
                                                onSnackbar={onSnackbar}
                                                regions={regions}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/donations"
                                        render={props => <CharityDonations {...props} http={http} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/employees"
                                        render={props => (
                                            <Employees
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                collector={collector}
                                                onSnackbar={onSnackbar}
                                                twoFactorAuthenticationWarning={twoFactorAuthenticationWarning}
                                                rolePermissions={rolePermissions}
                                                pickupsEnabled={pickupsEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/services"
                                        render={props => (
                                            <Services
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                collector={collector}
                                                onSnackbar={onSnackbar}
                                                twoFactorAuthenticationWarning={twoFactorAuthenticationWarning}
                                                rolePermissions={rolePermissions}
                                                pickupsEnabled={pickupsEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/admins"
                                        render={props => (
                                            <Admins
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                collector={collector}
                                                onSnackbar={onSnackbar}
                                                twoFactorAuthenticationWarning={twoFactorAuthenticationWarning}
                                                rolePermissions={rolePermissions}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/google-calls"
                                        render={props => (
                                            <GoogleCalls
                                                {...props}
                                                // http={http}
                                                // google={google}
                                                // operator={operator}
                                                // onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/promos"
                                        render={props => (
                                            <Promos
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collector={collector}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/promo-usage"
                                        render={props => <PromoUsage {...props} http={http} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/fees-usage"
                                        render={props => <FeesUsage {...props} />}
                                    />
                                    <Route exact path="/operators/:_id/tips" render={props => <Tips {...props} />} />
                                    <Route
                                        exact
                                        path="/operators/:_id/complaints"
                                        render={props => <Complaints {...props} />}
                                    />
                                    <Route exact path="/operators/:_id/stops" render={props => <Stops {...props} />} />
                                    <Route
                                        exact
                                        path="/operators/:_id/commodities"
                                        render={props => (
                                            <Commodities
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/collectors"
                                        render={props => (
                                            <Collectors
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                collectors={collectors}
                                                onSnackbar={onSnackbar}
                                                pickupsEnabled={pickupsEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/options"
                                        render={props => (
                                            <Options
                                                {...props}
                                                history={history}
                                                google={google}
                                                reloadOperator={reloadOperator}
                                                collector={collector}
                                                processors={processors}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/debug"
                                        render={props => (
                                            <Debug
                                                {...props}
                                                google={google}
                                                operator={operator}
                                                auth={auth}
                                                http={http}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/saved-notifications"
                                        render={props => (
                                            <SavedNotifications
                                                {...props}
                                                google={google}
                                                operator={operator}
                                                auth={auth}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/role-permissions"
                                        render={props => (
                                            <RolePermissions
                                                {...props}
                                                google={google}
                                                operator={operator}
                                                auth={auth}
                                                pickupsEnabled={pickupsEnabled}
                                                tippingEnabled={tippingEnabled}
                                                promosEnabled={props.promosEnabled}
                                                charityEnabled={charityEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/announcements"
                                        render={props => (
                                            <Announcements
                                                {...props}
                                                google={google}
                                                operator={operator}
                                                auth={auth}
                                                collectors={collectors}
                                                charityEnabled={charityEnabled}
                                            />
                                        )}
                                    />
                                    <Route exact path="/operators/:_id/logs" render={props => <Logs auth={auth} />} />
                                    <Route
                                        exact
                                        path="/operators/:_id/db-logs"
                                        render={props => <DbLogs auth={auth} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/driver"
                                        render={props => (
                                            <DriverDashboard
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                pickups={pickups}
                                                redemptions={redemptions}
                                                redemptionFee={redemptionFee}
                                                minRedemption={minRedemption}
                                                redemptionPending={redemptionPending}
                                                onSnackbar={onSnackbar}
                                                reloadOperator={reloadOperator}
                                                onCustomHeader={handleCustomHeader}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/driver-completed"
                                        render={props => (
                                            <DriverComplete
                                                {...props}
                                                http={http}
                                                location={location}
                                                admin
                                                history={history}
                                                operator={operator}
                                                collectors={collectors}
                                                processors={processors}
                                                receivers={receivers}
                                                reloadOperator={reloadOperator}
                                                onSnackbar={onSnackbar}
                                                collector={collector}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/collector-completed"
                                        render={props => (
                                            <DriverComplete
                                                {...props}
                                                http={http}
                                                location={location}
                                                admin
                                                history={history}
                                                operator={operator}
                                                collectors={collectors}
                                                processors={processors}
                                                receivers={receivers}
                                                reloadOperator={reloadOperator}
                                                onSnackbar={onSnackbar}
                                                collector={collector}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/collector-history"
                                        render={props => (
                                            <DriverHistory
                                                {...props}
                                                history={history}
                                                operator={operator}
                                                collectors={collectors}
                                                collector={collector}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/pickups-calendar"
                                        render={props => (
                                            <PickupsCalendar
                                                {...props}
                                                collectors={collectors}
                                                history={history}
                                                initialCollector={collector}
                                                http={http}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/import-pickups"
                                        render={props => (
                                            <BulkImport
                                                {...props}
                                                collectors={collectors}
                                                history={history}
                                                initialCollector={collector}
                                                http={http}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/collector"
                                        render={props => (
                                            <CollectorDashboard
                                                {...props}
                                                history={history}
                                                google={google}
                                                reloadOperator={reloadOperator}
                                                collector={collector}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/quickbooks"
                                        render={props => <QuickBooks {...props} />}
                                    />

                                    <Route
                                        exact
                                        path="/operators/:_id/reconciliation"
                                        render={props => <Reconciliation {...props} />}
                                    />

                                    <Route
                                        exact
                                        path="/operators/:_id/regions"
                                        render={props => (
                                            <Regions
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/fees"
                                        render={props => (
                                            <Fees
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/taxes"
                                        render={props => (
                                            <Taxes
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/trucks"
                                        render={props => (
                                            <Trucks
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collector={collector}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/rating-platforms"
                                        render={props => <RatingPlatforms {...props} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/integrations"
                                        render={props => <Integrations {...props} onSnackbar={onSnackbar} />}
                                    />
                                    <Route exact path="/operators/:_id/rates" render={props => <Rates {...props} />} />
                                    {/* <Route
                                        exact
                                        path="/operators/:_id/droplocations"
                                        render={props => (
                                            <DropLocations
                                                {...props}
                                                http={http}
                                                google={google}
                                                operator={operator}
                                                collectors={collectors}
                                                onSnackbar={onSnackbar}
                                            />
                                        )}
                                    /> */}
                                    <Route
                                        exact
                                        path="/operators/:_id/commodity-pickups"
                                        render={props => <CommodityPickups {...props} collector={collector} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/send-outs"
                                        render={props => <SendOuts {...props} operator={operator} regions={regions} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/settings"
                                        render={props => (
                                            <ConfigurationV2
                                                {...props}
                                                twoFactorAuthenticationWarning={twoFactorAuthenticationWarning}
                                                operator={operator}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/informationForms"
                                        render={props => (
                                            <InformationFormsV2
                                                {...props}
                                                operator={operator}
                                                pickupsEnabled={pickupsEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/email-editor"
                                        render={props => (
                                            <Emails
                                                {...props}
                                                operator={operator}
                                                pickupsEnabled={pickupsEnabled}
                                                charityEnabled={charityEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/infractions"
                                        render={props => (
                                            <Infractions
                                                {...props}
                                                http={http}
                                                operator={operator}
                                                collector={collector}
                                                auth={auth}
                                                onSnackbar={onSnackbar}
                                                setRefreshUnresolvedInfractionCount={
                                                    setRefreshUnresolvedInfractionCount
                                                }
                                                redemptionOptions={redemptionOptions}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/ip-blocking"
                                        render={props => <IPBlocking {...props} operator={operator} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/req-logs"
                                        render={props => <ReqLogs {...props} operator={operator} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/msg-logs"
                                        render={props => <MsgLogs {...props} http={http} operator={operator} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/profile"
                                        render={props => (
                                            <Profile
                                                {...props}
                                                operator={operator}
                                                reloadOperator={reloadOperator}
                                                onLogoutAll={logOutAll}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/questionnaires"
                                        render={props => <Questionnaires {...props} operator={operator} />}
                                    />
                                    {/* <Route
                                        exact
                                        path="/operators/:_id/asset-tracking"
                                        render={props => <AssetTracking {...props} operator={operator} />}
                                    /> */}
                                    <Route
                                        exact
                                        path="/operators/:_id/asset-tracking"
                                        render={props => (
                                            <AssetManagement
                                                {...props}
                                                operator={operator}
                                                setRefreshProblemAssetCount={setRefreshProblemAssetCount}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/inventory"
                                        render={props => <Inventory {...props} operator={operator} />}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/bottle-drives"
                                        render={props => (
                                            <BottleDrives
                                                {...props}
                                                operator={operator}
                                                http={http}
                                                onSnackbar={onSnackbar}
                                                setRefreshPendingBottleDriveCount={setRefreshPendingBottleDriveCount}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/operators/:_id/img-capture-status"
                                        render={props => <ImgCaptureStatus {...props} operator={operator} />}
                                    />
                                    {(!_user.isCollectorAdmin(operator) ||
                                        _.get(
                                            collector,
                                            'configuration.bulkPermissions.accessOTCBooking.update',
                                            false
                                        )) && (
                                        <Route
                                            exact
                                            path="/operators/:_id/OTC"
                                            render={props => (
                                                <OTC
                                                    {...props}
                                                    http={http}
                                                    google={google}
                                                    operator={operator}
                                                    onSnackbar={onSnackbar}
                                                />
                                            )}
                                        />
                                    )}
                                    <Route
                                        exact
                                        path="/operators/:_id/journal-entries"
                                        render={props => (
                                            <JournalEntries
                                                {...props}
                                                pickupsEnabled={pickupsEnabled}
                                                collectors={collectors}
                                                collector={collector}
                                                regions={regions}
                                            />
                                        )}
                                    />
                                    <Route
                                        render={props => (
                                            <Error header="Error 404" description="Requested page was not found." />
                                        )}
                                    />
                                </Switch>
                            )}
                        </Grid>
                    </Grid>
                </main>
            </div>
            <Menu data-cy="help-menu" anchorEl={helpDialogEl} open={Boolean(helpDialogEl)} onClose={handleHelpClose}>
                {helpCenterFunctions.showHowItWorks && (
                    <MenuItem data-cy="help-menu-how-it-works" onClick={handleHowItWorks(true)}>
                        <ListItemIcon>
                            <Icon>settings</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'How it works'} />
                    </MenuItem>
                )}
                {helpCenterFunctions.showFaq && (
                    <MenuItem data-cy="help-menu-faq" onClick={handleFAQ(true)}>
                        <ListItemIcon>
                            <Icon>question_answer</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'FAQ'} />
                    </MenuItem>
                )}
                {helpCenterFunctions.showContactUs && (
                    <MenuItem data-cy="help-menu-contact" onClick={handleContactDialog(true)}>
                        <ListItemIcon>
                            <Icon>chat</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'Contact us'} />
                    </MenuItem>
                )}
            </Menu>
            <Menu id="lang-menu" anchorEl={langDialogEl} open={Boolean(langDialogEl)} onClose={handleLangClose}>
                {availableLangs.map(selectedLang => {
                    return (
                        <MenuItem selected={lang === selectedLang} onClick={() => handleSelectLanguage(selectedLang)}>
                            <ListItemText primary={{ en: 'English (CAN)', fr: 'Français (CAN)' }[selectedLang]} />
                        </MenuItem>
                    );
                })}
            </Menu>
            {helpCenterFunctions.showHowItWorks && (
                <HowItWorks
                    open={howItWorksOpen}
                    tabIndex={JSON.parse(process.env.REACT_APP_GET_RECYCLING_SOFTWARE_OPTION_ENABLED) ? 2 : 0}
                    onFAQ={handleFAQ}
                    onClose={handleHowItWorks(false)}
                    isRegisteredUser={true}
                />
            )}
            <FAQ
                open={faqOpen}
                history={history}
                tabIndex={JSON.parse(process.env.REACT_APP_GET_RECYCLING_SOFTWARE_OPTION_ENABLED) ? 2 : 0}
                onClose={handleFAQ(false)}
                http={http}
            />
            <ContactForm
                http={http}
                open={contactDialogOpen}
                onSubmitSuccess={handleContactSubmitSuccess}
                onClose={handleContactDialog(false)}
            />
            <RefreshRequiredDialog open={refreshRequired && !deviceHelper.isNativeApp()} />

            <EmailReceiptResultDialog
                open={showResultDialog}
                onClose={() => {
                    setShowResultDialog(false);
                    setResultDialogText('');
                }}
                text={resultDialogText}
            />
        </OperatorProvider>
    );
    async function getDepotTimeout() {
        const res = await http.getJSON(`/collectors/${collector._id}/getTimeout`);
        if (res.ok) {
            setTimeout(res.data.depotLoginTimeout);
        }
    }
    async function getIsDepotLogin() {
        const res = await http.getJSON(`/users/${operator._id}/getDepotLogin`);
        if (res.ok) {
            setIsDepotLogin(res.data.depotLogin);
        }
    }
    async function updateRecentPage() {
        await http.post(
            `/users/${operator._id}/updateRecentPage`,
            {
                depotLoginRecentPage: window.location.pathname
            },
            true
        );
    }
}

OperatorSite.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withWidth()(withStyles(styles, { withTheme: true })(OperatorSite));
