import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import {
    Paper,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    ListItemAvatar,
    Divider,
    Icon,
    Tooltip,
    IconButton,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Collapse,
    Badge
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

import { Icon as MDIcon } from '@mdi/react';
import {
    mdiAlert,
    mdiClockCheck,
    mdiCodeJson,
    mdiRefresh,
    mdiFileDownload,
    mdiFileUpload,
    mdiAccount,
    mdiCurrencyUsd,
    mdiBottleSoda,
    mdiSync,
    mdiClipboardListOutline,
    mdiNoteText
} from '@mdi/js';

import HttpContext from 'utils/contexts/HttpContext';
import { getSwitch, getTextInput } from 'components/CRUDTables/helperFunctions';

import IntegrationForm from 'components/Integrations/Forms/IntegrationForm';
import IntegrationPermissionsDialog from 'components/Integrations/Dialogs/integrationPermissionsDialog';
import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const Integrations = ({ theme, onSnackbar }) => {
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);

    const [loading, setLoading] = useState(false);
    const [allIntegrations, setAllIntegrations] = useState([]);
    const [syncConfiguration, setSyncConfiguration] = useState({});

    const [updatingIntegration, setUpdatingIntegration] = useState(null);

    const [timer, setTimer] = useState(-1);
    const [timerType, setTimerType] = useState(null);
    const [resetTimer, setResetTimer] = useState(false);

    const [taskRunning, setTaskRunning] = useState(false);

    const [mode, setMode] = useState('CREATE');
    const [form, setForm] = useState({});
    const [formOpen, setFormOpen] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoDialogType, setInfoDialogType] = useState('');

    const [permissionsOpen, setPermissionsOpen] = useState(false);

    const [historyLoading, setHistoryLoading] = useState(false);
    const [history, setHistory] = useState([]);

    const [debugOpen, setDebugOpen] = useState(false);
    const [debugData, setDebugData] = useState({});

    const [integrationTypes, setIntegrationTypes] = useState([]);
    const [typeFilter, setTypeFilter] = useState([]);
    const [resubmitFilter, setResubmitFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState(['OK', 'FAIL', 'WARNING']);
    const [directionFilter, setDirectionFilter] = useState(['INCOMING', 'OUTGOING']);

    const [config, setConfig] = useState(null);
    const [configLoading, setConfigLoading] = useState(false);

    const warnAction = useContext(ConfirmDialogContext);

    const permissionsDescriptions = {
        setKantechPasscode: 'Set Kantech Passcodes for Users',
        porCreate: 'Create Order',
        porSubmit: 'Receive Counts',
        incomingLabels: 'Receive Labels',
        incomingRedemptions: 'Receive ETransfer Req.',
        incomingChequeRedemptions: 'Receive Cheque Req.',
        outgoingCounts: 'Send Counts',
        outgoingAccountStatus: 'Send Account Status',
        outgoingAccountDetails: 'Send Account Details',
        outgoingRedemptions: 'Send ETransfers',
        outgoingChequeRedemptions: 'Send Cheques',
        outgoingInventory: 'Send Inventory',
        outgoingImages: 'Send Images',
        outgoingDropLocations: 'Send Drop Locations',
        getPaymentOptions: 'Get Payment Options',
        sendPayment: 'Send Payment',
        getPaymentStatus: 'Get Payment Status',
        getAccessDoorCode: 'Get Access Door Code',
        sendRegistrationDetails: 'Send Customer Registration Details',
        // sendUpdateDetails: 'Send Customer Update Details',
        updateLegacyPassword: 'Update Customer Legacy password',
        verifyLoginDetails: 'Send/Verify Customer Login Details',
        validateId: 'Validate Incoming User ID',
        getUser: 'Return User Name/ID',
        getDropLocations: 'Return Drop Locations',
        getBagStatus: 'Return Bag Status',
        pollTOMRASessions: 'Poll TOMRA Sessions',
        scanQRCode: 'Scan QR Code'
    };

    const saveIntegration = async integration => {
        setFormLoading(true);

        let controllerPath = `/integrations/createIntegration`;
        let modeDescription = 'added';

        if (mode !== 'CREATE') {
            controllerPath = `/integrations/${integration._id}/updateIntegration`;
            modeDescription = 'updated';
        }

        const res = await http.postJSON(controllerPath, integration);
        const data = _.get(res, 'data', {});

        if (data.ok) {
            setForm({});
            setFormOpen(false);
            await loadIntegrations();
            onSnackbar(`Integration successfully ${modeDescription}!`);
        } else if (data.message) {
            onSnackbar(data.message, 'error');
        } else {
            onSnackbar(`Integration could not be ${modeDescription}!`, 'error');
        }

        setFormLoading(false);
    };

    const onAddIntegration = () => {
        setMode('CREATE');
        setForm({});
        setFormOpen(true);
    };

    const onEditIntegration = async integration => {
        setMode('EDIT');
        setForm(integration);
        setFormOpen(true);
    };

    const onDeleteIntegration = async integration => {
        setFormLoading(true);

        const res = await http.getJSON(`/integrations/${integration._id}/deleteIntegration`);
        if (res.ok) {
            setFormOpen(false);
            onSnackbar('Integration successfully deleted!');
            await loadIntegrations();
        } else {
            onSnackbar('Integration could not be deleted!', 'error');
        }

        setFormLoading(false);
    };

    const handleFormOpen = state => {
        setFormOpen(state);
    };

    const handleDebug = async (state, historyItemId = '') => {
        if (!state) {
            setDebugOpen(false);
            return;
        }

        const res = await http.getJSON(`/integrationHistory/${historyItemId}/getData`);
        if (res.ok) {
            const debugData = _.get(res, 'data.integrationData', {});
            setDebugOpen(true);
            setDebugData(debugData);
        } else {
            onSnackbar('Data could not be loaded!', 'error');
        }
    };

    const startTimer = timerType => {
        setTimer(0);
        setTimerType(timerType);
    };

    const endTimer = () => {
        setResetTimer(true);
        setTimerType(null);
    };

    const testConnection = async integration => {
        startTimer('testConnection');
        const res = await http.getJSON(`/integrations/${integration._id}/testConnection`);
        const error = _.get(res, 'data.error', false);

        if (error) {
            onSnackbar(_.get(res, 'data.message', 'Error occurred while processing request'), 'error');
        } else if (res.ok) {
            onSnackbar(`Successfully connected to ${integration.name} API`);
        } else {
            onSnackbar('Error occurred while processing request', 'error');
        }

        await loadHistory();
        endTimer();
    };

    const sync = async integration => {
        startTimer('sync');
        const res = await http.getJSON(`/integrations/${integration._id}/sync`);
        const error = _.get(res, 'data.error', false);

        if (error) {
            onSnackbar(_.get(res, 'data.message', 'Error occurred while processing request'), 'error');
        } else if (res.ok) {
            onSnackbar('Sync operation completed');
        } else {
            onSnackbar('Error occurred while processing request', 'error');
        }

        await loadHistory();
        endTimer();
    };

    const loadHistory = async () => {
        setHistoryLoading(true);

        const res = await http.getJSON('/integrationHistory/getAll');
        if (res.ok) {
            const { integrationHistory } = res.data;
            setHistory(integrationHistory);
        }

        setHistoryLoading(false);
    };

    const resubmitHistory = async historyItemId => {
        const res = await http.getJSON(`/integrationHistory/${historyItemId}/resubmit`);
        const error = _.get(res, 'data.error', false);

        if (error) {
            onSnackbar(_.get(res, 'data.message', 'Error occurred while processing request'), 'error');
        } else if (res.ok) {
            onSnackbar('Request was resubmitted successfully');
            await loadHistory();
        } else {
            onSnackbar('Error occurred while processing request', 'error');
        }
    };

    const populateIntegrationTypes = integrations => {
        const integrationsAsTypes = integrations.map(integration => integration.name);
        const uniqueTypes = [...new Set(integrationsAsTypes), 'Unknown'];

        setTypeFilter(uniqueTypes);
        setIntegrationTypes(uniqueTypes);
    };

    const loadIntegrations = async () => {
        setLoading(true);

        const res = await http.getJSON('/integrations/getAll');
        if (res.ok) {
            const { integrations } = res.data;

            setAllIntegrations(integrations);
            populateIntegrationTypes(integrations);
        }

        setLoading(false);
    };

    const loadSyncConfiguration = async () => {
        setLoading(true);

        const res = await http.getJSON('/integrations/loadSyncConfiguration');
        if (res.ok) {
            const { syncConfiguration } = res.data;
            setSyncConfiguration(syncConfiguration);
        }

        setLoading(false);
    };

    const loadConfig = async () => {
        setConfigLoading(true);
        const res = await http.getJSON('/system/allConfigs');
        if (res.ok) {
            setConfig(res.data.config);
        }
        setConfigLoading(false);
    };

    const handleInfoDialog = (state, type) => {
        setInfoDialogOpen(state);
        setInfoDialogType(type);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ..._.pick(config, 'expressSignIn', 'sync')
        },
        validationSchema: Yup.object({
            expressSignIn: Yup.object({
                client_id: Yup.string(),
                client_secret: Yup.string(),
                expressURL: Yup.string(),
                signInPath: Yup.string(),
                tokenPath: Yup.string(),
                confirmationPath: Yup.string()
            }),
            sync: Yup.object({
                integrationId: Yup.string(),
                collectorsEndpoint: Yup.string(),
                collectorScanAtDropEndpoint: Yup.string(),
                collectorCapabilitiesEndpoint: Yup.string(),
                collectorAcceptedCommoditiesEndpoint: Yup.string(),
                collectorHoursEndpoint: Yup.string(),
                ratesEndpoint: Yup.string(),
                skusEndpoint: Yup.string(),
                materialsEndpoint: Yup.string(),
                errorsEndpoint: Yup.string(),
                serviceFeeEndpoint: Yup.string()
            })
        }),
        onSubmit: async values => {
            const res = await http.postJSON('/system/updateIntegrationConfigs', { config: values }, true);
            if (res.ok) {
                onSnackbar('Values updated successfully');
                setConfig(res.data.config);
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        }
    });

    useEffect(() => {
        async function load() {
            await loadSyncConfiguration();
            await loadIntegrations();
            await loadHistory();
            await loadConfig();
        }

        load();
    }, []);

    useEffect(() => {
        formik.handleReset();
    }, [config]);

    useEffect(() => {
        if (resetTimer || timer < 0) {
            setTimer(-1);
            setResetTimer(false);
            setTaskRunning(false);
            return;
        }

        setTaskRunning(true);
        setTimeout(() => {
            setTimer(timer + 1);
        }, 1000);
    }, [timer]);

    const getContentForIntegration = integration => {
        const testEndpoint = _.get(integration, 'endpoints.testEndpoint');

        const outgoingTestAllowed = testEndpoint; //&& (outgoingCounts || outgoingRedemptions);

        const { enabled, integrationId } = syncConfiguration;

        let errors = 0;
        for (let historyItem of history) {
            if (integration._id != _.get(historyItem, 'integration._id')) {
                continue;
            }
            if (historyItem.status != 'FAIL') {
                continue;
            }

            errors += 1;
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Errors" disableFocusListener disableTouchListener>
                    <IconButton
                        onClick={() => {
                            setTypeFilter([integration.name]);
                            setStatusFilter(['FAIL']);
                        }}
                    >
                        <Badge badgeContent={errors} color="error">
                            <Icon>error</Icon>
                        </Badge>
                    </IconButton>
                </Tooltip>
                {enabled && integrationId === integration._id && (
                    <Tooltip title="Sync to outgoing API" disableFocusListener disableTouchListener>
                        <IconButton
                            disabled={!_.isNil(updatingIntegration) || taskRunning}
                            onClick={() => sync(integration)}
                        >
                            <Icon>sync</Icon>
                        </IconButton>
                    </Tooltip>
                    // <Button
                    //     variant="contained"
                    //     color="secondary"
                    //     disabled={!_.isNil(updatingIntegration) || taskRunning}
                    //     onClick={() => sync(integration)}
                    //     style={{ marginRight: theme.spacing.unit * 2 }}
                    // >
                    //     {timerType === 'sync' && timer > 0 ? (
                    //         <>
                    //             <CircularProgress
                    //                 size={18}
                    //                 thickness={4.8}
                    //                 style={{ marginRight: theme.spacing.unit }}
                    //             />
                    //             {formatsSecondsToTime(timer)}
                    //         </>
                    //     ) : (
                    //         'Sync'
                    //     )}
                    // </Button>
                )}
                {outgoingTestAllowed && (
                    <Tooltip title="Test Connection" disableFocusListener disableTouchListener>
                        <IconButton
                            onClick={() => testConnection(integration)}
                            disabled={!_.isNil(updatingIntegration) || taskRunning}
                        >
                            <Icon>wifi_tethering</Icon>
                        </IconButton>
                    </Tooltip>
                    // <Button
                    //     variant="contained"
                    //     color="secondary"
                    //     disabled={!_.isNil(updatingIntegration) || taskRunning}
                    //     onClick={() => testConnection(integration)}
                    //     style={{ marginRight: theme.spacing.unit * 2 }}
                    // >
                    //     {timerType === 'testConnection' && timer > 0 ? (
                    //         <>
                    //             <CircularProgress
                    //                 size={18}
                    //                 thickness={4.8}
                    //                 style={{ marginRight: theme.spacing.unit }}
                    //             />
                    //             {formatsSecondsToTime(timer)}
                    //         </>
                    //     ) : (
                    //         'Test Connection'
                    //     )}
                    // </Button>
                )}
            </div>
        );
    };

    return (
        <>
            <Paper
                style={{
                    minHeight: '80px',
                    margin: theme.spacing.unit * 2,
                    padding: theme.spacing.unit * 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    overflow: 'auto'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="integrationType">Type Filters</InputLabel>
                        <Select
                            multiple
                            input={<OutlinedInput id="integrationType" name="integrationType" labelWidth={85} />}
                            value={typeFilter || []}
                            onChange={e => setTypeFilter(e.target.value)}
                            style={{
                                marginRight: theme.spacing.unit,
                                minWidth: 150,
                                maxWidth: 225
                            }}
                        >
                            {integrationTypes.map(type => (
                                <MenuItem key={`integration-filter-type-${type}`} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="status">History Status</InputLabel>
                        <Select
                            multiple
                            input={<OutlinedInput id="status" name="status" labelWidth={105} />}
                            value={statusFilter || []}
                            onChange={e => setStatusFilter(e.target.value)}
                            style={{
                                marginRight: theme.spacing.unit,
                                minWidth: 150,
                                maxWidth: 225
                            }}
                        >
                            <MenuItem key={`integration-filter-status-ok`} value={'OK'}>
                                OK
                            </MenuItem>
                            <MenuItem key={`integration-filter-status-fail`} value={'FAIL'}>
                                FAIL
                            </MenuItem>
                            <MenuItem key={`integration-filter-status-warning`} value={'WARNING'}>
                                WARNING
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="dataTransfer">Data Transfer</InputLabel>
                        <Select
                            multiple
                            input={<OutlinedInput id="dataTransfer" name="dataTransfer" labelWidth={100} />}
                            value={directionFilter || []}
                            onChange={e => setDirectionFilter(e.target.value)}
                            style={{
                                marginRight: theme.spacing.unit,
                                minWidth: 150,
                                maxWidth: 225
                            }}
                        >
                            <MenuItem key={`integration-filter-dir-in`} value={'INCOMING'}>
                                INCOMING
                            </MenuItem>
                            <MenuItem key={`integration-filter-dir-out`} value={'OUTGOING'}>
                                OUTGOING
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="canResubmitOnly"
                                    name="canResubmitOnly"
                                    color="primary"
                                    onChange={() => setResubmitFilter(!resubmitFilter)}
                                    value={resubmitFilter}
                                    checked={resubmitFilter}
                                />
                            }
                            label="Show resubmittable only"
                        />
                    </FormControl>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        onClick={() => setPermissionsOpen(true)}
                        color="primary"
                        data-cy="integrations-table-edit-permissions"
                        style={{ marginRight: theme.spacing.unit }}
                    >
                        <Icon style={{ marginRight: theme.spacing.unit }}>settings</Icon>
                        Permissions
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => onAddIntegration()}
                        color="primary"
                        data-cy="integrations-table-add-new"
                    >
                        <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                        Add New
                    </Button>
                </div>
            </Paper>

            {loading && (
                <Paper
                    style={{
                        minHeight: '80px',
                        padding: theme.spacing.unit * 2,
                        margin: theme.spacing.unit * 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress size={32} />
                </Paper>
            )}
            {!loading && _.isEmpty(allIntegrations) && (
                <Paper
                    style={{
                        minHeight: '80px',
                        padding: theme.spacing.unit * 2,
                        margin: theme.spacing.unit * 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="h5">No integrations available</Typography>
                </Paper>
            )}
            {!loading &&
                !_.isEmpty(allIntegrations) &&
                allIntegrations.map(integration => {
                    // if (!typeFilter.includes(integration.name)) return <></>;

                    const allowedIPs = integration.allowedIPs;

                    return (
                        <Paper
                            style={{
                                padding: theme.spacing.unit * 2,
                                margin: theme.spacing.unit * 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                overflowX: 'auto',
                                overflowY: 'hidden'
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={2}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: theme.spacing.unit / 2
                                    }}
                                >
                                    {integration.logoURL && (
                                        <img
                                            src={integration.logoURL}
                                            alt={integration.name}
                                            style={{
                                                maxWidth: '100%', // or 80% of container?
                                                maxHeight: '54px' // or 80% of container?
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingLeft: theme.spacing.unit * 2
                                    }}
                                >
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{integration.name}</span> -{' '}
                                        <span style={{ color: 'grey' }}>{integration.description}</span>
                                    </Typography>
                                    {integration.active && (
                                        <Typography>
                                            <span style={{ fontWeight: 500 }}>Outgoing API</span> -{' '}
                                            <span style={{ color: 'grey' }}>
                                                {integration.integrationURL || 'None Configured'}
                                            </span>
                                        </Typography>
                                    )}
                                    {integration.active && (
                                        <Typography>
                                            <span style={{ fontWeight: 500 }}>Allowed IP's</span> -{' '}
                                            <span style={{ color: 'grey' }}>
                                                {allowedIPs.join(', ') || 'None configured'}
                                            </span>
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}
                                >
                                    {integration.active && getContentForIntegration(integration)}
                                    <Tooltip title="Edit" disableFocusListener disableTouchListener>
                                        <IconButton
                                            onClick={() => {
                                                onEditIntegration(integration);
                                            }}
                                        >
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Delete" disableFocusListener disableTouchListener>
                                        <IconButton
                                            onClick={() =>
                                                warnAction(
                                                    () => onDeleteIntegration(integration),
                                                    `Are you sure you want to delete the ${
                                                        integration.name
                                                    } integration?`
                                                )
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Paper>
                    );
                })}
            {!loading && !historyLoading && (
                <Paper
                    style={{
                        margin: theme.spacing.unit * 2,
                        marginTop: 0,
                        padding: theme.spacing.unit * 2,
                        paddingTop: theme.spacing.unit,
                        height: 500
                    }}
                >
                    <List style={{ maxHeight: '100%', overflowY: 'auto', paddingBottom: theme.spacing.unit * 2 }}>
                        {history.map((hist, idx) => {
                            const integrationId = _.get(hist, 'integration._id');
                            const integration = _.find(allIntegrations, { _id: integrationId });

                            if (!integration && !typeFilter.includes('Unknown')) {
                                return <></>;
                            }

                            if (integration && !typeFilter.includes(integration.name)) {
                                return <></>;
                            }

                            const showIncoming = directionFilter.includes('INCOMING');
                            if (hist.incoming && !showIncoming) return <></>;

                            const showOutgoing = directionFilter.includes('OUTGOING');
                            if (!hist.incoming && !showOutgoing) return <></>;

                            const showSuccess = statusFilter.includes('OK');
                            if (hist.status === 'OK' && !showSuccess) return <></>;

                            const showFail = statusFilter.includes('FAIL');
                            if (hist.status === 'FAIL' && !showFail) return <></>;

                            const showWarning = statusFilter.includes('WARNING');
                            if (hist.status === 'WARNING' && !showWarning) return <></>;

                            const didSucceed = hist.status !== 'FAIL';
                            const canBeResubmitted = !didSucceed && _.get(hist, 'data.canResubmit', false);

                            const resubmittableOnly = resubmitFilter;
                            if (resubmittableOnly && !canBeResubmitted) return <></>;

                            const histStatusIcon = getStatusIcon(hist.status);
                            const histStatusColor = getStatusIconColor(hist.status);
                            const histTypeIcon = getHistoryIcon(hist.type);

                            return (
                                <>
                                    <ListItem key={`${hist.description}-${idx}`}>
                                        {histStatusIcon && (
                                            <ListItemAvatar style={{ minWidth: '16px' }}>
                                                <MDIcon path={histStatusIcon} color={histStatusColor} size={1} />
                                            </ListItemAvatar>
                                        )}
                                        <ListItemText
                                            primary={_.get(hist, 'description', 'Unknown Operation')}
                                            secondary={
                                                (hist.isResubmission ? '[RESUBMISSION] ' : '') +
                                                moment(hist.date).format('L - LTS')
                                            }
                                            style={{ minWidth: '200px' }}
                                        />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {histTypeIcon && (
                                                <div
                                                    style={{
                                                        margin: theme.spacing.unit,
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <Tooltip title={hist.type}>
                                                        <MDIcon path={histTypeIcon} size={1} color={colors.grey[600]} />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            <div
                                                style={{
                                                    margin: theme.spacing.unit,
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Tooltip title={hist.incoming ? 'Incoming req.' : 'Outgoing req.'}>
                                                    <MDIcon
                                                        path={hist.incoming ? mdiFileDownload : mdiFileUpload}
                                                        size={1}
                                                        color={colors.grey[600]}
                                                    />
                                                </Tooltip>
                                            </div>
                                            {canBeResubmitted && (
                                                <IconButton onClick={() => resubmitHistory(hist._id)}>
                                                    <MDIcon path={mdiRefresh} size={1} color={colors.grey[600]} />
                                                </IconButton>
                                            )}
                                            <IconButton
                                                onClick={() => {
                                                    handleDebug(true, hist._id);
                                                }}
                                            >
                                                <MDIcon path={mdiCodeJson} size={1} color={colors.grey[600]} />
                                            </IconButton>
                                        </div>
                                    </ListItem>

                                    <Divider
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            marginRight: theme.spacing.unit * 2
                                        }}
                                    />
                                </>
                            );
                        })}
                    </List>
                </Paper>
            )}
            {!configLoading && formik.values.expressSignIn && process.env.REACT_APP_REGION_EXT === 'EXP' && (
                <ConfigWidget theme={theme} formik={formik} title="Express Sign In">
                    <Grid item xs={12}>
                        <Typography variant="body1">Request Parameters</Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {getTextInput(theme, 'expressSignIn.client_id', 'Client ID', formik)}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {getTextInput(theme, 'expressSignIn.client_secret', 'Client Secret', formik, 'password')}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">URL + Paths</Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {getTextInput(theme, 'expressSignIn.expressURL', 'Express URL', formik, 'text')}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {getTextInput(theme, 'expressSignIn.signInPath', 'Sign-In Path', formik, 'text')}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {getTextInput(theme, 'expressSignIn.tokenPath', 'Token Path', formik, 'text')}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {getTextInput(theme, 'expressSignIn.confirmationPath', 'Confirmation URL', formik)}
                    </Grid>
                </ConfigWidget>
            )}

            {!configLoading && !_.isEmpty(allIntegrations) && (
                <ConfigWidget theme={theme} formik={formik} title="Sync">
                    <Grid item xs={12}>
                        {getSwitch(theme, 'sync.enabled', 'Enable Sync', formik)}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">Integration Id</Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {getTextInput(theme, 'sync.integrationId', 'Integration Id', formik)}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">Endpoints</Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(theme, 'sync.collectorsEndpoint', 'Collectors Endpoint', formik, 'text')}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(
                            theme,
                            'sync.collectorScanAtDropEndpoint',
                            'Collector Scan Function Endpoint ',
                            formik,
                            'text'
                        )}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(
                            theme,
                            'sync.collectorCapabilitiesEndpoint',
                            'Collector Capabilities Endpoint',
                            formik,
                            'text'
                        )}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(
                            theme,
                            'sync.collectorAcceptedCommoditiesEndpoint',
                            'Collector Material Types Endpoint',
                            formik,
                            'text'
                        )}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(
                            theme,
                            'sync.collectorAcceptableProgramsEndpoint',
                            'Collector Acceptable Commodities Endpoint',
                            formik,
                            'text'
                        )}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(theme, 'sync.collectorHoursEndpoint', 'Collector Hours Endpoint', formik, 'text')}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(theme, 'sync.ratesEndpoint', 'Rates Endpoint', formik, 'text')}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(theme, 'sync.skusEndpoint', 'Skus Endpoint', formik, 'text')}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {getTextInput(theme, 'sync.materialsEndpoint', 'Materials Endpoint', formik, 'text')}
                    </Grid>
                    {/* <Grid item md={3} xs={12}>
                        {getTextInput(theme, 'sync.errorsEndpoint', 'Errors Endpoint', formik)}
                    </Grid> */}
                    <Grid item md={3} xs={12}>
                        {getTextInput(theme, 'sync.serviceFeeEndpoint', 'Service Fee Endpoint', formik)}
                    </Grid>
                </ConfigWidget>
            )}
            <IntegrationForm
                theme={theme}
                onSnackbar={onSnackbar}
                integration={form}
                formLoading={formLoading}
                formOpen={formOpen}
                handleFormOpen={handleFormOpen}
                saveIntegration={saveIntegration}
                onInfoDialog={handleInfoDialog}
                permissionsDescriptions={permissionsDescriptions}
            />
            <Dialog open={infoDialogOpen}>
                <DialogTitle>{infoDialogType}</DialogTitle>
                <DialogContent>
                    <Typography>{getDialogText(infoDialogType)}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setInfoDialogOpen(false)} disabled={formLoading}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
            {!loading && (
                <IntegrationPermissionsDialog
                    open={permissionsOpen}
                    theme={theme}
                    integrations={allIntegrations}
                    syncConfiguration={syncConfiguration}
                    onClose={() => setPermissionsOpen(false)}
                    permissionsDescriptions={permissionsDescriptions}
                />
            )}
            {debugOpen && (
                <CustomDebugDialog
                    open={debugOpen}
                    json={debugData}
                    onClose={() => handleDebug(false)}
                    allowDecryption
                />
            )}
        </>
    );
};

export default withTheme()(Integrations);

function getStatusIcon(historyStatus) {
    switch (historyStatus) {
        case 'OK':
            return mdiClockCheck;
        case 'FAIL':
            return mdiAlert;
        case 'WARNING':
            return mdiAlert;

        default:
    }

    return null;
}

function getStatusIconColor(historyStatus) {
    switch (historyStatus) {
        case 'OK':
            return colors.green[500];
        case 'FAIL':
            return colors.red[500];
        case 'WARNING':
            return colors.yellow[700];

        default:
            return colors.red[500];
    }
}

function getHistoryIcon(historyItemType) {
    switch (historyItemType) {
        case 'AUTH':
            return mdiAccount;
        case 'COUNTS':
            return mdiBottleSoda;
        case 'REDEMPTIONS':
            return mdiCurrencyUsd;
        case 'SYNC':
            return mdiSync;
        case 'INVENTORY':
            return mdiClipboardListOutline;
        case 'LABELS':
            return mdiNoteText;
        default:
    }

    return null;
}

function getDialogText(dialogType) {
    switch (dialogType) {
        case 'Integration URL':
            return 'The integration URL is the URL to which outgoing requests will be made (if applicable)';
        case 'Access Account':
            return `An access account is used to provide a means of getting an authentication token from ${
                process.env.REACT_APP_BRAND_NAME
            } for a third party API. By leaving this field blank, the server will accept requests from any IP which is listed in the Allowed IP field below.`;
        case 'X-API-Key':
            return 'This header will be included with all outgoing requests for this integration.';
        case 'Content Type':
            return 'Content type for auth request';
        case 'Other Parameters':
            return 'Other parameters that will be sent as part of the auth request alongside the username and password. Uses application/x-www-form-urlencoded format (key=value&key2=value2)';
        case 'POST Counts':
            return 'The endpoint to which counts are submitted';
        case 'POST Status':
            return 'The endpoint to which changes of account status are submitted';
        case 'POST Details':
            return 'The endpoint to which changes of account details are submitted';
        case 'POST Redemptions':
            return 'The endpoint to which redemptions are submitted';
        case 'Token Endpoint':
            return 'The endpoint from which an authentication token is requested. If you need to use a url that is different from the integration URL, enter the full url here (i.e. https://url.com/endpoint)';
        case 'Public Key Endpoint':
            return 'The endpoint from which an shared public key is requested. If you need to use a url that is different from the integration URL, enter the full url here (i.e. https://url.com/endpoint)';
        case 'Private Key File Path':
            return 'Path from root to private key file used for creating JWT assertion';
        case 'JWT Issuer':
            return `Issuer of the JWT assertion (e.g. ${process.env.REACT_APP_ORIGIN_URL})`;
        case 'JWT Audience':
            return `Audience for the JWT assertion (e.g. www.apiprovider.com)`;
        case 'JWT Scope':
            return `Scope of the JWT assertion (value differs depending on audience)`;
        case 'POST Test':
            return 'An endpoint which can be used to test for a 200 response as a means of verifying an existing authentication token';
        case 'POST Register':
            return 'The endpoint to which customer data is sent upon registration';
        // case 'POST Update':
        //     return 'The endpoint to which customer data is sent when the customer account is updated';
        case 'POST Update Legacy Password':
            return 'The endpoint called when an Express Legacy migrated customer resets their password before their first login on Return-It App.';
        case 'POST Login':
            return 'The endpoint to which customer data is sent and validated when the customer logs in';
        case 'POST Locations':
            return 'The endpoint to which kiosk drop locations are sent';
        case 'POST Payment Options':
            return 'The endpoint to check what payment options are available for a given customer';
        case 'POST Send Payment':
            return 'The endpoint to generate and send an e-Transfer to a customer';
        case 'POST Payment Status':
            return 'The endpoint to query current status of sent payments';
        case 'Outgoing Username':
            return 'An outgoing username which is used to authenticate a token request';
        case 'Outgoing Password':
            return 'An outgoing password which is used to authenticate a token request';
        case 'Poll TOMRA Sessions':
            return 'The endpoint from which TOMRA counts are polled';
        case 'Poll TOMRA Sessions Freq.':
            return 'The frequency at which TOMRA counts will be polled';
        case 'Scan QR Code':
            return 'Once a user scans a QR code (on an RVM or terminal), data will be sent to the corresponding API at this endpoint';
        case 'Token Prefix':
            return 'Once a user scans a QR code (on an RVM or terminal), this unique prefix (i.e. MX-) will be used to determine which integration to send data to.';
        case 'TOMRA Webhook Secret':
            return 'Secret against which payload from TOMRA will be verified for authenticity';
        case 'Lost Bag SKU':
            return 'Incoming count SKU used to identify a "lost" count. Works in tandem with adjustment sku to determine a count "quantity". (Applicable to Express payloads)';
        case 'Adjustment SKU':
            return 'Internal SKU for adjustment. This will be used when creating an adjustment for a user. (Applicable to Express payloads)';

        default:
    }

    return '';
}

function ConfigWidget(props) {
    const [expanded, setExpanded] = useState(false);
    const { children, formik, theme, title } = props;
    return (
        <Paper
            style={{
                padding: theme.spacing.unit * 2,
                margin: theme.spacing.unit * 2
            }}
        >
            <Typography variant="h6">
                <IconButton onClick={() => setExpanded(!expanded)}>
                    <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                </IconButton>
                {title}
            </Typography>
            <Collapse in={expanded}>
                <Grid container spacing={theme.spacing.unit}>
                    {children}
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing.unit * 2 }}>
                    <Button color="primary" onClick={formik.handleSubmit} variant="contained">
                        Save
                    </Button>
                </div>
            </Collapse>
        </Paper>
    );
}
