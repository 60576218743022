import React, { useContext } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import { DialogContent, Dialog, DialogActions, Button, withTheme, Grid, Avatar } from '@material-ui/core';

import { getTextInput, getSwitch } from '../helperFunctions';
import { _commodity } from 'std';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { convertToImportName } from 'utils/misc';
import LocalizedTextInput from '../Inputs/LocalizedTextInput';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { useMemo } from 'react';

function OtherMaterialsForm(props) {
    const { open, loading, onClose, onSubmit, theme, selectedMaterial } = props;

    const { lang } = useContext(LocalizationContext);

    const handleSubmit = values => {
        const toSubmit = _.cloneDeep(values);
        toSubmit.iconName = convertToImportName(values.iconName);
        onSubmit(toSubmit);
    };

    const allIconNames = useMemo(
        () => Object.keys(allIcons).map(importName => convertImportToIconName(importName)),
        []
    );

    const formik = useFormik({
        initialValues: {
            _id: _.get(selectedMaterial, '_id', null),
            disabled: _.get(selectedMaterial, 'disabled', false),
            payloadInputName: _.get(selectedMaterial, 'payloadInputName', ''),
            code: _.get(selectedMaterial, 'code', ''),
            iconName: convertImportToIconName(_.get(selectedMaterial, 'iconName', ''))
        },
        validationSchema: Yup.object({
            disabled: Yup.boolean(),
            payloadInputName: Yup.string().required('You must enter a value.'),
            code: Yup.string().required('You must enter a value'),
            iconName: Yup.string()
                .transform((value, originalValue) => convertToImportName(originalValue))
                .oneOf(allIconNames.map(iconName => convertToImportName(iconName)), 'Invalid icon name')
                .required('You must enter an icon name')
        }),
        onSubmit: handleSubmit
    });

    return (
        <Dialog open={open}>
            <DialogContent>
                {getSwitch(theme, 'disabled', 'Disabled', formik)}
                <Grid item xs={12}>
                    <LocalizedTextInput
                        label="Display Name"
                        value={_.get(formik, 'values.payloadInputName')}
                        onChange={value => formik.setFieldValue('payloadInputName', value)}
                        touched={_.get(formik, 'touched.payloadInputName')}
                        error={_.get(formik, 'errors.payloadInputName')}
                        infoText="ex. General Electronics, Clothing Donation"
                    />
                </Grid>
                {getTextInput(theme, 'code', 'Code', formik)}
                <Grid item xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: 'rgba(0,0,0,0)',
                                marginRight: theme.spacing.unit,
                                marginTop: theme.spacing.unit * 1.5
                            }}
                        >
                            <MDIcon
                                path={_.get(
                                    allIcons,
                                    convertToImportName(
                                        _.get(formik.values, 'iconName', _.get(selectedMaterial, 'iconName', ''))
                                    )
                                )}
                                size={1.5}
                                color={'black'}
                            />
                        </Avatar>
                        {getTextInput(theme, 'iconName', 'Icon', formik)}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <ExternalLink text="Click here to see all icons" url="https://materialdesignicons.com/" />
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={loading} onClick={formik.handleSubmit} data-cy="service-fee-submit">
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function convertImportToIconName(importName) {
    return _.kebabCase(importName.substring(3));
}

export default withTheme()(OtherMaterialsForm);
