import { useEffect, useState } from 'react';
import _ from 'lodash';

const usePermissions = ({ http, auth }) => {
    // Currently used for customers permissions
    // Can be extended to work for admins...

    const [customerPermissions, setCustomerPermissions] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchPermissions = async () => {
        setLoading(true);

        const [resImpactReport, resTaxReceiptOptOut] = await Promise.all([
            http.getJSON('/system/configuration/showImpactReportInSideMenu', true, true),
            http.getJSON('/system/configuration/allowTaxReceiptOptOut', true, true)
        ]);

        const customerPermissions = {};

        if (resImpactReport.ok) {
            customerPermissions.showImpactReportInSideMenu = resImpactReport.data.showImpactReportInSideMenu;
        }

        if (resTaxReceiptOptOut.ok) {
            customerPermissions.allowTaxReceiptOptOut = resTaxReceiptOptOut.data.allowTaxReceiptOptOut;
        }

        setCustomerPermissions(customerPermissions);
        setLoading(false);
    };

    useEffect(() => {
        if (_.isEmpty(http) || _.isEmpty(auth) || !auth.isAuthenticated) {
            // http and auth are both dependencies for this hook
            return;
        }

        fetchPermissions();
    }, [auth]);

    return {
        customerPermissions
    };
};

export default usePermissions;
