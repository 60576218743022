import React, { useContext } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    TextField,
    Typography,
    colors
} from '@material-ui/core';
import _ from 'lodash';
import crypto from 'crypto';

import ReactJson from 'react-json-view';

import { withTheme } from '@material-ui/core/styles';

import { Clipboard } from '@capacitor/clipboard';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { useState } from 'react';

function CustomDebugDialog({ theme, open, json, onClose, allowDecryption = null }) {
    const { lang } = useContext(LocalizationContext);
    const [encryptedText, setEncryptedText] = useState('');
    const [decryptedText, setDecryptedText] = useState('');

    const decrypt = () => {
        try {
            const { algorithm, key, iv } = json.data.encryption;

            const decipher = crypto.createDecipheriv(algorithm, Buffer.from(key, 'base64'), Buffer.from(iv, 'base64'));
            let dec = decipher.update(encryptedText, 'base64', 'utf8');
            dec += decipher.final('utf8');
            setDecryptedText(dec);
        } catch (err) {
            setDecryptedText(
                'Unable to decrypt text. Please make sure you have copied the encrypted string correctly without missing/adding any characters.'
            );
        }
    };

    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogTitle>Viewing JSON</DialogTitle>
            <DialogContent>
                <ReactJson theme="bright:inverted" collapsed={2} indentWidth={2} src={json || {}} />
                {_.get(json, 'data.encryption.key') &&
                    _.get(json, 'data.encryption.iv') &&
                    _.get(json, 'data.encryption.algorithm') &&
                    allowDecryption && (
                        <>
                            <TextField
                                multiline
                                variant="outlined"
                                fullWidth
                                value={encryptedText}
                                onChange={e => setEncryptedText(e.target.value)}
                            />
                            <Button color="primary" onClick={decrypt}>
                                Decrypt
                            </Button>
                            {!_.isEmpty(decryptedText) && (
                                <div
                                    style={{
                                        backgroundColor: colors.grey[200],
                                        borderRadius: 5,
                                        padding: theme.spacing.unit * 2
                                    }}
                                >
                                    <Typography variant="caption" style={{ wordWrap: 'break-word' }}>
                                        {decryptedText}
                                    </Typography>
                                </div>
                            )}
                        </>
                    )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => Clipboard.write({ string: JSON.stringify(json) })}>
                    Copy JSON
                </Button>
                <Button color="default" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(CustomDebugDialog);
