import React, { Component } from 'react';

import _ from 'lodash';

import CharityInfoDialog from 'components/CharityInfoDialog/CharityInfoDialog';
import CharityMultiSelectDialog from './CharityMultiSelectDialog';

import GMapsAutocomplete from 'components/GMapsAutocomplete';

import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

import { _charity } from 'std';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

class CharityMultiSelect extends Component {
    static contextType = LocalizationContext;
    state = {
        charitySelectDialogOpen: false,
        taxReceiptInfoDialogOpen: false,
        donationInfoDialogOpen: false,
        infoDialogOpen: false,
        charityInfo: undefined,
        currentSelections: this.props.charitiesPreferred
        //currentSelections: undefined
    };
    handleCurrentSelections = charityIDs => () => {
        let updatedSelections = _.cloneDeep(this.state.currentSelections);
        for (const charityID of charityIDs) {
            const charityAlreadyThere = updatedSelections.some(selectedCharity => selectedCharity === charityID);
            if (charityAlreadyThere) {
                updatedSelections = updatedSelections.filter(selectedCharity => selectedCharity !== charityID);
            } else {
                updatedSelections.push(charityID);
            }
        }
        this.setState({ currentSelections: updatedSelections });
    };

    handleCharitySelectDialog = state => () => {
        this.setState({ charitySelectDialogOpen: state });
    };

    handleDonationInfoDialog = state => () => {
        this.setState({ donationInfoDialogOpen: state });
    };

    handleClearSelectedCharities = () => {
        //this.props.clearCharitiesPreferred();
        //this.props.onSetDonation(false);
        this.setState({ currentSelections: [] });
    };

    handleInfoDialog = (state, _id) => () => {
        this.setState({
            infoDialogOpen: state,
            charityInfo: _.find(this.props.charities, charity => charity._id === _id)
        });
    };

    handleTaxReceiptInfoDialog = state => () => {
        this.setState({ taxReceiptInfoDialogOpen: state });
    };

    render() {
        const { lang } = this.context;

        const {
            theme,
            donate,
            charities,
            charitiesPreferred, //NEW
            subdivisionPreferred,
            taxReceiptRequested,
            taxReceiptsDisabled,
            taxReceiptError,
            style,
            lockCharityDonationsToThisAccount,
            showAddressInputOnTaxRecipts,
            location,
            onLocation,
            hideTaxReciptAndSubdivision,
            hideTaxRecipt,
            inputStyle,
            TextFieldProps,
            http,
            google,
            regionToFilterFeatured = null,
            placeholder = null,
            label = loc('organizationName', lang, { organizationName: terms.ORGANIZATION_NAME }),
            lockDonationPreference,
            clearPreferredCharities,
            filterToggle,
            disableTextField
        } = this.props;

        const {
            charitySelectDialogOpen,
            taxReceiptInfoDialogOpen,
            infoDialogOpen,
            donationInfoDialogOpen,
            charityInfo,
            currentSelections,
            handleCurrentSelections,
            handleClearSelectedCharities
        } = this.state;

        if (_.isNil(charities)) {
            return null;
        }
        // const charitiesSelected = _.find(charities, charity => charity._id === charitiesPreferred && charity.approved);
        const charitiesSelected = charities.filter(
            charity => Array.isArray(charitiesPreferred) && charitiesPreferred.includes(charity._id) && charity.approved
        );

        if (_.isNil(charitiesSelected) && donate) handleClearSelectedCharities();
        const subdivisionsAvailable = !_.isNil(charitiesSelected) && !_.isEmpty(charitiesSelected.subdivisions);
        const taxReceiptsIssued = _.isEmpty(charitiesSelected) ? false : charitiesSelected.taxReceiptsIssued;
        const updatedSubdivisionPreferred = _.isNil(subdivisionPreferred)
            ? 'General Donation'
            : _.clone(subdivisionPreferred);

        const selectOrClearInputAdornment = (
            <InputAdornment position="end">
                <IconButton
                    data-cy="charity-select-dropdown"
                    style={{ color: colors.grey[500] }}
                    onClick={() => {
                        this.handleCharitySelectDialog(true)();
                        this.setState({ currentSelections: charitiesPreferred });
                    }}
                    disabled={!filterToggle}
                >
                    <Icon>edit</Icon>
                </IconButton>

                <IconButton
                    data-cy="charity-select-clear"
                    style={{ color: colors.grey[500] }}
                    onClick={() => {
                        this.props.clearCharitiesPreferred();
                        this.handleClearSelectedCharities();
                    }}
                    disabled={!filterToggle}
                >
                    <Icon>clear</Icon>
                </IconButton>
            </InputAdornment>
        );

        // let taxReceiptInfo = null;
        // if (true) {
        //     taxReceiptInfo = (
        //         <>
        //             <FormControl style={{ marginTop: theme.spacing.unit }}>
        //                 <div style={{ display: 'flex', alignItems: 'center' }}>
        //                     <Checkbox
        //                         disabled={true}
        //                         data-cy="charity-select-tax-receipts-checkbox"
        //                         checked={taxReceiptRequested}
        //                         // onChange={this.props.onToggleTaxReceiptRequested}
        //                     />
        //                     <Typography>{loc('pickupDialog63', lang)}</Typography>
        //                     <IconButton
        //                         style={{ marginLeft: theme.spacing.unit * 2 }}
        //                         onClick={this.handleTaxReceiptInfoDialog(true)}
        //                     >
        //                         <Icon>help</Icon>
        //                     </IconButton>
        //                 </div>
        //             </FormControl>
        //             {showAddressInputOnTaxRecipts && (
        //                 <Collapse in={taxReceiptRequested}>
        //                     <GMapsAutocomplete
        //                         data-cy="pickupdialog-address-input"
        //                         http={http}
        //                         google={google}
        //                         label={loc('dashboardSetting7', this.context.lang)}
        //                         placeholder={loc('charitySelect1', this.context.lang)}
        //                         selectedValue={_.get(location, 'description', '')}
        //                         types={['address']}
        //                         // restrictions={{ country: 'ca' }}
        //                         onSuggestionSelected={onLocation}
        //                         error={{
        //                             fail: taxReceiptError,
        //                             reason: loc('growthGeneral38', this.context.lang)
        //                         }}
        //                     />
        //                 </Collapse>
        //             )}
        //         </>
        //     );
        // } else if (!taxReceiptsIssued && !_.isNil(charitiesSelected)) {
        //     taxReceiptInfo = (
        //         <Typography color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
        //             {loc('dashboardUpdate4', this.context.lang, {
        //                 organization: terms.ORGANIZATION_NAME.toLowerCase()
        //             })}
        //         </Typography>
        //     );
        // }

        const placeholderText = _.isNil(placeholder) ? loc('noDonation', lang) : placeholder;
        return (
            <div style={style}>
                <FormControl fullWidth data-cy="charity-select-organization-select">
                    <TextField
                        id="outlined-select-charity"
                        data-cy="charity-select-org-select"
                        value={
                            !_.isEmpty(charitiesPreferred) ? `${charitiesPreferred.length} Charities` : `0 Charities`
                        }
                        //onClick={this.handleCharitySelectDialog(true)}
                        disabled={!disableTextField}
                        InputProps={{
                            readOnly: true,
                            endAdornment: selectOrClearInputAdornment
                        }}
                        label={label}
                        variant="outlined"
                        style={inputStyle}
                        {...TextFieldProps}
                        helperText={
                            lockDonationPreference
                                ? loc('donationPreferenceLockedMessage', lang, {
                                      brandname: process.env.REACT_APP_BRAND_NAME
                                  })
                                : ''
                        }
                    />
                </FormControl>

                <CharityMultiSelectDialog
                    open={charitySelectDialogOpen}
                    charities={charities}
                    charitiesPreferred={charitiesPreferred}
                    onChange={this.props.handleCharitiesPreferred}
                    onInfoDialog={this.handleInfoDialog}
                    handleClearSelectedCharities={this.handleClearSelectedCharities}
                    onClose={this.handleCharitySelectDialog(false)}
                    hideSubdivisions={hideTaxReciptAndSubdivision}
                    regionToFilterFeatured={regionToFilterFeatured}
                    currentSelections={currentSelections}
                    handleCurrentSelections={this.handleCurrentSelections}
                />

                <Dialog
                    open={taxReceiptInfoDialogOpen}
                    fullWidth
                    maxWidth="sm"
                    onClose={() => {
                        this.handleTaxReceiptInfoDialog(false);
                    }}
                >
                    <DialogTitle>{loc('pickupDialog64', lang)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {taxReceiptsDisabled ? (
                                <span>{loc('charitySelect2', this.context.lang)}</span>
                            ) : (
                                <span>{loc('pickupDialog65', lang)}</span>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-cy="charity-select-close"
                            color="primary"
                            onClick={() => {
                                this.handleTaxReceiptInfoDialog(false);
                                this.handleClearSelectedCharities();
                            }}
                        >
                            {loc('close', this.context.lang)}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={donationInfoDialogOpen}
                    fullWidth
                    maxWidth="sm"
                    onClose={this.handleDonationInfoDialog(false)}
                >
                    <DialogTitle>{loc('history2', this.context.lang)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {loc('charitySelect3', this.context.lang)} <br />
                            <span style={{ marginRight: 4, fontSize: '125%', color: theme.palette.primary.main }}>
                                ★
                            </span>
                            {loc('pickupDialog52', this.context.lang)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleDonationInfoDialog(false)}>
                            {loc('close', this.context.lang)}
                        </Button>
                    </DialogActions>
                </Dialog>

                <CharityInfoDialog open={infoDialogOpen} charity={charityInfo} onClose={this.handleInfoDialog(false)} />
            </div>
        );
    }
}

export default withTheme()(CharityMultiSelect);
