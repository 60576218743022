import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { _collector } from 'std';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CollectorCapacities from 'components/CustomInputs/CollectorCapacities';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Collapse,
    FormHelperText,
    Typography,
    Grid,
    Checkbox,
    colors,
    Icon,
    InputAdornment,
    Tooltip
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DateListInput from 'components/InputComponents/DateListInput';
import CustomTextInput from '../../../InputComponents/CustomTextInput';
import GMapsAutocomplete from 'components/GMapsAutocomplete';
import VolumeFeeRatesInput from '../../Inputs/VolumeFeeRatesInput';
import HideCustomerInfoInput from '../../Inputs/HideCustomerInfoInput';

import { getSwitch, getTextInput, getSelect, getTimePicker } from '../../helperFunctions';

import {
    TIME_ZONES_STD,
    TIME_ZONES_AUS,
    TIME_ZONES_EXP,
    TIME_ZONES_MXD,
    TIME_ZONES_CON,
    DESTINATION_ROUTING_OPTIONS
} from '../../../../constants';
import moment from 'moment';
import { getWalkInName, isAUSRegion, isCONRegion, isEXPRegion, isSTDRegion, isValidEmail } from 'utils/misc';
import { getWindowWidth, getWindowHeight } from 'utils/misc.js';
import { validate } from '../../../../utils/validate';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import StringListInput from 'components/CustomInputs/StringListInput';
import CustomWrapper from 'components/BulkComponents/CustomWrapper';
import { useRef } from 'react';
import { FormControlLabel, TextField, Switch } from '@material-ui/core';
import { isWidthDown } from '@material-ui/core/withWidth';

const iconColors = [
    'pink',
    'red',
    'deepOrange',
    'orange',
    'amber',
    'yellow',
    'lightGreen',
    'green',
    'teal',
    'blue',
    'indigo',
    'purple',
    'grey',
    'black'
];

const STD_RATING_PLATFORMS = [
    {
        ratingPlatform: '5dfab962d95ff490dd363e0f',
        url: 'https://apps.apple.com/ca/app/skipthedepot/id1359990960'
    },
    {
        ratingPlatform: '5dfab962d95ff490dd363e10',
        url: 'https://play.google.com/store/apps/details?id=com.skipthedepot.app&hl=en_CA'
    },
    {
        ratingPlatform: '5dfab962d95ff490dd363e11',
        url: 'https://search.google.com/local/writereview?placeid=ChIJeZys8iBwcVMRddw9jMsXmsY'
    },
    {
        ratingPlatform: '5dfab962d95ff490dd363e15',
        url: 'https://www.yelp.ca/biz/skip-the-depot-calgary-4'
    },
    {
        ratingPlatform: '5dfab962d95ff490dd363e14',
        url: 'https://www.yellowpages.ca/bus/Alberta/Calgary/Skip-the-Depot/102124673.html'
    },
    {
        ratingPlatform: '5e0e71b0f922f1343d9191db',
        url: 'https://www.facebook.com/SkipTheDepot/reviews/'
    }
];

const EXP_RATING_PLATFORMS = [];
const MXD_RATING_PLATFORMS = [];
const CON_RATING_PLATFORMS = [];

const AUS_RATING_PLATFORMS = [
    {
        ratingPlatform: '5dfab962d95ff490dd363e0f',
        url: 'https://itunes.apple.com/ca/app/returnitcollect/id1474675831?mt=8'
    },
    {
        ratingPlatform: '5dfab962d95ff490dd363e10',
        url: 'https://play.google.com/store/apps/details?id=au.com.returnit'
    }
];

const defaltLateCountsGracePeriod = 2;
const defaltViewTripsNDaysAhead = 7;
const defaultCurrency = process.env.REACT_APP_REGION_EXT === 'AUS' ? 'AUS' : 'CAD';
let defaultRatingPlatforms;
if (process.env.REACT_APP_REGION_EXT === 'STD') defaultRatingPlatforms = STD_RATING_PLATFORMS;
else if (process.env.REACT_APP_REGION_EXT === 'EXP') defaultRatingPlatforms = EXP_RATING_PLATFORMS;
else if (process.env.REACT_APP_REGION_EXT === 'MXD') defaultRatingPlatforms = MXD_RATING_PLATFORMS;
else if (process.env.REACT_APP_REGION_EXT === 'CON') defaultRatingPlatforms = CON_RATING_PLATFORMS;
else if (process.env.REACT_APP_REGION_EXT === 'AUS') defaultRatingPlatforms = AUS_RATING_PLATFORMS;

const defaultLateFee = 500;
const defaultGasPrice = 102;
let timeZones;
if (process.env.REACT_APP_REGION_EXT === 'STD') timeZones = TIME_ZONES_STD;
else if (process.env.REACT_APP_REGION_EXT === 'EXP') timeZones = TIME_ZONES_EXP;
else if (process.env.REACT_APP_REGION_EXT === 'MXD') timeZones = TIME_ZONES_MXD;
else if (process.env.REACT_APP_REGION_EXT === 'CON') timeZones = TIME_ZONES_CON;
else if (process.env.REACT_APP_REGION_EXT === 'AUS') timeZones = TIME_ZONES_AUS;

const defaultCollectorCapacity = [0, 135, 135, 135, 135, 135, 0];
const defaultEmployeeWage = 1800;
const defaultFuelEfficency = 3.75;

const defaultAvailability = [
    { day: 'Monday', hours: '8am–6pm' },
    { day: 'Tuesday', hours: '8am–6pm' },
    { day: 'Wednesday', hours: '8am–6pm' },
    { day: 'Thursday', hours: '8am–6pm' },
    { day: 'Friday', hours: '8am–6pm' },
    { day: 'Saturday', hours: '8am–6pm' },
    { day: 'Sunday', hours: '8am–6pm' }
];

const sixCharCodeRegions = ['CON', 'EXP'];

const PORDefaultSortOptions = ['Nothing', 'First Items', 'All Items'];

const collectorTabs = ['general', 'info', 'transporter', 'processor'];

function CollectorForm(props) {
    const {
        commodities,
        otherMaterials,
        collectorWarnings,
        collector,
        collectors,
        selectableCollectors,
        loading,
        editing,
        open,
        containerSizes = [],
        http,
        google,
        theme,
        fullScreen,
        ratesObj,
        onSubmit,
        onClose,
        pickupsEnabled,
        width,
        serviceFees
    } = props;
    const { lang } = useContext(LocalizationContext);

    const defaultDropLocationAvailability = _.get(collector, 'availability', defaultAvailability);

    const lateCountsFeeCents = _.get(collector, 'configuration.lateCountsFee.amount', defaultLateFee);
    const lateCountFeeDollars = lateCountsFeeCents !== '' ? lateCountsFeeCents / 100 : '';
    const gasCostCents = _.get(collector, 'gasPrice', defaultGasPrice);
    const gasCostDollars = gasCostCents !== '' ? gasCostCents / 100 : '';
    const wageMarkupCents = _.get(collector, 'wageMarkup', 220);
    const wageMarkupDollars = wageMarkupCents !== '' ? wageMarkupCents / 100 : '';

    const [loadingFormConfig, setLoadingFormConfig] = useState(false);

    const [selectedTab, setSelectedTab] = useState('general');
    const [overrideColorToggle, setOverrideColorToggle] = useState(!_.isNil(_.get(collector, 'overrideColor')));
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    const isSmallScreen = isWidthDown('xs', width);

    const defaultServiceFees = useMemo(() => {
        return _.filter(serviceFees, { default: true }).map(sf => {
            const sfObj = { ...sf };
            sfObj.serviceFeeId = sf._id;
            delete sfObj._id;
            delete sfObj.default;

            return sfObj;
        });
    }, [serviceFees]);

    const notServicedDates = _.get(collector, 'notServicedDates', []).filter(date =>
        moment(date, 'YYYY/MM/DD').isSameOrAfter(moment())
    );

    const validServiceFeesModels = ['EVEN_SPLIT_UP_TO_MIN'];

    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue(e.target.name, e.target.value);
            }
        }, 100)
    );

    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik, e.target.name);
    };

    const handleTimeChange = (dayIdx, newTime, isStartTime) => {
        let hours = newTime.hours();
        const minutes = newTime.minutes();
        const minuteString = minutes > 0 ? (minutes > 10 ? ':' + minutes : ':' + '0' + minutes) : '';

        const timeSuffix = hours > 12 ? 'pm' : 'am';
        hours = hours > 12 ? hours - 12 : hours;

        const oldHours = formik.values.availability[dayIdx].hours;
        const split = oldHours.split('–');

        let newHours;

        if (isStartTime) {
            newHours = hours + minuteString + timeSuffix + '–' + split[1];
        } else {
            newHours = split[0] + '–' + hours + minuteString + timeSuffix;
        }

        const newAvailability = setItemAtIndex(formik.values.availability, dayIdx, 'hours', newHours);

        formik.setFieldValue('availability', newAvailability);
    };

    const handleToggleDay = dayIdx => {
        let newHours;

        if (formik.values.availability[dayIdx].hours !== 'Closed') {
            newHours = 'Closed';
        } else if (defaultDropLocationAvailability[dayIdx].hours === 'Closed') {
            newHours = defaultAvailability[dayIdx].hours;
        } else {
            newHours = defaultDropLocationAvailability[dayIdx].hours;
        }

        const newAvailability = setItemAtIndex(formik.values.availability, dayIdx, 'hours', newHours);

        formik.setFieldValue('availability', newAvailability);
    };

    const handleToggleAlwaysOpen = dayIdx => {
        let newHours;

        if (formik.values.availability[dayIdx].hours !== '12am–11:59pm') {
            newHours = '12am–11:59pm';
        } else if (
            defaultDropLocationAvailability[dayIdx].hours === 'Closed' ||
            defaultDropLocationAvailability[dayIdx].hours === '12am–11:59pm'
        ) {
            newHours = defaultAvailability[dayIdx].hours;
        } else {
            newHours = defaultDropLocationAvailability[dayIdx].hours;
        }

        const newAvailability = setItemAtIndex(formik.values.availability, dayIdx, 'hours', newHours);

        formik.setFieldValue('availability', newAvailability);
    };

    const handleAcceptedPayloadChange = e => {
        const { value } = e.target;

        formik.setFieldValue('payloadAccepted', value);
    };

    const handleOtherMaterialsChange = e => {
        const { value } = e.target;
        formik.setFieldValue('otherMaterials', value);
    };

    const handleCollectorWarningsChange = e => {
        const { value } = e.target;
        formik.setFieldValue('collectorWarnings', value);
    };

    const handleFormSubmit = values => {
        //const { pickupAggregation, aggregationPreferences } = collector.configuration;
        let valuesToSubmit = _.cloneDeep(values);
        valuesToSubmit.configuration.lateCountsFee.amount = Math.round(
            valuesToSubmit.configuration.lateCountsFee.amount * 100
        ); //convert to cents
        valuesToSubmit.gasPrice = Math.round(valuesToSubmit.gasPrice * 100);
        valuesToSubmit.employeeWage = Math.round(valuesToSubmit.employeeWage * 100);
        valuesToSubmit.wageMarkup = Math.round(valuesToSubmit.wageMarkup * 100);
        valuesToSubmit.walkInLabelCode = valuesToSubmit.walkInLabelCode.toUpperCase();

        valuesToSubmit.overrideColor = overrideColorToggle ? valuesToSubmit.overrideColor : null;
        // valuesToSubmit.bottleDriveContacts = emailListStringToArray(valuesToSubmit.bottleDriveContacts);
        onSubmit(valuesToSubmit);
    };

    const formik = useFormik({
        initialValues: {
            _id: editing ? _.get(collector, '_id', '') : undefined,
            disabled: _.get(collector, 'disabled', false),
            name: _.get(collector, 'name', ''),
            code: _.get(collector, 'code', ''),
            location: _.get(collector, 'location', null),
            notServicedDates: notServicedDates,
            timezone: _.get(collector, 'timezone', timeZones[0]),
            configuration: {
                manageCosts: _.get(collector, 'configuration.manageCosts', false),
                currency: _.get(collector, 'configuration.currency', defaultCurrency),
                rates: _.get(collector, 'configuration.rates', ratesObj.masterRatesId),
                serviceFees: _.get(collector, 'configuration.serviceFees', defaultServiceFees),
                serviceFeesModel: _.get(collector, 'configuration.serviceFeesModel', validServiceFeesModels[0]),
                lateCountsFee: {
                    gracePeriod: _.get(
                        collector,
                        'configuration.lateCountsFee.gracePeriod',
                        defaltLateCountsGracePeriod
                    ),
                    amount: lateCountFeeDollars
                },
                chargeVolumeFee: _.get(collector, 'configuration.chargeVolumeFee', false),
                volumeFeeRates: _.get(collector, 'configuration.volumeFeeRates', []),
                viewTripsNDaysAhead: _.get(collector, 'configuration.viewTripsNDaysAhead', defaltViewTripsNDaysAhead),
                pickupAggregation: _.get(collector, 'configuration.pickupAggregation', 'CAPACITY_FIRST'),
                aggregationPreferences: _.get(collector, 'configuration.aggregationPreferences'),
                canReschedulePickups: _.get(collector, 'configuration.canReschedulePickups', false),
                // defaultToCountingAllBags: _.get(collector, 'configuration.defaultToCountingAllBags', false),
                // mobileTimeSlotBookings: _.get(collector, 'configuration.mobileTimeSlotBookings', false),
                //inHouseTimeSlotBookings: _.get(collector, 'configuration.inHouseTimeSlotBookings', false),
                mobileTimeSlotIncrement: _.get(collector, 'configuration.mobileTimeSlotIncrement', 60),
                //inHouseTimeSlotIncrement: _.get(collector, 'configuration.inHouseTimeSlotIncrement', 60),
                //maxInHouseBookingsPerIncrement: _.get(collector, 'configuration.maxInHouseBookingsPerIncrement', 1)
                bulkPermissions: _.get(collector, 'configuration.bulkPermissions', undefined),
                enableClockin: _.get(collector, 'configuration.enableClockin', false),
                enableCounting: _.get(collector, 'configuration.enableCounting', false),
                enablePickups: _.get(collector, 'configuration.enablePickups', false),
                orderApprovalRequired: _.get(collector, 'configuration.orderApprovalRequired', false),
                orderApprovalThreshold: _.get(collector, 'configuration.orderApprovalThreshold', 20)
            },
            lunchBreakDuration: _.get(collector, 'lunchBreakDuration', 30),
            bulkStationConfig: _.get(
                collector,
                'bulkStationConfig',
                generateDefaultBulkStationConfig(_.first(collectors))
            ),
            intuit: {
                customerRef: _.get(collector, 'intuit.customerRef', '')
            },
            payloadImageConfirmation: {
                enabled: _.get(collector, 'payloadImageConfirmation.enabled', false),
                minimum: _.get(collector, 'payloadImageConfirmation.minimum', 99)
            },
            ratingPlatforms: _.get(collector, 'ratingPlatforms', defaultRatingPlatforms),
            gasPrice: gasCostDollars,
            employeeWage: _.get(collector, 'employeeWage', defaultEmployeeWage) / 100,
            fuelEfficiency: _.get(collector, 'fuelEfficiency', defaultFuelEfficency),
            // offloadTime: _.get(collector, 'offloadTime', 30),
            wageMarkup: wageMarkupDollars,
            // customerSupportEmail: _.get(collector, 'customerSupportEmail', ''),
            phones: _.get(collector, 'phones', []),
            emails: _.get(collector, 'emails', []),
            quickdropOverrideCode: _.get(collector, 'quickdropOverrideCode', getRandomOverrideCode()),
            capacities: _collector.getCapacities(collector) || defaultCollectorCapacity,
            IP: _.get(collector, 'IP', ''),
            depotLoginTimeout: _.get(collector, 'depotLoginTimeout', 0),
            walkInLabelCode: _.get(collector, 'walkInLabelCode', ''),
            // bottleDriveContacts: _.get(collector, 'bottleDriveContacts', []).join(',')
            routeDestinationByDriverHomeLocation: _.get(collector, 'routeDestinationByDriverHomeLocation', false),
            destinationCollectors: _.get(collector, 'destinationCollectors', []),
            hideCustomerIdentification: {
                hideForAdmin: _.get(collector, 'hideCustomerIdentification.hideForAdmin', false),
                hideForClerk: _.get(collector, 'hideCustomerIdentification.hideForClerk', false)
            },
            routeDestinationBy: _.get(collector, 'routeDestinationBy', 'Zone'),
            openToPublic: _.get(collector, 'openToPublic', false),
            showNameOnMap: _.get(collector, 'showNameOnMap', false),
            //nameColor: _.get(collector, 'nameColor', false),
            color: _.get(collector, 'color', 'grey'),
            overrideColor: _.isNil(_.get(collector, 'overrideColor'))
                ? theme.palette.primary[500]
                : _.get(collector, 'overrideColor'),
            availability: defaultDropLocationAvailability,
            PORDefaultSort: _.get(collector, 'PORDefaultSort', 'Nothing'),
            payloadAccepted: _.get(collector, 'payloadAccepted', []),
            otherMaterials: _.get(collector, 'otherMaterials', []),
            collectorWarnings: _.get(collector, 'collectorWarnings', [])
        },
        validationSchema: Yup.object({
            disabled: Yup.boolean(),
            name: Yup.string().required('You must enter a name'),
            code: sixCharCodeRegions.includes(process.env.REACT_APP_REGION_EXT)
                ? Yup.string()
                      .required('You must enter a collector code')
                      .min(6, 'Collector code must be 6 characters')
                      .max(6, 'Collector code must be 6 characters')
                : Yup.string().required('You must enter a collector code'),
            location: Yup.object({
                description: Yup.string().required('You must enter a location')
            }),
            notServicedDates: Yup.array(),
            timezone: Yup.string().required(),
            configuration: Yup.object({
                currency: Yup.string().required('You must enter a currency'),
                serviceFeesModel: Yup.string().oneOf(validServiceFeesModels),
                lateCountsFee: Yup.object({
                    gracePeriod: Yup.number()
                        .required('You must enter a number')
                        .integer('This must be a whole number')
                        .min(0, 'This must be greater than or equal to 0'),
                    amount: Yup.number()
                        .min(0, 'This must be greater than or equal to 0')
                        .required('You must enter an amount')
                }),
                viewTripsNDaysAhead: Yup.number()
                    .nullable()
                    .min(0, 'This must be greater than or equal to 0'),
                mobileTimeSlotIncrement: Yup.number().min(0, 'This must be greater than or equal to 0')
                //inHouseTimeSlotIncrement: Yup.number().min(0, 'This must be greater than or equal to 0'),
                //maxInHouseBookingsPerIncrement: Yup.number().min(0, 'This must be greater than or equal to 0')
            }),
            intuit: Yup.object({
                customerRef: Yup.number().nullable()
            }),
            lunchBreakDuration: Yup.number().min(0, 'This must be greater than or equal to 0'),
            payloadImageConfirmation: Yup.object({
                enabled: Yup.boolean(),
                minimum: Yup.number()
                    .min(0, 'This must be greater than or equal to 0')
                    .integer()
                    .integer('This should be a whole number')
                    .required('You must enter a number')
            }),
            gasPrice: Yup.number()
                .min(0, 'This must be greater than or equal to 0')
                .required('You must enter a number'),
            employeeWage: Yup.number()
                .min(0, 'This must be greater than or equal to 0')
                .required('You must enter a number'),
            fuelEfficiency: Yup.number()
                .min(0, 'This must be greater than or equal to 0')
                .required('You must enter a number'),
            // offloadTime: Yup.number()
            //     .min(0, 'This must be greater than or equal to 0')
            //     .required('You must enter a number'),
            wageMarkup: Yup.number()
                .min(0, 'This must be greater than or equal to 0')
                .required('You must enter a number'),

            // customerSupportEmail: Yup.string().email('Please enter a valid email'),
            quickdropOverrideCode: Yup.string().required('You must enter an override code'),
            capacities: Yup.array(),
            walkInLabelCode: Yup.string().when('configuration.enableCounting', {
                is: true,
                then: Yup.string().required(`You must enter a ${getWalkInName('en')} label code`),
                otherwise: Yup.string()
            }),
            // bottleDriveContacts: Yup.string().test(
            //     'valid email list',
            //     'Must contain valid email seperated by comma, space, or new line',
            //     (value, context) => {
            //         if (_.isNil(value) || _.isEmpty(value.trim())) return true;
            //         let emailList = value.split(/[\s\n,]/);
            //         for (let email of emailList) {
            //             if (_.isEmpty(email)) continue;
            //             if (!isValidEmail(email)) return false;
            //         }
            //         return true;
            //     }
            // ),
            routeDestinationBy: Yup.string().required('You must select a routing option')
        }),
        onSubmit: handleFormSubmit
    });

    /*
    console.log(formik);
    console.log('formik errors', formik.errors);
    console.log('formik values', formik.values);*/

    return (
        <>
            <Dialog open={open} fullScreen={fullScreen}>
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {editing ? 'Update' : 'Create'} {editing && getSwitch(theme, 'disabled', 'Disabled', formik)}
                    </div>
                </DialogTitle>
                <span>
                    <ToggleButtonGroup
                        value={selectedTab}
                        exclusive
                        onChange={(e, selectedTab) => !_.isEmpty(selectedTab) && setSelectedTab(selectedTab)}
                        style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit, display: 'flex' }}
                    >
                        {collectorTabs.map(tab => (
                            <ToggleButton
                                key={tab}
                                value={tab}
                                style={{
                                    flexGrow: 1,
                                    height: 50
                                }}
                                data-cy={`collector-form-${tab}-tab`}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    {tab}
                                    {tab === 'general' ? (
                                        <Icon
                                            style={{
                                                color: 'blue'
                                            }}
                                        >
                                            horizontal_rule
                                        </Icon>
                                    ) : (tab === 'info' && formik.values.openToPublic) ||
                                      (tab === 'transporter' && formik.values.configuration.enablePickups) ||
                                      (tab === 'processor' && formik.values.configuration.enableCounting) ? (
                                        <Icon
                                            style={{
                                                color: 'green'
                                            }}
                                        >
                                            check
                                        </Icon>
                                    ) : (
                                        <Icon
                                            style={{
                                                color: 'red'
                                            }}
                                        >
                                            highlight_off
                                        </Icon>
                                    )}
                                </div>
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </span>
                <DialogContent
                    style={{
                        height: isSmallScreen ? 'auto' : '700px',
                        width: isSmallScreen ? '100%' : '600px'
                    }}
                >
                    {selectedTab === 'general' && (
                        <div style={{ marginTop: theme.spacing.unit }}>
                            <Typography variant="subtitle2">Collector Info</Typography>

                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item md={4} xs={6}>
                                    {getTextInput(theme, 'name', 'Name', formik)}
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <CustomTextInput
                                        style={{ marginRight: theme.spacing.unit, flex: 1 }}
                                        upperCase
                                        {...formik.getFieldProps('code')}
                                        error={formik.touched.code && formik.errors.code ? true : null}
                                        label={loc('growthPostInfo4', lang)}
                                        margin="normal"
                                        variant="outlined"
                                        helperText={
                                            formik.touched.code && formik.errors.code ? formik.errors.code : null
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    {getSelect(theme, 'timezone', 'Timezone', timeZones, formik)}
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <GMapsAutocomplete
                                        onBlur={formik.handleBlur}
                                        http={http}
                                        google={google}
                                        location={{ lat: 51.074297, lng: -114.106329 }}
                                        label="Address"
                                        placeholder="Enter an address"
                                        selectedValue={_.get(formik, 'values.location.description', '')}
                                        types={['address']}
                                        error={{
                                            fail:
                                                _.get(formik.errors, 'location', false) &&
                                                _.get(formik.touched, 'location', false),
                                            reason: 'You must enter a start location'
                                        }}
                                        style={{ marginTop: theme.spacing.unit }}
                                        onSuggestionSelected={({ suggestion, place }) => {
                                            formik.setFieldValue('location', {
                                                description: suggestion.description,
                                                place_id: suggestion.place_id,
                                                lat: place.geometry.location.lat(),
                                                lng: place.geometry.location.lng(),
                                                city: place.address_components.find(ac => ac.types.includes('locality'))
                                                    .long_name,
                                                province: place.address_components.find(ac =>
                                                    ac.types.includes('administrative_area_level_1')
                                                ).long_name,
                                                postalCode: _.get(
                                                    _.find(place.address_components, component =>
                                                        component.types.includes('postal_code')
                                                    ),
                                                    'short_name',
                                                    undefined
                                                )
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {getSelect(
                                        theme,
                                        'payloadAccepted',
                                        'Primary Materials Accepted',
                                        commodities,
                                        formik,
                                        handleAcceptedPayloadChange,
                                        '_id',
                                        `payloadInputName.${lang}`,
                                        true
                                    )}
                                </Grid>
                                {!_.isEmpty(otherMaterials) && (
                                    <Grid item xs={12}>
                                        {getSelect(
                                            theme,
                                            'otherMaterials',
                                            'Other Materials Accepted',
                                            otherMaterials,
                                            formik,
                                            handleOtherMaterialsChange,
                                            '_id',
                                            `payloadInputName.${lang}`,
                                            true
                                        )}
                                    </Grid>
                                )}
                                {!_.isEmpty(collectorWarnings) && (
                                    <Grid item xs={12}>
                                        {getSelect(
                                            theme,
                                            'collectorWarnings',
                                            'Warnings to Display on Collector',
                                            collectorWarnings,
                                            formik,
                                            handleCollectorWarningsChange,
                                            '_id',
                                            `type`,
                                            true
                                        )}
                                    </Grid>
                                )}
                            </Grid>

                            <Typography variant="subtitle2">Accounting</Typography>
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item md={3} xs={6}>
                                    {getTextInput(
                                        theme,
                                        'configuration.lateCountsFee.gracePeriod',
                                        'Late Count Period',
                                        formik,
                                        'number'
                                    )}
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    {getTextInput(
                                        theme,
                                        'configuration.lateCountsFee.amount',
                                        'Late Fee($)',
                                        formik,
                                        'number'
                                    )}
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    {getTextInput(theme, 'intuit.customerRef', 'Intuit Ref', formik, 'number')}
                                </Grid>

                                <Grid item md={3} xs={6}>
                                    {getTextInput(theme, 'configuration.currency', 'Currency', formik)}
                                </Grid>
                            </Grid>
                            {getSwitch(theme, 'configuration.manageCosts', 'Manage Collector Costs', formik)}
                            {/* <div>{getTextInput(theme, 'bottleDriveContacts', 'Bottle Drive Contacts', formik)}</div> */}
                        </div>
                    )}
                    {selectedTab === 'info' && (
                        <div style={{ marginTop: theme.spacing.unit }}>
                            <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                                Contact Info
                            </Typography>

                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={6}>
                                    <StringListInput
                                        InputProps={{
                                            variant: 'outlined'
                                        }}
                                        label="Emails"
                                        values={formik.values.emails}
                                        onChange={values => formik.setFieldValue('emails', values)}
                                        error={email => {
                                            let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                            return !(email === '') && !regexEmail.test(email.toLowerCase());
                                        }}
                                        errorMessage="Please enter a valid email address."
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <StringListInput
                                        InputProps={{
                                            variant: 'outlined'
                                        }}
                                        label="Phones"
                                        values={formik.values.phones}
                                        onChange={values => formik.setFieldValue('phones', values)}
                                        error={phone => {
                                            let errorsObj = validate(['phone'], phone, lang);
                                            return errorsObj.fail && !(phone === '');
                                        }}
                                        errorMessage="Please enter a valid phone."
                                        type="phone"
                                    />
                                </Grid>
                            </Grid>
                            {getSwitch(theme, 'openToPublic', 'Open to Public', formik)}
                            <Collapse in={formik.values.openToPublic}>
                                {(isAUSRegion() || isSTDRegion()) && (
                                    <div style={{ display: 'flex' }}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={overrideColorToggle}
                                                    onChange={e => setOverrideColorToggle(e.target.checked)}
                                                    style={{ marginRight: 0 }}
                                                />
                                            }
                                            label={<Typography>Override Pin Color</Typography>}
                                        />

                                        {overrideColorToggle ? (
                                            <TextField
                                                type="color"
                                                onChange={handleChangeColorWrapper}
                                                value={formik.values.overrideColor || theme.palette.primary[500]}
                                                onBlur={formik.handleBlur}
                                                name="overrideColor"
                                                label="Primary Color"
                                                margin="normal"
                                                variant="outlined"
                                                style={{ width: '340px', marginLeft: theme.spacing.unit * 2 }}
                                            />
                                        ) : (
                                            <TextField
                                                type="color"
                                                value={theme.palette.primary[500]}
                                                onBlur={formik.handleBlur}
                                                label="Primary Default"
                                                margin="normal"
                                                variant="outlined"
                                                disabled
                                                style={{ width: '340px', marginLeft: theme.spacing.unit * 2 }}
                                            />
                                        )}
                                    </div>
                                )}
                                {getSwitch(theme, 'showNameOnMap', 'Show Name On Map', formik)}
                                {(isCONRegion() || isEXPRegion()) && (
                                    <Collapse in={formik.values.showNameOnMap}>
                                        <div style={{ display: 'flex' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={overrideColorToggle}
                                                        onChange={e => setOverrideColorToggle(e.target.checked)}
                                                        style={{ marginRight: 0 }}
                                                    />
                                                }
                                                label={<Typography>Override Name Color</Typography>}
                                            />

                                            {overrideColorToggle ? (
                                                <TextField
                                                    type="color"
                                                    onChange={handleChangeColorWrapper}
                                                    value={formik.values.overrideColor || theme.palette.primary[500]}
                                                    onBlur={formik.handleBlur}
                                                    name="overrideColor"
                                                    label="Primary Color"
                                                    margin="normal"
                                                    variant="outlined"
                                                    style={{ width: '322px', marginLeft: theme.spacing.unit * 2 }}
                                                />
                                            ) : (
                                                <TextField
                                                    type="color"
                                                    value={theme.palette.primary[500]}
                                                    onBlur={formik.handleBlur}
                                                    label="Primary Default"
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled
                                                    style={{ width: '322px', marginLeft: theme.spacing.unit * 2 }}
                                                />
                                            )}
                                        </div>
                                    </Collapse>
                                )}
                            </Collapse>
                            {formik.values.openToPublic && (
                                <div style={{ marginTop: theme.spacing.unit * 3 }}>
                                    <CustomWrapper theme={theme} title={'Availability'}>
                                        <div
                                            style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}
                                        >
                                            {formik.values.availability.map((day, idx) => {
                                                const closed = _.get(day, 'hours') === 'Closed';
                                                const alwaysOpen = _.get(day, 'hours') === '12am–11:59pm';

                                                const startTime = moment.utc();
                                                const endTime = moment.utc();

                                                let timeSegments;

                                                if (!closed) {
                                                    timeSegments = parseAvailability(_.get(day, 'hours'));
                                                } else {
                                                    if (
                                                        _.get(defaultDropLocationAvailability[idx], 'hours') ===
                                                        'Closed'
                                                    ) {
                                                        timeSegments = parseAvailability(
                                                            _.get(defaultAvailability[idx], 'hours')
                                                        );
                                                    } else {
                                                        timeSegments = parseAvailability(
                                                            _.get(defaultDropLocationAvailability[idx], 'hours')
                                                        );
                                                    }
                                                }

                                                startTime.hours(timeSegments.startHours);
                                                startTime.minutes(timeSegments.startMinutes);

                                                endTime.hours(timeSegments.endHours);
                                                endTime.minutes(timeSegments.endMinutes);

                                                return (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Checkbox
                                                                checked={!closed}
                                                                onChange={() => handleToggleDay(idx)}
                                                                disabled={formik.values.existsAtReturnSite}
                                                            />
                                                            <Typography
                                                                disabled={formik.values.existsAtReturnSite}
                                                                style={{
                                                                    marginRight: theme.spacing.unit * 2
                                                                }}
                                                            >
                                                                {_.get(day, 'day')}:
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                maxWidth: '70%',
                                                                marginBottom: theme.spacing.unit,
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            <div style={{ marginRight: theme.spacing.unit / 2 }}>
                                                                {getTimePicker(
                                                                    theme,
                                                                    'startTime',
                                                                    'Start Time',
                                                                    formik,
                                                                    startTime,
                                                                    time => handleTimeChange(idx, time, true),
                                                                    closed ||
                                                                        alwaysOpen ||
                                                                        formik.values.existsAtReturnSite
                                                                )}
                                                            </div>
                                                            <div style={{ marginLeft: theme.spacing.unit / 2 }}>
                                                                {getTimePicker(
                                                                    theme,
                                                                    'endTime',
                                                                    'End Time',
                                                                    formik,
                                                                    endTime,
                                                                    time => handleTimeChange(idx, time, false),
                                                                    closed ||
                                                                        alwaysOpen ||
                                                                        formik.values.existsAtReturnSite
                                                                )}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    disabled={
                                                                        closed || formik.values.existsAtReturnSite
                                                                    }
                                                                    checked={alwaysOpen}
                                                                    onChange={() => handleToggleAlwaysOpen(idx)}
                                                                />
                                                                <Typography
                                                                    style={{
                                                                        marginRight: theme.spacing.unit / 2,
                                                                        marginLeft: -theme.spacing.unit
                                                                    }}
                                                                >
                                                                    24hr
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </CustomWrapper>
                                </div>
                            )}
                        </div>
                    )}
                    {selectedTab === 'transporter' && (
                        <div style={{ padding: '10px 0' }}>
                            {/* <Typography variant="subtitle2">Accounting</Typography> */}
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography variant="subtitle2">Pickup Options</Typography>
                                    {getSwitch(
                                        theme,
                                        'configuration.enablePickups',
                                        'Enable Pickups',
                                        formik,
                                        {},
                                        {},
                                        undefined,
                                        '',
                                        !pickupsEnabled
                                    )}
                                </div>
                                {pickupsEnabled && (
                                    <Collapse in={_.get(formik, 'values.configuration.enablePickups')}>
                                        <DateListInput
                                            values={formik.values.notServicedDates}
                                            onDatesChanged={dates => formik.setFieldValue('notServicedDates', dates)}
                                            dateFormat="YYYY-MM-DD"
                                            label="Unserviced Dates"
                                            disablePast={true}
                                        />

                                        {getSelect(
                                            theme,
                                            'routeDestinationBy',
                                            'Destination By',
                                            DESTINATION_ROUTING_OPTIONS,
                                            formik
                                        )}
                                        <div style={{ display: 'flex', marginTop: theme.spacing.unit }}>
                                            {/* <div
                                                style={{
                                                    marginRight: theme.spacing.unit,
                                                    flex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {getSwitch(
                                                    theme,
                                                    'configuration.mobileTimeSlotBookings',
                                                    'Mobile Time Slot Bookings',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    { fontSize: '80%' }
                                                )}
                                            </div> */}
                                            {formik.values.configuration.mobileTimeSlotBookings ? (
                                                <div style={{ flex: 3 }}>
                                                    {getTextInput(
                                                        theme,
                                                        'configuration.mobileTimeSlotIncrement',
                                                        'Mobile booking incr. (min)',
                                                        formik,
                                                        'number'
                                                    )}
                                                </div>
                                            ) : (
                                                <div style={{ flex: 3 }}>
                                                    <CollectorCapacities
                                                        {...formik.getFieldProps('capacities')}
                                                        onChange={capacities =>
                                                            formik.setFieldValue('capacities', capacities)
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {getSwitch(
                                            theme,
                                            'configuration.enableClockin',
                                            'Enable Driver Clock In/Out',
                                            formik,
                                            {},
                                            { fontSize: '80%' }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'configuration.canReschedulePickups',
                                            'Allow collector managers to reschedule pickups',
                                            formik,
                                            {},
                                            { fontSize: '80%' }
                                        )}
                                        <div style={{ marginTop: theme.spacing.unit }}>
                                            <Typography variant="subtitle2">Defaults</Typography>
                                            <Grid container spacing={theme.spacing.unit}>
                                                <Grid item md={3} xs={4}>
                                                    {getTextInput(
                                                        theme,
                                                        'configuration.viewTripsNDaysAhead',
                                                        'Viewable Days',
                                                        formik,
                                                        'number'
                                                    )}
                                                </Grid>
                                                <Grid item md={3} xs={4}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            marginLeft: theme.spacing.unit * 2
                                                        }}
                                                    >
                                                        {getSwitch(
                                                            theme,
                                                            'payloadImageConfirmation.enabled',
                                                            'Image Confirmation',
                                                            formik,
                                                            { marginTop: theme.spacing.unit * 2 },
                                                            { fontSize: '80%' }
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item md={3} xs={4}>
                                                    {_.get(
                                                        formik,
                                                        'values.payloadImageConfirmation.enabled',
                                                        false
                                                    ) && (
                                                        <div>
                                                            {getTextInput(
                                                                theme,
                                                                'payloadImageConfirmation.minimum',
                                                                'Confirmation Min',
                                                                formik,
                                                                'number'
                                                            )}
                                                        </div>
                                                    )}
                                                </Grid>
                                                <Grid item md={3} xs={4}>
                                                    {getTextInput(
                                                        theme,
                                                        'lunchBreakDuration',
                                                        'Lunch Duration',
                                                        formik,
                                                        'number'
                                                    )}
                                                </Grid>
                                                <Grid item md={3} xs={4}>
                                                    {getTextInput(
                                                        theme,
                                                        'gasPrice',
                                                        'Gas Price($/L)',
                                                        formik,
                                                        'number'
                                                    )}
                                                </Grid>
                                                <Grid item md={3} xs={4}>
                                                    {getTextInput(
                                                        theme,
                                                        'employeeWage',
                                                        'Wage($/hr)',
                                                        formik,
                                                        'number'
                                                    )}
                                                </Grid>
                                                <Grid item md={3} xs={4}>
                                                    {getTextInput(
                                                        theme,
                                                        'fuelEfficiency',
                                                        'Efficency(km/L)',
                                                        formik,
                                                        'number'
                                                    )}
                                                </Grid>
                                                <Grid item md={3} xs={4}>
                                                    {getTextInput(
                                                        theme,
                                                        'wageMarkup',
                                                        'Wage Markup ($)',
                                                        formik,
                                                        'number'
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Collapse>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedTab === 'processor' && (
                        <>
                            <div style={{ marginTop: theme.spacing.unit }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="subtitle2">Counting Options</Typography>
                                    {getSwitch(theme, 'configuration.enableCounting', 'Enable Counting', formik)}
                                </div>
                                <Collapse in={_.get(formik, 'values.configuration.enableCounting')}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1 }}>
                                            {getTextInput(theme, 'IP', 'Depot Login IP', formik)}
                                        </div>
                                        <div style={{ marginLeft: theme.spacing.unit, flex: 1 }}>
                                            {getTextInput(
                                                theme,
                                                'depotLoginTimeout',
                                                'Depot Login Timeout (sec; 0 to disable)',
                                                formik
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1 }}>
                                            {getTextInput(
                                                theme,
                                                'walkInLabelCode',
                                                process.env.REACT_APP_REGION_EXT === 'CON'
                                                    ? 'Walk-in Order Label Code'
                                                    : `${getWalkInName('en')} Label Code`,
                                                formik
                                            )}
                                        </div>
                                        <div style={{ marginLeft: theme.spacing.unit, flex: 1 }}>
                                            {process.env.REACT_APP_REGION_EXT !== 'AUS' &&
                                                getTextInput(theme, 'quickdropOverrideCode', 'Override Code', formik)}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1 }}>
                                            <VolumeFeeRatesInput
                                                chargeVolumeFee={_.get(
                                                    formik,
                                                    'values.configuration.chargeVolumeFee',
                                                    false
                                                )}
                                                volumeFeeRates={_.get(
                                                    formik,
                                                    'values.configuration.volumeFeeRates',
                                                    []
                                                )}
                                                containerSizes={containerSizes}
                                                onChargeVolumeFee={value =>
                                                    formik.setFieldValue('configuration.chargeVolumeFee', value)
                                                }
                                                onVolumeFeeRatesChange={value =>
                                                    formik.setFieldValue('configuration.volumeFeeRates', value)
                                                }
                                                style={{ marginTop: theme.spacing.unit * 2 }}
                                            />
                                        </div>
                                        <div style={{ marginLeft: theme.spacing.unit, flex: 1 }}>
                                            <HideCustomerInfoInput
                                                onHideCustomerInfoAdmins={value =>
                                                    formik.setFieldValue(
                                                        'hideCustomerIdentification.hideForAdmin',
                                                        value
                                                    )
                                                }
                                                hideCustomerInfoAdmins={_.get(
                                                    formik,
                                                    'values.hideCustomerIdentification.hideForAdmin',
                                                    false
                                                )}
                                                onHideCustomerInfoClerks={value =>
                                                    formik.setFieldValue(
                                                        'hideCustomerIdentification.hideForClerk',
                                                        value
                                                    )
                                                }
                                                hideCustomerInfoClerks={_.get(
                                                    formik,
                                                    'values.hideCustomerIdentification.hideForClerk',
                                                    false
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginRight: theme.spacing.unit,
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {getSelect(
                                            theme,
                                            'PORDefaultSort',
                                            'POR Default Sort',
                                            PORDefaultSortOptions,
                                            formik
                                        )}
                                        {/* {getSwitch(
                                            theme,
                                            'configuration.defaultToCountingAllBags',
                                            'Default To Sorting All Bags',
                                            formik,
                                            {},
                                            { fontSize: '80%' }
                                        )} */}
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1, alignContent: 'center', height: 72 }}>
                                            {getSwitch(
                                                theme,
                                                'configuration.orderApprovalRequired',
                                                'Admin Approval Required',
                                                formik,
                                                { marginTop: 15, marginLeft: 5 },
                                                {},
                                                undefined,
                                                'If enabled, managers and clerks will not be able to close orders that fail the approval threshold',
                                                false,
                                                {
                                                    color: theme.palette.text.disabled,
                                                    marginLeft: 10
                                                }
                                            )}
                                        </div>
                                        {_.get(formik, 'values.configuration.orderApprovalRequired', false) && (
                                            <div style={{ marginLeft: theme.spacing.unit, flex: 1 }}>
                                                {getTextInput(
                                                    theme,
                                                    'configuration.orderApprovalThreshold',
                                                    'Approval Threshold (%)',
                                                    formik,
                                                    'number',
                                                    {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                    title="Threshold container difference (per bag) between past collections and current collection"
                                                                    style={{
                                                                        color: theme.palette.text.disabled
                                                                    }}
                                                                >
                                                                    <Icon>info</Icon>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Collapse>
                            </div>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <div>
                        {isEXPRegion() && (
                            <Typography
                                variant="body2"
                                color="error"
                                style={{ marginRight: '10px', fontSize: '0.8rem' }}
                            >
                                Manually updated data on this page will be overwritten on the next sync
                            </Typography>
                        )}

                        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={onClose} disabled={loading}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                data-cy="collector-edit-dialog-submit"
                                disabled={loading || loadingFormConfig}
                                onClick={formik.handleSubmit}
                            >
                                {loc('submit', lang)}
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withMobileDialog()(withTheme()(CollectorForm));

function getRandomOverrideCode() {
    let number = Math.floor(Math.random() * (9999 + 1));
    if (number <= 9999) {
        number = ('000' + number).slice(-4);
    }
    return number;
}
function emailListStringToArray(emailListString) {
    if (_.isNil(emailListString)) return [];
    if (_.isArray(emailListString)) return emailListString;
    let emailList = emailListString.split(/[\s\n,]/);
    let emailListArray = [];
    for (let email of emailList) {
        if (_.isEmpty(email)) continue;
        if (isValidEmail(email)) emailListArray.push(email);
    }
    return emailListArray;
}

function setItemAtIndex(arr, idx, itemName, item) {
    return [
        ...arr.slice(0, idx),
        {
            ...arr[idx],
            [itemName]: item
        },
        ...arr.slice(idx + 1, arr.length)
    ];
}

function parseAvailability(availability) {
    const split = availability.split('–');

    const startTime = parseTime(split[0]);
    const endTime = parseTime(split[1]);

    return { startHours: startTime[0], startMinutes: startTime[1], endHours: endTime[0], endMinutes: endTime[1] };
}

function parseTime(time) {
    time = time.toLowerCase();

    const colonSplit = time.split(':');

    let hours = parseInt(colonSplit[0]),
        minutes = 0;

    if (colonSplit.length > 1) {
        minutes = parseInt(colonSplit[1].substring(0, 2));
    }

    if (time.includes('pm')) {
        hours += 12;
    }

    if (time.includes('am') && time.includes('12')) {
        hours -= 12;
    }

    return [hours, minutes];
}

function getColor(color) {
    try {
        if (_.isNil(color) || color === '') {
            return '';
        }
        return color === 'black' ? colors['grey'][900] : colors[color][600];
    } catch (e) {
        return '';
    }
}

const generateDefaultBulkStationConfig = collector => {
    const collectorBulkStationConfig = _.get(collector, 'bulkStationConfig');
    if (collectorBulkStationConfig) {
        return collectorBulkStationConfig.map(config => _.omit(config, ['_id']));
    }

    if (process.env.REACT_APP_REGION_EXT === 'AUS') {
        return [];
    }

    return [
        {
            sku: 10010,
            enabled: true,
            fillCapacity: 0,
            fullWidth: false
        },
        {
            sku: 10020,
            enabled: true,
            fillCapacity: 0,
            fullWidth: false
        }
    ];
};
