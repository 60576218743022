import React, { useState, useContext } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import CollectorWarningsForm from './Forms/CollectorWarningsForm';

function CollectorWarningsTable(props) {
    const { operator, theme } = props;

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const { lang } = useContext(LocalizationContext);

    const endpoints = {
        getEndPoint: '/commodities/getAllCollectorWarnings',
        getEditEndPoint: _id => `/commodities/${_id}/updateCollectorWarning`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/commodities/createCollectorWarning' // url to add new documents
    };

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState('createdAt');
    const [activeOrder, setActiveOrder] = useState('desc');

    const { data, loading, handleCreate, handleEdit } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const columns = [
        {
            key: 'iconName',
            header: 'Icon',
            formatValue: (value, document) => (
                <MDIcon path={_.get(allIcons, value)} color={_.get(document, 'color')} size={1} />
            )
        },
        { key: 'type', header: 'Warning Type' },
        { key: `text`, header: 'Warning Text', formatValue: value => _.get(value, lang, '') }
    ];

    const editForm = (
        <CollectorWarningsForm
            theme={theme}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            selectedWarning={selectedDocument}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
        />
    );

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={serviceFee => _.get(serviceFee, 'disabled', false)}
                defaultRowsPerPage={5}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Collector Warnings'}
                disabledHeaderText={'Disabled Warnings'}
                loading={loading}
                hideDateFilters
            />
        </>
    );
}

export default withTheme()(CollectorWarningsTable);
