import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';
import mustache from 'mustache';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';

import ComplaintScreen from './Screens/ComplaintScreen';
import RateScreen from './Screens/RateScreen';
import ReferralScreen from './Screens/ReferralScreen';
import ShareScreen from './Screens/ShareScreen';
import SummaryScreen from './Screens/SummaryScreen';
import TipScreen from './Screens/TipScreen';
import { _bulk } from 'std';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const description = `${process.env.REACT_APP_ORIGIN_URL} is a door to door recycling app.`;
const iconSize = 36;

const iconStyle = {
    padding: '0px 2px'
};

const REFERRAL_STEP = 'REFERRAL_STEP';
const TIPPING_STEP = 'TIPPING_STEP';
const SUMMARY_STEP = 'SUMMARY_STEP';
const RATE_STEP = 'RATE_STEP';
const SHARE_STEP = 'SHARE_STEP';
const COMPLAINT_STEP = 'COMPLAINT_STEP';

function ShareAndFeedbackDialog(props) {
    const {
        subject,
        message,
        url,
        open,
        onClose,
        hashtags,
        customer,
        step,
        isLastInteraction,
        bulks,
        pickups,
        commodities,
        nextPlatformToRateOn,
        onSnackbar,
        http,
        onShare,
        onNativeShare,
        onRateApp,
        shareAndFeedbackDialogConfig,
        refundSummaryConfig,
        onStep,
        showSingleStep,
        environmentalStats,
        isAdmin,
        tips,
        enableTipping,
        rates,
        allRates,
        customerFinancialStats,
        pickupsEnabled
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [ratingDialogText, setRatingDialogText] = useState(null);
    const [ratingDialogTextLoading, setRatingDialogTextLoading] = useState(false);
    const [referralConfigObj, setReferralConfigObj] = useState(null);
    const [referralConfigObjLoading, setReferralConfigObjLoading] = useState(false);
    const [problemWithPickup, setProblemWithPickup] = useState(false);

    const [bulk, setBulk] = useState({});
    const [pickup, setPickup] = useState({});
    const [hasTip, setHasTip] = useState(false);

    const [submissionValues, setSubmissionValues] = useState({});

    useEffect(() => {
        const getRatingDialogContent = async () => {
            setRatingDialogTextLoading(true);
            const ratingDialogTextRes = await http.getJSON('/config/ratingDialogText', false, true);
            if (ratingDialogTextRes.ok) {
                setRatingDialogText(ratingDialogTextRes.data.config);
            }
            setRatingDialogTextLoading(false);
        };

        const refreshCustomerReferral = async () => {
            setReferralConfigObjLoading(true);
            let res = await http.getJSON('/config/referral', false, true);

            const mustacheData = {
                ...customer,
                customerFirstName: !_.isNil(_.get(customer, 'name.first', null))
                    ? _.capitalize(customer.name.first)
                    : 'Guest'
            };

            if (res.ok) {
                let referralConfig = res.data.config;

                let rules = mustache.render(referralConfig.rules, mustacheData);
                let rulesDialogHandle = mustache.render(referralConfig.rulesDialogHandle, mustacheData);
                let rulesTitle = mustache.render(referralConfig.rulesTitle, mustacheData);

                let codeDisplayLabel = mustache.render(referralConfig.codeDisplayLabel, mustacheData);
                let primaryActionLabel = mustache.render(referralConfig.primaryActionLabel, mustacheData);

                let referralURL = mustache.render(referralConfig.referralURL, mustacheData);
                let referralText = mustache.render(referralConfig.referralText, mustacheData);
                let referralTitle = mustache.render(referralConfig.referralTitle, mustacheData);

                let sharePopupDialogTitle = mustache.render(referralConfig.sharePopupDialogTitle, mustacheData);

                const referralURLDisplayLabel = mustache.render(referralConfig.referralURLDisplayLabel, mustacheData);

                setReferralConfigObj({
                    referralConfig,
                    rulesDialogHandle,
                    rulesTitle,
                    rules,
                    codeDisplayLabel,
                    primaryActionLabel,
                    referralText,
                    referralURL,
                    referralTitle,
                    sharePopupDialogTitle,
                    referralURLDisplayLabel
                });
            }
            setReferralConfigObjLoading(false);
        };

        getRatingDialogContent();
        refreshCustomerReferral();
    }, []);

    const onShareType = type => () => {
        if (!isAdmin) {
            http.post(`/users/customerShare`, { type });
        }
        onShare();
    };

    const onPreviousStep = () => {
        if (showSingleStep) {
            onClose();
        } else {
            const screenConfigs = Object.keys(shareAndFeedbackDialogConfig).map(
                key => shareAndFeedbackDialogConfig[key]
            );
            const currentScreenConfig = _.find(screenConfigs, screenConfig => screenConfig.key === step);

            const enabledScreensLowerOrdering = _.filter(
                screenConfigs,
                screenConfig => screenConfig.enabled && screenConfig.ordering < currentScreenConfig.ordering
            );

            if (_.isNil(nextPlatformToRateOn)) {
                let ratingScreenIndex = _.findIndex(enabledScreensLowerOrdering, screen => screen.key === RATE_STEP);
                if (ratingScreenIndex !== -1) {
                    enabledScreensLowerOrdering.splice(ratingScreenIndex, 1);
                }
            }

            if (_.isEmpty(enabledScreensLowerOrdering)) {
                if (
                    !pickupsEnabled ||
                    _.isNil(submissionValues.bulkForFeedback) ||
                    _bulk.isWalkIn(submissionValues.bulkForFeedback) ||
                    _bulk.isQuickDrop(submissionValues.bulkForFeedback)
                ) {
                    onStep(SUMMARY_STEP);
                } else {
                    onStep(TIPPING_STEP);
                }
            } else {
                const previousScreen = _.maxBy(enabledScreensLowerOrdering, 'ordering');
                onStep(previousScreen.key);
            }
        }
    };

    const onNextStep = () => {
        if (showSingleStep) {
            onClose();
        } else {
            const screenConfigs = Object.keys(shareAndFeedbackDialogConfig).map(
                key => shareAndFeedbackDialogConfig[key]
            );

            const currentScreenConfig = _.find(screenConfigs, screenConfig => screenConfig.key === step);

            const enabledScreensHigherOrdering = _.filter(
                screenConfigs,
                screenConfig => screenConfig.enabled && screenConfig.ordering > currentScreenConfig.ordering
            );

            if (_.isNil(nextPlatformToRateOn)) {
                let ratingScreenIndex = _.findIndex(enabledScreensHigherOrdering, screen => screen.key === RATE_STEP);
                if (ratingScreenIndex !== -1) {
                    enabledScreensHigherOrdering.splice(ratingScreenIndex, 1);
                }
            }

            if (_.isEmpty(enabledScreensHigherOrdering)) {
                onClose();
            } else {
                const nextScreen = _.minBy(enabledScreensHigherOrdering, 'ordering');
                onStep(nextScreen.key);
            }
        }
    };

    const onSubmit = async (subValues = submissionValues) => {
        const {
            isPositive,
            bulksSummarized,
            bulkForFeedback,
            tipAmount,
            feedback,
            feedbackType,
            reason,
            driverWasOnTime,
            driverLeftBags,
            driverWasProfessional
        } = subValues;
        if (!isAdmin) {
            await http.post(`/users/customerFeedback`, {
                isPositive,
                surveyFeedbackEnabled: true,
                bulk: _.pick(bulkForFeedback, ['_id', 'customerIssues', 'quickdrop']),
                bulksSummarizedIds: bulksSummarized.map(bulk => bulk._id)
            });
            if (tipAmount > 0) {
                let res1 = await http.post('/tips/create', {
                    order: bulk,
                    originalAmount: tipAmount,
                    adjustedAmount: tipAmount
                });

                if (!res1.ok) {
                    onSnackbar(loc('errorSubmittingTip', lang), 'error');
                }
            }
            let customerFeedback = {};
            if (!_.isNil(driverWasOnTime)) {
                customerFeedback.onTime = driverWasOnTime;
            }
            if (!_.isNil(driverLeftBags)) {
                customerFeedback.leftBags = driverLeftBags;
            }
            if (!_.isNil(driverWasProfessional)) {
                customerFeedback.professional = driverWasProfessional;
            }
            if (!_.isEmpty(customerFeedback)) {
                if (!isAdmin) {
                    let res3 = await http.post('/users/customerDriverFeedback', {
                        pickup,
                        customerFeedback
                    });
                } else {
                    onSnackbar('Feedback not sent! Admins cannot rate on behalf of customers', 'info');
                }
            }
            if (!_.isEmpty(feedback)) {
                if (feedbackType === 'complaint') {
                    let res2 = await http.post('/users/pickupComplaint', {
                        message: feedback,
                        reason,
                        email: _.get(customer, 'email', 'N/A'),
                        name: 'N/A',
                        bulk
                    });

                    if (res2.ok) {
                        onClose();
                        onSnackbar(loc('thanksForFeedback', lang));
                        return;
                    }
                } else {
                    let res2 = await http.post('/users/pickupCompliment', {
                        message: feedback,
                        reason: 'N/A',
                        email: _.get(customer, 'email', 'N/A'),
                        name: 'N/A',
                        bulk
                    });
                }
            }
        }

        onClose();
    };

    const onCompleteSummaryStep = async (isPositive, bulksSummarized, bulkForFeedback) => {
        // upon completing the summary step, grab/determine then assign the bulk, pickup and hasTip for the next dialog steps
        const pickupTemp = _.get(bulkForFeedback, 'pickup');
        const hasTipTemp = tips.some(tip => _.get(tip, 'pickup') === _.get(pickupTemp, '_id', '').toString());

        setBulk(bulkForFeedback);
        setPickup(pickupTemp);
        setHasTip(hasTipTemp);

        setProblemWithPickup(!isPositive);
        let submissionValuesCopy = _.cloneDeep(submissionValues);
        submissionValuesCopy.isPositive = _.isNil(isPositive) ? submissionValuesCopy.isPositive : isPositive;
        submissionValuesCopy.bulksSummarized = _.isNil(bulksSummarized)
            ? submissionValuesCopy.bulksSummarized
            : bulksSummarized;
        submissionValuesCopy.bulkForFeedback = _.isNil(bulkForFeedback)
            ? submissionValuesCopy.bulkForFeedback
            : bulkForFeedback;
        setSubmissionValues(submissionValuesCopy);
        if (!pickupsEnabled || _bulk.isWalkIn(bulkForFeedback) || _bulk.isQuickDrop(bulkForFeedback)) {
            if (isAdmin || isPositive) {
                const screenConfigs = Object.keys(shareAndFeedbackDialogConfig).map(
                    key => shareAndFeedbackDialogConfig[key]
                );
                let enabledScreens = _.filter(screenConfigs, screenConfig => screenConfig.enabled);

                //don't show rating dialog if no more platoforms to rate
                if (_.isNil(nextPlatformToRateOn)) {
                    let ratingScreenIndex = _.findIndex(
                        enabledScreens,
                        enabledScreen => enabledScreen.key === RATE_STEP
                    );
                    if (ratingScreenIndex !== -1) {
                        enabledScreens.splice(ratingScreenIndex, 1);
                    }
                }

                if (_.isEmpty(enabledScreens)) {
                    if (isAdmin) {
                        onClose();
                    } else {
                        // Passing in copy because it takes too long for useState to update the state variable used by onSubmit.
                        await onSubmit(submissionValuesCopy);
                    }
                } else {
                    const nextScreen = _.minBy(enabledScreens, 'ordering');
                    onStep(nextScreen.key);
                }
            } else {
                onStep(COMPLAINT_STEP);
            }
        } else onStep(TIPPING_STEP);
    };

    const onCompleteDriverRatingStep = async (
        tipAmount,
        feedback,
        feedbackType,
        reason,
        driverWasOnTime,
        driverLeftBags,
        driverWasProfessional
    ) => {
        let submissionValuesCopy = _.cloneDeep(submissionValues);

        submissionValuesCopy.tipAmount = _.isNil(tipAmount) ? submissionValuesCopy.tipAmount : tipAmount;
        submissionValuesCopy.feedback = _.isNil(feedback) ? submissionValuesCopy.feedback : feedback;
        submissionValuesCopy.feedbackType = _.isNil(feedbackType) ? submissionValuesCopy.feedbackType : feedbackType;
        submissionValuesCopy.reason = _.isNil(reason) ? submissionValuesCopy.reason : reason;
        submissionValuesCopy.driverWasOnTime = _.isNil(driverWasOnTime)
            ? submissionValuesCopy.driverWasOnTime
            : driverWasOnTime;
        submissionValuesCopy.driverLeftBags = _.isNil(driverLeftBags)
            ? submissionValuesCopy.driverLeftBags
            : driverLeftBags;
        submissionValuesCopy.driverWasProfessional = _.isNil(driverWasProfessional)
            ? submissionValuesCopy.driverWasProfessional
            : driverWasProfessional;

        setSubmissionValues(submissionValuesCopy);
        if (isAdmin || feedbackType !== 'complaint') {
            const screenConfigs = Object.keys(shareAndFeedbackDialogConfig).map(
                key => shareAndFeedbackDialogConfig[key]
            );
            let enabledScreens = _.filter(screenConfigs, screenConfig => screenConfig.enabled);

            //don't show rating dialog if no more platforms to rate
            if (_.isNil(nextPlatformToRateOn)) {
                let ratingScreenIndex = _.findIndex(enabledScreens, enabledScreen => enabledScreen.key === RATE_STEP);
                if (ratingScreenIndex !== -1) {
                    enabledScreens.splice(ratingScreenIndex, 1);
                }
            }

            if (_.isEmpty(enabledScreens)) {
                if (isAdmin) {
                    onClose();
                } else {
                    await onSubmit(submissionValuesCopy);
                }
            } else {
                const nextScreen = _.minBy(enabledScreens, 'ordering');
                onStep(nextScreen.key);
            }
        } else {
            if (isAdmin) {
                onClose();
            } else {
                await onSubmit(submissionValuesCopy);
            }
        }
    };

    let STEPS = {};

    STEPS[COMPLAINT_STEP] = (
        <ComplaintScreen
            customer={customer}
            bulk={bulk}
            pickup={pickup}
            handleGoBack={() => onStep(SUMMARY_STEP)}
            onClose={onSubmit}
            onSnackbar={onSnackbar}
            http={http}
            pickupsEnabled={pickupsEnabled}
        />
    );

    STEPS[RATE_STEP] = (
        <RateScreen
            customer={customer}
            nextPlatformToRateOn={nextPlatformToRateOn}
            onRateApp={onRateApp}
            onNextScreen={onNextStep}
            ratingDialogText={ratingDialogText}
            http={http}
            lang={lang}
            isAdmin={isAdmin}
            onSubmit={onSubmit}
            onClose={onClose}
            onBack={onPreviousStep}
            isLastScreen={
                _.get(shareAndFeedbackDialogConfig, 'ratingScreen.ordering', 1) >=
                Object.keys(shareAndFeedbackDialogConfig).length
            }
        />
    );

    STEPS[REFERRAL_STEP] = (
        <ReferralScreen
            http={http}
            isAdmin={isAdmin}
            customer={customer}
            hashtags={hashtags}
            onNextScreen={onNextStep}
            onSnackbar={onSnackbar}
            iconSize={iconSize}
            iconStyle={iconStyle}
            description={description}
            referralConfigObj={referralConfigObj}
            showSingleStep={showSingleStep}
            onClose={onClose}
            onBack={onPreviousStep}
            onSubmit={onSubmit}
            isLastScreen={
                _.get(shareAndFeedbackDialogConfig, 'referralScreen.ordering', 2) >=
                Object.keys(shareAndFeedbackDialogConfig).length
            }
        />
    );

    STEPS[SHARE_STEP] = (
        <ShareScreen
            customer={customer}
            iconSize={iconSize}
            iconStyle={iconStyle}
            description={description}
            message={message}
            subject={subject}
            url={url}
            hashtags={hashtags}
            onShare={onShareType}
            onNativeShare={onNativeShare}
            onNextScreen={onNextStep}
            environmentalStats={environmentalStats}
            customerFinancialStats={customerFinancialStats}
            showSingleStep={showSingleStep}
            onClose={onClose}
            onSubmit={onSubmit}
            onBack={onPreviousStep}
            isAdmin={isAdmin}
            isLastScreen={
                _.get(shareAndFeedbackDialogConfig, 'shareScreen.ordering', 3) >=
                Object.keys(shareAndFeedbackDialogConfig).length
            }
        />
    );

    STEPS[SUMMARY_STEP] = (
        <SummaryScreen
            rates={rates}
            allRates={allRates}
            pickups={pickups}
            bulks={bulks}
            commodities={commodities}
            isLastInteraction={isLastInteraction}
            onClose={onClose}
            isAdmin={isAdmin}
            completeFeedbackStep={onCompleteSummaryStep}
            includeFeesText={_.get(refundSummaryConfig, 'includeFeesText', true)}
        />
    );
    STEPS[TIPPING_STEP] = (
        <TipScreen
            hasTip={hasTip}
            pickup={pickup}
            bulk={bulk}
            onClose={onClose}
            isAdmin={isAdmin}
            customer={customer}
            onNextStep={onCompleteDriverRatingStep}
            problemWithPickup={problemWithPickup}
            http={http}
            enableTipping={
                enableTipping && !_.get(bulk, 'receiver.disabled', false) && !_.get(bulk, 'receiver.banned', false)
            }
            onBack={() => onStep(SUMMARY_STEP)}
            submissionValues={submissionValues}
        />
    );
    return (
        <>
            <Dialog open={open} fullWidth>
                {referralConfigObjLoading || ratingDialogTextLoading ? (
                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    STEPS[step]
                )}
            </Dialog>
        </>
    );
}

//export default withTheme()(ShareDialog);
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(ShareAndFeedbackDialog));
