import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { parseAsync } from 'json2csv';
import { _commodity, _charity, _pickup } from 'std';
import { Filesystem, FilesystemDirectory, FilesystemEncoding } from '@capacitor/filesystem';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Geolocation } from '@capacitor/geolocation';

import VERSION from 'VERSION.js';

import bulkHelper from '../helpers/bulkHelper.js';
import { AUS_STATES, AUS_STATES_FULL_NAME, AVAILABLE_LANGS } from 'constants.js';
import { loc } from 'localizations/localizationHandler.js';
import customDriverCommentRenderer from './Markdown/customRenderer/driverComment/customDriverCommentRenderer.js';
import ReactMarkdown from 'react-markdown';
import { DialogContentText, Typography } from '@material-ui/core';

const { detect } = require('detect-browser');
const browser = detect();

const SUNDAY = 0;
const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;

const weekdayName = [];
weekdayName[MONDAY] = 'Mon';
weekdayName[TUESDAY] = 'Tues';
weekdayName[WEDNESDAY] = 'Wed';
weekdayName[THURSDAY] = 'Thur';
weekdayName[FRIDAY] = 'Fri';
weekdayName[SATURDAY] = 'Sat';
weekdayName[SUNDAY] = 'Sun';

export const isProductionEnv = process.env.REACT_APP_ENV === 'PROD';
export const isStagingEnv = process.env.REACT_APP_ENV === 'TEST';
export const isTrainEnv = process.env.REACT_APP_ENV === 'TRAIN';
export const isLocalEnv = process.env.REACT_APP_ENV === 'LOCAL';
export const isTestingEnv = process.env.REACT_APP_AUTOMATED_TESTER_MODE;

export function formatsSecondsToTime(timeInSeconds, showHours = false) {
    var pad = function(num, size) {
            return ('000' + num).slice(size * -1);
        },
        time = parseFloat(timeInSeconds).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60),
        milliseconds = time.slice(-3);

    return (showHours ? `${hours}:` : '') + pad(minutes, 2) + ':' + pad(seconds, 2);
}

export function getFilteredName(name, path) {
    const value = _.get(name, path, '');

    const validChars = /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s-/.@&',()]+$/;
    const filteredString = value.match(validChars);

    return filteredString ? filteredString.join('').trim() : '';
}

export function profileNotComplete(customer) {
    if (customer.adminView) {
        return false;
    }

    if (customer.forcePasswordChange) return true;

    let needsToChangeLocation = _.isEqual(_.get(customer, 'isExpressLegacyLocationUpdated', true), false);

    if (isEXPRegion() && needsToChangeLocation) return true;

    const phone = _.get(customer, 'phone');
    const firstName = _.get(customer, 'name.first', '');
    const lastName = _.get(customer, 'name.last', '');
    const address = _.get(customer, 'location.description', '');

    if (_.isEmpty(phone) || _.isEmpty(firstName) || _.isEmpty(lastName) || _.isEmpty(address)) return true;

    // Verify that name satisfies regex
    const firstNameFiltered = getFilteredName(customer, 'name.first');
    const lastNameFiltered = getFilteredName(customer, 'name.last');

    if (_.isEmpty(firstNameFiltered) || _.isEmpty(lastNameFiltered)) return true;

    return false;
}

export function removeSpacesFromString(str) {
    return str.replace(/\s/g, '');
}

export function emailIsVerified(customer) {
    return true;
    // return _.get(customer, 'verification.email.verified', false);
}

export function getCustomerName(customer) {
    if (_.isNil(customer)) {
        return 'N/A';
    }
    return (_.get(customer, 'name.first', 'Guest') + ' ' + _.get(customer, 'name.last', 'account')).trim();
}

export function getUserName(user) {
    if (_.isNil(user)) {
        return 'N/A';
    }
    return (_.get(user, 'name.first', 'N/A') + ' ' + _.get(user, 'name.last', '')).trim();
}

export function getCustomerFirstNameAndLastInitial(customer) {
    return (_.get(customer, 'name.first', 'Guest') + ' ' + _.get(customer, 'name.last', 'account').charAt(0)).trim();
}

export function getCustomerFirstName(customer) {
    return _.get(customer, 'name.first', 'Guest').trim();
}

export function getCustomerLastName(customer) {
    return _.get(customer, 'name.last', 'Account').trim();
}

export function getReceiverName(receiver) {
    return (_.get(receiver, 'name.first', 'UNASSIGNED') + ' ' + _.get(receiver, 'name.last', '')).trim();
}

export function getPlural(word) {
    if (_.isEmpty(word)) return '';

    if (word.endsWith('s')) {
        return word;
    } else if (word.endsWith('x') || word.endsWith('ch') || word.endsWith('sh')) {
        return word + 'es';
    } else if (word.endsWith('y')) {
        return word.slice(0, -1) + 'ies';
    } else {
        return word + 's';
    }
}

export function getHomeMarkerPayloadString(pickup, commoditiesAvailable, lang = 'en') {
    if (!_.isNil(pickup)) {
        const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups([pickup], commoditiesAvailable, lang);
        const groupedPayloadItems = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);

        const payloadStringParts = Object.keys(groupedPayloadItems).map(itemKey => {
            const count = groupedPayloadItems[itemKey];
            return `${count} ${count > 1 ? getPlural(itemKey) : itemKey}`;
        });
        const payloadString = payloadStringParts.join(', ');

        return payloadString;
    } else {
        return '0 bags';
    }
}

export function formatAsCurrency(cents, lang = 'en') {
    let locale = 'en-CA';
    switch (lang) {
        case 'fr':
            locale = 'fr-CA';
            break;
        default:
            break;
    }

    return (cents / 100 || 0).toLocaleString(locale, { style: 'currency', currency: 'CAD' });
}

// https://stackoverflow.com/a/8358141/5309948
export function formatAsPhoneNumber(s) {
    let s2 = ('' + s).replace(/\D/g, '');
    let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
}

export function formatAsAddress(location, extended = false, showUnitNumber = true) {
    if (_.isNil(location)) {
        return 'N/A';
    }

    if (_.isEmpty(location.description)) {
        return 'No address is provided';
    }

    const addressComponents = location.description.split(',');

    switch (process.env.REACT_APP_REGION_EXT) {
        case 'STD':
        case 'EXP':
        case 'MXD':
        case 'CON':
            return (
                (!_.isNil(location.unitNumber) && !_.isEmpty(location.unitNumber) && showUnitNumber
                    ? `#${location.unitNumber}, `
                    : '') +
                addressComponents[0] +
                (extended && addressComponents.length > 1 ? ',' + addressComponents[1] : '')
            );
        case 'AUS':
            return (
                (!_.isNil(location.unitNumber) && !_.isEmpty(location.unitNumber) && showUnitNumber
                    ? `#${location.unitNumber}, `
                    : '') +
                addressComponents[0] +
                (addressComponents.length > 1 ? ',' + addressComponents[1] : '')
            );
        default:
            throw new Error('Invalid environment.');
    }
}

export function convertSecondsToHHmm(seconds) {
    if (_.isNil(seconds)) {
        return 'N/A';
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);

    return `${hours.toLocaleString()} hr ${minutes} min`;
}

export function convertSecondsToMinutes(seconds, digits, showUnit = true) {
    if (_.isNil(seconds)) {
        return 'N/A';
    }

    const minutes = seconds / 60;

    return `${minutes.toFixed(digits).toLocaleString()}${showUnit ? ' min' : ''}`;
}

export function convertSecondsToHours(seconds, showUnit = true) {
    if (_.isNil(seconds)) {
        return 'N/A';
    }

    const hours = seconds / 3600;

    return `${hours.toFixed(2).toLocaleString()}${showUnit ? ' hr' : ''}`;
}

export function truncateBagtag(bagtag) {
    // still need this?
    // if (bagtag.toString().length !== 24) {
    //     throw new Error('Invalid bagtag format. Should be exactly 24 chars.');
    // }
    if (_.isNil(bagtag)) {
        return 'MISSINGBAGTAGERROR';
    } else {
        return bagtag.replace(/^0+/, '');
    }
}

export function padBagtag(bagtag) {
    if (bagtag.toString().length > 24) {
        throw new Error('Invalid bag ID format. Should not exceed 24 chars.');
    }

    return bagtag.replace(/ /g, '').padStart(24, '0');
}

// TODO: will be deprecated soon
export function getBulkCompletionDate(bulk) {
    if (_.isNil(bulk.dateCompleted)) {
        if (!_.isNil(bulk.pickup)) {
            return bulk.pickup.completionDate;
        } else {
            return bulk.createdAt;
        }
    } else {
        return bulk.dateCompleted;
    }
}

export function uppercaseFirstLetter(string) {
    if (!_.isNil(string) && string !== '') {
        return string[0].toUpperCase() + string.substring(1);
    } else {
        return '';
    }
}

export function getCustomerLocale(lang) {
    let locale;
    switch (lang) {
        case 'en':
            locale = 'en-ca';
            break;
        case 'fr':
            locale = 'fr-ca';
            break;
        default:
            locale = 'en-ca';
    }
    return locale;
}

export function getVerifiedLanguage(lang) {
    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];
    if (!availableLangs.includes(lang)) {
        return process.env.REACT_APP_REGION_LANGUAGE;
    }

    return lang;
}

// super duper advanced logic to determine device
export const deviceHelper = {
    isNativeApp: function() {
        return !_.isNil(window.cordova);
    },
    AndroidCordova: function() {
        return _.get(window, 'Capacitor.platform', undefined) === 'android';
    },
    iOSCordova: function() {
        return _.get(window, 'Capacitor.platform', undefined) === 'ios';
    },
    AndroidWeb: function() {
        return navigator.userAgent.match(/Android/i);
    },
    iOSWeb: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    MobileWeb: function() {
        return deviceHelper.AndroidWeb() || deviceHelper.iOSWeb();
    }
};

export async function getDeviceInformation() {
    try {
        const ua = navigator.userAgent || navigator.vendor || window.opera;
        const isInstagram = ua.includes('Instagram');
        const isFacebook = ua.includes('FBAN') || ua.includes('FBAV');

        const dimensions = `${_.get(window.screen, 'width', 0) * _.get(window, 'devicePixelRatio', 0)} x ${_.get(
            window.screen,
            'height',
            0
        ) * _.get(window, 'devicePixelRatio', 0)}`;

        const uuid = (await Device.getId()).identifier;
        const info = await Device.getInfo();
        let appVersion = VERSION;
        if (deviceHelper.isNativeApp()) {
            const { appVersion, appBuild, appId, appName } = await App.getInfo();
            return {
                device: getRealiPhoneModel(_.get(info, 'model')) || 'Unknown', // Android or iOS
                version: _.get(info, 'osVersion', 'Unknown'),
                browser: 'Capacitor',
                dimensions,
                memoryUsed: `${_.get(info, 'memUsed', 0) / 1048576} MB`,
                appVersion: appVersion || '0.0.0',
                uuid,
                isInAppBrowser: isInstagram || isFacebook
            };
        } else {
            return {
                device: _.get(browser, 'os', 'Unknown'),
                version: _.get(browser, 'version', 'Unknown'),
                browser: isInstagram ? 'Instagram' : _.get(browser, 'name', 'Unknown'),
                dimensions,
                memoryUsed: `${_.get(info, 'memUsed', 0) / 1048576} MB`,
                appVersion: appVersion || '0.0.0',
                uuid,
                isInAppBrowser: isInstagram || isFacebook
            };
        }
    } catch (err) {
        return {
            device: 'N/A',
            version: 'N/A',
            browser: 'N/A',
            dimensions: 'N/A'
        };
    }
}

export function getWindowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

export function getWindowHeight() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

export function getAppBarHeight(breakpoints) {
    let windowWidth = getWindowWidth();
    if (windowWidth >= breakpoints.values.sm) {
        return 64;
    } else {
        return 56;
    }
}

function patchVersionUpdated(versionServer, versionFront) {
    let minorServer = versionServer.split('.')[1];
    let minorFront = versionFront.split('.')[1];

    if (minorServer !== minorFront) return false;

    let patchServer = versionServer.split('.')[2];
    let patchFront = versionFront.split('.')[2];

    return patchServer > patchFront;
}

export function changeRequiresRefresh(versionServer, versionFront) {
    // Web hotfix
    if (deviceHelper.isNativeApp()) return false;

    return patchVersionUpdated(versionServer, versionFront);
}

export function patchCanUpdate(versionServer, versionFront) {
    // Android/iOS forced update for patch change
    if (!deviceHelper.isNativeApp()) return false;

    return patchVersionUpdated(versionServer, versionFront);
}

export function breakingChangeRequiresUpdate(versionServer, versionFront) {
    // Server updated
    let minorServer = versionServer.split('.')[1];
    let minorFront = versionFront.split('.')[1];
    return minorServer > minorFront;
}

export function breakingChangeAwaitingServer(versionServer, versionFront) {
    // Client updated
    let minorServer = versionServer.split('.')[1];
    let minorFront = versionFront.split('.')[1];
    return minorServer < minorFront;
}

export function getCharityPreferred(pickups, redemptions, charityPreferred, charitySelected, charities) {
    let lastPickup = _.last(pickups);
    let lastRedemption = _.last(redemptions);

    let charityIDToReturn;

    if (!_.isNil(charitySelected)) {
        charityIDToReturn = charitySelected;
    } else if (_.isNil(lastPickup) && _.isNil(lastRedemption)) {
        charityIDToReturn = charityPreferred ? charityPreferred : null; // default state of a new account
    } else if (!_.isNil(lastPickup) && _.isNil(lastRedemption)) {
        charityIDToReturn = lastPickup.charity ? lastPickup.charity : null;
    } else if (_.isNil(lastPickup) && !_.isNil(lastRedemption)) {
        charityIDToReturn = isEXPRegion() ? null : lastRedemption.charity ? lastRedemption.charity._id : null;
    } else {
        if (lastPickup.createdAt > lastRedemption.createdAt) {
            charityIDToReturn = lastPickup.charity ? lastPickup.charity : null;
        } else {
            charityIDToReturn = lastRedemption.charity ? lastRedemption.charity._id : null;
        }
    }

    const charity = _.find(charities, { _id: charityIDToReturn });
    if (!_.isNil(charity) && !_charity.isRejected(charity)) {
        return charityIDToReturn;
    } else {
        return null;
    }
}

export function getSubdivisionPreferred(pickups, redemptions, subdivisionPreferred) {
    let lastPickup = _.last(pickups);
    let lastRedemption = _.last(redemptions);

    if (!_.isNil(subdivisionPreferred)) {
        return subdivisionPreferred;
    }

    if (_.isNil(lastPickup) && _.isNil(lastRedemption)) {
        return null; // default state of a new account
    } else if (!_.isNil(lastPickup) && _.isNil(lastRedemption)) {
        return lastPickup.subdivision ? lastPickup.subdivision : null;
    } else if (_.isNil(lastPickup) && !_.isNil(lastRedemption)) {
        return lastRedemption.subdivision ? lastRedemption.subdivision : null;
    } else {
        if (lastPickup.createdAt > lastRedemption.createdAt) {
            return lastPickup.subdivision ? lastPickup.subdivision : null;
        } else {
            return lastRedemption.subdivision ? lastRedemption.subdivision : null;
        }
    }
}

export function getPickupDisplayedInfo(pickup, accountType) {
    let id = _.get(pickup, 'customer.uniqueID', 'N/A');

    if (accountType === 'System Administrator') {
        return (
            <div>
                #{id} {getCustomerName(pickup.customer)}
                {_.get(pickup, 'customer.isSchemeIDUser', false) && (
                    <>
                        <br /> SchemeID: {_.get(pickup, 'customer.schemeID', 'N/A')}
                    </>
                )}
            </div>
        );
    } else {
        return (
            _.get(pickup, 'customer.name.first', 'N/A') +
            ' ' +
            (!_.isNil(_.get(pickup, 'customer.name.last', undefined))
                ? _.get(pickup, 'customer.name.last', '')[0] + '.'
                : '')
        );
    }
}

export function getPickupFrequency(pickup) {
    let frequency; // in days
    if (!_.isNil(pickup.frequency)) {
        frequency = pickup.frequency;
    } else {
        frequency = moment(pickup.nextDate)
            .startOf('day')
            .diff(moment(pickup.date).startOf('day'), 'days');
    }

    return frequency;
}

export function getFormattedPickupFrequency(theme, pickup, period) {
    let normalizedFrequency = _.round(getPickupFrequency(pickup) / period, 3);
    let text;
    switch (period) {
        case 7:
            text = 'week';
            break;
        case 28:
            text = 'month';
            break;
        default:
            throw new Error('Invalid period supplied.');
    }
    let ext = normalizedFrequency === 1 ? '' : 's';
    if (normalizedFrequency % 1 !== 0) {
        return (
            <span style={{ color: theme.palette.secondary.main }}>
                {normalizedFrequency} {text}
                {ext}
            </span>
        );
    } else {
        return (
            <span>
                {normalizedFrequency} {text}
                {ext}
            </span>
        );
    }
}

export function downloadObjectAsCSV(data, exportName, parseOptions = null) {
    return new Promise((resolve, reject) => {
        console.log(`Downloading ${exportName}.csv`, data);
        let options;
        if (_.isNil(parseOptions)) {
            options = { fields: _.keys(_.first(data)) };
        } else {
            options = parseOptions;
        }
        parseAsync(data, options)
            .then(csv => {
                window.URL = window.webkitURL || window.URL;

                let csvFile = new Blob([csv], { type: 'text/csv' });
                if (deviceHelper.isNativeApp()) {
                    Filesystem.writeFile({
                        path: `${exportName}.csv`,
                        data: csv,
                        directory: FilesystemDirectory.Documents,
                        encoding: FilesystemEncoding.UTF8
                    }).then(() => {
                        resolve();
                    });
                } else {
                    let a = document.createElement('a');
                    a.download = exportName + '.csv';
                    a.href = window.URL.createObjectURL(csvFile);
                    a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    resolve();
                }
            })
            .catch(err => reject(err));
    });
}

export function wait(ms) {
    return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
    });
}

export function isAUSRegion() {
    return process.env.REACT_APP_REGION_EXT === 'AUS';
}

export function isEXPRegion() {
    return process.env.REACT_APP_REGION_EXT === 'EXP';
}

export function isMXDRegion() {
    return process.env.REACT_APP_REGION_EXT === 'MXD';
}

export function isCONRegion() {
    return process.env.REACT_APP_REGION_EXT === 'CON';
}

export function isSTDRegion() {
    return process.env.REACT_APP_REGION_EXT === 'STD';
}

export function isABDRegion() {
    return process.env.REACT_APP_REGION_EXT_DEMO === 'ABD';
}

export function parsePhoneNumber(phoneNumber) {
    return parseInt(phoneNumber, 10).toString();
}

export function showOnGoogleMaps(location) {
    const url = `https://www.google.com/maps/dir/?api=1&query=&destination=${location.lat},${
        location.lng
    }&travelmode=driving`;
    if (deviceHelper.AndroidCordova()) {
        window.open(url, '_system');
    } else if (deviceHelper.iOSCordova()) {
        window.open(url, '_system');
    } else {
        window.open(url, '_blank');
    }
}

export async function getGPSLocation() {
    if (navigator && navigator.geolocation) {
        try {
            const coordinates = await Geolocation.getCurrentPosition({
                maximumAge: 1000,
                timeout: 5000,
                enableHighAccuracy: true
            });

            return coordinates;
        } catch (err) {
            console.log('Error getting location', err);
        }
    }
    return null;
}

export function inverseValue(val) {
    return -val;
}

export function getTotalAmountFromItems(items, skus) {
    let total = 0;
    for (let item of items) {
        const sku = _(skus)
            .filter(s => s.sku === item.sku)
            .value();

        if (!_.isEmpty(sku)) {
            total += Math.trunc(inverseValue(_.first(sku).value) * item.quantity); //always round down decimal cents
        }
    }
    return total;
}

export function getTotalAmount(containerGroups) {
    return _.keys(containerGroups).reduce((sum, material) => {
        return (sum += containerGroups[material].reduce((sum, container) => {
            let totalRate = -container.value;
            return (sum += Math.trunc(totalRate * container.quantity) || 0);
        }, 0));
    }, 0);
}

export function getBulkCounterFinancials({ bulk, countForm, currentCounts, skus }) {
    if (_.isNil(bulk) || _.isNil(countForm) || _.isNil(currentCounts)) {
        return { totalCountFormAmount: 0, totalFees: 0, totalPreviousCountsAmount: 0, grandTotal: 0 };
    }
    const totalCountFormAmount = getTotalAmount(countForm);
    const totalFees = _.get(bulk, 'customFees', []).reduce((sum, item) => {
        return (sum += item.amount);
    }, 0);
    const totalPreviousCountsAmount = currentCounts.reduce((sum, c) => {
        return sum + getTotalAmountFromItems(c.items, skus[bulk.skuType]);
    }, 0);
    const grandTotal = totalPreviousCountsAmount - totalFees;
    return { totalCountFormAmount, totalFees, totalPreviousCountsAmount, grandTotal };
}

export function valuesInObj(obj, stack) {
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            valuesInObj(obj[key], stack);
        } else {
            if (typeof obj[key] === 'string') {
                stack.push(obj[key].toLowerCase());
            } else {
                stack.push(obj[key]);
            }
        }
    }
}

export function generateCommunityDaysOfTheWeek(zone) {
    if (_.isNil(zone) || _.isEmpty(zone.startingDates)) return 'Pickup is out of zone';

    const daysOfWeek = zone.startingDates.map(date => {
        return weekdayName[moment(date).isoWeekday()];
    });

    return 'Pickup days: ' + daysOfWeek.join(', ');
}

export function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function toggleFullScreen(el) {
    if (!el) {
        el = document.body; // Make the body go full screen.
    }
    var isInFullScreen =
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        document.mozFullScreen ||
        document.webkitIsFullScreen;

    if (isInFullScreen) {
        cancelFullScreen();
    } else {
        requestFullScreen(el);
    }
    return false;
}

export function toDollars(cents) {
    return _.round(cents / 100, 2);
}

function cancelFullScreen() {
    var el = document;
    var requestMethod =
        el.cancelFullScreen ||
        el.webkitCancelFullScreen ||
        el.mozCancelFullScreen ||
        el.exitFullscreen ||
        el.webkitExitFullscreen;
    if (requestMethod) {
        // cancel full screen.
        requestMethod.call(el);
    } else if (typeof window.ActiveXObject !== 'undefined') {
        // Older IE.
        var wscript = new window.ActiveXObject('WScript.Shell');
        if (wscript !== null) {
            wscript.SendKeys('{F11}');
        }
    }
}

function requestFullScreen(el) {
    // Supports most browsers and their versions.
    var requestMethod =
        el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;

    if (requestMethod) {
        // Native full screen.
        requestMethod.call(el);
    } else if (typeof window.ActiveXObject !== 'undefined') {
        // Older IE.
        var wscript = new window.ActiveXObject('WScript.Shell');
        if (wscript !== null) {
            wscript.SendKeys('{F11}');
        }
    }
    return false;
}

function filterSKUs(item) {
    switch (process.env.REACT_APP_REGION_EXT) {
        case 'AUS':
            return !item.materialType.includes('Non-eligible');
        case 'STD':
        case 'EXP':
        case 'MXD':
        case 'CON':
            return !(item.sku === 9010 || item.sku === 9020);
        default:
            throw new Error('REACT_APP_REGION_EXT is not set');
    }
}

export function getCustomerFinancialStats(
    bulks,
    commodities,
    redemptions = [],
    redemptionPending = false,
    tips = [],
    startDate = null, //moment obj
    endDate = null //moment obj
) {
    let filteredBulks = bulks;
    let filteredTips = tips;

    if (!_.isNil(startDate) && !_.isNil(endDate)) {
        filteredBulks = bulks.filter(
            b =>
                moment(b.createdAt).isSameOrBefore(moment(endDate).endOf('day')) &&
                moment(b.createdAt).isSameOrAfter(moment(startDate).startOf('day'))
        );
        filteredTips = tips.filter(
            t =>
                moment(t.createdAt).isSameOrBefore(moment(endDate).endOf('day')) &&
                moment(t.createdAt).isSameOrAfter(moment(startDate).startOf('day'))
        );
    }

    const skuTypesForStats = _.filter(commodities, { includeContainersInStats: true }).map(commodity =>
        _commodity.getSkuType(commodity)
    );
    const bulksToIncludeForQuantity = _.filter(filteredBulks, bulk => skuTypesForStats.includes(bulk.skuType));

    const customerFinancialStats = {
        balance: filteredBulks.reduce((sum, bulk) => {
            if (!bulk.charity) {
                let tip = _.find(filteredTips, t => t.order && t.order.toString() === bulk._id.toString());
                return (
                    sum +
                    (bulkHelper.isRedeemed(bulk)
                        ? 0
                        : bulkHelper.getCustomerAmount(bulk) -
                          (bulkHelper.isCompleted(bulk) && tip ? tip.adjustedAmount || tip.originalAmount || 0 : 0) *
                              100)
                );
            } else {
                return sum;
            }
        }, 0),
        totalQuantity: bulksToIncludeForQuantity.reduce((sum, bulk) => {
            return (sum += bulk.counts.reduce((sum, count) => {
                return (sum += count.items
                    .filter(item => filterSKUs(item))
                    .reduce((sum, item) => {
                        return (sum += item.quantity);
                    }, 0));
            }, 0));
        }, 0),
        personalRevenue: 0,
        totalDonated: 0,
        totalClothing: 0,
        totalElectronics: 0,
        amountPending: redemptionPending ? _.last(redemptions).amount - _.last(redemptions).fee : undefined,
        amountDonated:
            !_.isNil(_.last(redemptions)) &&
            !_.isNil(_.last(redemptions).charity) &&
            !_.isNil(_.last(filteredBulks)) &&
            !_.isNil(_.last(filteredBulks).charity)
                ? _.last(redemptions).amount
                : undefined
    };

    filteredBulks.forEach(bulk => {
        if (bulk.charity) {
            customerFinancialStats.totalDonated += !bulkHelper.isCompleted(bulk)
                ? 0
                : bulkHelper.getCustomerAmount(bulk);
        } else {
            customerFinancialStats.personalRevenue += !bulkHelper.isCompleted(bulk)
                ? 0
                : bulk.skuType === 'Opening Balance'
                ? 0
                : bulkHelper.getCustomerAmount(bulk);
        }
        // NOTE: this is an old way to do it but will keep older stats intact
        customerFinancialStats.totalClothing += _.get(bulk, 'payload.clothing', 0);
        customerFinancialStats.totalElectronics += _.get(bulk, 'payload.electronics', 0);

        if (bulkHelper.isClothing(bulk)) {
            customerFinancialStats.totalClothing += bulk.commodityAmount;
        } else if (bulkHelper.isElectronics(bulk)) {
            customerFinancialStats.totalElectronics += bulk.commodityAmount;
        }
    });

    return customerFinancialStats;
}

export function getCharityFinancialStats(bulks, commodities, startDate = null, endDate = null) {
    let filteredBulks = bulks;

    if (!_.isNil(startDate) && !_.isNil(endDate)) {
        filteredBulks = bulks.filter(
            b =>
                moment(b.createdAt).isSameOrBefore(moment(endDate).endOf('day')) &&
                moment(b.createdAt).isSameOrAfter(moment(startDate).startOf('day'))
        );
    }

    const skuTypesForStats = _.filter(commodities, { includeContainersInStats: true }).map(commodity =>
        _commodity.getSkuType(commodity)
    );
    const bulksToIncludeForQuantity = _.filter(filteredBulks, bulk => skuTypesForStats.includes(bulk.skuType));

    const charityFinancialStats = {
        balance: filteredBulks.reduce((sum, bulk) => {
            const total = bulkHelper.isRedeemed(bulk) ? 0 : bulkHelper.getCustomerAmount(bulk);
            const tip =
                bulkHelper.isRedeemed(bulk) || !bulkHelper.isCompleted(bulk)
                    ? 0
                    : (bulk.tip ? bulk.tip.adjustedAmount || bulk.tip.originalAmount || 0 : 0) * 100;
            return sum + total - tip;
        }, 0),
        totalRevenue: filteredBulks.reduce((sum, bulk) => {
            return (
                sum +
                bulkHelper.getCustomerAmount(bulk) -
                (bulk.tip ? bulk.tip.adjustedAmount || bulk.tip.originalAmount || 0 : 0) * 100
            );
        }, 0),
        totalDonations: filteredBulks.length,
        totalQuantity: bulksToIncludeForQuantity.reduce((sum, bulk) => {
            return (sum += bulk.counts.reduce((sum, count) => {
                return (sum += count.items.reduce((sum, item) => {
                    return (sum += item.quantity);
                }, 0));
            }, 0));
        }, 0),
        totalClothing: 0,
        totalElectronics: 0
    };

    filteredBulks.forEach(bulk => {
        // NOTE: this is an old way to do it but will keep older stats intact
        charityFinancialStats.totalClothing += _.get(bulk, 'payload.clothing', 0);
        charityFinancialStats.totalElectronics += _.get(bulk, 'payload.electronics', 0);

        if (bulkHelper.isClothing(bulk)) {
            charityFinancialStats.totalClothing += bulk.commodityAmount;
        } else if (bulkHelper.isElectronics(bulk)) {
            charityFinancialStats.totalElectronics += bulk.commodityAmount;
        }
    });

    return charityFinancialStats;
}

export function getEnvironmentalStats(financialStats, impactReportConfig) {
    let environmentalStats = {
        // =====================================
        // DEFAULT VALUES USED IN ShareScreen.js
        // =====================================
        water: financialStats.totalQuantity * 6.686, //litres
        emissions: financialStats.totalQuantity * 0.145, //kg
        // energy: financialStats.totalQuantity * 1.551, //mJ
        landfill: financialStats.totalQuantity * 0.07, //kg

        // =====================================
        // DEFAULT VALUES USED BEFORE 2024-04-22
        // updated in #2887 - @rarmclea
        // =====================================
        // emissions: financialStats.totalQuantity * 0.0971718, //kg
        energy: financialStats.totalQuantity * 0.3330947 //kwh
        // landfill: financialStats.totalQuantity * 0.0511431 //kg
    };
    if (!_.isNil(impactReportConfig)) {
        environmentalStats = {
            water: financialStats.totalQuantity * impactReportConfig.waterAmount,
            emissions: financialStats.totalQuantity * impactReportConfig.carbonAmount,
            energy: financialStats.totalQuantity * impactReportConfig.energyAmount,
            landfill: financialStats.totalQuantity * impactReportConfig.landfillAmount
        };
    }

    return environmentalStats;
}

export function getDonateBulksFromPickups(pickups = [], bulks = []) {
    let bulksPassedToSummary = [];

    let lastCompletedPickup = _.last(
        _.filter(pickups, function(pickup) {
            return pickup.complete && !pickup.aborted;
        })
    );

    if (_.isNil(lastCompletedPickup) || _.isNil(lastCompletedPickup.charity)) {
        // only for donated pickup
        return [];
    }

    let bulksFromLastPickup = _.filter(bulks, function(bulk) {
        return _.get(bulk, 'pickup._id') === lastCompletedPickup._id;
    });

    if (bulksFromLastPickup.length <= 1) {
        // use default behavoir, dont pass any bulks
        return [];
    } else {
        bulksPassedToSummary = [...bulksFromLastPickup];
        // let allBulksCompleted = bulks.every(bulk => bulkHelper.isCompleted(bulk));
        // if (allBulksCompleted) {
        //     bulksPassedToSummary = [...bulksFromLastPickup];
        // } else {
        //     // pass the most recently completed one + all incompleted bulks
        //     let incompletedBulks = bulks.filter(bulk => !bulkHelper.isCompleted(bulk));
        //     bulksPassedToSummary = !_.isNil(lastCompletedBulk)
        //         ? [lastCompletedBulk, ...incompletedBulks]
        //         : incompletedBulks;
        // }
    }

    return bulksPassedToSummary;
}

export function mapCodeToName(options = [], errorCodeArr = []) {
    if (_.isEmpty(options) || _.isEmpty(errorCodeArr)) {
        return [];
    }

    let codeMap = {};
    options.forEach(obj => {
        codeMap[obj.code] = obj.label;
    });

    let errorNameArr = errorCodeArr.map(code => codeMap[code]).filter(name => !_.isNil(name));
    return errorNameArr;
}

// replacement for REACT_APP_QUICKDROP_NAME
export function getQuickDropName(lang = process.env.REACT_APP_REGION_LANGUAGE) {
    const env = process.env.REACT_APP_REGION_EXT;
    const DEFAULT_NAME = {
        AUS: 'Express',
        STD: 'Drop&Go',
        EXP: 'Express',
        MXD: 'Bag Drop',
        CON: 'Bag Drop'
    }[env];

    if (lang === 'fr') {
        return 'Dépôt De Sacs';
    }

    return DEFAULT_NAME;
}

export function getWalkInName(lang = process.env.REACT_APP_REGION_LANGUAGE) {
    const env = process.env.REACT_APP_REGION_EXT;
    const DEFAULT_NAME = {
        AUS: 'In-Depot',
        STD: 'In-Depot',
        EXP: 'In-Depot',
        MXD: 'In-Depot',
        CON: 'In-Depot'
    }[env];

    if (env === 'CON') {
        return lang === 'fr' ? 'Machine Récupératrice À L’unité' : 'Reverse Vending Machine';
    }
    if (lang === 'fr') {
        return 'En-Dépôt';
    }

    return DEFAULT_NAME;
}

export function getUserAccountStatusAndModifiers(user) {
    let string = '';
    if (user.deleted) {
        string += 'Deleted';
    } else if (user.banned) {
        string += 'Banned';
    } else if (user.disabled) {
        string += 'Closed';
    } else {
        string += 'Active';
    }
    let modifiers = [];
    if (user.suspended) {
        modifiers.push('Suspended');
    }
    if (user.underReview) {
        modifiers.push('Under Review');
    }
    if (user.stale) {
        modifiers.push('Stale');
    }
    if (!_.isEmpty(modifiers)) {
        string += ' [';
        string += modifiers.join(', ').toString();
        string += ']';
    }
    return string;
}

export function getUserAccountStatus(user) {
    if (user.deleted) {
        return 'Deleted';
    }
    if (user.banned) {
        return 'Banned';
    }
    if (user.disabled) {
        return 'Closed';
    }
    if (user.suspended) {
        return 'Suspended';
    }
    if (user.underReview) {
        return 'Under Review';
    }
    if (user.stale) {
        return 'Stale';
    }
    return 'Active';
}

export function getStateFullNameFromAbbreviation(state) {
    switch (state) {
        case 'ACT':
            return 'Australian Capital Territory';
        case 'NSW':
            return 'New South Wales';
        case 'NT':
            return 'Northern Territory';
        case 'QLD':
            return 'Queensland';
        case 'SA':
            return 'South Australia';
        case 'TAS':
            return 'Tasmania';
        case 'VIC':
            return 'Victoria';
        case 'WA':
            return 'Western Australia';
        default:
            return 'N/A';
    }
}

export function getStateAbbreviationFromFullName(state) {
    switch (state) {
        case 'Australian Capital Territory':
            return 'ACT';
        case 'New South Wales':
            return 'NSW';
        case 'Northern Territory':
            return 'NT';
        case 'Queensland':
            return 'QLD';
        case 'South Australia':
            return 'SA';
        case 'Tasmania':
            return 'TAS';
        case 'Victoria':
            return 'VIC';
        case 'Western Australia':
            return 'WA';
        default:
            return 'N/A';
    }
}

// gets AUS state from an address
export function getStateFromAddress(address) {
    let state = parseOutStateAbbreviation(address);

    if (_.isNull(state)) {
        return parseOutStateFromFullName(address);
    }

    return state;
}

function parseOutStateAbbreviation(address) {
    let substring = '';
    for (let i = 0; i < address.length; i++) {
        const char = address.charAt(i);

        if (/[a-zA-Z]/.test(char)) {
            substring += char;
        } else {
            if (AUS_STATES.includes(substring)) {
                return substring;
            }
            substring = '';
        }
    }

    return null;
}

function parseOutStateFromFullName(address) {
    // Remove first entry - dont want to compare against street name
    let addressSplit = address.split(',').slice(-2);
    let trimmedAddressSplit = addressSplit.map(a => a.trim());

    let stateFullName = null;
    if (_.size(addressSplit) > 0) {
        stateFullName = _.find(AUS_STATES_FULL_NAME, stateFullName =>
            _.some(trimmedAddressSplit, el => _.includes(el, stateFullName))
        );
    }

    return getStateAbbreviationFromFullName(stateFullName);
}

export function getOpenStatusFromAvailability(availability, timezone, lang) {
    let openStatusObj = { open: false, openStatusText: '' };
    const today = moment(new Date()).day();
    const todayAvailability = availability[Math.max(0, today - 1)];
    if (todayAvailability.hours.includes('Closed')) {
        openStatusObj.openStatusText = loc('pickupDialogDrop8', lang);
        openStatusObj.open = false;
    } else if (todayAvailability.hours === '12am–11:59pm') {
        openStatusObj.openStatusText = loc('pickupDialogDrop7', lang);
        openStatusObj.open = true;
    } else {
        const openTime = getHoursMomentFromString(todayAvailability.hours.split('–')[0], timezone);
        const closeTime = getHoursMomentFromString(todayAvailability.hours.split('–')[1], timezone);
        if (moment().isBetween(openTime, closeTime)) {
            openStatusObj.open = true;
            if (Math.abs(moment(new Date()).diff(closeTime)) < 60 * 60 * 1000) {
                openStatusObj.openStatusText = loc('pickupDialogDrop7b', lang);
            } else {
                openStatusObj.openStatusText = loc('pickupDialogDrop7', lang);
            }
        } else {
            openStatusObj.open = false;
            if (Math.abs(moment(new Date()).diff(openTime)) < 60 * 60 * 1000) {
                openStatusObj.openStatusText = loc('pickupDialogDrop8b', lang);
            } else {
                openStatusObj.openStatusText = loc('pickupDialogDrop8', lang);
            }
        }
    }
    return openStatusObj;
}

export function getHoursMomentFromString(string, timezone) {
    const period = string.slice(-2);
    let [hours, minutes] = string.slice(0, -2).split(':');

    minutes = parseInt(minutes, 10);

    hours = parseInt(hours, 10);

    if (period.toLowerCase() === 'pm' && hours !== 12) {
        hours += 12;
    } else if (period.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
    }
    return moment()
        .tz(timezone)
        .startOf('day')
        .add(hours, 'hours')
        .add(minutes, 'minutes');
}

export function groupQuickDropBulks(quickdropBulks, isAdmin = false) {
    const groupedQuickdropBulks = [];
    let currentGroup = [];
    //no way to determine what bulks were created from a single quickdrop submission other than the createdAt dates will be close together
    //group bulks that were created within 5 secs of each other
    for (let bulk of quickdropBulks) {
        // GITLAB #2862 - Do not Show Express Processed history items until they have been creditted to customer
        let isCompleted = _.get(bulk, 'dateCompleted') != null;
        let isCounted = _.get(bulk, 'dateCounted') != null;
        let isRogue = _.get(bulk, 'autoComplete', false);
        // NOT Counted AND NOT Completed = Dropped Off
        // Is Counted but NOT Completed = Processed
        // Counted AND Completed = Creditted to Customer
        if ((isEXPRegion() || isCONRegion()) && !isAdmin && isRogue && !isCompleted) {
            continue;
        }

        if (_.isEmpty(currentGroup)) {
            groupedQuickdropBulks.push(currentGroup);
            currentGroup.push(bulk);
        } else {
            const lastBulk = _.last(currentGroup);
            const timeDiff = moment(_.get(lastBulk, 'createdAt'))
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .diff(moment(_.get(bulk, 'createdAt')).tz(process.env.REACT_APP_REGION_TIMEZONE), 'seconds');

            //some imported bulks from express legacy are created at the same time, quickdrops shouldn't have more then one bulk of a certain skuType
            const skuTypeExistsInGroup = _.some(currentGroup, b => _.get(b, 'skuType') === _.get(bulk, 'skuType'));
            if (Math.abs(timeDiff) <= 5 && !skuTypeExistsInGroup) {
                currentGroup.push(bulk);
            } else {
                currentGroup = [bulk];
                groupedQuickdropBulks.push(currentGroup);
            }
        }
    }

    return groupedQuickdropBulks;
}

export function DriverCommentMarkdownHelpertext(props) {
    const { theme, source } = props;

    return (
        <div style={{ marginBottom: 16 }}>
            <DialogContentText
                style={{
                    color: theme.palette.text.secondary,
                    fontSize: 12
                }}
            >
                <span>Markdown</span>
                <ul style={{ marginTop: 0 }}>
                    <li>New Line: (two spaces)</li>
                    <li>Bullet Points: ('*', or '-')</li>
                    <li>Numbering: (1. 2.)</li>
                    <li>Bold: (**word**)</li>
                    <li>Italicize: (_word_ or *word*)</li>
                </ul>
            </DialogContentText>
            <ReactMarkdown
                allowedTypes={['root', 'text', 'break', 'paragraph', 'emphasis', 'strong', 'list', 'listItem', 'image']}
                unwrapDisallowed
                source={source}
                renderers={customDriverCommentRenderer}
            />
        </div>
    );
}

export function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

export function aboveETransferLimit(balance, redemptions, options) {
    if (!_.get(options, 'enableEtransferLimit', false)) {
        return false;
    }

    const redemptionsForCurrentYear = _.filter(redemptions, redemption => {
        if (!redemption.createdAt) {
            return false;
        }

        const redeemedDate = moment(redemption.createdAt);
        if (redeemedDate.isBefore(moment().startOf('year'))) {
            return false;
        }

        if (redemption.cancelled) {
            return false;
        }

        return true;
    });

    const redemptionAmountForCurrentYear = redemptionsForCurrentYear.reduce((total, curr) => {
        return total + _.get(curr, 'amount', 0) - _.get(curr, 'fee', 0);
    }, 0);

    if (balance + redemptionAmountForCurrentYear > 100 * _.get(options, 'etransferLimit', 1000)) {
        return true;
    }

    return false;
}

export function getEnvName() {
    if (isTestingEnv) {
        return '';
    } else if (isLocalEnv) {
        return 'LOCAL';
    } else if (isStagingEnv) {
        return 'STAGING';
    } else if (isTrainEnv) {
        return 'TRAIN';
    } else if (isProductionEnv) {
        return 'PROD';
    } else {
        return 'UNKNOWN';
    }
}

export function getRealiPhoneModel(unfriendlyModel) {
    const modelMap = {
        'i386 ': ' iPhone Simulator',
        'x86_64 ': ' iPhone Simulator',
        'arm64 ': ' iPhone Simulator',
        'iPhone1,1 ': ' iPhone',
        'iPhone1,2 ': ' iPhone 3G',
        'iPhone2,1 ': ' iPhone 3GS',
        'iPhone3,1 ': ' iPhone 4',
        'iPhone3,2 ': ' iPhone 4 GSM Rev A',
        'iPhone3,3 ': ' iPhone 4 CDMA',
        'iPhone4,1 ': ' iPhone 4S',
        'iPhone5,1 ': ' iPhone 5 (GSM)',
        'iPhone5,2 ': ' iPhone 5 (GSM+CDMA)',
        'iPhone5,3 ': ' iPhone 5C (GSM)',
        'iPhone5,4 ': ' iPhone 5C (Global)',
        'iPhone6,1 ': ' iPhone 5S (GSM)',
        'iPhone6,2 ': ' iPhone 5S (Global)',
        'iPhone7,1 ': ' iPhone 6 Plus',
        'iPhone7,2 ': ' iPhone 6',
        'iPhone8,1 ': ' iPhone 6s',
        'iPhone8,2 ': ' iPhone 6s Plus',
        'iPhone8,4 ': ' iPhone SE (GSM)',
        'iPhone9,1 ': ' iPhone 7',
        'iPhone9,2 ': ' iPhone 7 Plus',
        'iPhone9,3 ': ' iPhone 7',
        'iPhone9,4 ': ' iPhone 7 Plus',
        'iPhone10,1 ': ' iPhone 8',
        'iPhone10,2 ': ' iPhone 8 Plus',
        'iPhone10,3 ': ' iPhone X Global',
        'iPhone10,4 ': ' iPhone 8',
        'iPhone10,5 ': ' iPhone 8 Plus',
        'iPhone10,6 ': ' iPhone X GSM',
        'iPhone11,2 ': ' iPhone XS',
        'iPhone11,4 ': ' iPhone XS Max',
        'iPhone11,6 ': ' iPhone XS Max Global',
        'iPhone11,8 ': ' iPhone XR',
        'iPhone12,1 ': ' iPhone 11',
        'iPhone12,3 ': ' iPhone 11 Pro',
        'iPhone12,5 ': ' iPhone 11 Pro Max',
        'iPhone12,8 ': ' iPhone SE 2nd Gen',
        'iPhone13,1 ': ' iPhone 12 Mini',
        'iPhone13,2 ': ' iPhone 12',
        'iPhone13,3 ': ' iPhone 12 Pro',
        'iPhone13,4 ': ' iPhone 12 Pro Max',
        'iPhone14,2 ': ' iPhone 13 Pro',
        'iPhone14,3 ': ' iPhone 13 Pro Max',
        'iPhone14,4 ': ' iPhone 13 Mini',
        'iPhone14,5 ': ' iPhone 13',
        'iPhone14,6 ': ' iPhone SE 3rd Gen',
        'iPhone14,7 ': ' iPhone 14',
        'iPhone14,8 ': ' iPhone 14 Plus',
        'iPhone15,2 ': ' iPhone 14 Pro',
        'iPhone15,3 ': ' iPhone 14 Pro Max',
        'iPhone15,4 ': ' iPhone 15',
        'iPhone15,5 ': ' iPhone 15 Plus',
        'iPhone16,1 ': ' iPhone 15 Pro',
        'iPhone16,2 ': ' iPhone 15 Pro Max',
        'iPhone17,1 ': ' iPhone 16 Pro',
        'iPhone17,2 ': ' iPhone 16 Pro Max',
        'iPhone17,3 ': ' iPhone 16',
        'iPhone17,4 ': ' iPhone 16 Plus',
        'iPod1,1 ': ' 1st Gen iPod',
        'iPod2,1 ': ' 2nd Gen iPod',
        'iPod3,1 ': ' 3rd Gen iPod',
        'iPod4,1 ': ' 4th Gen iPod',
        'iPod5,1 ': ' 5th Gen iPod',
        'iPod7,1 ': ' 6th Gen iPod',
        'iPod9,1 ': ' 7th Gen iPod',
        'iPad1,1 ': ' iPad',
        'iPad1,2 ': ' iPad 3G',
        'iPad2,1 ': ' 2nd Gen iPad',
        'iPad2,2 ': ' 2nd Gen iPad GSM',
        'iPad2,3 ': ' 2nd Gen iPad CDMA',
        'iPad2,4 ': ' 2nd Gen iPad New Revision',
        'iPad3,1 ': ' 3rd Gen iPad',
        'iPad3,2 ': ' 3rd Gen iPad CDMA',
        'iPad3,3 ': ' 3rd Gen iPad GSM',
        'iPad2,5 ': ' iPad mini',
        'iPad2,6 ': ' iPad mini GSM+LTE',
        'iPad2,7 ': ' iPad mini CDMA+LTE',
        'iPad3,4 ': ' 4th Gen iPad',
        'iPad3,5 ': ' 4th Gen iPad GSM+LTE',
        'iPad3,6 ': ' 4th Gen iPad CDMA+LTE',
        'iPad4,1 ': ' iPad Air (WiFi)',
        'iPad4,2 ': ' iPad Air (GSM+CDMA)',
        'iPad4,3 ': ' 1st Gen iPad Air (China)',
        'iPad4,4 ': ' iPad mini Retina (WiFi)',
        'iPad4,5 ': ' iPad mini Retina (GSM+CDMA)',
        'iPad4,6 ': ' iPad mini Retina (China)',
        'iPad4,7 ': ' iPad mini 3 (WiFi)',
        'iPad4,8 ': ' iPad mini 3 (GSM+CDMA)',
        'iPad4,9 ': ' iPad Mini 3 (China)',
        'iPad5,1 ': ' iPad mini 4 (WiFi)',
        'iPad5,2 ': ' 4th Gen iPad mini (WiFi+Cellular)',
        'iPad5,3 ': ' iPad Air 2 (WiFi)',
        'iPad5,4 ': ' iPad Air 2 (Cellular)',
        'iPad6,3 ': ' iPad Pro (9.7 inch, WiFi)',
        'iPad6,4 ': ' iPad Pro (9.7 inch, WiFi+LTE)',
        'iPad6,7 ': ' iPad Pro (12.9 inch, WiFi)',
        'iPad6,8 ': ' iPad Pro (12.9 inch, WiFi+LTE)',
        'iPad6,11 ': ' iPad (2017)',
        'iPad6,12 ': ' iPad (2017)',
        'iPad7,1 ': ' iPad Pro 2nd Gen (WiFi)',
        'iPad7,2 ': ' iPad Pro 2nd Gen (WiFi+Cellular)',
        'iPad7,3 ': ' iPad Pro 10.5-inch 2nd Gen',
        'iPad7,4 ': ' iPad Pro 10.5-inch 2nd Gen',
        'iPad7,5 ': ' iPad 6th Gen (WiFi)',
        'iPad7,6 ': ' iPad 6th Gen (WiFi+Cellular)',
        'iPad7,11 ': ' iPad 7th Gen 10.2-inch (WiFi)',
        'iPad7,12 ': ' iPad 7th Gen 10.2-inch (WiFi+Cellular)',
        'iPad8,1 ': ' iPad Pro 11 inch 3rd Gen (WiFi)',
        'iPad8,2 ': ' iPad Pro 11 inch 3rd Gen (1TB, WiFi)',
        'iPad8,3 ': ' iPad Pro 11 inch 3rd Gen (WiFi+Cellular)',
        'iPad8,4 ': ' iPad Pro 11 inch 3rd Gen (1TB, WiFi+Cellular)',
        'iPad8,5 ': ' iPad Pro 12.9 inch 3rd Gen (WiFi)',
        'iPad8,6 ': ' iPad Pro 12.9 inch 3rd Gen (1TB, WiFi)',
        'iPad8,7 ': ' iPad Pro 12.9 inch 3rd Gen (WiFi+Cellular)',
        'iPad8,8 ': ' iPad Pro 12.9 inch 3rd Gen (1TB, WiFi+Cellular)',
        'iPad8,9 ': ' iPad Pro 11 inch 4th Gen (WiFi)',
        'iPad8,10 ': ' iPad Pro 11 inch 4th Gen (WiFi+Cellular)',
        'iPad8,11 ': ' iPad Pro 12.9 inch 4th Gen (WiFi)',
        'iPad8,12 ': ' iPad Pro 12.9 inch 4th Gen (WiFi+Cellular)',
        'iPad11,1 ': ' iPad mini 5th Gen (WiFi)',
        'iPad11,2 ': ' iPad mini 5th Gen',
        'iPad11,3 ': ' iPad Air 3rd Gen (WiFi)',
        'iPad11,4 ': ' iPad Air 3rd Gen',
        'iPad11,6 ': ' iPad 8th Gen (WiFi)',
        'iPad11,7 ': ' iPad 8th Gen (WiFi+Cellular)',
        'iPad12,1 ': ' iPad 9th Gen (WiFi)',
        'iPad12,2 ': ' iPad 9th Gen (WiFi+Cellular)',
        'iPad14,1 ': ' iPad mini 6th Gen (WiFi)',
        'iPad14,2 ': ' iPad mini 6th Gen (WiFi+Cellular)',
        'iPad13,1 ': ' iPad Air 4th Gen (WiFi)',
        'iPad13,2 ': ' iPad Air 4th Gen (WiFi+Cellular)',
        'iPad13,4 ': ' iPad Pro 11 inch 5th Gen',
        'iPad13,5 ': ' iPad Pro 11 inch 5th Gen',
        'iPad13,6 ': ' iPad Pro 11 inch 5th Gen',
        'iPad13,7 ': ' iPad Pro 11 inch 5th Gen',
        'iPad13,8 ': ' iPad Pro 12.9 inch 5th Gen',
        'iPad13,9 ': ' iPad Pro 12.9 inch 5th Gen',
        'iPad13,10 ': ' iPad Pro 12.9 inch 5th Gen',
        'iPad13,11 ': ' iPad Pro 12.9 inch 5th Gen',
        'iPad13,16 ': ' iPad Air 5th Gen (WiFi)',
        'iPad13,17 ': ' iPad Air 5th Gen (WiFi+Cellular)',
        'iPad13,18 ': ' iPad 10th Gen',
        'iPad13,19 ': ' iPad 10th Gen',
        'iPad14,3 ': ' iPad Pro 11 inch 4th Gen',
        'iPad14,4 ': ' iPad Pro 11 inch 4th Gen',
        'iPad14,5 ': ' iPad Pro 12.9 inch 6th Gen',
        'iPad14,6 ': ' iPad Pro 12.9 inch 6th Gen',
        'iPad14,8 ': ' iPad Air 6th Gen',
        'iPad14,9 ': ' iPad Air 6th Gen',
        'iPad14,10 ': ' iPad Air 7th Gen',
        'iPad14,11 ': ' iPad Air 7th Gen',
        'iPad16,3 ': ' iPad Pro 11 inch 5th Gen',
        'iPad16,4 ': ' iPad Pro 11 inch 5th Gen',
        'iPad16,5 ': ' iPad Pro 12.9 inch 7th Gen',
        'iPad16,6 ': ' iPad Pro 12.9 inch 7th Gen',
        'Watch1,1 ': ' Apple Watch 38mm case',
        'Watch1,2 ': ' Apple Watch 42mm case',
        'Watch2,6 ': ' Apple Watch Series 1 38mm case',
        'Watch2,7 ': ' Apple Watch Series 1 42mm case',
        'Watch2,3 ': ' Apple Watch Series 2 38mm case',
        'Watch2,4 ': ' Apple Watch Series 2 42mm case',
        'Watch3,1 ': ' Apple Watch Series 3 38mm case (GPS+Cellular)',
        'Watch3,2 ': ' Apple Watch Series 3 42mm case (GPS+Cellular)',
        'Watch3,3 ': ' Apple Watch Series 3 38mm case (GPS)',
        'Watch3,4 ': ' Apple Watch Series 3 42mm case (GPS)',
        'Watch4,1 ': ' Apple Watch Series 4 40mm case (GPS)',
        'Watch4,2 ': ' Apple Watch Series 4 44mm case (GPS)',
        'Watch4,3 ': ' Apple Watch Series 4 40mm case (GPS+Cellular)',
        'Watch4,4 ': ' Apple Watch Series 4 44mm case (GPS+Cellular)',
        'Watch5,1 ': ' Apple Watch Series 5 40mm case (GPS)',
        'Watch5,2 ': ' Apple Watch Series 5 44mm case (GPS)',
        'Watch5,3 ': ' Apple Watch Series 5 40mm case (GPS+Cellular)',
        'Watch5,4 ': ' Apple Watch Series 5 44mm case (GPS+Cellular)',
        'Watch5,9 ': ' Apple Watch SE 40mm case (GPS)',
        'Watch5,10 ': ' Apple Watch SE 44mm case (GPS)',
        'Watch5,11 ': ' Apple Watch SE 40mm case (GPS+Cellular)',
        'Watch5,12 ': ' Apple Watch SE 44mm case (GPS+Cellular)',
        'Watch6,1 ': ' Apple Watch Series 6 40mm case (GPS)',
        'Watch6,2 ': ' Apple Watch Series 6 44mm case (GPS)',
        'Watch6,3 ': ' Apple Watch Series 6 40mm case (GPS+Cellular)',
        'Watch6,4 ': ' Apple Watch Series 6 44mm case (GPS+Cellular)',
        'Watch6,6 ': ' Apple Watch Series 7 41mm case (GPS)',
        'Watch6,7 ': ' Apple Watch Series 7 45mm case (GPS)',
        'Watch6,8 ': ' Apple Watch Series 7 41mm case (GPS+Cellular)',
        'Watch6,9 ': ' Apple Watch Series 7 45mm case (GPS+Cellular)',
        'Watch6,10 ': ' Apple Watch SE 40mm case (GPS)',
        'Watch6,11 ': ' Apple Watch SE 44mm case (GPS)',
        'Watch6,12 ': ' Apple Watch SE 40mm case (GPS+Cellular)',
        'Watch6,13 ': ' Apple Watch SE 44mm case (GPS+Cellular)',
        'Watch6,14 ': ' Apple Watch Series 8 41mm case (GPS)',
        'Watch6,15 ': ' Apple Watch Series 8 45mm case (GPS)',
        'Watch6,16 ': ' Apple Watch Series 8 41mm case (GPS+Cellular)',
        'Watch6,17 ': ' Apple Watch Series 8 45mm case (GPS+Cellular)',
        'Watch6,18 ': ' Apple Watch Ultra',
        'Watch7,1 ': ' Apple Watch Series 9 41mm case (GPS)',
        'Watch7,2 ': ' Apple Watch Series 9 45mm case (GPS)',
        'Watch7,3 ': ' Apple Watch Series 9 41mm case (GPS+Cellular)',
        'Watch7,4 ': ' Apple Watch Series 9 45mm case (GPS+Cellular)',
        'Watch7,5 ': ' Apple Watch Ultra 2'
    };
    return modelMap[unfriendlyModel] || unfriendlyModel;
}

export function getTimeDifferenceFromNow(time) {
    return formatsSecondsToTime(
        moment(new Date())
            .diff(moment(time), 'seconds')
            .toString()
    );
}
