import React, { useMemo, useContext } from 'react';
import mustache from 'mustache';
import _ from 'lodash';

import { _user } from 'std';

import { aboveETransferLimit, formatAsCurrency, isEXPRegion } from 'utils/misc';

import { canRedeemWithoutDonation } from 'helpers/redemptionHelper';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import { withMobileDialog, Dialog, MobileStepper, Collapse } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import Pending from './screens/Pending';
import Insufficient from './screens/Insufficient';
import Donations from './screens/Donations';
import Cashout from './screens/Cashout';
import AusDirectBanking from './screens/AusDirectBanking';
import BankTransfer from './screens/BankTranfer';
import InteracETransfer from './screens/InteracETransfer';
import Cheque from './screens/Cheque';
import Summary from './screens/Summary';
import ConfirmationDialog from './screens/ConfirmationDialog';
import InfoDialog from './screens/InfoDialog';
import NotApproved from './screens/NotApproved';

import useRedemptionDialog from './hooks/useRedemptionDialog';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const paymentTypeText = {
    INTERAC_ETRANSFERS: 'Interac e-Transfer',
    CHEQUE: 'cheque',
    AUS_DIRECT_BANKING: 'Australian direct banking',
    CASH: 'cash',
    BANK_TRANSFER: 'Bank transfer'
};

function RedemptionDialog({
    http,
    operator,
    google,
    customer,
    charity_id,
    collectors,
    theme,
    fullScreen,
    open,
    onClose,
    redemptions,
    redemptionOptions,
    email,
    accountName,
    redemptionPending,
    charities,
    minRedemptionCustomer,
    minRedemptionCharity,
    redemptionFee,
    // donationDisabled,
    //banking,
    balance,
    redemptionType,
    charity,
    charityPreferred,
    subdivisionPreferred,
    onDonateSuccess,
    onRedeemSuccess,
    reloadCharity,
    reloadCustomer,
    lastPickup,
    lockDonationPreference,
    disablingAccount,
    deletingAccount,
    charityEnabled,
    emailVerificationRequired,
    emailVerificationRequiredForETransfer,
    handleVerifyEmailDialog,
    growthPlanEnabled
}) {
    const banking = useMemo(() => {
        const redemptionsWithBanking = _.filter(redemptions, redemption => !_.isEmpty(_.get(redemption, 'banking')));
        return _.get(_.last(redemptionsWithBanking), 'banking', null);
    }, [redemptions]);

    const {
        amountPending,
        paymentModel,
        emailForAPIRedemption,
        inProgress,
        activeStep,
        donate,
        modifiedRedemptionFee,
        redemptionForm,
        charityPref,
        subdivisionPref,
        taxReceiptRequested,
        customerLocation,
        infoDialogOpen,
        cashedOutBy,
        confirmationDialogOpen,
        securityAnswerDialogOpen,
        askForPreviousBankInfo,
        usePreviousBankingInfo,
        formErrors,
        loading,
        handlePaymentModelChange,
        handleChangeCashedOutBy,
        handleNext,
        handleBack,
        toggleDonate,
        handleChange,
        handleCharityPreferred,
        handleSubdivisionPreferred,
        handleUsePreviousBankingInformation,
        toggleTaxReceiptRequested,
        handleInfoDialog,
        handleConfirmationDialog,
        handleSecurityAnswerDialog,
        handleRedeem,
        handleSetDonation,
        handleCustomerLocation,
        handleCustomerLocationUnit,
        handleSetPaymentModel,
        handleEmailForAPIRedemption,
        securityQuestions
    } = useRedemptionDialog({
        http,
        customer,
        redemptionFee,
        redemptionType,
        redemptionOptions,
        charity_id,
        charities,
        redemptions,
        redemptionPending,
        email,
        authorizedEmails: _.get(customer, 'authorizedEmails', [])
            .filter(authorization => authorization.authorized)
            .map(authorization => authorization.email),
        accountName,
        charityPreferred,
        subdivisionPreferred,
        onDonateSuccess,
        onRedeemSuccess,
        reloadCharity,
        reloadCustomer,
        banking,
        open,
        deletingOrDisablingAccount: deletingAccount || disablingAccount,
        emailVerificationRequired,
        emailVerificationRequiredForETransfer,
        handleVerifyEmailDialog,
        charityEnabled
    });

    const { lang } = useContext(LocalizationContext);
    const sysAdmin = _user.isSystemAdmin(operator) || _user.isInternalRole(operator);
    const collAdmin = _user.isCollectorAdmin(operator);
    const customerCanRedeemWithoutDonation = canRedeemWithoutDonation(
        customer,
        balance,
        minRedemptionCustomer,
        modifiedRedemptionFee
    );
    const charityCanRedeemWithoutDonation = canRedeemWithoutDonation(
        charity,
        balance,
        minRedemptionCharity,
        modifiedRedemptionFee
    );
    const canRedeemWithoutDonationFlag =
        redemptionType === 'charity' ? charityCanRedeemWithoutDonation : customerCanRedeemWithoutDonation;

    const isAboveETransferLimit = aboveETransferLimit(balance, redemptions, redemptionOptions[redemptionType]);

    const charitySelected = _.find(charities, charity => charity._id === charityPref);
    const charityNameSelected = !_.isNil(charitySelected) ? charitySelected.name : null;
    const confirmationText = donate
        ? loc('redemption62', lang, {
              balance: formatAsCurrency(balance, lang),
              charity: charityNameSelected
          })
        : loc('redemption50', lang, {
              balance: formatAsCurrency(balance, lang),
              charity: _.get(redemptionForm, paymentModel === 'CHEQUE' ? 'street' : 'email', email),
              method: paymentTypeText[paymentModel]
          });

    useMemo(() => {
        let options = redemptionType === 'charity' ? redemptionOptions.charity : redemptionOptions.customer;
        if (collAdmin || (sysAdmin && !canRedeemWithoutDonationFlag)) {
            handleSetPaymentModel('CASH');
            return;
        }

        let minimum = 0;
        if (options) {
            switch (paymentModel) {
                case 'INTERAC_ETRANSFERS':
                    minimum = parseInt(options.eTransferMinimum) * 100;
                    break;
                case 'CHEQUE':
                    minimum = parseInt(options.chequeMinimum) * 100;
                    break;
                case 'BANK_TRANSFER':
                    minimum = parseInt(options.bankTransferMinimum) * 100;
                    break;
                case 'CASH':
                    minimum = parseInt(options.cashMinimum) * 100;
                    break;
                case 'AUS_DIRECT_BANKING':
                    minimum = parseInt(options.directDepositMinimum) * 100;
                    break;
                default:
                    minimum = 0;
            }
        }

        if (paymentModel === 'INTERAC_ETRANSFERS' && isAboveETransferLimit) {
            handleSetPaymentModel('NONE');
            return;
        }

        handleSetPaymentModel(balance < minimum ? 'NONE' : paymentModel);
    }, [balance, redemptionOptions, canRedeemWithoutDonationFlag]);

    const firstStep = useMemo(
        () => (
            <Donations
                donate={donate}
                charities={charities}
                charityPreferred={charityPref}
                subdivisionPreferred={subdivisionPref}
                taxReceiptRequested={taxReceiptRequested}
                customerLocation={customerLocation}
                customer={customer}
                theme={theme}
                cashOutAvailable={sysAdmin || collAdmin}
                handleCharityPreferred={handleCharityPreferred}
                handleChange={handleSubdivisionPreferred}
                toggleDonate={toggleDonate}
                handleSetDonation={handleSetDonation}
                toggleTaxReceiptRequested={toggleTaxReceiptRequested}
                onCustomerLocation={handleCustomerLocation}
                onCustomerLocationUnit={handleCustomerLocationUnit}
                onClose={onClose}
                handleBack={handleBack}
                handleNext={handleNext}
                handleConfirmationDialog={handleConfirmationDialog}
                balance={balance}
                minRedemption={minRedemptionCustomer}
                activeStep={activeStep}
                http={http}
                google={google}
                hasLastPickup={!_.isNil(lastPickup)}
                redemptionFee={modifiedRedemptionFee}
                lockDonationPreference={lockDonationPreference}
                disablingAccount={disablingAccount}
                deletingAccount={deletingAccount}
                loading={loading}
                growthPlanEnabled={growthPlanEnabled}
            />
        ),
        [
            donate,
            charities,
            charityPreferred,
            subdivisionPreferred,
            taxReceiptRequested,
            customerLocation,
            theme,
            handleCharityPreferred,
            handleChange,
            toggleDonate,
            handleSetDonation,
            toggleTaxReceiptRequested,
            handleConfirmationDialog,
            handleCustomerLocation,
            balance,
            minRedemptionCustomer,
            minRedemptionCharity
        ]
    );

    const secondStep = useMemo(
        () => (
            <Cashout
                operator={operator}
                theme={theme}
                balance={balance}
                customer={customer}
                charity={charity}
                collectors={collectors}
                handleBack={handleBack}
                handleNext={handleNext}
                handleChange={handlePaymentModelChange}
                handleChangeCashedOutBy={handleChangeCashedOutBy}
                paymentModel={paymentModel}
                cashedOutBy={cashedOutBy}
                minRedemptionCustomer={minRedemptionCustomer}
                minRedemptionCharity={minRedemptionCharity}
                modifiedRedemptionFee={modifiedRedemptionFee}
                redemptionOptions={redemptionOptions}
                redemptionType={redemptionType}
                activeStep={activeStep}
                canRedeemWithoutDonation={canRedeemWithoutDonationFlag}
                setPaymentModel={handleSetPaymentModel}
                redemptions={redemptions}
                loading={loading}
                disablingAccount={disablingAccount}
                deletingAccount={deletingAccount}
            />
        ),
        [handleBack, handleNext]
    );

    const thirdStep = useMemo(() => {
        switch (paymentModel) {
            case 'AUS_DIRECT_BANKING':
                return (
                    <AusDirectBanking
                        askForPreviousBankInfo={askForPreviousBankInfo}
                        theme={theme}
                        banking={banking}
                        handleUsePreviousBankingInformation={handleUsePreviousBankingInformation}
                        redemptionForm={redemptionForm}
                        handleChange={handleChange}
                        usePreviousBankingInfo={usePreviousBankingInfo}
                        formErrors={formErrors}
                        donationDisabled={!charityEnabled}
                        handleBack={handleBack}
                        handleNext={handleNext}
                    />
                );
            case 'INTERAC_ETRANSFERS':
                return (
                    <InteracETransfer
                        customer={customer}
                        charity={charity}
                        redemptionType={redemptionType}
                        theme={theme}
                        redemptionForm={redemptionForm}
                        formErrors={formErrors}
                        handleChange={handleChange}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        etransfername={loc('ETRANSFERS_NAME', lang)}
                        securityAnswerDialogOpen={securityAnswerDialogOpen}
                        handleSecurityAnswerDialog={handleSecurityAnswerDialog}
                        securityQuestions={securityQuestions}
                        onClose={onClose}
                        emailVerificationRequire={emailVerificationRequired}
                        emailVerificationRequiredForETransfer={emailVerificationRequiredForETransfer}
                        emailForAPIRedemption={emailForAPIRedemption}
                        handleEmailForAPIRedemption={handleEmailForAPIRedemption}
                        redemptionOptions={redemptionOptions}
                    />
                );
            case 'CHEQUE':
                return (
                    <Cheque
                        theme={theme}
                        redemptionForm={redemptionForm}
                        handleChange={handleChange}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        formErrors={formErrors}
                    />
                );

            case 'BANK_TRANSFER':
                return (
                    <BankTransfer
                        askForPreviousBankInfo={askForPreviousBankInfo}
                        theme={theme}
                        banking={banking}
                        handleUsePreviousBankingInformation={handleUsePreviousBankingInformation}
                        redemptionForm={redemptionForm}
                        handleChange={handleChange}
                        usePreviousBankingInfo={usePreviousBankingInfo}
                        formErrors={formErrors}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        donationDisabled={!charityEnabled}
                    />
                );
            default:
                return null;
        }
    }, [paymentModel, handleBack, handleNext]);

    const showTimeFrame = (timeframeToggles, paymentModel) => {
        switch (paymentModel) {
            case 'INTERAC_ETRANSFERS':
                return _.get(timeframeToggles, 'etransfer', false);
            case 'CHEQUE':
                return _.get(timeframeToggles, 'cheque', false);
            case 'BANK_TRANSFER':
                return _.get(timeframeToggles, 'bankTransfer', false);
            case 'CASH':
                return _.get(timeframeToggles, 'cash', false);
            case 'AUS_DIRECT_BANKING':
                return _.get(timeframeToggles, 'directDeposit', false);
            default:
                return false;
        }
    };

    const getTimeFrameText = (redemptionOptions, paymentModel, lang) => {
        const timeframeText = _.get(redemptionOptions, 'timeframeText', {});
        let processTimeObject = {
            time1: 1,
            time2: 1
        };
        let processTimeUnit = 'timeSpanHours';
        let processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
        switch (paymentModel) {
            case 'INTERAC_ETRANSFERS':
                const eTransferProcessingTimeUnit = _.get(redemptionOptions, 'eTransferProcessingTimeUnit');
                // original units

                processTimeObject = {
                    time1: _.get(redemptionOptions, 'eTransferProcessingTimeMin', 1),
                    time2: _.get(redemptionOptions, 'eTransferProcessingTimeMax', 1)
                };
                processTimeUnit = 'timeSpanBusinessHours';
                processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
                if (!_.isNil(eTransferProcessingTimeUnit) && !_.isEmpty(eTransferProcessingTimeUnit)) {
                    processTimeUnit = 'timeSpan' + eTransferProcessingTimeUnit;
                    processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
                }

                return mustache.render(_.get(timeframeText, `etransfer.${lang}`, ''), { processTimeMessage });
            case 'CHEQUE':
                const chequeProcessingTimeUnit = _.get(redemptionOptions, 'chequeProcessingTimeUnit');
                // original units

                processTimeObject = {
                    time1: _.get(redemptionOptions, 'chequeProcessingTimeMin', 1),
                    time2: _.get(redemptionOptions, 'chequeProcessingTimeMax', 1)
                };
                processTimeUnit = 'timeSpanWeeks';
                processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
                if (!_.isNil(chequeProcessingTimeUnit) && !_.isEmpty(chequeProcessingTimeUnit)) {
                    processTimeUnit = 'timeSpan' + chequeProcessingTimeUnit;
                    processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
                }

                return mustache.render(_.get(timeframeText, `cheque.${lang}`, ''), { processTimeMessage });
            case 'BANK_TRANSFER':
                const bankTransferProcessingTimeUnit = _.get(redemptionOptions, 'bankTransferProcessingTimeUnit');
                // original units

                processTimeObject = {
                    time1: _.get(redemptionOptions, 'bankTransferProcessingTimeMin', 1),
                    time2: _.get(redemptionOptions, 'bankTransferProcessingTimeMax', 1)
                };
                processTimeUnit = 'timeSpanWeeks';
                processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
                if (!_.isNil(bankTransferProcessingTimeUnit) && !_.isEmpty(bankTransferProcessingTimeUnit)) {
                    processTimeUnit = 'timeSpan' + bankTransferProcessingTimeUnit;
                    processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
                }

                return mustache.render(_.get(timeframeText, `bankTransfer.${lang}`, ''), { processTimeMessage });
            case 'CASH':
                return _.get(timeframeText, `cash.${lang}`, '');
            case 'AUS_DIRECT_BANKING':
                const directDepositProcessingTimeUnit = _.get(redemptionOptions, 'directDepositProcessingTimeUnit');
                // original units

                processTimeObject = {
                    time1: _.get(redemptionOptions, 'directDepositProcessingTimeMin', 1),
                    time2: _.get(redemptionOptions, 'directDepositProcessingTimeMax', 1)
                };
                processTimeUnit = 'timeSpanDays';
                processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
                if (!_.isNil(directDepositProcessingTimeUnit) && !_.isEmpty(directDepositProcessingTimeUnit)) {
                    processTimeUnit = 'timeSpan' + directDepositProcessingTimeUnit;
                    processTimeMessage = loc(processTimeUnit, lang, processTimeObject);
                }

                return mustache.render(_.get(timeframeText, `directDeposit.${lang}`, ''), { processTimeMessage });
            default:
                return '';
        }
    };

    const getMinMaxTimeValues = (minDays, maxDays) => {
        return {
            minHours: minDays * 24,
            maxHours: maxDays * 24,
            minDays,
            maxDays,
            minWeeks: _.round(minDays / 7),
            maxWeeks: _.round(maxDays / 7)
        };
    };

    const fourthStep = useMemo(
        () => (
            <Summary
                theme={theme}
                balance={balance}
                redemptionFee={modifiedRedemptionFee}
                paymentModel={paymentModel}
                handleInfoDialog={handleInfoDialog}
                infoDialogOpen={infoDialogOpen}
                handleConfirmationDialog={handleConfirmationDialog}
                handleBack={handleBack}
                etransfername={loc('ETRANSFERS_NAME', lang)}
                disablingAccount={disablingAccount}
                deletingAccount={deletingAccount}
                confirmationText={confirmationText}
                handleRedeem={handleRedeem}
                loading={loading}
                showTimeFrame={
                    redemptionType === 'customer'
                        ? showTimeFrame(_.get(redemptionOptions, 'customer.timeframeToggles', {}), paymentModel)
                        : false
                }
                timeFrameText={getTimeFrameText(_.get(redemptionOptions, 'customer', {}), paymentModel, lang)}
            />
        ),
        [theme, balance, modifiedRedemptionFee, handleBack]
    );

    let steps = [secondStep, thirdStep, fourthStep];
    if (redemptionType !== 'charity' && charityEnabled) steps.unshift(firstStep);

    if (
        // !sysAdmin &&
        // !collAdmin &&
        !canRedeemWithoutDonation(
            redemptionType === 'charity' ? charity : customer,
            balance,
            redemptionType === 'charity' ? minRedemptionCharity : minRedemptionCustomer,
            modifiedRedemptionFee
        )
    ) {
        if (!charityEnabled) {
            steps = [];
        }

        steps.unshift(
            <Insufficient
                minRedemption={
                    redemptionType === 'charity'
                        ? !_.get(charity, 'bypassRedemptionMinimum', false)
                            ? minRedemptionCharity
                            : modifiedRedemptionFee + 1
                        : !_.get(customer, 'bypassRedemptionMinimum', false)
                        ? minRedemptionCustomer
                        : modifiedRedemptionFee + 1
                }
                donationDisabled={!charityEnabled}
                redemptionType={redemptionType}
                balance={balance}
                onClose={onClose}
                handleNext={handleNext}
            />
        );
    }

    if (redemptionPending) {
        steps = [];
        steps.unshift(
            <Pending
                amountPending={amountPending}
                onClose={onClose}
                redemptions={redemptions}
                redemptionOptions={_.get(redemptionOptions, redemptionType)}
                theme={theme}
            />
        );
    }

    if (charity && !charity.approved) {
        steps = [];
        steps.unshift(<NotApproved onClose={onClose} />);
    }

    if (!open) return null;

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                open={open}
                onClose={deletingAccount || disablingAccount ? () => {} : onClose}
            >
                <DialogTitlePrimary closeButtonShown onClose={onClose}>
                    {deletingAccount || disablingAccount
                        ? loc('redemption66', lang)
                        : isEXPRegion()
                        ? loc('cashOutExp', lang)
                        : loc('redemption1', lang)}
                </DialogTitlePrimary>
                {!isEXPRegion() && steps.length > 1 && (
                    <Collapse in={!donate}>
                        <MobileStepper
                            variant="dots"
                            steps={steps.length}
                            position="static"
                            activeStep={activeStep}
                            nextButton={<div style={{ height: theme.spacing.unit * 2 }} />}
                            backButton={<div style={{ height: theme.spacing.unit * 2 }} />}
                            style={{
                                backgroundColor: theme.palette.background.paper,
                                paddingTop: theme.spacing.unit * 4
                            }}
                        />
                    </Collapse>
                )}
                {/*<Collapse in={balance < minRedemption || redemptionPending}>
                    <div style={{ marginTop: theme.spacing.unit * 3 }} />                </Collapse>*/}
                <div>{steps[activeStep]}</div>
            </Dialog>
            <InfoDialog
                infoDialogOpen={infoDialogOpen}
                handleConfirmationDialog={handleConfirmationDialog}
                redemptionFee={modifiedRedemptionFee}
                formattedRedemptionFee={formatAsCurrency(modifiedRedemptionFee, lang)}
                handleInfoDialog={handleInfoDialog}
                paymentModel={paymentModel}
            />
            <ConfirmationDialog
                confirmationDialogOpen={confirmationDialogOpen}
                handleConfirmationDialog={handleConfirmationDialog}
                confirmationText={confirmationText}
                inProgress={inProgress}
                handleRedeem={handleRedeem}
            />
        </>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(RedemptionDialog));
