import React, { useContext } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import {
    DialogContent,
    Dialog,
    DialogActions,
    Button,
    withTheme,
    Grid,
    Avatar,
    InputAdornment,
    Tooltip,
    Icon
} from '@material-ui/core';

import { getTextInput, getSwitch } from '../helperFunctions';
import { _commodity } from 'std';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { convertToImportName } from 'utils/misc';
import LocalizedTextInput from '../Inputs/LocalizedTextInput';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { useMemo } from 'react';

function CollectorWarningsForm(props) {
    const { open, loading, onClose, onSubmit, theme, selectedWarning } = props;

    const { lang } = useContext(LocalizationContext);

    const handleSubmit = values => {
        const toSubmit = _.cloneDeep(values);
        toSubmit.iconName = convertToImportName(values.iconName);
        onSubmit(toSubmit);
    };

    const allIconNames = useMemo(
        () => Object.keys(allIcons).map(importName => convertImportToIconName(importName)),
        []
    );

    const formik = useFormik({
        initialValues: {
            _id: _.get(selectedWarning, '_id', null),
            disabled: _.get(selectedWarning, 'disabled', false),
            text: _.get(selectedWarning, 'text', ''),
            type: _.get(selectedWarning, 'type', ''),
            iconName: convertImportToIconName(_.get(selectedWarning, 'iconName', ''))
        },
        validationSchema: Yup.object({
            disabled: Yup.boolean(),
            text: Yup.string().required('You must enter a value.'),
            type: Yup.string().required('You must enter a value'),
            iconName: Yup.string()
                .transform((value, originalValue) => convertToImportName(originalValue))
                .oneOf(allIconNames.map(iconName => convertToImportName(iconName)), 'Invalid icon name')
                .required('You must enter an icon name')
        }),
        onSubmit: handleSubmit
    });

    return (
        <Dialog open={open}>
            <DialogContent>
                {getSwitch(theme, 'disabled', 'Disabled', formik)}
                <Grid item xs={12}>
                    <LocalizedTextInput
                        label="Warning Text"
                        value={_.get(formik, 'values.text')}
                        onChange={value => formik.setFieldValue('text', value)}
                        touched={_.get(formik, 'touched.text')}
                        error={_.get(formik, 'errors.text')}
                        infoText="The contents of the warning"
                    />
                </Grid>
                <Grid item xs={12}>
                    {getTextInput(
                        theme,
                        'type',
                        'Warning Type',
                        formik,
                        'text',
                        getInfoIcon('Type', 'Short description to be used when editing collector', theme)
                    )}
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: 'rgba(0,0,0,0)',
                                marginRight: theme.spacing.unit,
                                marginTop: theme.spacing.unit * 1.5
                            }}
                        >
                            <MDIcon
                                path={_.get(
                                    allIcons,
                                    convertToImportName(
                                        _.get(formik.values, 'iconName', _.get(selectedWarning, 'iconName', ''))
                                    )
                                )}
                                size={1.5}
                                color={'black'}
                            />
                        </Avatar>
                        {getTextInput(theme, 'iconName', 'Icon', formik)}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <ExternalLink text="Click here to see all icons" url="https://materialdesignicons.com/" />
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={loading} onClick={formik.handleSubmit} data-cy="service-fee-submit">
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function convertImportToIconName(importName) {
    return _.kebabCase(importName.substring(3));
}

export default withTheme()(CollectorWarningsForm);

function getInfoIcon(title, infoText, theme) {
    return {
        endAdornment: (
            <InputAdornment position="end">
                {!_.isEmpty(infoText) && (
                    <Tooltip
                        title={infoText}
                        data-cy={`config-${_.kebabCase(title)}-info-text`}
                        style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                    >
                        <Icon data-cy={`config-${_.kebabCase(title)}-info-icon`}>info</Icon>
                    </Tooltip>
                )}
            </InputAdornment>
        )
    };
}
