import React, { useContext, useState, useEffect } from 'react';

import _ from 'lodash';

import MDIcon from '@mdi/react';
import { mdiHandHeartOutline, mdiSprout } from '@mdi/js';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import charityTaskHelper from '../../helpers/charityTaskHelper';
import PropTypes from 'prop-types';
import { getTaxableBulksByCustomer } from 'helpers/charityTaxReceiptsHelper';
import HttpContext from 'utils/contexts/HttpContext';
import moment from 'moment-timezone';
import { Badge, Chip, Icon, colors, Avatar, withTheme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto'
    },
    chip: {
        margin: theme.spacing.unit / 2,
        borderRadius: '4px',
        width: '90px',
        height: '90px'
    },
    labelContainer: {
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    span: {
        whiteSpace: 'normal',
        fontSize: '12px',
        textAlign: 'center'
    },
    badgeSuccess: {
        backgroundColor: '#4dae51',
        color: 'white'
    },
    badgeGrowthSuccess: {
        backgroundColor: '#4dae51',
        color: 'white',
        border: '1px solid white'
    },
    badgeFail: {
        backgroundColor: '#ff0000',
        color: 'white'
    }
});
const TODAY = moment().tz(process.env.REACT_APP_REGION_TIMEZONE);

const CURRENT_YEAR = moment()
    .tz(process.env.REACT_APP_REGION_TIMEZONE)
    .year();

const CURRENT_YEAR_END = moment()
    .tz(process.env.REACT_APP_REGION_TIMEZONE)
    .endOf('year');
function CharityActionChips(props) {
    const {
        classes,
        charity,
        customersDonating,
        shares,
        onShareDialogOpen,
        onCharityGrowthPlan,
        shareDialogEnabled,
        onRedirectToPage,
        theme,
        bulks,
        growthPlanEnabled
    } = props;
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);

    const countComplete = charityTaskHelper.numberOfTasksComplete(_.get(charity, 'charityTasks', []));
    const [charityEndOfTaxYear, setCharityEndOfTaxYear] = useState(charity.taxReceiptsEndOfYear);

    let endOfTaxYear = moment(charityEndOfTaxYear || CURRENT_YEAR_END)
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .set('year', CURRENT_YEAR)
        .endOf('day');

    if (moment(endOfTaxYear).isBefore(TODAY)) {
        endOfTaxYear = moment(endOfTaxYear).set('year', CURRENT_YEAR + 1);
    }

    const lastTaxableYear = moment(endOfTaxYear).year();

    const [taxData, setTaxData] = useState([]);
    const updatedTaxData = getTaxableBulksByCustomer(charity, bulks, endOfTaxYear, lastTaxableYear);
    let outstandingTaxReceipts = 0;
    updatedTaxData.forEach((value, key) => {
        outstandingTaxReceipts += value.taxableCount;
    });

    return (
        <div className={classes.chipContainer}>
            <Chip
                label={
                    <div className={classes.labelContainer}>
                        <Badge
                            classes={
                                customersDonating > 0 ? { badge: classes.badgeSuccess } : { badge: classes.badgeFail }
                            }
                            badgeContent={customersDonating}
                            showZero
                            overlap="circular"
                        >
                            <MDIcon path={mdiHandHeartOutline} size={1.5} color="white" />
                        </Badge>
                        <span className={classes.span}>{loc('dashboardMain10', lang)}</span>
                    </div>
                }
                className={classes.chip}
                style={{ backgroundColor: '#d09834' }}
                onClick={() => onRedirectToPage('charityDonations')}
            />
            {charity.taxReceiptsIssued && (
                <Chip
                    label={
                        <div className={classes.labelContainer}>
                            <Badge
                                classes={
                                    outstandingTaxReceipts == 0
                                        ? { badge: classes.badgeSuccess }
                                        : { badge: classes.badgeFail }
                                }
                                badgeContent={outstandingTaxReceipts}
                                showZero
                                style={{ marginTop: '10px' }}
                            >
                                <Icon style={{ color: 'black', fontSize: '40px' }}>receipt</Icon>
                            </Badge>
                            <span className={classes.span} style={{ color: 'black' }}>
                                {loc('taxReceipts', lang)}
                            </span>
                        </div>
                    }
                    className={classes.chip}
                    style={{ backgroundColor: '#edd847' }}
                    onClick={() => onRedirectToPage('charityReceipts')}
                />
            )}
            {shareDialogEnabled && (
                <Chip
                    label={
                        <div className={classes.labelContainer}>
                            <Badge
                                classes={shares > 0 ? { badge: classes.badgeSuccess } : { badge: classes.badgeFail }}
                                badgeContent={shares}
                                showZero
                            >
                                <Icon style={{ fontSize: '40px' }}>share</Icon>
                            </Badge>
                            <span className={classes.span}>{loc('labels5', lang)}</span>
                        </div>
                    }
                    className={classes.chip}
                    style={{ backgroundColor: '#37a4dd' }}
                    onClick={onShareDialogOpen}
                />
            )}
            {growthPlanEnabled && (
                <Chip
                    label={
                        <div className={classes.labelContainer}>
                            <Badge
                                classes={
                                    countComplete >= 5
                                        ? { badge: classes.badgeGrowthSuccess }
                                        : { badge: classes.badgeFail }
                                }
                                badgeContent={countComplete}
                                showZero
                                style={{ marginTop: '10px' }}
                            >
                                <MDIcon path={mdiSprout} size={1.5} color="white" />
                            </Badge>
                            <span className={classes.span}>{loc('labels22', lang)}</span>
                        </div>
                    }
                    className={classes.chip}
                    style={{ backgroundColor: '#4dae51' }}
                    onClick={
                        !_.isNil(onCharityGrowthPlan)
                            ? event => {
                                  event.stopPropagation();
                                  onCharityGrowthPlan(true)();
                              }
                            : undefined
                    }
                />
            )}
        </div>
    );
}

CharityActionChips.propTypes = {
    classes: PropTypes.object.isRequired,
    charity: PropTypes.object,
    customersDonating: PropTypes.number.isRequired,
    shares: PropTypes.number.isRequired,
    onShareDialogOpen: PropTypes.func.isRequired,
    shareDialogEnabled: PropTypes.bool.isRequired,
    onRedirectToPage: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles)(CharityActionChips);
