import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { getDistanceFromLatLonInKm } from 'utils/latlngFunctions';
import { formatsSecondsToTime, getQuickDropName } from 'utils/misc';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Icon,
    colors,
    withTheme,
    Typography,
    DialogContentText,
    TextField,
    CircularProgress,
    IconButton,
    Divider,
    Paper
} from '@material-ui/core';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';

import { deviceHelper } from 'utils/misc';
import { TextToSpeech } from '@capacitor-community/text-to-speech';

const OpenAccessDoor = ({
    customerLocation,
    onSnackbar,
    onBack,
    onNext,
    selectedDropLocation,
    onRefreshLocation,
    http,
    inProgress,
    setInProgress,
    overrideLocation,
    onOverrideLocation,
    GPSLoading,
    isLastStep,
    theme,
    customer,
    kantechPhoneNumber,
    serviceEmail,
    servicePhone,
    onAccessDoorCode,
    onResetAccessDoorCode,
    accessDoorCode,
    accessDoorCodeExpiry
}) => {
    const { lang } = useContext(LocalizationContext);
    const [overrideCodeDialogOpen, setOverrideCodeDialogOpen] = useState(false);
    const [overrideCode, setOverrideCode] = useState('');
    const [overrideCodeError, setOverrideCodeError] = useState(null);

    const [showWarning, setShowWarning] = useState(false);

    const [warningTitle, setWarningTitle] = useState(null);
    const [warningText, setWarningText] = useState(null);
    const [warningColor, setWarningColor] = useState(colors.orange[500]);

    const [gpsRequired, setGPSRequired] = useState(_.get(selectedDropLocation, 'gpsRequired', true));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initialize = async () => {
            await handleRequestPin();
            onRefreshLocation();
        };
        initialize();
    }, []);

    const getProximityRadius = async () => {
        const res = await http.getJSON('/config/proximityCheck', false, true);
        if (res.ok) {
            return res.data.proximityCheck.distance;
        } else {
            return 20;
        }
    };

    const handleVerifyOverrideCode = async () => {
        setInProgress(true);
        const res = await http.postJSON(
            `/collectors/${selectedDropLocation.collector._id}/verifyQuickdropOverrideCode`,
            {
                code: overrideCode
            }
        );
        if (res.ok) {
            if (res.data.valid) {
                onOverrideLocation(true);
                setOverrideCodeDialogOpen(false);
                onSnackbar('Override code was valid');
            } else {
                setOverrideCodeError(res.data.errorMessage);
            }
        } else {
            onSnackbar('error submitting code', 'error');
        }
        setInProgress(false);
    };

    const handleDisplayWarning = proximityRadius => {
        if (!overrideLocation) {
            if (_.isNil(customerLocation)) {
                setWarningTitle(
                    <Typography variant="h6" style={{ color: 'white' }}>
                        <b>{loc('pickupDialogDrop12', lang)}</b>
                    </Typography>
                );
                setWarningText(<Typography style={{ color: 'white' }}>{loc('pickupDialogDrop13', lang)}</Typography>);
                setWarningColor(colors.red[500]);
                setShowWarning(true);
                return true;
            } else {
                const distanceMeters =
                    getDistanceFromLatLonInKm(
                        selectedDropLocation.location.lat,
                        selectedDropLocation.location.lng,
                        customerLocation.lat,
                        customerLocation.lng
                    ) * 1000;

                if (distanceMeters > proximityRadius) {
                    setWarningTitle(
                        <Typography variant="h6" style={{ color: 'white' }}>
                            <b>{loc('youAreToFarAway', lang)}</b>
                        </Typography>
                    );
                    setShowWarning(true);
                    return true;
                }
                return false;
            }
        }
        setShowWarning(true);
        return true;
    };

    const handleRequestAccessDoorCode = async () => {
        const res = await http.getJSON(`/dropLocations/${customer._id}/getAccessDoorCode`, true);
        if (res.ok) {
            onAccessDoorCode({
                accessDoorCode: _.get(res.data, 'accessDoorCode', ''),
                accessDoorCodeExpiry: moment(_.get(res.data, 'accessDoorCodeExpiry', ''))
            });
            setTimeout(() => {
                onResetAccessDoorCode();
            }, Math.abs(moment().diff(moment(_.get(res.data, 'accessDoorCodeExpiry', '')), 'seconds')) * 1000);
        } else {
            onSnackbar(_.get(res, 'errorMessage', 'Error fetching one-time PIN'), 'error');
        }
    };

    const handleRequestPin = async () => {
        var showWarning = false;

        if (_.isNil(_.get(customer, 'kantechPasscode', null))) {
            showWarning = true;
            setShowWarning(true);
        } else {
            if (gpsRequired) {
                const proximityRadius = await getProximityRadius();
                showWarning = handleDisplayWarning(proximityRadius);
            }
        }

        if (!showWarning) {
            await handleRequestAccessDoorCode();
        }
        setLoading(false);
    };

    const textToSpeech = async string => {
        try {
            if (deviceHelper.isNativeApp()) {
                // Support for ios/android app
                await TextToSpeech.speak({
                    rate: 0.6,
                    text: string,
                    locale: `${lang}-CA`
                });

                return;
            }

            const msg = new SpeechSynthesisUtterance();
            msg.rate = 0.6;
            msg.text = string;
            msg.lang = `${lang}-CA`;

            window.speechSynthesis.cancel(); // Clears any previous utterances
            window.speechSynthesis.speak(msg);
        } catch (err) {
            // console.error(err);
        }
    };

    if (loading) {
        return (
            <>
                <Paper
                    elevation={0}
                    style={{
                        minHeight: '80px',
                        padding: theme.spacing.unit * 2,
                        margin: theme.spacing.unit * 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress size={32} />
                </Paper>
            </>
        );
    }
    return (
        <>
            <DialogContent style={{ marginTop: theme.spacing.unit }}>
                <CustomFormTitle titleText={loc('pickupDialogDrop44', lang)} iconName="fiber_pin" />
                {_.isNil(_.get(customer, 'kantechPasscode', null)) ? (
                    <DialogContentText
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        {lang === 'fr' ? (
                            <>
                                Votre compte n'est actuellement pas configuré pour ouvrir la porte d'accès. Veuillez
                                contacter le support à l'adresse <b>{serviceEmail}</b> ou nous appeler au{' '}
                                <b>{servicePhone}</b>.
                            </>
                        ) : (
                            <>
                                Your account is currently not configured to open the Access Door. Please contact support
                                at <b>{serviceEmail}</b> or call as at <br /> <b>{servicePhone}</b>.
                            </>
                        )}
                    </DialogContentText>
                ) : showWarning ? (
                    <DialogContentText
                        style={{
                            marginBottom: theme.spacing.unit * 2,
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        {/* {loc('pickupDialogDrop45', lang, {
                            kantechPasscode: _.get(customer, 'kantechPasscode', 'N/A'),
                            kantechPhoneNumber: kantechPhoneNumber
                        })} */}
                        {lang === 'fr' ? (
                            <>
                                Envoyez <b>{_.get(customer, 'kantechPasscode', 'N/A')}</b> à <b>{kantechPhoneNumber}</b>{' '}
                                pour recevoir votre code PIN à usage unique
                            </>
                        ) : (
                            <>
                                Text <b>{_.get(customer, 'kantechPasscode', 'N/A')}</b> to <b>{kantechPhoneNumber}</b>{' '}
                                to receive your one-time PIN
                            </>
                        )}

                        <div
                            style={{
                                marginTop: theme.spacing.unit * 2,
                                marginBottom: theme.spacing.unit * 2,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Typography variant="h4">{_.get(customer, 'kantechPasscode', 'N/A')}</Typography>
                            <div style={{ marginTop: 6 }}>
                                <IconButton
                                    onClick={() =>
                                        textToSpeech(
                                            _.isNil(customer.kantechPasscode)
                                                ? 'not available'
                                                : _.get(customer, 'kantechPasscode', '')
                                                      .split('')
                                                      .join('. ')
                                        )
                                    }
                                    style={{ padding: 0, margin: 0 }}
                                >
                                    <Icon>volume_up</Icon>
                                </IconButton>
                            </div>
                        </div>
                    </DialogContentText>
                ) : !_.isNil(accessDoorCode) && !_.isEmpty(accessDoorCode) ? (
                    <div>
                        <CustomWrapper title={loc('pickupDialogDrop46', lang)}>
                            <div
                                fullwidth
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: theme.spacing.unit
                                }}
                            >
                                <Typography variant="h4">{accessDoorCode}</Typography>
                            </div>
                        </CustomWrapper>
                        {
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ float: 'right', fontSize: '60%' }}
                            >
                                {loc('pickupDialogDrop47', lang)} <WaitToRetry time={accessDoorCodeExpiry} />
                            </Typography>
                        }
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            disabled={
                                !_.isNil(accessDoorCode) && !_.isEmpty(accessDoorCode) && !_.isNil(accessDoorCodeExpiry)
                            }
                            variant="contained"
                            color="primary"
                            onClick={handleRequestPin}
                            fullWidth
                            style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                        >
                            {loc('pickupDialogDrop44', lang)}
                        </Button>
                    </div>
                )}

                {/* <div style={{ display: 'flex', marginTop: theme.spacing.unit * 2 }}>
                        <Divider style={{ flex: 2, marginTop: 12 }} />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                paddingRight: theme.spacing.unit,
                                paddingLeft: theme.spacing.unit
                            }}
                        >
                            <Typography variant="body1" color="textSecondary" style={{ flex: 1 }}>
                                {loc('or', lang)}
                            </Typography>
                        </div>
                        <Divider style={{ flex: 2, marginTop: 12 }} />
                    </div> */}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button color="primary" onClick={onNext} data-cy="quickdrop-steps-next">
                    {loc(isLastStep ? 'close' : 'next', lang)}
                </Button>
            </DialogActions>
            <Dialog open={overrideCodeDialogOpen} onClose={() => setOverrideCodeDialogOpen(false)}>
                <DialogContent>
                    <Typography>{loc('pickupDialogDrop17', lang)}</Typography>
                    <TextField
                        margin="normal"
                        variant="outlined"
                        value={overrideCode}
                        helperText={overrideCodeError}
                        error={!_.isNil(overrideCodeError)}
                        label={loc('pickupDialogDrop18', lang)}
                        onChange={e => setOverrideCode(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={inProgress} onClick={() => setOverrideCodeDialogOpen(false)} color="primary">
                        {loc('Cancel', lang)}
                    </Button>
                    <Button
                        disabled={_.isEmpty(overrideCode) || inProgress}
                        onClick={handleVerifyOverrideCode}
                        color="primary"
                    >
                        <span style={{ marginRight: theme.spacing.unit }}>{loc('pickupDialogDrop19', lang)}</span>{' '}
                        {inProgress && <CircularProgress size={16} />}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* {showWarning && (
                <WarningDialog
                    onRefreshLocation={onRefreshLocation}
                    theme={theme}
                    GPSLoading={GPSLoading}
                    setOverrideCodeDialogOpen={setOverrideCodeDialogOpen}
                    lang={lang}
                    onBack={onBack}
                    showWarning={showWarning}
                    warningColor={warningColor}
                    warningText={warningText}
                    warningTitle={warningTitle}
                />
            )} */}
        </>
    );
};

export default withTheme()(OpenAccessDoor);

const WarningDialog = ({
    onRefreshLocation,
    theme,
    GPSLoading,
    setOverrideCodeDialogOpen,
    lang,
    onBack,
    showWarning,
    warningColor,
    warningText,
    warningTitle
}) => {
    return (
        <Dialog
            open={showWarning}
            PaperProps={{
                style: {
                    backgroundColor: warningColor
                }
            }}
        >
            <DialogTitle disableTypography>{warningTitle}</DialogTitle>
            <DialogContent>
                <div>{warningText}</div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBack} style={{ color: 'white' }}>
                    {loc('pickupDialogDrop16', lang)}
                </Button>
                <Button
                    data-cy="open-access-door-refresh-location"
                    onClick={onRefreshLocation}
                    size="small"
                    variant="outlined"
                    style={{
                        color: 'white',
                        borderColor: 'white'
                    }}
                    disabled={GPSLoading}
                >
                    <span style={{ marginRight: theme.spacing.unit }}>{loc('pickupDialogDrop15', lang)}</span>
                    {GPSLoading ? (
                        <CircularProgress size={22} style={{ color: 'white' }} />
                    ) : (
                        <Icon style={{ color: 'white' }}>refresh</Icon>
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

function WaitToRetry({ time }) {
    const [timeElapsed, setTimeElapsed] = useState(getTimeDifferenceFromNow(time));

    useEffect(() => {
        setInterval(() => {
            setTimeElapsed(getTimeDifferenceFromNow(time));
        }, 1000);
    });
    return <>{timeElapsed}</>;
}

function getTimeDifferenceFromNow(time) {
    return formatsSecondsToTime(
        moment(time)
            .diff(moment(new Date()), 'seconds')
            .toString()
    );
}
