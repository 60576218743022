import React, { Component } from 'react';

import _ from 'lodash';

import CharityInfoDialog from 'components/CharityInfoDialog/CharityInfoDialog';
import CharitySelectDialog from './CharitySelectDialog';

import GMapsAutocomplete from 'components/GMapsAutocomplete';

import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import { PermissionsConsumer } from 'utils/contexts/PermissionsContext';

import { loc } from 'localizations/localizationHandler';
import { isEXPRegion } from 'utils/misc';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

class CharityInfo extends Component {
    static contextType = LocalizationContext;
    state = {
        charitySelectDialogOpen: false,
        taxReceiptInfoDialogOpen: false,
        donationInfoDialogOpen: false,
        infoDialogOpen: false,
        charityInfo: undefined
    };

    handleCharitySelectDialog = state => () => {
        const { donate } = this.props;
        if (!donate) {
            this.setState({ charitySelectDialogOpen: state && !this.props.lockCharityDonationsToThisAccount });
        }
    };

    handleDonationInfoDialog = state => () => {
        this.setState({ donationInfoDialogOpen: state });
    };

    handleCharityPreferred = (_id, subdivision) => e => {
        this.setState({ charitySelectDialogOpen: false }, () => {
            this.props.onCharityPreferred(_id, subdivision)(e);
            this.props.onSetDonation(true);
        });
    };

    handleClearSelectedCharity = () => {
        this.props.onCharityPreferred()();
        this.props.onSetDonation(false);
    };

    handleInfoDialog = (state, _id) => () => {
        this.setState({
            infoDialogOpen: state,
            charityInfo: _.find(this.props.charities, charity => charity._id === _id)
        });
    };

    handleTaxReceiptInfoDialog = state => () => {
        this.setState({ taxReceiptInfoDialogOpen: state });
    };

    render() {
        const { lang } = this.context;

        const {
            theme,
            quickRegistration,
            donate,
            charities,
            charityPreferred,
            subdivisionPreferred,
            taxReceiptRequested,
            taxReceiptsDisabled,
            taxReceiptError,
            style,
            lockCharityDonationsToThisAccount,
            showAddressInputOnTaxRecipts,
            location,
            onLocation,
            onLocationUnit,
            hideTaxReciptAndSubdivision,
            hideTaxRecipt,
            inputStyle,
            TextFieldProps,
            http,
            google,
            regionToFilterFeatured = null,
            placeholder = null,
            label = loc('organizationName', lang, { organizationName: terms.ORGANIZATION_NAME }),
            lockDonationPreference,
            width,
            growthPlanEnabled
        } = this.props;

        const {
            charitySelectDialogOpen,
            taxReceiptInfoDialogOpen,
            infoDialogOpen,
            donationInfoDialogOpen,
            charityInfo
        } = this.state;

        let isSmallScreen = isWidthDown('xs', width);

        if (_.isNil(charities)) {
            console.error('Organizations have not loaded properly.');
            return null;
        }
        const charitySelected = _.find(charities, charity => charity._id === charityPreferred && charity.approved);
        if (_.isNil(charitySelected) && donate) this.handleClearSelectedCharity();
        const subdivisionsAvailable = !_.isNil(charitySelected) && !_.isEmpty(charitySelected.subdivisions);
        const taxReceiptsIssued = _.isEmpty(charitySelected) ? false : charitySelected.taxReceiptsIssued;
        const updatedSubdivisionPreferred = _.isNil(subdivisionPreferred)
            ? 'General Donation'
            : _.clone(subdivisionPreferred);

        const selectOrClearInputAdornment =
            !_.isNil(charitySelected) && donate ? (
                <InputAdornment position="end">
                    {lockCharityDonationsToThisAccount ? (
                        <IconButton disabled style={{ color: colors.grey[700] }}>
                            <Icon>lock</Icon>
                        </IconButton>
                    ) : (
                        <IconButton
                            data-cy="charity-select-clear"
                            style={{ color: colors.grey[500] }}
                            disabled={lockCharityDonationsToThisAccount || lockDonationPreference}
                            onClick={this.handleClearSelectedCharity}
                        >
                            <Icon>clear</Icon>
                        </IconButton>
                    )}
                </InputAdornment>
            ) : (
                <InputAdornment position="end">
                    <Icon>arrow_drop_down</Icon>
                </InputAdornment>
            );

        let taxReceiptInfo = null;
        if (taxReceiptsIssued && !hideTaxRecipt) {
            taxReceiptInfo = (
                <>
                    <PermissionsConsumer>
                        {permissionsContextValue => {
                            const allowTaxReceiptOptOut = _.get(
                                permissionsContextValue,
                                'customerPermissions.allowTaxReceiptOptOut',
                                true
                            );

                            if (!allowTaxReceiptOptOut) {
                                return <></>;
                            }

                            return (
                                <FormControl style={{ marginTop: theme.spacing.unit }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            data-cy="charity-select-tax-receipts-checkbox"
                                            checked={taxReceiptRequested}
                                            onChange={this.props.onToggleTaxReceiptRequested}
                                        />
                                        <Typography>{loc('pickupDialog63', lang)}</Typography>
                                        <IconButton
                                            style={{ marginLeft: theme.spacing.unit * 2 }}
                                            onClick={this.handleTaxReceiptInfoDialog(true)}
                                        >
                                            <Icon>help</Icon>
                                        </IconButton>
                                    </div>
                                </FormControl>
                            );
                        }}
                    </PermissionsConsumer>
                    {showAddressInputOnTaxRecipts && (
                        <Collapse in={taxReceiptRequested}>
                            <div style={{ display: isSmallScreen ? 'unset' : 'flex', marginTop: theme.spacing.unit }}>
                                <TextField
                                    data-cy="charitySelect-unit-input"
                                    name="location.unitNumber"
                                    label={loc('labels9', lang)}
                                    variant="outlined"
                                    value={_.get(location, 'unitNumber', '')}
                                    style={{ width: isSmallScreen ? '100%' : 150, marginRight: theme.spacing.unit }}
                                    onChange={e => onLocationUnit(e.target.value)}
                                />
                                <div
                                    style={{
                                        width: '100%',
                                        marginTop: isSmallScreen ? theme.spacing.unit * 2 : 'unset'
                                    }}
                                >
                                    <GMapsAutocomplete
                                        data-cy="pickupdialog-address-input"
                                        http={http}
                                        google={google}
                                        label={loc('dashboardSetting7', this.context.lang)}
                                        placeholder={loc('charitySelect1', this.context.lang)}
                                        selectedValue={_.get(location, 'description', '')}
                                        types={['address']}
                                        // restrictions={{ country: 'ca' }}
                                        onSuggestionSelected={onLocation}
                                        error={{
                                            fail: taxReceiptError,
                                            reason: loc('growthGeneral38', this.context.lang)
                                        }}
                                    />
                                </div>
                            </div>
                        </Collapse>
                    )}
                </>
            );
        } else if (!taxReceiptsIssued && !_.isNil(charitySelected)) {
            taxReceiptInfo = (
                <Typography color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                    {loc('dashboardUpdate4', this.context.lang, {
                        organization: terms.ORGANIZATION_NAME.toLowerCase()
                    })}
                </Typography>
            );
        }

        if (quickRegistration) {
            taxReceiptInfo = (
                <Typography color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                    {loc('dashboardUpdate8', this.context.lang)}
                </Typography>
            );
        }

        const placeholderText = _.isNil(placeholder)
            ? isEXPRegion()
                ? loc('noDonationExp', lang)
                : loc('noDonation', lang)
            : placeholder;

        return (
            <div style={style}>
                <FormControl fullWidth data-cy="charity-select-organization-select">
                    <TextField
                        id="outlined-select-charity"
                        data-cy="charity-select-org-select"
                        value={!_.isNil(charitySelected) && donate ? charitySelected.name : placeholderText}
                        onClick={this.handleCharitySelectDialog(true)}
                        disabled={lockCharityDonationsToThisAccount || lockDonationPreference}
                        InputProps={{
                            readOnly: true,
                            endAdornment: selectOrClearInputAdornment,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        style={{ color: isEXPRegion() ? theme.palette.primary.main : colors.red[500] }}
                                    >
                                        {donate
                                            ? 'favorite'
                                            : isEXPRegion()
                                            ? 'credit_card_outline'
                                            : 'favorite_border'}
                                    </Icon>
                                </InputAdornment>
                            )
                        }}
                        label={isEXPRegion() ? loc('redemption1Exp', lang) : label}
                        variant="outlined"
                        style={inputStyle}
                        {...TextFieldProps}
                        helperText={
                            lockDonationPreference
                                ? loc('donationPreferenceLockedMessage', lang, {
                                      brandname: process.env.REACT_APP_BRAND_NAME
                                  })
                                : ''
                        }
                    />
                </FormControl>

                <CharitySelectDialog
                    open={charitySelectDialogOpen}
                    charities={charities}
                    onChange={this.handleCharityPreferred}
                    onInfoDialog={this.handleInfoDialog}
                    onClose={this.handleCharitySelectDialog(false)}
                    hideSubdivisions={hideTaxReciptAndSubdivision}
                    regionToFilterFeatured={regionToFilterFeatured}
                    growthPlanEnabled={growthPlanEnabled}
                />
                <Collapse in={donate && !_.isNil(charitySelected) && !hideTaxReciptAndSubdivision}>
                    <Collapse in={subdivisionsAvailable}>
                        <FormControl
                            data-cy="charity-select-select-dropdown"
                            fullWidth
                            style={{ ...inputStyle, marginTop: theme.spacing.unit * 2 }}
                        >
                            <InputLabel ref={90} variant="outlined" htmlFor="subdivisionPreferred">
                                {loc('pickupDialog62', lang)}
                            </InputLabel>
                            <Select
                                value={updatedSubdivisionPreferred}
                                renderValue={value => `${value}`}
                                onChange={this.props.onChange}
                                input={
                                    <OutlinedInput
                                        labelWidth={180}
                                        name="subdivisionPreferred"
                                        id="subdivisionPreferred"
                                        data-cy="charity-select-team-dropdown"
                                        className="select-icon"
                                    />
                                }
                                disabled={lockDonationPreference}
                            >
                                <MenuItem value={null}>
                                    <ListItemText primary="General Donation" style={{ fontStyle: 'italic' }} />
                                </MenuItem>
                                {subdivisionsAvailable &&
                                    charitySelected.subdivisions.map((subdivision, index) => {
                                        return (
                                            <MenuItem
                                                data-cy={`charity-select-select-dropdown-team-${index}`}
                                                key={subdivision}
                                                value={subdivision}
                                            >
                                                {subdivision}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Collapse>
                    {taxReceiptInfo}
                    {/* {(lockCharityDonationsToThisAccount) &&
                        (<Typography color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                            All of your proceeds are locked to donate to your {terms.ORGANIZATION_NAME.toLowerCase()}.
                        </Typography>
                        )} */}
                </Collapse>

                <Dialog
                    open={taxReceiptInfoDialogOpen}
                    fullWidth
                    maxWidth="sm"
                    onClose={this.handleTaxReceiptInfoDialog(false)}
                >
                    <DialogTitle>{loc('pickupDialog64', lang)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {taxReceiptsDisabled ? (
                                <span>{loc('charitySelect2', this.context.lang)}</span>
                            ) : (
                                <span>
                                    {loc('pickupDialog65', lang, { appName: process.env.REACT_APP_BRAND_NAME })}
                                </span>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-cy="charity-select-close"
                            color="primary"
                            onClick={this.handleTaxReceiptInfoDialog(false)}
                        >
                            {loc('close', this.context.lang)}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={donationInfoDialogOpen}
                    fullWidth
                    maxWidth="sm"
                    onClose={this.handleDonationInfoDialog(false)}
                >
                    <DialogTitle>{loc('history2', this.context.lang)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {loc('charitySelect3', this.context.lang)} <br />
                            <span style={{ marginRight: 4, fontSize: '125%', color: theme.palette.primary.main }}>
                                ★
                            </span>
                            {loc('pickupDialog52', this.context.lang)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleDonationInfoDialog(false)}>
                            {loc('close', this.context.lang)}
                        </Button>
                    </DialogActions>
                </Dialog>

                <CharityInfoDialog open={infoDialogOpen} charity={charityInfo} onClose={this.handleInfoDialog(false)} />
            </div>
        );
    }
}

export default withWidth()(withTheme()(CharityInfo));
