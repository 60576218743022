import React, { useState, useContext, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import Badge from '../Badge/Badge';

import * as colors from '@material-ui/core/colors';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import MessageCollectorDialog from 'components/InputComponents/MessageCollectorDialog';

import { openWindow } from 'helpers/windowHelper';

import { getQuickDropName, getWalkInName, isAUSRegion, isEXPRegion, isCONRegion } from 'utils/misc';

import { loc, locDate } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import MultiSelect from 'components/InputComponents/MultiSelect';

import {
    mdiTruck,
    mdiTagArrowDown,
    mdiCurrencyUsd,
    mdiReceipt,
    mdiStore,
    mdiMapMarker,
    mdiQrcodeScan,
    mdiAlertCircleOutline,
    mdiNoteText,
    mdiEmoticonSadOutline,
    mdiEmoticonSad,
    mdiCashEdit
} from '@mdi/js';
import * as allIcons from '@mdi/js';

import MDIcon from '@mdi/react';

const ITEMS_SHOWN_INCREMENT = 10;

const filterIcons = {
    PICKUP: mdiTruck,
    REDEMPTION: mdiCurrencyUsd,
    TAXRECEIPT: mdiReceipt,
    WALKIN: isCONRegion() ? mdiQrcodeScan : mdiStore,
    INHOUSE: mdiTagArrowDown,
    ADJUSTMENT: mdiCashEdit,
    INVOICE: mdiReceipt,
    LABELS: mdiNoteText
};

function HistoryWidget(props) {
    const {
        theme,
        loading,
        items,
        defaultFilters,
        disableInAppRedemptionCancel,
        style,
        adminView,
        http,
        history,
        auth,
        reloadData,
        isCollectionProgramUser
    } = props;

    const { lang } = useContext(LocalizationContext);

    const uniqueAddresses = useMemo(() => {
        return _(items)
            .filter(item => !_.isEmpty(item.addressFilterVal))
            .map(item => item.addressFilterVal)
            .uniq()
            .value();
    }, [items]);

    const uniqueIssues = useMemo(() => {
        return adminView
            ? _(items)
                  .filter(item => !_.isEmpty(item.issues))
                  .map(item => item.issues)
                  .flatten()
                  .uniqBy('value')
                  .value()
            : [];
    }, [items]);

    const complaintsExist = useMemo(() => {
        return _.some(
            items,
            item => _.get(item, 'hasResolvedComplaints', false) || _.get(item, 'hasUnresolvedComplaints', false)
        );
    }, [items]);

    const allNonLocationFilters = useMemo(() => {
        const complaintFilters = complaintsExist
            ? [
                  { name: loc('unresolvedComplaints', lang), value: 'UNRESOLVEDCOMPLAINTS' },
                  { name: loc('resolvedComplaints', lang), value: 'RESOLVEDCOMPLAINTS' }
              ]
            : [];
        return [...defaultFilters, ...uniqueIssues, ...complaintFilters];
    }, [defaultFilters, uniqueIssues, complaintsExist, lang]);

    const onSnackbar = useContext(SnackbarContext);
    const warnAction = useContext(ConfirmDialogContext);

    const [itemsShown, setItemsShown] = useState(ITEMS_SHOWN_INCREMENT);
    const testMode = JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE);
    const [filters, setFilters] = useState(
        allNonLocationFilters
            .map(filter => filter.value)
            .filter(value => (isEXPRegion() && !testMode ? value !== 'LABELS' : true))
    );
    const [locationFilters, setLocationFilters] = useState(uniqueAddresses);
    const [bagtagFilter, setBagtagFilter] = useState('');
    const [collapse, setCollapse] = useState([]);

    const [adjustmentHelpDialogOpen, setAdjustmentHelpDialogOpen] = useState(false);
    const [adjustmentHelpDialogItem, setAdjustmentHelpDialogItem] = useState({});
    const [messageCollectorDialogOpen, setMessageCollectorDialogOpen] = useState(false);
    const [messageCollectorSelectedItem, setMessageCollectorSelectedItem] = useState(null);

    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imagesToView, setImagesToView] = useState([]);

    const toggleFilter = type => () => {
        let filtersUpdated = _.cloneDeep(filters);
        if (filtersUpdated.includes(type)) {
            filtersUpdated = _.without(filtersUpdated, type);
        } else {
            filtersUpdated.push(type);
        }
        setFilters(filtersUpdated);
    };

    const toggleCollapse = key => () => {
        let collapseUpdated;
        if (collapse.includes(key)) {
            collapseUpdated = _.without(collapse, key);
        } else {
            collapseUpdated = _.concat(collapse, key);
        }

        setCollapse(collapseUpdated);
    };

    const handleMore = () => {
        setItemsShown(itemsShown + ITEMS_SHOWN_INCREMENT);
    };

    const handleAdjustmentHelpDialog = (state, item = undefined) => () => {
        setAdjustmentHelpDialogItem(!_.isNil(item) ? item : adjustmentHelpDialogItem);
        setAdjustmentHelpDialogOpen(state);
    };

    const handleMessageCollectorDialogClose = () => {
        setMessageCollectorDialogOpen(false);
    };

    const handleReverseRedemption = async item => {
        if (_.get(item, 'isDonation', false)) {
            const res = await http.post(`/redemptions/${item.key}/reverseDonation`, {}, true);
            if (res.ok) {
                onSnackbar('Donation successfully reversed');
                await reloadData();
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        } else {
            const res = await http.post(`/redemptions/reject`, { redemptionIds: [item.key] }, true);
            if (res.ok) {
                onSnackbar('Redemption successfully reversed');
                await reloadData();
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        }
    };

    const filteredItems = useMemo(() => {
        let filteredItems = items;

        // filter by type
        filteredItems = _.filter(filteredItems, item => {
            return (
                filters.includes(item.type) ||
                _.some(_.get(item, 'issues', []), issue => filters.includes(_.get(issue, 'value'))) ||
                (_.get(item, 'hasUnresolvedComplaints') && filters.includes('UNRESOLVEDCOMPLAINTS')) ||
                (_.get(item, 'hasResolvedComplaints') && filters.includes('RESOLVEDCOMPLAINTS'))
            );
        });

        // filter by location
        filteredItems = _.filter(filteredItems, item => {
            if (!_.isEmpty(item.addressFilterVal)) {
                return locationFilters.includes(item.addressFilterVal);
            }

            return true;
        });

        // filter by bagtag
        filteredItems = _.filter(filteredItems, item => {
            if (!_.isEmpty(bagtagFilter)) {
                if (!item.bagtags) return false;
                return item.bagtags.includes(bagtagFilter);
            }

            return true;
        });

        return filteredItems;
    }, [items, filters, locationFilters, bagtagFilter]);

    const shownItems = _.take(filteredItems, itemsShown);

    let messageCollectorSubject = loc('dashboardHistory19', lang);
    if (!_.isEmpty(_.get(messageCollectorSelectedItem, 'bagtag'))) {
        messageCollectorSubject += `: ${loc('dashboardHistory20', lang)} ${_.get(
            messageCollectorSelectedItem,
            'bagtag',
            ''
        )}`;
    }

    // let abortedHelpDialogText;
    // if (!_.isNil(fullReason)) {
    //     abortedHelpDialogText = fullReason;
    // } else {
    //     if (userFault) {
    //         abortedHelpDialogText =
    //             'Our driver had some troubles locating your bags. Please double check your address is correct and update your directions with any other information that could help.';
    //     } else {
    //         abortedHelpDialogText = `Your pickup was rescheduled by ${
    //             process.env.REACT_APP_BRAND_NAME
    //         } due to unforeseen circumstances. You can find the rescheduled date on your dashboard and adjust it if necessary.`;
    //     }
    // }

    return (
        <React.Fragment>
            <Paper style={style}>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: -theme.spacing.unit * 1.5 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                        <TextField
                            select
                            SelectProps={{
                                multiple: true,
                                renderValue: f =>
                                    `${f.length === allNonLocationFilters.length ? loc('all', lang) : f.length} ${loc(
                                        'types',
                                        lang
                                    )}`,
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            marginTop: '30px'
                                        }
                                    }
                                }
                            }}
                            value={filters}
                            onChange={e => setFilters(e.target.value)}
                            fullWidth
                            variant="outlined"
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button
                                    onClick={e => {
                                        e.stopPropagation();
                                        const newSelectedValues = allNonLocationFilters.map(({ name, value }) => value);
                                        setFilters(newSelectedValues);
                                    }}
                                >
                                    {loc('all', lang)}
                                </Button>
                                <Button
                                    onClick={e => {
                                        e.stopPropagation();
                                        setFilters([]);
                                    }}
                                >
                                    {loc('none', lang)}
                                </Button>
                            </div>
                            {defaultFilters.map(({ name, value, obj }) => {
                                return (
                                    <MenuItem key={value} value={value}>
                                        <Checkbox checked={filters.includes(value)} />
                                        <ListItemText style={{ whiteSpace: 'normal' }}>
                                            <span
                                                style={{
                                                    marginRight: theme.spacing.unit,
                                                    verticalAlign: 'middle',
                                                    lineHeight: '1'
                                                }}
                                            >
                                                <MDIcon
                                                    path={_.get(filterIcons, value)}
                                                    size={1}
                                                    color={theme.palette.text.secondary}
                                                />
                                            </span>
                                            {name}
                                        </ListItemText>
                                    </MenuItem>
                                );
                            })}
                            {complaintsExist && <Divider style={{ backgroundColor: theme.palette.text.secondary }} />}
                            {complaintsExist && (
                                <MenuItem value="UNRESOLVEDCOMPLAINTS">
                                    <Checkbox checked={filters.includes('UNRESOLVEDCOMPLAINTS')} />
                                    <ListItemText style={{ whiteSpace: 'normal' }}>
                                        <span
                                            style={{
                                                marginRight: theme.spacing.unit,
                                                verticalAlign: 'middle',
                                                lineHeight: '1'
                                            }}
                                        >
                                            <MDIcon
                                                path={mdiEmoticonSadOutline}
                                                size={1}
                                                color={theme.palette.text.secondary}
                                            />
                                        </span>
                                        {loc('unresolvedComplaints', lang)}
                                    </ListItemText>
                                </MenuItem>
                            )}
                            {complaintsExist && (
                                <MenuItem value="RESOLVEDCOMPLAINTS">
                                    <Checkbox checked={filters.includes('RESOLVEDCOMPLAINTS')} />
                                    <ListItemText style={{ whiteSpace: 'normal' }}>
                                        <span
                                            style={{
                                                marginRight: theme.spacing.unit,
                                                verticalAlign: 'middle',
                                                lineHeight: '1'
                                            }}
                                        >
                                            <MDIcon
                                                path={mdiEmoticonSad}
                                                size={1}
                                                color={theme.palette.text.secondary}
                                            />
                                        </span>
                                        {loc('resolvedComplaints', lang)}
                                    </ListItemText>
                                </MenuItem>
                            )}
                            {adminView && <Divider style={{ backgroundColor: theme.palette.text.secondary }} />}
                            {adminView &&
                                uniqueIssues.map(({ name, value, obj }) => {
                                    return (
                                        <MenuItem key={value} value={value}>
                                            <Checkbox checked={filters.includes(value)} />
                                            <ListItemText style={{ whiteSpace: 'normal' }}>
                                                <span
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        verticalAlign: 'middle',
                                                        lineHeight: '1'
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiAlertCircleOutline}
                                                        size={1}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </span>
                                                {name}
                                            </ListItemText>
                                        </MenuItem>
                                    );
                                })}
                        </TextField>

                        {!isCollectionProgramUser && uniqueAddresses.length > 0 && (
                            <LocationFilter
                                locationFilters={locationFilters}
                                uniqueAddresses={uniqueAddresses}
                                setLocationFilters={setLocationFilters}
                                lang={lang}
                                theme={theme}
                            />
                        )}

                        {defaultFilters.includes('LABELS') && (
                            <FormControlLabel
                                label={'Labels'}
                                control={
                                    <Checkbox
                                        checked={filters.includes('LABELS')}
                                        style={{ color: colors.lightBlue[500] }}
                                        onChange={toggleFilter('LABELS')}
                                    />
                                }
                                style={{ marginRight: 10 }}
                            />
                        )}
                    </div>
                </div>
                {adminView && (
                    <TextField
                        style={{ marginTop: theme.spacing.unit }}
                        value={bagtagFilter}
                        onChange={e => setBagtagFilter(e.target.value)}
                        fullWidth
                        label="Bagtag"
                        variant="outlined"
                    />
                )}
                <Divider style={{ marginBottom: theme.spacing.unit, marginTop: theme.spacing.unit }} />
                {/* {loading && (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: theme.spacing.unit * 2
                        }}
                    >
                        <CircularProgress style={{ color: 'primary' }} />
                    </div>
                )} */}
                <List style={{ paddingBottom: 0 }}>
                    {!loading && items.length < 1 && (
                        <Typography style={{ marginTop: theme.spacing.unit }}>{loc('history6', lang)}</Typography>
                    )}
                    {!loading && items.length > 0 && shownItems.length < 1 && (
                        <Typography style={{ marginTop: theme.spacing.unit }}>{loc('history7', lang)}</Typography>
                    )}
                    {items.length > 0 &&
                        shownItems.length > 0 &&
                        shownItems.map((item, idx) => {
                            return (
                                <React.Fragment key={item.key}>
                                    <ListItem
                                        data-cy={_.get(item, 'id', null)}
                                        dense
                                        disableGutters
                                        style={{
                                            paddingTop: theme.spacing.unit / 2,
                                            paddingBottom: theme.spacing.unit / 2
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Avatar style={{ backgroundColor: item.color }}>{item.icon}</Avatar>

                                            <Badge
                                                style={{
                                                    marginTop: theme.spacing.unit,
                                                    color: theme.palette.text.primary
                                                }}
                                            >
                                                {moment(item.date).isValid()
                                                    ? locDate(
                                                          item.date,
                                                          'DD MMM YYYY',
                                                          lang,
                                                          _.get(item, 'timezone', process.env.REACT_APP_REGION_TIMEZONE)
                                                      )
                                                    : item.date}
                                            </Badge>
                                        </div>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                                        {item.primary}{' '}
                                                        {(_.get(item, 'hasDriverIssues') ||
                                                            !_.isEmpty(item.payloadIssuesImages)) && (
                                                            <Icon
                                                                style={{
                                                                    color: colors.red[500],
                                                                    marginLeft: theme.spacing.unit / 2
                                                                }}
                                                            >
                                                                error
                                                            </Icon>
                                                        )}
                                                    </Typography>
                                                    <Typography color="textSecondary" style={{ fontWeight: 'bold' }}>
                                                        {item.address
                                                            ? item.address
                                                            : item.adjustmentReason
                                                            ? item.adjustmentReason
                                                            : null}
                                                    </Typography>
                                                </>
                                            }
                                            secondary={
                                                <>
                                                    <span style={{ fontStyle: 'oblique' }}>{item.secondary}</span>
                                                    {item.commodity && (
                                                        <MDIcon
                                                            path={allIcons[item.commodity.iconName]}
                                                            size={0.7}
                                                            color={item.commodity.color}
                                                            style={{ transform: 'translate(5px, 3px)' }}
                                                        />
                                                    )}
                                                </>
                                            }
                                        />
                                        {!_.isNil(item.content) && (
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    data-cy={!_.isNil(item.id) ? 'expand-' + item.id : null}
                                                    onClick={toggleCollapse(item.key)}
                                                >
                                                    <Icon>
                                                        {collapse.includes(item.key) ? 'expand_less' : 'expand_more'}
                                                    </Icon>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                        {item.adjustment && (
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={handleAdjustmentHelpDialog(true, item)}>
                                                    <Icon>help</Icon>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                    <Collapse in={collapse.includes(item.key)}>
                                        <div
                                            style={{
                                                marginTop: theme.spacing.unit * 2,
                                                marginBottom: theme.spacing.unit * 2
                                            }}
                                        >
                                            {item.content}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-evenly'
                                            }}
                                        >
                                            {adminView &&
                                                item.type === 'REDEMPTION' &&
                                                item.isCancellable &&
                                                !disableInAppRedemptionCancel && (
                                                    <Tooltip title="Reverse">
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            style={{
                                                                marginBottom: theme.spacing.unit * 2,
                                                                marginRight: theme.spacing.unit * 2
                                                            }}
                                                            onClick={() =>
                                                                warnAction(
                                                                    () => handleReverseRedemption(item),
                                                                    `Are you sure you want to reverse this ${
                                                                        _.get(item, 'isDonation', false)
                                                                            ? 'donation'
                                                                            : 'redemption'
                                                                    }?`
                                                                )
                                                            }
                                                        >
                                                            <Icon>undo</Icon>
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                        </div>
                                    </Collapse>
                                </React.Fragment>
                            );
                        })}
                </List>
                {filteredItems.length > itemsShown && (
                    <div style={{ textAlign: 'center' }}>
                        <Button color="primary" onClick={handleMore}>
                            {loc('history5', lang)}
                        </Button>
                    </div>
                )}
            </Paper>

            <Dialog open={adjustmentHelpDialogOpen} fullWidth maxWidth="sm" onClose={handleAdjustmentHelpDialog(false)}>
                <DialogTitle>{loc('history8', lang)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {loc('history9', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
                    </DialogContentText>
                    {adjustmentHelpDialogItem.adjustmentReason && (
                        <DialogContentText>
                            {loc('history12', lang)}:{' '}
                            <span style={{ color: theme.palette.primary.main }}>
                                {processAdjustmentReason(adjustmentHelpDialogItem.adjustmentReason)}
                            </span>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleAdjustmentHelpDialog(false)}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={imageDialogOpen} maxWidth="xs" fullWidth onClose={() => setImageDialogOpen(false)}>
                <DialogTitlePrimary closeButtonShown onClose={() => setImageDialogOpen(false)}>
                    {loc('history10', lang)}
                </DialogTitlePrimary>
                <DialogContent style={{ margin: theme.spacing.unit, padding: 0 }}>
                    <ImageViewer
                        displayImages={imagesToView}
                        disableDelete={true}
                        scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                    />
                </DialogContent>
            </Dialog>

            <MessageCollectorDialog
                open={messageCollectorDialogOpen}
                onClose={handleMessageCollectorDialogClose}
                initialSubject={messageCollectorSubject}
                initialBody={loc('history11', lang)}
                customerInfo={{
                    customerUniqueID: _.get(messageCollectorSelectedItem, 'customerUniqueID', ''),
                    bagtag: _.get(messageCollectorSelectedItem, 'bagtag', ''),
                    bulkType: _.get(messageCollectorSelectedItem, 'bulkType', ''),
                    date: _.get(messageCollectorSelectedItem, 'date', ''),
                    employeeName: _.get(messageCollectorSelectedItem, 'employeeName', ''),
                    collectorName: _.get(messageCollectorSelectedItem, 'collector.name', ''),
                    address: _.get(messageCollectorSelectedItem, 'address', ''),
                    payload: _.get(messageCollectorSelectedItem, 'payload', ''),
                    aborted: _.get(messageCollectorSelectedItem, 'aborted', ''),
                    pickupCompleted: _.get(messageCollectorSelectedItem, 'pickupCompleted', false)
                }}
                collectorId={_.get(messageCollectorSelectedItem, 'collector._id', '')}
                http={http}
            />
        </React.Fragment>
    );
}

export default withTheme()(HistoryWidget);

function LocationFilter({locationFilters, uniqueAddresses, setLocationFilters, lang, theme}) {
    return (
        <MultiSelect
            fullWidth
            variant="outlined"
            selectedValues={locationFilters}
            selectableValues={uniqueAddresses.map(address => {
                return {
                    name: address,
                    value: address
                };
            })}
            onChange={value => setLocationFilters(value)}
            renderValue={f =>
                `${f.length === uniqueAddresses.length ? loc('all', lang) : f.length} ${loc('locations', lang)}`
            }
            renderMenuItem={({ name, value, obj }) => {
                return (
                    <MenuItem
                        key={value}
                        value={value}
                        style={{ height: 'auto', paddingTop: theme.spacing.unit, paddingBottom: 0 }}
                    >
                        <Checkbox checked={locationFilters.includes(value)} />
                        <ListItemIcon>
                            <MDIcon path={mdiMapMarker} size={1} color={theme.palette.text.secondary} />
                        </ListItemIcon>
                        <ListItemText
                            style={{
                                whiteSpace: 'normal',
                                paddingLeft: 0
                            }}
                        >
                            {name}
                        </ListItemText>
                    </MenuItem>
                );
            }}
            style={{ marginLeft: theme.spacing.unit }}
        />
    );
}

function processAdjustmentReason(string) {
    let newString = _.clone(string);
    newString = newString.trim();
    if (_.last(newString) === '.' || _.last(newString) === '!') {
        newString = newString.slice(0, -1);
    }
    newString = newString.charAt(0).toUpperCase() + newString.slice(1) + '.';

    return newString;
}
