import React, { useMemo, useState, useContext } from 'react';

import _ from 'lodash';
import { formatAsCurrency } from 'utils/misc';
import bulkHelper from 'helpers/bulkHelper';
import { _bulk } from 'std';
// custom components
import BulkCountsInputs from 'components/BulkComponents/BulkCountsInputs';
import KeyPad from './Keypad';
// react components
import { withTheme } from '@material-ui/core/styles';
import {
    withMobileDialog,
    withWidth,
    DialogActions,
    DialogContent,
    Dialog,
    Button,
    Grid,
    Paper
} from '@material-ui/core';

import { Typography, colors } from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import { mdiBasketUnfill } from '@mdi/js';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import HttpContext from 'utils/contexts/HttpContext';
import OperatorContext from 'utils/contexts/OperatorContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import useWindowSize from 'utils/hooks/useWindowSize';

function CountInputPanel({
    theme,
    bulk,
    inProgress,
    skus,
    config,
    countForm,
    countingMode,
    totalCountFormAmount,
    disableEditing,
    customFeesForm,
    grandTotal,
    createBulkDisabled,
    handleApplyCustomFee,
    onCustomFeeDialog,
    onCountFormChange,
    onCreateBulk,
    onSubmitCounts,
    onAddCount,
    setIdleCountdown,
    resetTimeoutCount,
    onOpenReportDialog,
    onOpenComplaintDialog,
    reportType,
    hidePreset,
    hideKeypad,
    keySize,
    onOpenAICountDialog,
    currentCountingSessionId,
    onSnackbar
}) {
    const operator = useContext(OperatorContext);
    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const [bulkStationConfig, setBulkStationConfig] = useState(config || []);
    const [warningDialogLabel, setWarningDialogLabel] = useState('');
    const [amount, setAmount] = useState(0);
    const [countWarningDialogOpen, setCountWarningDialogOpen] = useState(false);
    const [display, setDisplay] = useState('');
    React.useEffect(() => {
        setBulkStationConfig(config);
    }, [config]);
    const handleAppendNumber = numToAppend => {
        const currentNum = parseInt(amount);

        if (currentNum === 0 || _.isNil(currentNum)) {
            setAmount(numToAppend);
        } else {
            setAmount(parseInt(`${currentNum}${numToAppend}`));
        }
    };

    // const handleRemoveNumber = () => {
    //     const currentNum = amount.toString();
    //     const updatedNum = currentNum.slice(0, -1);
    //     if (_.isEmpty(updatedNum) || _.isNil(updatedNum)) {
    //         setAmount(0);
    //     } else {
    //         setAmount(parseInt(updatedNum));
    //     }
    // };

    const triggerCountDialogWarningWithTimer = label => {
        setWarningDialogLabel(label);
        setCountWarningDialogOpen(true);
        setTimeout(function() {
            setCountWarningDialogOpen(false);
        }, _.get(operator, 'collector.configuration.bulkStationDialogAutoTimeout', 3000));
    };

    const handleAddCountsAndUpdateBulkSorter = (amount, sku, label) => {
        let resultAmount = amount;
        if (display.includes('X')) {
            let displayItems = display.split('X');
            resultAmount = displayItems.reduce((a, b) =>
                !isNaN(parseInt(b)) ? parseInt(a) * parseInt(b) : parseInt(a)
            );
        }

        if (!resultAmount) {
            setAmount(0);
            setDisplay('');
            return;
        }
        if (onAddCount(resultAmount, sku)) {
            // returns true if submitted properly
            let updatedBulkStationConfig = _.cloneDeep(bulkStationConfig);
            updatedBulkStationConfig.forEach(configSku => {
                if (configSku.fillCapacity > 0 && configSku.sku === sku.sku) {
                    let updatedAmount = configSku.fillAmountRemaining - resultAmount;
                    // if 0, reset to max
                    if (updatedAmount === 0) {
                        updatedAmount = configSku.fillCapacity;
                        triggerCountDialogWarningWithTimer(label);
                    } else if (updatedAmount < 0) {
                        updatedAmount = configSku.fillCapacity + (updatedAmount % configSku.fillCapacity);
                        triggerCountDialogWarningWithTimer(label);
                    }
                    configSku.fillAmountRemaining = updatedAmount;
                }
            });

            setBulkStationConfig(updatedBulkStationConfig);
            setAmount(0);
            setDisplay('');
            http.post(`/users/${operator._id}/updateBulkStationConfig`, {
                updatedBulkStationConfig
            });
        } else {
            // TODO: handle this case
        }
    };

    const handleResetAndUpdateBulkSorter = () => {
        let updatedBulkStationConfig = _.cloneDeep(bulkStationConfig);
        updatedBulkStationConfig.forEach(configSku => {
            configSku.fillAmountRemaining = configSku.fillCapacity;
        });
        setBulkStationConfig(updatedBulkStationConfig);
        setAmount(0);
        setDisplay('');
        http.post(`/users/${operator._id}/updateBulkStationConfig`, {
            updatedBulkStationConfig
        });
    };

    const handleClear = () => {
        resetTimeoutCount();
        setAmount(0);
        setDisplay('');
    };

    const handleAddOperator = operator => {
        resetTimeoutCount();
        // operator has to be a string
        const currentNum = amount.toString();
        const updatedNum = currentNum.concat(operator);
        setDisplay(updatedNum);
    };

    const handleNumInputChange = e => {
        resetTimeoutCount();
        const newVal = e.target.value;

        const lastInput = newVal[newVal.length - 1];
        if (display.includes('X') && !isNaN(parseInt(lastInput))) {
            setDisplay(newVal);
        } else if (lastInput === 'X') {
            handleAddOperator(lastInput);
        } else {
            if (!_.isEmpty(newVal)) {
                setAmount(parseInt(e.target.value));
            } else {
                setAmount(0);
            }
        }
    };

    const handlePresetButtonClick = value => {
        resetTimeoutCount();
        if (display.includes('X')) {
            setDisplay(display.concat(value));
        } else {
            setAmount(parseInt(value));
        }
    };

    const handleNumButtonClick = value => {
        resetTimeoutCount();
        if (display.includes('X')) {
            setDisplay(display.concat(value));
        } else {
            handleAppendNumber(parseInt(value));
        }
    };

    const handleCalculateResult = () => {
        let displayItems = display.split('X');
        let result = displayItems.reduce((a, b) => (!isNaN(parseInt(b)) ? parseInt(a) * parseInt(b) : parseInt(a)));

        setAmount(result);
        setDisplay('');
    };

    const countFormEmpty = useMemo(() => noCounts(countForm) && _.isEmpty(customFeesForm), [countForm, customFeesForm]);

    const [width, height] = useWindowSize();
    let mobileMode = width <= 600;
    const heightOffset = mobileMode ? theme.spacing.unit * 2 : theme.spacing.unit;

    return (
        <>
            <Grid
                className="fade-in"
                item
                md={4}
                xs={12}
                style={{ height: '100%', paddingBottom: 'env(safe-area-inset-bottom)' }}
            >
                <Paper
                    elevation={1}
                    style={{
                        padding: theme.spacing.unit,
                        display: 'flex',
                        flexDirection: 'column',
                        height: `calc(100% - ${heightOffset}px)`
                    }}
                >
                    {bulkHelper.isRedeemed(bulk) ? (
                        <span style={{ textAlign: 'center' }}>
                            <Typography
                                variant="subtitle1"
                                style={{
                                    marginBottom: theme.spacing.unit,
                                    marginTop: theme.spacing.unit * 2
                                }}
                            >
                                <span
                                    style={{ color: theme.palette.text.secondary }}
                                    data-cy="bulk-counter-dialog-bulk-redeemed-msg"
                                >
                                    This order was redeemed by the customer and can no longer be edited/counted.{' '}
                                    {!createBulkDisabled &&
                                        'To credit lost items, create an adjustment order with the customer ID'}
                                </span>{' '}
                                {!createBulkDisabled && <Highlight>{bulkHelper.getCustomerUniqueID(bulk)}</Highlight>}
                            </Typography>
                            {!createBulkDisabled && (
                                <Button
                                    size="small"
                                    style={{ marginTop: theme.spacing.unit }}
                                    variant="outlined"
                                    onClick={() => {
                                        onCreateBulk(true);
                                        resetTimeoutCount();
                                    }}
                                    color="primary"
                                >
                                    Create Adjustment
                                </Button>
                            )}
                        </span>
                    ) : (
                        <>
                            {countingMode === 'classic' && (
                                <>
                                    <div style={{ flex: `1 1 auto`, overflowY: 'auto', overflowX: 'hidden' }}>
                                        <BulkCountsInputs
                                            bulk={bulk}
                                            countForm={countForm}
                                            onCountChange={onCountFormChange}
                                            onCreateBulk={() => {
                                                resetTimeoutCount();
                                                warnAction(() => {
                                                    resetTimeoutCount();
                                                    onCreateBulk();
                                                }, 'You are about to create an adjustment order for this customer. Do you wish to continue?');
                                            }}
                                            disabled={disableEditing}
                                        />
                                    </div>
                                    <Button
                                        data-cy="bulk-counter-return-value-0"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        fullWidth
                                        style={{ marginTop: theme.spacing.unit, minHeight: 40 }}
                                        onClick={() => {
                                            resetTimeoutCount();
                                            if (totalCountFormAmount > 12000) {
                                                warnAction(() => {
                                                    resetTimeoutCount();
                                                    onSubmitCounts();
                                                }, 'You are counting a very large amount. Are you sure this is correct?');
                                            } else {
                                                onSubmitCounts();
                                            }
                                        }}
                                        disabled={countFormEmpty || inProgress}
                                    >
                                        Add {formatAsCurrency(totalCountFormAmount)}
                                    </Button>
                                </>
                            )}

                            {countingMode === 'keypad' && (
                                <KeyPad
                                    bulkStationConfig={bulkStationConfig}
                                    skus={skus}
                                    bulkSkuType={bulk.skuType}
                                    disableEditing={disableEditing}
                                    amount={amount}
                                    display={display}
                                    bulkDonating={_bulk.isDonating(bulk)}
                                    handleAddCountsAndUpdateBulkSorter={handleAddCountsAndUpdateBulkSorter}
                                    onCustomFeeDialog={onCustomFeeDialog}
                                    handleClear={handleClear}
                                    setAmount={setAmount}
                                    grandTotal={grandTotal}
                                    handlePresetButtonClick={handlePresetButtonClick}
                                    handleNumButtonClick={handleNumButtonClick}
                                    handleAppendNumber={handleAppendNumber}
                                    // handleRemoveNumber={handleRemoveNumber}
                                    handleResetAndUpdateBulkSorter={handleResetAndUpdateBulkSorter}
                                    handleApplyCustomFee={handleApplyCustomFee}
                                    handleCalculateResult={handleCalculateResult}
                                    hasOperator={display.includes('X')}
                                    handleAddOperator={handleAddOperator}
                                    handleNumInputChange={handleNumInputChange}
                                    setIdleCountdown={setIdleCountdown}
                                    resetTimeoutCount={resetTimeoutCount}
                                    inProgress={inProgress}
                                    bulk={bulk}
                                    onOpenReportDialog={onOpenReportDialog}
                                    onOpenComplaintDialog={onOpenComplaintDialog}
                                    reportType={reportType}
                                    hidePreset={hidePreset}
                                    hideKeypad={hideKeypad}
                                    keySize={keySize}
                                    onOpenAICountDialog={onOpenAICountDialog}
                                    currentCountingSessionId={currentCountingSessionId}
                                    onSnackbar={onSnackbar}
                                />
                            )}
                        </>
                    )}
                </Paper>
            </Grid>
            <Dialog
                open={countWarningDialogOpen}
                fullWidth
                onClose={() => {
                    resetTimeoutCount();
                    setCountWarningDialogOpen(false);
                }}
            >
                <DialogContent
                    style={{
                        backgroundColor: colors.yellow[700],
                        textAlign: 'center',
                        color: theme.palette.getContrastText(colors.yellow[700])
                    }}
                >
                    <MDIcon path={mdiBasketUnfill} size={6} color={theme.palette.getContrastText(colors.yellow[700])} />
                    <Typography variant="h6">
                        Please clear the <strong>{warningDialogLabel}</strong> bin!
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            resetTimeoutCount();
                            setCountWarningDialogOpen(false);
                        }}
                        size="small"
                        variant="text"
                    >
                        close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default withWidth()(withMobileDialog({ breakpoint: 'xs' })(withTheme()(CountInputPanel)));

function noCounts(containerGroups, customFees) {
    if (_.isNil(containerGroups) || _.isEmpty(containerGroups)) return false;
    for (let key in containerGroups) {
        if (!containerGroups[key].every(value => value.quantity === 0)) {
            return false;
        }
    }
    return true;
}

const Highlight = ({ children }) => {
    return <span style={{ color: colors.cyan[600] }}>{children}</span>;
};
