import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import {
    Button,
    Grid,
    Paper,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    DialogTitle,
    withStyles,
    withTheme,
    LinearProgress,
    TextField,
    FormHelperText
} from '@material-ui/core';
import { SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid';
import { Grid as TableGrid, Table, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { validate } from 'utils/validate';
import { isProductionEnv } from 'utils/misc';

function ApproveRedemptionDialog({ http, open, onClose, redemptionRows = [], onSnackbar, redemptionOptions }) {
    const { lang } = useContext(LocalizationContext);

    const [selectedRedemption, setSelectedRedemption] = useState([]);
    const [requireEmailForAPIRedemption, setRequireEmailForAPIRedemption] = useState(false);
    const [customersAffected, setCustomersAffected] = useState(0);
    const [emailForAPIRedemption, setEmailForAPIRedemption] = useState('');
    const handleSelectRedemptions = redemptions => setSelectedRedemption(redemptions);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setSelectedRedemption(redemptionRows.map((redemption, i) => i));
        setCustomersAffected(_.size(_.uniq(redemptionRows.map(redemption => redemption.customerId))));
    }, [redemptionRows]);

    async function handleClick() {
        setIsLoading(true);

        let selectedRedemptionIDs = selectedRedemption.map(index => redemptionRows[index]._id);
        let nonSelectedRedemptionIDs = redemptionRows
            .map(redemption => redemption._id)
            .filter(r => !_.includes(selectedRedemptionIDs, r));

        let approveRes = await http.post('/users/ReviewRedemptions', {
            redemption_ids: selectedRedemptionIDs.map(id => ({ _id: id })),
            emailForAPIRedemption
        });
        let rejectedRes = await http.post('/redemptions/reject', {
            redemptionIds: nonSelectedRedemptionIDs.map(id => ({ _id: id }))
        });

        if (approveRes.status === 207) {
            onSnackbar('Some redemptions could not be processed', 'info');
        } else if (approveRes.ok && rejectedRes.ok) {
            onSnackbar('Redemptions successfully approved/rejected');
        } else {
            onSnackbar('There was an issue approving/rejecting redemptions', 'error');
        }

        setSelectedRedemption([]);
        onClose();
        setIsLoading(false);
    }

    useEffect(() => {
        let useAPI = _.get(redemptionOptions, 'paymentsAPI', 'None') !== 'None';
        let eTransferApprovalRequired = _.get(redemptionOptions, 'eTransferApprovalRequired', true);
        let selectedRedemptionIDs = selectedRedemption.map(index => redemptionRows[index]._id);
        let selectedRedemptionsIncludeETransfers = _.find(
            redemptionRows,
            r => r.paymentModel === 'INTERAC_ETRANSFERS' && _.includes(selectedRedemptionIDs, r._id)
        );
        setRequireEmailForAPIRedemption(
            !isProductionEnv && useAPI && !eTransferApprovalRequired && selectedRedemptionsIncludeETransfers
        );
    }, [selectedRedemption, redemptionOptions]);

    return (
        <>
            <Dialog open={open} onClose={() => {}} fullWidth maxWidth={'lg'}>
                {isLoading && <LinearProgress />}

                <DialogTitle>Approve or Reverse Redemption Requests</DialogTitle>
                <DialogContent>
                    <Typography variant="caption">
                        {loc('redemption69', lang, { userAmount: customersAffected })}
                    </Typography>
                    <Grid item xs={12} style={{ marginLeft: -8, marginTop: 16 }}>
                        <Paper
                            id="redemptions-under-review-table"
                            data-cy="redemptions-under-review-table"
                            style={{ 'box-shadow': 'none' }}
                        >
                            <TableGrid
                                rows={redemptionRows}
                                columns={[
                                    { title: 'E-mail', name: 'email' },
                                    { title: 'Name', name: 'name' },
                                    { title: 'Date Requested', name: 'dateRequested' },
                                    { title: 'Amount', name: 'amount' },
                                    { title: 'Type', name: 'type' },
                                    { title: 'Method', name: 'method' }
                                ]}
                            >
                                <SelectionState
                                    onSelectionChange={handleSelectRedemptions}
                                    selection={selectedRedemption}
                                />
                                <IntegratedSelection />
                                <Table cellComponent={TableCellComponent} tableComponent={TableComponent} />
                                <TableHeaderRow />
                                <TableSelection showSelectAll />
                            </TableGrid>
                        </Paper>
                    </Grid>

                    {requireEmailForAPIRedemption && (
                        <>
                            <TextField
                                value={emailForAPIRedemption}
                                onChange={e => setEmailForAPIRedemption(e.target.value)}
                                label="Email for INTERAC Notification (Staging Only)"
                                variant="outlined"
                                type="email"
                                style={{ display: 'flex', marginTop: 16 }}
                                error={validate(['email'], emailForAPIRedemption, 'en').fail}
                                helperText={validate(['email'], emailForAPIRedemption, 'en').reason}
                            />
                            <FormHelperText>
                                On approval, an API call generates a live email notification from INTERAC to the
                                provided email. Ensure the email is active and accessible, as an inactive email will
                                cause the API payment to fail.
                            </FormHelperText>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        size="small"
                        disabled={
                            isLoading ||
                            (requireEmailForAPIRedemption && validate(['email'], emailForAPIRedemption, 'en').fail)
                        }
                        onClick={() => {
                            handleClick();
                        }}
                        data-cy="continue"
                    >
                        {loc('continue', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const styles = theme => ({
    tableStyles: {
        tableLayout: 'auto'
    }
});
const TableComponentBase = ({ classes, ...props }) => <Table.Table {...props} className={classes.tableStyles} />;
const TableCellComponent = ({ ...props }) => <Table.Cell {...props} style={{ overflow: 'visible' }} />;
const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withTheme()(ApproveRedemptionDialog);
