import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

import {
    formatAsPhoneNumber,
    getQuickDropName,
    isAUSRegion,
    isEXPRegion,
    isCONRegion,
    showOnGoogleMaps,
    uppercaseFirstLetter
} from 'utils/misc';

import { red, green } from '@material-ui/core/colors';
import {
    Grid,
    Select,
    OutlinedInput,
    MenuItem,
    Typography,
    Button,
    Icon,
    DialogContentText,
    FormControl,
    InputLabel,
    FormHelperText,
    withTheme,
    InputAdornment,
    CircularProgress,
    IconButton,
    Collapse,
    Divider,
    Avatar,
    Paper
} from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import beverageIconEXP from 'icons/Beverage_Pin_EXP.png';
import beverageIcon from 'icons/Beverage_Pin_STD.png';
import electronicsIconEXP from 'icons/Electronics_Pin_EXP.png';
import electronicsIcon from 'icons/Electronics_Pin_STD.png';
import clothingIcon from 'icons/Clothing_Pin_STD.png';
import quickDropIconSTD from 'icons/Quickdrop_Pin_STD.png';
import quickDropIconEXP from 'icons/Quickdrop_Pin_EXP.png';
import quickDropIconAUS from 'icons/Quickdrop_Pin_AUS.png';
import quickDropIconCON from 'icons/Quickdrop_Pin_CON.png';
// import electronicsIcon from 'icons/electronics.png';
import { _commodity, _dropLocation } from 'std';
import { DROP_OFF_TYPES } from 'constants.js';
import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const defaultAvailability = [
    { day: 'Monday', hours: '8am–6pm' },
    { day: 'Tuesday', hours: '8am–6pm' },
    { day: 'Wednesday', hours: '8am–6pm' },
    { day: 'Thursday', hours: '8am–6pm' },
    { day: 'Friday', hours: '8am–6pm' },
    { day: 'Saturday', hours: '8am–6pm' },
    { day: 'Sunday', hours: '8am–6pm' }
];

const DropAndGo = props => {
    const {
        http,
        theme,
        date,
        customer,
        quickDropType,
        // selectedLocationId,
        onDropLocationChange,
        dropLocations,
        helperText,
        loadingCollectorHours,
        loadingCollectorCommodities,
        collectorCommodities,
        collectorOtherMaterials,
        collectorWarnings,
        GPSLoading,
        openStatus,
        // selectedDropLocation,
        dropAndGoCollector,
        dropAndGoTechnologies,
        inHouseBookings,
        handleDateChange,
        setInProgress,
        // allowRequestLabels,
        contactPhone,
        onNext,
        isSmUp,
        onQRDialog,
        onAccessDoor,
        isOpen,
        width
    } = props;

    let isSmallScreen = isWidthDown('xs', width);

    const [commodityList, setCommodityList] = useState(collectorCommodities);
    const [otherMaterialsList, setOtherMaterialsList] = useState([]);
    const [warningsList, setWarningsList] = useState([]);
    const [technologyList, setTechnologyList] = useState([]);

    const { lang } = useContext(LocalizationContext);

    const selectedDropLocationTZ = _.get(
        dropAndGoCollector,
        'timezeone',
        _.get(_.first(dropAndGoTechnologies), 'collector.timezeone', process.env.REACT_APP_REGION_TIMEZONE)
    );

    const callLocation = () => {
        if (!contactPhone) return;
        const phone = contactPhone;
        window.open(`tel:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${phone}`);
    };

    const [inHouseBookingTimeSlots, setInHouseBookingTimeSlots] = useState([]);
    const [hoursExpanded, setHoursExpanded] = useState(false);
    const [acceptedItemsExpanded, setAcceptedItemsExpanded] = useState({});

    const [otherItemsExpanded, setOtherItemsExpanded] = useState(false);
    const [otherItemsOverflow, setOtherItemsOverflow] = useState(false);

    useEffect(() => {
        const updatedAcceptedItemsExpanded = commodityList.reduce((next, commodity) => {
            const { skuType } = commodity;
            return { ...next, [skuType]: false };
        }, {});

        setAcceptedItemsExpanded(updatedAcceptedItemsExpanded);
    }, [commodityList]);

    useEffect(() => {
        const length = _.sumBy(otherMaterialsList, obj => _.get(obj, `payloadInputName.${lang}`, '').length);
        const lengthWithIcons = length + otherMaterialsList.length * 3;
        const overflow = isSmallScreen ? lengthWithIcons > 20 : lengthWithIcons > 60;
        setOtherItemsOverflow(overflow);
    }, [otherMaterialsList]);

    const getOpenChangeTime = () => {
        if (openStatus.isOpen) {
            const today = moment(new Date()).day();
            let closesAt = isEXPRegion()
                ? openStatus.closesAt
                : parseInt(_.get(openStatus, `periods[${today}].close.time`));
            let suffix = 'am';
            if (closesAt >= 1300) {
                suffix = 'pm';
                closesAt = closesAt - 1200;
            }

            closesAt = closesAt.toString();

            return `Until ${closesAt.slice(0, closesAt.length - 2)}:${closesAt.slice(closesAt.length - 2)}${suffix}`;
        }
        return 'Refer to hours of operation';
    };
    const maxInlineItems = isSmUp ? 4 : 3;
    const printHours = () => {
        const today = moment(new Date()).day();
        if (openStatus && openStatus.weekday_text) {
            const [todayDay, todayHours] = openStatus.weekday_text[today - 1].split(': '); //ND: Keep space after colon otherwise this will break
            const hoursDisplay = (
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs={11}>
                        <div
                            style={{
                                transitionProperty: 'max-height',
                                transitionDuration: '0.3s',
                                transitionTimingFunction: 'ease',
                                maxHeight: hoursExpanded ? 600 : 50,
                                overflow: 'hidden',
                                overflowY: 'auto'
                            }}
                        >
                            <Collapse in={hoursExpanded}>
                                {openStatus.weekday_text.map((info, index) => {
                                    const [day, hours] = info.split(': '); //ND: Keep space after colon otherwise this will break
                                    return index < today - 1 ? (
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">{loc(day.toLowerCase(), lang)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {getFormattedHours(hours, lang)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <></>
                                    );
                                })}
                            </Collapse>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="body1" style={{ fontWeight: hoursExpanded ? 'bold' : '' }}>
                                        {loc(todayDay.toLowerCase(), lang)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" style={{ fontWeight: hoursExpanded ? 'bold' : '' }}>
                                        {getFormattedHours(todayHours, lang)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Collapse in={hoursExpanded}>
                                {openStatus.weekday_text.map((info, index) => {
                                    const [day, hours] = info.split(': '); //ND: Keep space after colon otherwise this will break
                                    return index > today - 1 ? (
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={4}>
                                                <Typography variant="body1">{loc(day.toLowerCase(), lang)}</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="body1">
                                                    {getFormattedHours(hours, lang)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <></>
                                    );
                                })}
                            </Collapse>
                        </div>
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <IconButton style={{ padding: 0, margin: 0 }} onClick={() => setHoursExpanded(!hoursExpanded)}>
                            <Icon
                                style={{
                                    fontSize: 30,
                                    padding: 0,
                                    margin: 0,
                                    color: theme.palette.text.primary
                                }}
                            >
                                {hoursExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                            </Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            );
            return hoursDisplay;
        } else {
            const todayAvailability = _.get(
                dropAndGoCollector,
                'availability',
                _.get(_.first(dropAndGoTechnologies), 'availability', defaultAvailability)
            )[Math.max(0, today - 1)];
            const hoursDisplay = (
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs={11}>
                        <div
                            style={{
                                transitionProperty: 'max-height',
                                transitionDuration: '0.3s',
                                transitionTimingFunction: 'ease',
                                maxHeight: hoursExpanded ? 200 : 50,
                                overflow: 'hidden',
                                overflowY: 'auto'
                            }}
                        >
                            <Collapse in={hoursExpanded}>
                                {_.get(
                                    dropAndGoCollector,
                                    'availability',
                                    _.get(_.first(dropAndGoTechnologies), 'availability', defaultAvailability)
                                ).map((time, index) => {
                                    return index < today - 1 ? (
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {loc(_.get(time, 'day', '-').toLowerCase(), lang)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {getFormattedHours(time.hours, lang)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <></>
                                    );
                                })}
                            </Collapse>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="body1" style={{ fontWeight: hoursExpanded ? 'bold' : '' }}>
                                        {loc(_.get(todayAvailability, 'day', '-').toLowerCase(), lang)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" style={{ fontWeight: hoursExpanded ? 'bold' : '' }}>
                                        {getFormattedHours(_.get(todayAvailability, 'hours', '-'), lang)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Collapse in={hoursExpanded}>
                                {_.get(
                                    dropAndGoCollector,
                                    'availability',
                                    _.get(_.first(dropAndGoTechnologies), 'availability', defaultAvailability)
                                ).map((time, index) => {
                                    return index > today - 1 ? (
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {loc(_.get(time, 'day', '-').toLowerCase(), lang)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {getFormattedHours(time.hours, lang)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <></>
                                    );
                                })}
                            </Collapse>
                        </div>
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <IconButton style={{ padding: 0, margin: 0 }} onClick={() => setHoursExpanded(!hoursExpanded)}>
                            <Icon
                                style={{
                                    fontSize: 30,
                                    padding: 0,
                                    margin: 0,
                                    color: theme.palette.text.primary
                                }}
                            >
                                {hoursExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                            </Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            );
            return hoursDisplay;
        }
    };

    const printCommodities = () => {
        if (_.isEmpty(commodityList) && _.isEmpty(otherMaterialsList)) return;
        return (
            <>
                <CustomWrapper
                    title={loc('acceptedMaterials', lang)}
                    outerDivStyle={{
                        paddingTop: '24px',
                        marginBottom: theme.spacing.unit * 2,
                        marginTop: theme.spacing.unit * 3,
                        marginLeft: theme.spacing.unit / 2,
                        marginRight: -theme.spacing.unit / 2,
                        borderLeft: null,
                        borderRight: null,
                        borderRadius: 0
                    }}
                >
                    {_.sortBy(commodityList, 'skuType').map((commodity, commodityIndex) => {
                        const acceptedExamples = _.get(commodity, 'eligibleExamples', []).filter(
                            example => example.accepted
                        );
                        const length = _.sumBy(acceptedExamples, obj => _.get(obj, `desc.${lang}`, '').length);
                        const examplesWithIcons = _.filter(
                            acceptedExamples,
                            obj => !_.isNil(obj.iconName) && !_.isEmpty(obj.iconName)
                        );
                        const lengthWithIcons = length + examplesWithIcons.length * 3;
                        const overflow = isSmallScreen ? lengthWithIcons > 20 : lengthWithIcons > 60;
                        const expanded = _.get(acceptedItemsExpanded, commodity.skuType);
                        return (
                            <Grid container spacing={24}>
                                <Grid item xs={4} sm={2} style={{ marginTop: 4, marginBottom: 4 }}>
                                    <Typography variant="body1">
                                        {_.capitalize(loc(_.get(commodity, 'skuType', ''), lang))}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={10} style={{ marginTop: 4, marginBottom: 4 }}>
                                    <Grid container spacing={theme.spacing.unit} alignItems="center">
                                        <Grid
                                            item
                                            xs={overflow ? 11 : 12}
                                            onClick={
                                                overflow
                                                    ? () =>
                                                          setAcceptedItemsExpanded({
                                                              ...acceptedItemsExpanded,
                                                              [commodity.skuType]: !expanded
                                                          })
                                                    : () => {}
                                            }
                                            style={{ cursor: overflow ? 'pointer' : 'default' }}
                                        >
                                            <div
                                                style={{
                                                    transitionProperty: 'max-height',
                                                    transitionDuration: '0.5s',
                                                    transitionTimingFunction: 'ease',
                                                    height: expanded ? null : 26,
                                                    maxHeight: expanded ? 600 : 26,
                                                    overflow: 'hidden'
                                                }}
                                                key={`accepted-${commodityIndex}`}
                                            >
                                                <div
                                                    style={{
                                                        marginBottom: theme.spacing.unit,
                                                        display: 'flex',
                                                        flexWrap: expanded ? 'wrap' : null
                                                    }}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap: expanded ? 'wrap' : null,
                                                                marginTop: overflow ? '-2px' : null
                                                            }}
                                                        >
                                                            {(acceptedExamples || []).map((example, index) => (
                                                                <Paper
                                                                    elevation={0}
                                                                    style={{
                                                                        margin: theme.spacing.unit / 2,
                                                                        padding: '4px',
                                                                        paddingTop: '0px',
                                                                        borderRadius: 3,
                                                                        height: 20,
                                                                        border: `1px solid rgba(0, 0, 0, 0.12)`,
                                                                        backgroundColor: theme.palette.background.paper,
                                                                        verticalAlign: 'middle',
                                                                        maxWidth: expanded ? null : 200,
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'
                                                                    }}
                                                                    key={`accepted-${commodityIndex}-${index}`}
                                                                >
                                                                    {!_.isNil(_.get(example, 'iconName')) &&
                                                                    !_.isEmpty(_.get(example, 'iconName')) ? (
                                                                        <div
                                                                            style={{
                                                                                display: 'inline-flex',
                                                                                alignItems: 'center'
                                                                            }}
                                                                        >
                                                                            <MDIcon
                                                                                path={allIcons[example.iconName]}
                                                                                color={'black'}
                                                                                size={0.5}
                                                                                style={{
                                                                                    marginRight: theme.spacing.unit / 2
                                                                                }}
                                                                            />
                                                                            <Typography
                                                                                noWrap
                                                                                variant="caption"
                                                                                style={{
                                                                                    fontSize: 10,
                                                                                    maxWidth: expanded ? null : 150,
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis'
                                                                                }}
                                                                            >
                                                                                {_.get(example, `desc.${lang}`)}
                                                                            </Typography>
                                                                        </div>
                                                                    ) : (
                                                                        <Typography
                                                                            noWrap
                                                                            variant="caption"
                                                                            style={{
                                                                                fontSize: 10,
                                                                                maxWidth: expanded ? null : 150,
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis'
                                                                            }}
                                                                        >
                                                                            {_.get(example, `desc.${lang}`)}
                                                                        </Typography>
                                                                    )}
                                                                </Paper>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        {overflow && (
                                            <Grid
                                                item
                                                xs={1}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <IconButton
                                                    style={{ padding: 0, margin: 0 }}
                                                    onClick={() =>
                                                        setAcceptedItemsExpanded({
                                                            ...acceptedItemsExpanded,
                                                            [commodity.skuType]: !expanded
                                                        })
                                                    }
                                                >
                                                    <Icon
                                                        style={{
                                                            fontSize: 30,
                                                            padding: 0,
                                                            margin: 0,
                                                            color: theme.palette.text.primary
                                                        }}
                                                    >
                                                        {expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                    </Icon>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                    {!_.isNil(otherMaterialsList) && !_.isEmpty(otherMaterialsList) && (
                        <Grid container spacing={24}>
                            <Grid item xs={4} sm={2} style={{ marginTop: 4, marginBottom: 4 }}>
                                <Typography variant="body1">{loc('other', lang)}:</Typography>
                            </Grid>
                            <Grid item xs={8} sm={10} style={{ marginTop: 4, marginBottom: 4 }}>
                                <Grid container spacing={theme.spacing.unit} alignItems="center">
                                    <Grid
                                        item
                                        xs={otherItemsOverflow ? 11 : 12}
                                        onClick={
                                            otherItemsOverflow
                                                ? () => setOtherItemsExpanded(!otherItemsExpanded)
                                                : () => {}
                                        }
                                        style={{ cursor: otherItemsOverflow ? 'pointer' : 'default' }}
                                    >
                                        <div
                                            style={{
                                                transitionProperty: 'max-height',
                                                transitionDuration: '0.5s',
                                                transitionTimingFunction: 'ease',
                                                height: otherItemsExpanded ? null : 35,
                                                maxHeight: otherItemsExpanded ? 600 : 35,
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginBottom: theme.spacing.unit,
                                                    display: 'flex',
                                                    flexWrap: otherItemsExpanded ? 'wrap' : null
                                                }}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexWrap: otherItemsExpanded ? 'wrap' : null,
                                                            marginTop: otherItemsOverflow ? '-2px' : '-2px'
                                                        }}
                                                    >
                                                        {otherMaterialsList.map((material, materialIndex) => {
                                                            return (
                                                                <Paper
                                                                    elevation={0}
                                                                    style={{
                                                                        margin: theme.spacing.unit / 2,
                                                                        padding: '4px',
                                                                        paddingTop: '0px',
                                                                        borderRadius: 3,
                                                                        height: 20,
                                                                        border: `1px solid rgba(0, 0, 0, 0.12)`,
                                                                        backgroundColor: theme.palette.background.paper,
                                                                        verticalAlign: 'middle',
                                                                        maxWidth: otherItemsExpanded ? null : 200,
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'
                                                                    }}
                                                                    key={`otherMaterial-${materialIndex}`}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'inline-flex',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        <MDIcon
                                                                            path={allIcons[material.iconName]}
                                                                            color={'black'}
                                                                            size={0.5}
                                                                            style={{
                                                                                marginRight: theme.spacing.unit / 2
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            noWrap
                                                                            variant="caption"
                                                                            style={{
                                                                                fontSize: 10,
                                                                                maxWidth: otherItemsExpanded
                                                                                    ? null
                                                                                    : 150,
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis'
                                                                            }}
                                                                        >
                                                                            {_.get(
                                                                                material,
                                                                                `payloadInputName.${lang}`
                                                                            )}
                                                                        </Typography>
                                                                    </div>
                                                                </Paper>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    {otherItemsOverflow && (
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <IconButton
                                                style={{ padding: 0, margin: 0 }}
                                                onClick={() => setOtherItemsExpanded(!otherItemsExpanded)}
                                            >
                                                <Icon
                                                    style={{
                                                        fontSize: 30,
                                                        padding: 0,
                                                        margin: 0,
                                                        color: theme.palette.text.primary
                                                    }}
                                                >
                                                    {otherItemsExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                </Icon>
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </CustomWrapper>
            </>
        );
    };

    const printWarnings = () => {
        if (_.isEmpty(warningsList)) return;
        return (
            <>
                <Grid spacing={24} style={{ marginBottom: theme.spacing.unit * 2 }}>
                    {warningsList.map((warning, index) => {
                        const lastItem = index === warningsList.length - 1;
                        return (
                            <Grid
                                item
                                container
                                style={{ marginBottom: !lastItem && theme.spacing.unit / 2, alignItems: 'center' }}
                                key={`warning-${_.get(warning, '_id', index)}`}
                            >
                                <Grid item xs={2} sm={1}>
                                    <MDIcon
                                        path={allIcons[warning.iconName]}
                                        color={'black'}
                                        size={1}
                                        style={{
                                            marginRight: theme.spacing.unit / 4
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={10} sm={11}>
                                    <Typography variant="body1" style={{ fontSize: '.85rem' }}>
                                        {_.get(warning, `text.${lang}`)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    };

    useEffect(() => {
        async function updateAvailableTimes() {
            const res = await http.getJSON(
                `/dropLocations/getAvailableBookingsForDay?date=${date}&dropLocation=${
                    _.first(dropAndGoTechnologies)._id
                }`
            );

            if (res.ok) {
                const { availableTimes } = res.data;
                if (!_.isNil(availableTimes) && !_.isEmpty(availableTimes)) {
                    // set default time to first booking slot available for the day
                    const defaultTime = moment(availableTimes[0]).tz(selectedDropLocationTZ);

                    setInHouseBookingTimeSlots(availableTimes);
                    handleDateChange(defaultTime);
                } else {
                    setInHouseBookingTimeSlots([]);
                }
            }
        }

        // need to add changing to inHouseTimeSlotBookings
        if (inHouseBookings) {
            setInProgress(true);
            updateAvailableTimes();
            setInProgress(false);
        }
    }, [moment(date).format('LL')]);

    const { beverageAccepted, electronicsAccepted, clothingAccepted } = useMemo(() => {
        const payloadAccepted = _dropLocation.getPayloadAccepted(_.first(dropAndGoTechnologies));
        let beverageAccepted = false,
            electronicsAccepted = false,
            clothingAccepted = false;
        _.forEach(payloadAccepted, commodity => {
            if (_commodity.getSkuType(commodity) === 'beverage') {
                beverageAccepted = true;
            } else if (_commodity.getSkuType(commodity) === 'electronics') {
                electronicsAccepted = true;
            } else if (_commodity.getSkuType(commodity) === 'clothing') {
                clothingAccepted = true;
            }
            //TO-DO add checks for large appliances and quickdrop location
        });

        return { beverageAccepted, electronicsAccepted, clothingAccepted };
    }, [dropAndGoTechnologies]);

    useEffect(() => {
        let commodities = _.cloneDeep(collectorCommodities);
        let technologies = [];
        if (!_.isNil(dropAndGoCollector) && dropAndGoCollector.openToPublic) {
            technologies.push({
                value: 'Primary Return Site',
                icon: 'business',
                localizationName: 'primaryReturnSite'
            });
            //also should get commodities...
        }
        dropAndGoTechnologies.forEach(dropLocation => {
            if (dropLocation.existsAtReturnSite) {
                //Append Drop Location accepted commodities to the collector's commodities
                const payloadAccepted = _dropLocation.getPayloadAccepted(dropLocation);
                _.forEach(payloadAccepted, commodity => {
                    if (!commodities.some(c => c.skuType === commodity.skuType)) {
                        commodities.push(commodity);
                    }
                    //TO-DO add checks for large appliances and quickdrop location
                });
            }
            if (!technologies.some(t => t.value === dropLocation.dropOffType)) {
                const technologyInfo = _.find(DROP_OFF_TYPES, type => type.value === dropLocation.dropOffType);
                technologies.push({
                    ...technologyInfo,
                    dropLocationId: dropLocation._id,
                    qrScanRequired: dropLocation.qrScanRequired
                });
            }
        });
        setCommodityList(commodities);
        setTechnologyList(technologies);
    }, [dropAndGoTechnologies, dropAndGoCollector, collectorCommodities]);

    useEffect(() => {
        const filteredOtherMaterials = collectorOtherMaterials.filter(material => !material.disabled);
        setOtherMaterialsList(filteredOtherMaterials);
    }, [collectorOtherMaterials]);

    useEffect(() => {
        const filteredCollectorWarnings = collectorWarnings.filter(warning => !warning.disabled);
        setWarningsList(filteredCollectorWarnings);
    }, [collectorWarnings]);

    const renderCommodityIcons = () => {
        if (_.isEmpty(commodityList)) return;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                {commodityList.map(commodity => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            verticalAlign: 'middle',
                            maxWidth: 125
                        }}
                    >
                        <tr style={{ fontWeight: 300, verticalAlign: 'middle', color: 'white' }}>
                            <td style={{ lineHeight: 0, width: 32, color: 'white' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: commodity.color
                                    }}
                                >
                                    <MDIcon
                                        key={commodity.iconName}
                                        path={allIcons[commodity.iconName]}
                                        color={'white'}
                                        size={1}
                                    />
                                </Avatar>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{
                                        marginLeft: theme.spacing.unit,
                                        marginRight: theme.spacing.unit
                                        // textTransform: 'capitalize'
                                    }}
                                >
                                    {_commodity.getPayloadInputName(commodity, lang)}
                                    {/* {uppercaseFirstLetter(_commodity.getPayloadInputName(commodity, lang))} */}
                                </Typography>
                            </td>
                        </tr>
                    </div>
                ))}
            </div>
        );
    };

    const renderTechnologyIcons = () => {
        if (_.isEmpty(technologyList)) return;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {technologyList.map(technology => (
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', maxWidth: 125 }}>
                        <tr style={{ fontWeight: 300, verticalAlign: 'middle', color: 'white' }}>
                            <td style={{ lineHeight: 0, width: 32, color: 'white' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main
                                    }}
                                >
                                    <Icon color="white"> {technology.icon}</Icon>
                                </Avatar>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{
                                        marginLeft: theme.spacing.unit,
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    {loc(technology.localizationName, lang)}
                                </Typography>
                            </td>
                        </tr>
                    </div>
                ))}
            </div>
        );
    };

    const renderContinueButtons = () => {
        let buttonTechnologies = _.filter(
            technologyList,
            t => t.value !== 'Primary Return Site' && !_.isNil(t.dropLocationId)
        );
        let hasQRButton = false;
        return (
            <>
                {/* {buttonTechnologies.length > 0 && (
                    <Grid item xs={12}>
                        <Divider style={{ marginTop: theme.spacing.unit * 4, marginBottom: theme.spacing.unit * 4 }} />
                    </Grid>
                )} */}
                {buttonTechnologies.map(technology => {
                    const isAccessDoor = technology.value === 'Access Door';
                    const isAppOnly = technology.value === 'App Only';
                    hasQRButton = technology.qrScanRequired ? true : hasQRButton;
                    const hasTwoButtons = isAccessDoor || hasQRButton;

                    let technologyName = `${loc(technology.localizationName, lang)}`;
                    if (isCONRegion() && lang === 'fr' && technology.localizationName.includes('kiosk')) {
                        // TODO: Remove if CA wants more global translations for kiosk (Retour Express)
                        technologyName = 'Retour Express';
                    }

                    return (
                        <>
                            <Typography
                                variant="subtitle1"
                                style={{
                                    textAlign: 'center',
                                    marginTop: theme.spacing.unit / 2,
                                    textTransform: 'capitalize'
                                }}
                            />
                            {isAccessDoor && (
                                <Grid item xs={12}>
                                    <Button
                                        data-cy="quickdrop-steps-continue-button"
                                        fullWidth
                                        variant="contained"
                                        onClick={onAccessDoor(technology.dropLocationId)}
                                        color="primary"
                                        style={{
                                            margin: theme.spacing.unit / 2
                                        }}
                                    >
                                        <Icon style={{ fontSize: 18, padding: 0, margin: '0px 8px' }}>
                                            {technology.icon}
                                        </Icon>
                                        {loc('openAccessDoorStep', lang)}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    data-cy="quickdrop-steps-instructions-button"
                                    fullWidth
                                    variant="contained"
                                    onClick={onNext(technology.dropLocationId)}
                                    color="primary"
                                    style={{
                                        margin: theme.spacing.unit / 2
                                    }}
                                    disabled={!isOpen && isAppOnly}
                                >
                                    <Icon style={{ fontSize: 18, padding: 0, margin: '0px 8px' }}>
                                        {technology.icon}
                                    </Icon>
                                    {isAppOnly
                                        ? `${loc('pickupDialogDrop9e', lang)} ${loc(technology.localizationName, lang)}`
                                        : `${technologyName} ${loc('quickdropInstructionsStep', lang)}`}
                                </Button>
                            </Grid>
                        </>
                    );
                })}
                {hasQRButton && (
                    <Grid item xs={12}>
                        <Button
                            data-cy="quickdrop-steps-continue-button"
                            fullWidth
                            variant="contained"
                            onClick={onQRDialog(true)}
                            color="primary"
                            style={{
                                margin: theme.spacing.unit / 2
                            }}
                        >
                            <Icon style={{ fontSize: 18, margin: `0px ${theme.spacing.unit}px` }}>qr_code_scanner</Icon>
                            {loc('scanQRCode', lang)}
                        </Button>
                    </Grid>
                )}
            </>
        );
    };

    return (
        <>
            {loadingCollectorHours || GPSLoading || loadingCollectorCommodities ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing.unit * 2 }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Grid
                        container
                        spacing={16}
                        style={{ marginTop: theme.spacing.unit * 2, paddingLeft: theme.spacing.unit / 2 }}
                    >
                        <Grid item xs={12} sm={2}>
                            <Typography variant="body1">
                                <b>{loc('address', lang)}:</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Typography variant="body1">
                                {_.get(
                                    dropAndGoCollector,
                                    'location.description',
                                    _.get(_.first(dropAndGoTechnologies), 'location.description', 'N/A')
                                )}
                            </Typography>
                        </Grid>
                        {!_.isNil(contactPhone) && !_.isEmpty(contactPhone) && (
                            <Grid item xs={12} sm={2}>
                                <Typography variant="body1">
                                    <b>{loc('call', lang)}:</b>
                                </Typography>
                            </Grid>
                        )}
                        {!_.isNil(contactPhone) && !_.isEmpty(contactPhone) && (
                            <Grid item xs={12} sm={10}>
                                <Typography variant="body1">{formatAsPhoneNumber(contactPhone)}</Typography>
                            </Grid>
                        )}

                        <Grid item xs={12} sm={2}>
                            <Typography variant="body1">
                                <b>{loc('hours', lang)}:</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            {printHours()}
                        </Grid>
                    </Grid>
                    {/* <Divider style={{ marginTop: theme.spacing.unit * 4, marginBottom: theme.spacing.unit * 4 }} /> */}
                    {/*{commodityList.length + technologyList.length <= maxInlineItems && (
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {renderCommodityIcons()}
                            {!_.isEmpty(commodityList) && !_.isEmpty(technologyList) && (
                                <div
                                    style={{
                                        borderLeft: '1px solid ' + theme.palette.text.hint,
                                        paddingLeft: theme.spacing.unit * 2,
                                        paddingRight: theme.spacing.unit / 2,
                                        marginLeft: theme.spacing.unit * 2,
                                        marginRight: theme.spacing.unit / 2,
                                        height: '50px'
                                    }}
                                />
                            )}
                            {renderTechnologyIcons()}
                        </div>
                    )} */}

                    {/* {commodityList.length + technologyList.length > maxInlineItems && (
                        <>
                            {renderCommodityIcons()}
                            <Divider
                                style={{ marginTop: theme.spacing.unit * 4, marginBottom: theme.spacing.unit * 4 }}
                            />
                            {renderTechnologyIcons()}
                        </>
                    )}
                    <Divider style={{ marginTop: theme.spacing.unit * 4, marginBottom: theme.spacing.unit * 4 }} /> */}

                    <Grid container spacing={theme.spacing.unit} style={{ paddingRight: theme.spacing.unit }}>
                        <Grid item xs={12}>
                            {printCommodities()}
                        </Grid>
                        <Grid item xs={12}>
                            {printWarnings()}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => {
                                    showOnGoogleMaps(
                                        _.get(
                                            dropAndGoCollector,
                                            'location',
                                            _.get(_.first(dropAndGoTechnologies), 'location')
                                        )
                                    );
                                }}
                                color="primary"
                                style={{
                                    margin: theme.spacing.unit / 2,
                                    borderWidth: 2,
                                    borderColor: theme.palette.primary.main
                                }}
                            >
                                <Icon style={{ margin: `0px ${theme.spacing.unit * 0.5}px` }}>directions</Icon>
                                {loc('pickupDialogDrop9', lang)}
                            </Button>
                        </Grid>
                        {!_.isNil(contactPhone) && (
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={() => {
                                        callLocation();
                                    }}
                                    color="primary"
                                    style={{
                                        margin: theme.spacing.unit / 2,
                                        borderWidth: 2,
                                        borderColor: theme.palette.primary.main
                                    }}
                                >
                                    <Icon style={{ margin: `0px ${theme.spacing.unit * 0.5}px` }}>phone</Icon>
                                    {loc('pickupDialogDrop9c', lang)}
                                </Button>
                            </Grid>
                        )}

                        {renderContinueButtons()}
                    </Grid>
                </>
            )}
        </>
    );
};

export default withWidth()(withTheme()(DropAndGo));

const getFormattedHours = (hours, lang) => {
    if (!lang || lang === 'en') {
        return hours == '12am–11:59pm' ? '24 hours' : hours;
    }

    if (lang === 'fr' && hours == '12am–11:59pm') {
        return '24 heures';
    }

    function convertMatch(match) {
        // Convert to fr format
        const period = match.slice(-2);
        let [hours, minutes] = match.slice(0, -2).split(':');

        minutes = minutes || '';

        hours = parseInt(hours, 10);

        if (period.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12;
        } else if (period.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
        }

        return `${hours}${minutes}h`;
    }

    return hours.replace(/\d{1,2}(:\d{2})?(am|pm)/gi, convertMatch).replace('–', '-');
};
