import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import { getCustomerName, formatAsCurrency, getBulkCompletionDate, convertToImportName } from 'utils/misc';
import * as terms from 'localizations/terms';
import * as allIcons from '@mdi/js';
import bulkHelper from 'helpers/bulkHelper';

import { HistoryWidget } from 'components/CustomerWidgets';
import MDIcon from '@mdi/react';

import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

import { mdiProgressClock, mdiCurrencyUsd, mdiHeart } from '@mdi/js';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const CharityHistory = ({ reloadCharity, charity, theme, bulks, commoditiesAvailable, redemptions, http }) => {
    const { lang } = useContext(LocalizationContext);
    const [historyIcons, setHistoryIcons] = useState({});
    async function fetchHistoryIcons() {
        let res = await http.getJSON('/system/configuration/historyIcons');
        if (res.ok) {
            setHistoryIcons(_.get(res, 'data.historyIcons', {}));
        }
    }
    useEffect(() => {
        fetchHistoryIcons();
        reloadCharity();
    }, []);

    const bulkItems = bulks.map((bulk, index) => {
        let commodity = null;
        commoditiesAvailable.forEach(comm => {
            if (comm.skuType === bulk.skuType) {
                commodity = comm;
            }
        });

        return {
            id: `charity-history-bulk-${index}`,
            key: bulk._id,
            type: 'DONATION',
            color: _.get(historyIcons, `donation.color`, colors.grey[200]),
            icon: (
                <MDIcon
                    path={_.get(allIcons, convertToImportName(_.get(historyIcons, `donation.icon`, 'dotsHorizontal')))}
                    size={1}
                    color={theme.palette.background.default}
                />
            ),
            date: getBulkCompletionDate(bulk),
            timezone: _.get(bulk, 'pickup.zone.timezone', process.env.REACT_APP_REGION_TIMEZONE), // for bulks with no pickup, we just use local timezone (should make sense for manual adjustments, but will need more thought for quick drop)
            primary: loc('history4', lang, {
                skuType: _.startCase(bulk.skuType),
                name: getCustomerName(bulk.owner),
                charity: charity.name
            }),
            secondary: formatAsCurrency(
                bulkHelper.getCustomerAmount(bulk) -
                    (bulk.tip ? bulk.tip.adjustedAmount || bulk.tip.originalAmount || 0 : 0) * 100,
                lang
            ),
            commodity: commodity
        };
    });

    // added filter to prevent uncounted bulks from being shown
    const redemptionsItems = redemptions.map(redemption => {
        let redemptionMessage;
        switch (redemption.paymentModel) {
            case 'AUS_DIRECT_BANKING':
                redemptionMessage = `${loc('ETRANSFERS_NAME_SHORT', lang)} ${
                    redemption.complete ? loc('sent', lang) : loc('requested', lang)
                } ${loc('to', lang)} ${_.get(redemption, 'banking.accountNumberMasked', 'N/A')}`;
                break;
            case 'INTERAC_ETRANSFERS':
                redemptionMessage = `${loc('ETRANSFERS_NAME_SHORT', lang)} ${
                    redemption.complete ? loc('sent', lang) : loc('requested', lang)
                } ${loc('to', lang)} ${redemption.email}`;
                break;
            case 'CHEQUE':
                redemptionMessage = `${loc('cheque', lang)} ${
                    redemption.complete ? loc('sent', lang) : loc('requested', lang)
                } ${loc('to', lang)} ${redemption.passphrase.split(' - ')[1]}`;
                break;
            case 'CASH':
                redemptionMessage = loc('history13', lang);
                break;
            case 'BANK_TRANSFER':
                redemptionMessage = `Bank transfer ${
                    redemption.complete ? loc('sent', lang) : loc('requested', lang)
                } ${loc('to', lang)} ${_.get(redemption, 'banking.accountNumberMasked', 'N/A')}`;
                break;
            default:
                redemptionMessage = '<unknown destination>'; // should never occur
        }
        return {
            key: redemption._id,
            type: 'REDEMPTION',
            color: _.get(
                historyIcons,
                `${redemption.complete ? 'redemptionCompleted' : 'redemptionPending'}.color`,
                colors.grey[200]
            ),
            icon: (
                <MDIcon
                    path={_.get(
                        allIcons,
                        convertToImportName(
                            _.get(
                                historyIcons,
                                `${redemption.complete ? 'redemptionCompleted' : 'redemptionPending'}.icon`,
                                'dotsHorizontal'
                            )
                        )
                    )}
                    size={1}
                    color={theme.palette.background.default}
                />
            ),
            date: redemption.createdAt,
            timezone: process.env.REACT_APP_REGION_TIMEZONE,
            primary: redemptionMessage,
            secondary: formatAsCurrency(redemption.amount - redemption.fee, lang)
        };
    });

    const combinedItems = _.sortBy(bulkItems.concat(redemptionsItems), item => item.date).reverse();

    return (
        <HistoryWidget
            theme={theme}
            items={combinedItems}
            defaultFilters={[
                { name: loc('history2', lang), value: 'DONATION' },
                { name: loc('history3', lang), value: 'REDEMPTION' }
            ]}
            style={{
                width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                margin: theme.spacing.unit * 2,
                padding: theme.spacing.unit * 4
            }}
        />
    );
};

export default withTheme()(CharityHistory);
