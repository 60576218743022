import { useState } from 'react';
import _ from 'lodash';

import CLIENT_VERSION from 'VERSION';

function useHttp({ dispatch, DEBUG, debugHistory, setDebugHistory }) {
    const [httpErrorDialogOpen, setHttpErrorDialogOpen] = useState(false);
    const [httpErrorType, setHttpErrorType] = useState(undefined);
    const [oversizedFileSize, setOversizedFileSize] = useState(null);

    const [bytesSent, setBytesSent] = useState(0);
    const [bytesReceived, setBytesReceived] = useState(0);

    const handleHttpErrorState = errorType => {
        setHttpErrorDialogOpen(true);
        setHttpErrorType(errorType);
    };

    const handleCloseHttpErrorDialog = () => {
        setHttpErrorDialogOpen(false);
    };

    /**
     * An object that handles all AJAX requests.
     * @type {Object}
     */
    const http = {
        /**
         * GET request that expects JSON response.
         * @param  {string}  url                  request target URL
         * @param  {Boolean} errorMessageExpected indicates whether an error will be returned from the server (or generated by the client) for local usage
         * @param  {Boolean} ignoreAllErrors      indicates whether all error messages will be ignored (except 401 Not Authenticated)
         * @return {Object}
         */
        getJSON: (url, errorMessageExpected = false, ignoreAllErrors = false) => {
            const reqStartTime = new Date();
            // console.log(`${url}, ${Number(reqStartTime)}`);
            return fetch(process.env.REACT_APP_API_URL + url, { credentials: 'include' })
                .then(async res => {
                    if (DEBUG) {
                        console.log(`GET ${url}`, res);
                    }

                    if (res.ok) {
                        let data = await res.json();

                        try {
                            const reqEndTime = new Date();
                            // console.log(`${url}, ${Number(reqEndTime)}`);

                            const dataStringified = JSON.stringify(data);
                            const resSize = Buffer.byteLength(dataStringified);

                            const newHistoryItem = {
                                url,
                                type: 'http-get',
                                size: resSize,
                                startTime: reqStartTime,
                                endTime: reqEndTime
                            };

                            // const resSize = res['headers'].get('Content-Length');

                            setBytesReceived(bytesReceived => bytesReceived + Number(resSize));
                            setDebugHistory(debugHistory => [...debugHistory, newHistoryItem]);
                        } catch (err) {
                            // Handle error
                        }

                        if (DEBUG) {
                            console.log(`GET ${url} (data)`, data);
                        }

                        return { data, ok: true };
                    } else {
                        if (res.status === 400 && errorMessageExpected) {
                            let errorMessage;
                            try {
                                errorMessage = (await res.json()).message; // error message supplied by the server
                            } catch (err) {
                                errorMessage = 'Bad request.'; // error message to be generated locally
                            }

                            return { errorMessage, ok: false, status: 400 };
                        } else if (res.status === 401) {
                            let banned = false;
                            try {
                                //check if 401 resulted from user being banned
                                banned = _.get(await res.json(), 'banned');
                            } catch (err) {
                                banned = false;
                            }

                            if (banned) {
                                handleHttpErrorState('ACCOUNT_BANNED');
                                dispatch({ type: 'SET_AUTH_FALSE' });
                            } else {
                                handleHttpErrorState('NOT_AUTHENTICATED');
                                dispatch({ type: 'SET_AUTH_FALSE' });
                            }
                        } else if (res.status === 403) {
                            if (!ignoreAllErrors) handleHttpErrorState('NOT_AUTHORIZED');
                        } else if (res.status === 502) {
                            if (!ignoreAllErrors) handleHttpErrorState('BACK_SERVER_OFFLINE');
                        } else {
                            if (!ignoreAllErrors) handleHttpErrorState('UNRECOVERABLE');
                        }

                        return { ok: false, status: res.status };
                    }
                })
                .catch(err => {
                    if (!ignoreAllErrors) handleHttpErrorState(true, 'UNRECOVERABLE');
                    return { ok: false, status: 503 };
                });
        },

        /**
         * Performs POST request to the API server. Returns plain response.
         * @param  {String}  url                  target URL
         * @param  {Object}  body                 payload
         * @param  {Boolean} errorMessageExpected indicates whether an error will be returned from the server (or generated by the client) for local usage
         * @return {Promise}                      promise which resolves into http response object
         */
        post: (url, body = {}, errorMessageExpected = false, fileUpload = false) => {
            const reqStartTime = new Date();
            body.clientVersion = CLIENT_VERSION;

            let options;
            if (fileUpload) {
                options = {
                    method: 'POST',
                    credentials: 'include',
                    body
                };
            } else {
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body),
                    credentials: 'include'
                };
            }

            return fetch(process.env.REACT_APP_API_URL + url, options)
                .then(async res => {
                    if (DEBUG) {
                        console.log(`POST ${url}`, res);
                    }

                    try {
                        const reqEndTime = new Date();
                        const reqSize = Buffer.byteLength(options.body);
                        const newHistoryItem = {
                            url,
                            type: 'http-post',
                            size: reqSize,
                            startTime: reqStartTime,
                            endTime: reqEndTime
                        };

                        setBytesSent(bytesSent => bytesSent + Number(reqSize));
                        setDebugHistory(debugHistory => [...debugHistory, newHistoryItem]);
                    } catch (err) {
                        // Handle error
                    }

                    if (res.status === 200) {
                        return { ok: true };
                    } else if (res.status >= 200 && res.status < 300) {
                        return { ok: true, status: res.status };
                    } else if (res.status === 400 && errorMessageExpected) {
                        let errorMessage;
                        const msg = await res.json();

                        try {
                            errorMessage = msg.message; // error message supplied by the server
                        } catch (err) {
                            errorMessage = 'Bad request.'; // error message to be generated locally
                        }

                        return { errorMessage, ok: false, status: 400, variant: _.get(msg, 'variant', 'error') };
                    } else if (res.status === 400) {
                        return { ok: false };
                    } else if (res.status === 429 && errorMessageExpected) {
                        let errorMessage;
                        const msg = await res.json();

                        try {
                            errorMessage = msg.message; // error message supplied by the server
                        } catch (err) {
                            errorMessage = 'Too Many Requests.'; // error message to be generated locally
                        }

                        return { errorMessage, ok: false, status: 429, variant: _.get(msg, 'variant', 'error') };
                    } else {
                        if (res.status === 401) {
                            handleHttpErrorState('NOT_AUTHENTICATED');
                            dispatch({ type: 'SET_AUTH_FALSE' });
                        } else if (res.status === 403) {
                            handleHttpErrorState('NOT_AUTHORIZED');
                        } else if (res.status === 413) {
                            if (body instanceof FormData) {
                                let clerkVideoFile = body.get('clerkVideoFile');
                                if (!_.isNil(clerkVideoFile)) {
                                    setOversizedFileSize(body.get('clerkVideoFile').size);
                                }
                            }
                            handleHttpErrorState('PAYLOAD_TOO_LARGE');
                        } else if (res.status === 480) {
                            handleHttpErrorState('CLIENT_SERVER_VERSION_MISMATCH');
                        } else if (res.status === 502) {
                            handleHttpErrorState('BACK_SERVER_OFFLINE');
                        } else if (res.status === 429) {
                            handleHttpErrorState('TOO_MANY_REQUESTS');
                        } else {
                            handleHttpErrorState('UNRECOVERABLE');
                        }

                        return { ok: false, status: res.status };
                    }
                })
                .catch(err => {
                    handleHttpErrorState('UNRECOVERABLE');
                    return { ok: false, status: 503 };
                });
        },

        postJSON: (url, body = {}, errorMessageExpected = false, fileUpload = false) => {
            const reqStartTime = new Date();
            body.clientVersion = CLIENT_VERSION;

            let options;
            if (fileUpload) {
                options = {
                    method: 'POST',
                    credentials: 'include',
                    body
                };
            } else {
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body),
                    credentials: 'include'
                };
            }

            return fetch(process.env.REACT_APP_API_URL + url, options)
                .then(async res => {
                    if (DEBUG) {
                        console.log(`POST ${url}`, res);
                    }

                    try {
                        const reqEndTime = new Date();
                        const reqSize = Buffer.byteLength(options.body);
                        const newHistoryItem = {
                            url,
                            type: 'http-post',
                            size: reqSize,
                            startTime: reqStartTime,
                            endTime: reqEndTime
                        };

                        setBytesSent(bytesSent => bytesSent + Number(reqSize));
                        setDebugHistory(debugHistory => [...debugHistory, newHistoryItem]);
                    } catch (err) {
                        // Handle error
                    }

                    if (res.status === 200) {
                        let data = await res.json();
                        if (DEBUG) {
                            console.log(`POST ${url} (data)`, data);
                        }

                        return { data, ok: true };
                    } else if (res.status === 400 && errorMessageExpected) {
                        let errorMessage;
                        try {
                            errorMessage = (await res.json()).message;
                        } catch (err) {
                            errorMessage = 'Bad request.';
                        }

                        return { errorMessage, ok: false, status: 400 };
                    } else {
                        if (res.status === 401) {
                            handleHttpErrorState('NOT_AUTHENTICATED');
                            dispatch({ type: 'SET_AUTH_FALSE' });
                        } else if (res.status === 480) {
                            handleHttpErrorState('CLIENT_SERVER_VERSION_MISMATCH');
                        } else if (res.status === 502) {
                            handleHttpErrorState('BACK_SERVER_OFFLINE');
                        } else if (res.status === 429) {
                            handleHttpErrorState('TOO_MANY_REQUESTS');
                        } else {
                            handleHttpErrorState('UNRECOVERABLE');
                        }

                        return { ok: false, status: res.status };
                    }
                })
                .catch(err => {
                    handleHttpErrorState('UNRECOVERABLE');
                    return { ok: false, status: 503 };
                });
        },

        /**
         * Performs POST request to the API server. Returns plain response.
         * @param  {String}  url                  target URL
         * @param  {Object}  body                 payload
         * @param  {Boolean} errorMessageExpected indicates whether an error will be returned from the server (or generated by the client) for local usage
         * @return {Promise}                      promise which resolves into http response object
         */
        download: (url, fileName, errorMessageExpected = false, ignoreAllErrors = false) => {
            return fetch(process.env.REACT_APP_API_URL + url, { credentials: 'include' })
                .then(async res => {
                    if (DEBUG) {
                        console.log(`GET ${url}`, res);
                    }

                    if (res.ok) {
                        let blob = await res.blob();
                        if (DEBUG) {
                            console.log(`GET ${url} (blob)`, blob);
                        }

                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove(); //afterwards we remove the element again

                        return { ok: true };
                    } else {
                        if (res.status === 400 && errorMessageExpected) {
                            let errorMessage;
                            try {
                                errorMessage = (await res.json()).message; // error message supplied by the server
                            } catch (err) {
                                errorMessage = 'Bad request.'; // error message to be generated locally
                            }

                            return { errorMessage, ok: false, status: 400 };
                        } else if (res.status === 401) {
                            handleHttpErrorState('NOT_AUTHENTICATED');
                            dispatch({ type: 'SET_AUTH_FALSE' });
                        } else if (res.status === 403) {
                            if (!ignoreAllErrors) handleHttpErrorState('NOT_AUTHORIZED');
                        } else if (res.status === 502) {
                            if (!ignoreAllErrors) handleHttpErrorState('BACK_SERVER_OFFLINE');
                        } else {
                            if (!ignoreAllErrors) handleHttpErrorState('UNRECOVERABLE');
                        }

                        return { ok: false, status: res.status };
                    }
                })
                .catch(err => {
                    console.error(err);
                    if (!ignoreAllErrors) handleHttpErrorState('UNRECOVERABLE');

                    return { ok: false, status: 503 };
                });
        }
    };

    return {
        http,
        httpErrorDialogOpen,
        httpErrorType,
        handleCloseHttpErrorDialog,
        oversizedFileSize,
        bytesReceived,
        bytesSent
    };
}

export default useHttp;
