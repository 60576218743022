import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import CollectorsSelectDropdown from 'components/DropDownSelects/Collectors';
import * as colors from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';

import { validate } from 'utils/validate';
import * as terms from 'localizations/terms';

import {
    withMobileDialog,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    OutlinedInput,
    FormControlLabel,
    TextField,
    Switch,
    Grid,
    Checkbox,
    Icon,
    InputAdornment,
    Collapse,
    ListItemSecondaryAction,
    IconButton,
    ListItemText,
    Avatar,
    ListItem,
    List
} from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { withTheme } from '@material-ui/core/styles';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { isAUSRegion, deviceHelper, getUserAccountStatus, isCONRegion } from 'utils/misc';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CharitySelectDialog from 'components/CharitySelect/CharitySelectDialog';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import CharityInfoDialog from 'components/CharityInfoDialog/CharityInfoDialog';
import AutoRedemptionDialog from 'components/RedemptionDialog/AutoRedemptionDialog';

import { Icon as MDIcon } from '@mdi/react';
import { mdiAccountSearch, mdiAccountOutline } from '@mdi/js';
import HelpDialog from 'components/HelpDialog/HelpDialog';
import { FEE_APPLICATION_OPTIONS, INVOICE_FREQUENCY_OPTIONS } from '../../constants';
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';

const DAYS_OF_THE_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const UserPreferencesDialog = ({
    http,
    collectors,
    customer,
    operator,
    charity,
    redemptionOptions,
    accountSelected,
    theme,
    fullScreen,
    open,
    onClose,
    reloadCustomer,
    reloadCharity,
    onSnackbar,
    charities,
    redemptionFee,
    redemptions,
    accountName,
    emailVerificationRequired,
    emailVerificationRequiredForETransfer,
    handleVerifyEmailDialog,
    balance,
    collectionProgramEnabled
}) => {
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);

    const history = useHistory();
    const [prefs, setPrefs] = useState({});
    const [pickupLimitError, setPickupLimitError] = useState({});
    const [collector, setCollector] = useState(_.get(customer, 'collector._id', 'all'));
    const [overrideProcessor, setOverrideProcessor] = useState(_.get(customer, 'overrideProcessor', 'all'));
    const [overrideTransporter, setOverrideTransporter] = useState(_.get(customer, 'overrideTransporter', 'all'));
    const [lockDonationPreference, setLockDonationPreference] = useState(customer.lockDonationPreference);

    const [isSchemeIDUser, setIsSchemeIDUser] = useState(_.get(customer, 'isSchemeIDUser', false));
    const [schemeID, setSchemeID] = useState(_.get(customer, 'schemeID', ''));
    const [schemeIdError, setSchemeIdError] = useState('');

    const [volumeReportPreference, setVolumeReportPreference] = useState(customer.volumeReportPreference);
    const [collectionProgramPreference, setCollectionProgramPreference] = useState(
        customer.collectionProgramPreference
    );
    const [multipleAccountAccessList, setMultipleAccountAccessList] = useState(
        _.get(customer, 'multipleAccountAccessList')
    );
    const [userSearchString, setUserSearchString] = useState('');
    const [userSearchError, setUserSearchError] = useState('');
    const [charityPreferred, setCharityPreferred] = useState(_.get(customer, 'charityPreferred', null));
    const [charitySelected, setCharitySelected] = useState(
        _.find(charities, c => c._id.toString() === charityPreferred)
    );
    const [charitySelectDialogOpen, setCharitySelectDialogOpen] = useState(false);
    const [charityInfoDialogOpen, setCharityInfoDialogOpen] = useState(false);
    const [charityInfo, setCharityInfo] = useState('');

    const [autoRedemptionPreference, setAutoRedemptionPreference] = useState(
        customer.autoRedemptionPreference || false
    );
    const [autoRedemptionDialogOpen, setAutoRedemptionDialogOpen] = useState(false);
    const [autoRedemptionDetails, setAutoRedemptionDetails] = useState(customer.autoRedemptionDetails || {});

    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoDialogContent, setInfoDialogContent] = useState('');
    const [infoDialogTitle, setInfoDialogTitle] = useState('');

    const [feeApplication, setFeeApplication] = useState(_.get(customer, 'feeApplication', FEE_APPLICATION_OPTIONS[0]));
    const [lowVolumeFee, setLowVolumeFee] = useState(_.get(customer, 'lowVolumeFee', 'Not Active'));
    const [invoiceFrequency, setInvoiceFrequency] = useState(_.get(customer, 'invoiceFrequency', '30th of Month'));
    const [feeInvoicePaymentWindow, setFeeInvoicePaymentWindow] = useState(
        _.get(customer, 'feeInvoicePaymentWindow', 30)
    );

    const {
        methods: { etransfer, cheque, bankTransfer, cash = false }
    } = redemptionOptions[accountSelected.split('-')[0] === 'Administrator' ? 'charity' : 'customer'];

    const [tab, setTab] = useState('account');

    const [growthPlanEnabled, setGrowthPlanEnabled] = useState(true);
    const [promos, setPromos] = useState(_.get(customer, 'forcePromoCode', []));

    const handleUpdateOverrideProcessor = async e => {
        const updatedProcessor = e.target.value === 'all' ? null : e.target.value;

        const res = await http.post(`/users/${customer._id}/updateCollectorOverrides`, { processor: updatedProcessor });
        if (res.ok) {
            await reloadCustomer();
            setOverrideProcessor(e.target.value);
            onSnackbar('Successfully updated the processor override');
        } else if (res.status === 400) {
            onSnackbar(res.errorMessage + '.', 'error');
        }
    };

    const handleUpdateOverrideTransporter = async e => {
        const updatedTransporter = e.target.value === 'all' ? null : e.target.value;

        const res = await http.post(`/users/${customer._id}/updateCollectorOverrides`, {
            transporter: updatedTransporter
        });
        if (res.ok) {
            await reloadCustomer();
            setOverrideTransporter(e.target.value);
            onSnackbar('Successfully updated the transporter override');
        } else if (res.status === 400) {
            onSnackbar(res.errorMessage + '.', 'error');
        }
    };

    useEffect(() => {
        //Display current preferences from database
        const allowedDaysOfTheWeek = _.get(customer, 'allowedDaysOfTheWeek', []);
        const isCharityAdmin = accountSelected.split('-')[0] === 'Administrator';
        const bypassRedemptionMinimum = isCharityAdmin
            ? _.get(charity, 'bypassRedemptionMinimum', false)
            : _.get(customer, 'bypassRedemptionMinimum', false);
        const multiplePickups = _.get(customer, 'multiplePickups', { enabled: false, limit: 3 });
        const allowCashRedemption = isCharityAdmin
            ? _.get(charity, 'allowCashRedemption', cash)
            : _.get(customer, 'allowCashRedemption', cash);
        const allowBankTransferRedemption = isCharityAdmin
            ? _.get(charity, 'allowBankTransferRedemption', bankTransfer)
            : _.get(customer, 'allowBankTransferRedemption', bankTransfer);
        const allowETransferRedemption = isCharityAdmin
            ? _.get(charity, 'allowETransferRedemption', etransfer)
            : _.get(customer, 'allowETransferRedemption', etransfer);
        const allowChequeRedemption = isCharityAdmin
            ? _.get(charity, 'allowChequeRedemption', cheque)
            : _.get(customer, 'allowChequeRedemption', cheque);
        const overridePaymentMethods = isCharityAdmin
            ? _.get(charity, 'overridePaymentMethods', false)
            : _.get(customer, 'overridePaymentMethods', false);
        const carryOverNonBeverage = isCharityAdmin
            ? _.get(charity, 'carryOverNonBeverage', false)
            : _.get(customer, 'carryOverNonBeverage', false);
        const bypassPickupThreshold = _.get(customer, 'bypassPickupThreshold', false);
        const bypassZoneTypeRestriction = _.get(customer, 'bypassZoneTypeRestriction', false);
        const preservePriority = _.get(customer, 'preservePriority', false);
        const alwaysConfirmedOverride = _.get(customer, 'alwaysConfirmedOverride', false);
        const bypassBagCountReview = _.get(customer, 'bypassReviewSettings.bypassBagCountReview', false);
        const bypassDriverComplaintReview = _.get(customer, 'bypassReviewSettings.bypassDriverComplaintReview', false);
        const bypassAmountDiffReview = _.get(customer, 'bypassReviewSettings.bypassAmountDiffReview', false);
        const bypassCounterComplaintReview = _.get(
            customer,
            'bypassReviewSettings.bypassCounterComplaintReview',
            false
        );
        const bypassRedemptionAmountReview = _.get(
            customer,
            'bypassReviewSettings.bypassRedemptionAmountReview',
            false
        );
        const bypassRedemptionEmailReview = _.get(customer, 'bypassReviewSettings.bypassRedemptionEmailReview', false);
        const bypassGarbageComplaintReview = _.get(
            customer,
            'bypassReviewSettings.bypassGarbageComplaintReview',
            false
        );
        const bypassHazardousComplaintReview = _.get(
            customer,
            'bypassReviewSettings.bypassHazardousComplaintReview',
            false
        );
        const bypassSameIPAddressReview = _.get(customer, 'bypassReviewSettings.bypassSameIPAddressReview', false);

        //const displayDepositTax = _.get(customer, 'displayDepositTax', false);

        setPrefs({
            allowedDaysOfTheWeek,
            bypassRedemptionMinimum,
            multiplePickups,
            allowCashRedemption: overridePaymentMethods ? allowCashRedemption : cash,
            allowChequeRedemption: overridePaymentMethods ? allowChequeRedemption : cheque,
            allowBankTransferRedemption: overridePaymentMethods ? allowBankTransferRedemption : bankTransfer,
            allowETransferRedemption: overridePaymentMethods ? allowETransferRedemption : etransfer,
            overridePaymentMethods,
            'bypassReviewSettings.bypassBagCountReview': bypassBagCountReview,
            'bypassReviewSettings.bypassDriverComplaintReview': bypassDriverComplaintReview,
            'bypassReviewSettings.bypassAmountDiffReview': bypassAmountDiffReview,
            'bypassReviewSettings.bypassCounterComplaintReview': bypassCounterComplaintReview,
            'bypassReviewSettings.bypassRedemptionAmountReview': bypassRedemptionAmountReview,
            'bypassReviewSettings.bypassRedemptionEmailReview': bypassRedemptionEmailReview,
            'bypassReviewSettings.bypassSameIPAddressReview': bypassSameIPAddressReview,
            'bypassReviewSettings.bypassGarbageComplaintReview': bypassGarbageComplaintReview,
            'bypassReviewSettings.bypassHazardousComplaintReview': bypassHazardousComplaintReview,
            carryOverNonBeverage,
            bypassPickupThreshold,
            bypassZoneTypeRestriction,
            preservePriority,
            alwaysConfirmedOverride
            //displayDepositTax
        });
    }, [accountSelected, cash, bankTransfer, etransfer, cheque, charity, customer]);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/config/charityTaskConfiguration');
            if (res.ok) {
                setGrowthPlanEnabled(res.data.config.growthPlanEnabled);
            }
        })();
    }, []);

    const handleChange = e => {
        setPrefs({ ...prefs, [e.target.name]: e.target.value });
    };

    const handleChecked = e => {
        setPrefs({ ...prefs, [e.target.name]: e.target.checked });
    };

    const handleOverridePaymentMethods = e => {
        let override = e.target.checked;
        const isCharityAdmin = accountSelected.split('-')[0] === 'Administrator';
        if (override) {
            setPrefs({
                ...prefs,
                allowCashRedemption: isCharityAdmin
                    ? _.get(charity, 'allowCashRedemption', cash)
                    : _.get(customer, 'allowCashRedemption', cash),
                allowETransferRedemption: isCharityAdmin
                    ? _.get(charity, 'allowETransferRedemption', etransfer)
                    : _.get(customer, 'allowETransferRedemption', etransfer),
                allowBankTransferRedemption: isCharityAdmin
                    ? _.get(charity, 'allowBankTransferRedemption', bankTransfer)
                    : _.get(customer, 'allowBankTransferRedemption', bankTransfer),
                allowChequeRedemption: isCharityAdmin
                    ? _.get(charity, 'allowChequeRedemption', cheque)
                    : _.get(customer, 'allowChequeRedemption', cheque),
                overridePaymentMethods: override
            });
        } else {
            setPrefs({
                ...prefs,
                allowCashRedemption: cash,
                allowETransferRedemption: etransfer,
                allowBankTransferRedemption: bankTransfer,
                allowChequeRedemption: cheque,
                overridePaymentMethods: override
            });
        }
    };

    const handleCharitySelectDialog = state => () => {
        setCharitySelectDialogOpen(state);
    };

    const handleCharityPreferred = _id => e => {
        setCharitySelectDialogOpen(false);
        setCharitySelected(_.find(charities, c => c._id === _id));
        setCharityPreferred(_id);
    };

    const handleCharityInfoDialog = (state, _id) => () => {
        setCharityInfoDialogOpen(state);
        setCharityInfo(_.find(charities, charity => charity._id === _id));
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter' && !_.isEmpty(userSearchString)) {
            handleAddAccountAccess();
        }
    };

    const handleAddAccountAccess = async () => {
        const res = await http.postJSON(`/users/${customer._id}/addAccountAccess`, { userSearchString }, true);
        if (res.ok) {
            setMultipleAccountAccessList(res.data.multipleAccountAccessList);
            setUserSearchError('');
            setUserSearchString('');
            if (res.ok) {
                onSnackbar('User preferences updated');
                reloadCustomer();
            }
        } else {
            setUserSearchError(res.errorMessage);
        }
    };

    const handleRemoveAccountAccess = async user_id => {
        const res = await http.postJSON(`/users/${customer._id}/removeAccountAccess`, { user_id });
        if (res.ok) {
            setMultipleAccountAccessList(res.data.multipleAccountAccessList);
            onSnackbar('User preferences updated');
            reloadCustomer();
        }
    };

    const handleViewUser = _id => () => {
        if (!deviceHelper.isNativeApp()) {
            window.open(`/customers/${_id}/`, '_blank');
        } else {
            history.push(`/customers/${_id}/`);
        }
    };

    const validatePickupLimit = pickupLimit => {
        const validatePickupLimit = validate(['required', 'pickupLimit'], pickupLimit, lang);
        setPickupLimitError(validatePickupLimit);
    };

    const handleMultiplePickupsChanged = e => {
        let updatedMultiplePickups = _.cloneDeep(prefs.multiplePickups);
        updatedMultiplePickups.limit = e.target.value;

        validatePickupLimit(updatedMultiplePickups.limit);
        setPrefs({ ...prefs, multiplePickups: updatedMultiplePickups });
    };

    const handleMultiplePickupsChecked = e => {
        let updatedMultiplePickups = _.cloneDeep(prefs.multiplePickups);
        updatedMultiplePickups.enabled = e.target.checked;

        if (updatedMultiplePickups.enabled) {
            validatePickupLimit(!_.isNil(updatedMultiplePickups.limit) ? updatedMultiplePickups.limit : 3);
        } else if (pickupLimitError.fail) {
            updatedMultiplePickups.limit = '';
        }

        setPrefs({ ...prefs, multiplePickups: updatedMultiplePickups });
    };

    const handleSave = async () => {
        if (accountSelected.split('-')[0] === 'Administrator') {
            let userPrefs = _.pick(prefs, ['allowedDaysOfTheWeek', 'multiplePickups']);
            userPrefs.lockDonationPreference = lockDonationPreference;
            userPrefs.volumeReportPreference = volumeReportPreference;
            userPrefs.collectionProgramPreference = collectionProgramPreference;
            if (collectionProgramPreference && customer.collectionProgramPreference != collectionProgramPreference) {
                // Customer collection program preference was modified and set to true
                userPrefs.collectionVerification = { status: 'approved' };
            }
            // userPrefs.multipleAccountAccessList = multipleAccountAccessList;
            userPrefs.charityPreferred = charityPreferred;
            userPrefs.charitySelected = _.get(charitySelected, '_id');
            const resUser = await http.post(`/users/${customer._id}/updatePreferences`, userPrefs);
            let charityPrefs = _.pick(prefs, [
                'bypassRedemptionMinimum',
                'overridePaymentMethods',
                'allowCashRedemption',
                'allowChequeRedemption',
                'allowBankTransferRedemption',
                'allowETransferRedemption'
            ]);
            const resCharity = await http.post(`/charities/${charity._id}/updatePreferences`, { prefs: charityPrefs });
            if (resUser.ok && resCharity.ok) {
                onClose();
                onSnackbar('Charity preferences updated', 'success');
                reloadCustomer();
                reloadCharity();
            } else {
                onSnackbar('Error saving charity preferences', 'error');
            }
        } else {
            let userPrefs = _.cloneDeep(prefs);
            userPrefs.feeApplication = feeApplication;
            userPrefs.lowVolumeFee = lowVolumeFee;
            userPrefs.forcePromoCode = promos;
            userPrefs.invoiceFrequency = invoiceFrequency;
            userPrefs.feeInvoicePaymentWindow = feeInvoicePaymentWindow;
            userPrefs.lockDonationPreference = lockDonationPreference;
            userPrefs.volumeReportPreference = volumeReportPreference;
            userPrefs.collectionProgramPreference = collectionProgramPreference;
            if (collectionProgramPreference && customer.collectionProgramPreference != collectionProgramPreference) {
                // Customer collection program preference was modified and set to true
                userPrefs.collectionVerification = { status: 'approved' };
            }
            userPrefs.autoRedemptionPreference = autoRedemptionPreference;
            userPrefs.autoRedemptionDetails = autoRedemptionDetails;
            // userPrefs.multipleAccountAccessList = multipleAccountAccessList;
            userPrefs.charityPreferred = charityPreferred;
            userPrefs.charitySelected = _.get(charitySelected, '_id', null);

            if (isSchemeIDUser) {
                if (_.isNil(schemeID) || _.isEmpty(schemeID)) {
                    setSchemeIdError('SchemeID is required');
                    return;
                } else {
                    userPrefs.schemeID = schemeID;
                }
            }

            if (!isSchemeIDUser) {
                userPrefs.schemeID = null;
            }

            userPrefs.isSchemeIDUser = isSchemeIDUser;

            const res = await http.post(`/users/${customer._id}/updatePreferences`, userPrefs);
            if (res.ok) {
                onClose();
                onSnackbar('User preferences updated');
                reloadCustomer();
            }
        }
    };

    const toggleInfoDialog = ({ content, title }) => {
        if (infoDialogOpen) {
            setInfoDialogOpen(false);
            setInfoDialogContent('');
            setInfoDialogTitle('');
        } else {
            setInfoDialogOpen(true);
            setInfoDialogContent(content);
            setInfoDialogTitle(title);
        }
    };

    return (
        <>
            <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={onClose}>
                <DialogTitlePrimary closeButtonShown onClose={onClose}>
                    User Preferences
                </DialogTitlePrimary>
                <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                    <ToggleButtonGroup
                        value={tab}
                        exclusive
                        onChange={(e, tab) => {
                            if (!_.isEmpty(tab)) setTab(tab);
                        }}
                        style={{ display: 'flex', flexWrap: 'wrap', marginBottom: theme.spacing.unit * 2 }}
                    >
                        <ToggleButton style={{ flexGrow: 1 }} data-cy="user-preferences-account-tab" value="account">
                            Account
                        </ToggleButton>
                        <ToggleButton style={{ flexGrow: 1 }} data-cy="user-preferences-pickup-tab" value="pickup">
                            Pickups
                        </ToggleButton>
                        {!isAUSRegion() && (
                            <ToggleButton
                                style={{ flexGrow: 1 }}
                                data-cy="user-preferences-redemption-tab"
                                value="redemption"
                            >
                                Redemptions
                            </ToggleButton>
                        )}
                        <ToggleButton style={{ flexGrow: 1 }} data-cy="user-preferences-audit-tab" value="audit">
                            Audit
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {/* <div>
                        <CustomTabs headers={headers} theme={theme} tabIndex={tab} onTabClick={i => setTab(i)} />
                    </div> */}
                    {tab === 'account' && (
                        <div>
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel variant="outlined">Low Volume Fee</InputLabel>
                                        <Select
                                            fullWidth
                                            input={
                                                <OutlinedInput
                                                    labelWidth={'Low Volume Fee'.length * theme.spacing.unit}
                                                    data-cy={`${_.kebabCase('Low Volume Fee')}-input`}
                                                />
                                            }
                                            onChange={e => setLowVolumeFee(e.target.value)}
                                            value={lowVolumeFee}
                                            data-cy="user-preferences-low-volume-fee"
                                        >
                                            {['Active', 'Not Active', 'Never Active'].map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <PromoCodesInput
                                            label={'Force Promo Code'}
                                            promos={promos}
                                            onApplyPromo={e => setPromos([e])}
                                            onRemovePromo={e => setPromos([])}
                                            isLastPickupStep={true}
                                            showRecurringWarning={true}
                                            forcePromoCode={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        data-cy="profile-enableVolumeReport-checkbox"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    data-cy="volume-report-preference"
                                                    onChange={e => setVolumeReportPreference(e.target.checked)}
                                                    checked={volumeReportPreference}
                                                />
                                            }
                                            label="Enable Volume Report for Customer"
                                        />
                                    </FormControl>
                                    {collectionProgramEnabled && (
                                        <FormControl
                                            fullWidth
                                            style={{ marginTop: 0, marginBottom: 0 }}
                                            data-cy="profile-enableCollectionProgram-checkbox"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        data-cy="collection-program-preference"
                                                        onChange={e => setCollectionProgramPreference(e.target.checked)}
                                                        checked={collectionProgramPreference}
                                                    />
                                                }
                                                label={`Collection Program (${isCONRegion() ? 'eCSP' : 'IC&I'}) Member`}
                                            />
                                        </FormControl>
                                    )}
                                    {_.isEmpty(charity) && (
                                        <FormControl
                                            fullWidth
                                            style={{ marginTop: 0, marginBottom: 0 }}
                                            data-cy="profile-enableAutoRedemption-checkbox"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        data-cy="auto-redemption-preference"
                                                        onChange={e =>
                                                            e.target.checked
                                                                ? setAutoRedemptionDialogOpen(true)
                                                                : setAutoRedemptionPreference(e.target.checked)
                                                        }
                                                        checked={autoRedemptionPreference}
                                                    />
                                                }
                                                label="Enable Automated Redemption"
                                            />
                                        </FormControl>
                                    )}

                                    <AutoRedemptionDialog
                                        open={autoRedemptionDialogOpen}
                                        customer={customer}
                                        operator={operator}
                                        onClose={() => {
                                            setAutoRedemptionDialogOpen(false);
                                            setAutoRedemptionPreference(false);
                                        }}
                                        theme={theme}
                                        http={http}
                                        redemptionFee={redemptionFee}
                                        redemptions={redemptions}
                                        email={customer.email}
                                        accountName={accountName}
                                        reloadCustomer={reloadCustomer}
                                        emailVerificationRequired={emailVerificationRequired}
                                        emailVerificationRequiredForETransfer={emailVerificationRequiredForETransfer}
                                        handleVerifyEmailDialog={handleVerifyEmailDialog}
                                        balance={balance}
                                        collectors={collectors}
                                        redemptionOptions={redemptionOptions}
                                        autoRedemptionDetails={autoRedemptionDetails}
                                        setAutoRedemptionDetails={setAutoRedemptionDetails}
                                        setAutoRedemptionPreference={setAutoRedemptionPreference}
                                        setAutoRedemptionDialogOpen={setAutoRedemptionDialogOpen}
                                    />
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        data-cy="profile-lockDonationPreference-checkbox"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    data-cy="lock-donation-preference"
                                                    onChange={e => setLockDonationPreference(e.target.checked)}
                                                    checked={lockDonationPreference}
                                                />
                                            }
                                            label="Lock user donation preference"
                                        />
                                    </FormControl>
                                    <Collapse in={lockDonationPreference}>
                                        <FormControl fullWidth data-cy="charity-select-organization-select">
                                            <TextField
                                                id="outlined-select-charity"
                                                data-cy="charity-select-org-select"
                                                value={
                                                    !_.isNil(charityPreferred)
                                                        ? _.get(charitySelected, 'name', 'N/A')
                                                        : loc('noDonation', lang)
                                                }
                                                onClick={handleCharitySelectDialog(true)}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon>arrow_drop_down</Icon>
                                                        </InputAdornment>
                                                    ),
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Icon style={{ color: colors.red[500] }}>favorite</Icon>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                label={loc('organizationName', lang, {
                                                    organizationName: terms.ORGANIZATION_NAME
                                                })}
                                                variant="outlined"
                                            />
                                        </FormControl>

                                        <CharitySelectDialog
                                            open={charitySelectDialogOpen}
                                            charities={charities}
                                            onChange={handleCharityPreferred}
                                            onInfoDialog={handleCharityInfoDialog}
                                            onClose={handleCharitySelectDialog(false)}
                                            hideSubdivisions={true}
                                            growthPlanEnabled={growthPlanEnabled}
                                        />

                                        <CharityInfoDialog
                                            open={charityInfoDialogOpen}
                                            charity={charityInfo}
                                            onClose={handleCharityInfoDialog(false)}
                                        />
                                    </Collapse>
                                </Grid>

                                {isAUSRegion() && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined">Fee Application</InputLabel>
                                            <Select
                                                fullWidth
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={'Fee Application'.length * theme.spacing.unit}
                                                        data-cy={`${_.kebabCase('Fee Application')}-input`}
                                                    />
                                                }
                                                onChange={e => setFeeApplication(e.target.value)}
                                                value={feeApplication}
                                                data-cy="user-preferences-fee-application"
                                            >
                                                {FEE_APPLICATION_OPTIONS.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {isAUSRegion() && feeApplication === 'Invoice' && (
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined">Invoice Frequency</InputLabel>
                                            <Select
                                                fullWidth
                                                onChange={e => setInvoiceFrequency(e.target.value)}
                                                value={invoiceFrequency}
                                                data-cy="user-preferences-invoice-frequency"
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={'Invoice Frequency'.length * theme.spacing.unit}
                                                        data-cy={`${_.kebabCase('Invoice Frequency')}-input`}
                                                    />
                                                }
                                            >
                                                {INVOICE_FREQUENCY_OPTIONS.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {isAUSRegion() && feeApplication === 'Invoice' && (
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                data-cy="user-preferences-invoice-payment-window"
                                                fullWidth
                                                type="number"
                                                name="feeInvoicePaymentWindow"
                                                label={'Invoice Payment Window'}
                                                value={feeInvoicePaymentWindow}
                                                variant="outlined"
                                                onChange={e => setFeeInvoicePaymentWindow(Math.max(e.target.value, 1))}
                                                error={pickupLimitError.fail}
                                                helperText={pickupLimitError.reason}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                            </Grid>
                            <Typography variant="h6">{`Give ${_.get(
                                customer,
                                'name.first',
                                'this user'
                            )} permission to view:`}</Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    verticalAlign: 'center',
                                    paddingTop: theme.spacing.unit
                                }}
                            >
                                <TextField
                                    data-cy="link-account-customer-id-search"
                                    label="Customer ID or Email"
                                    value={userSearchString}
                                    margin="normal"
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    helperText={!_.isEmpty(userSearchError) ? userSearchError : null}
                                    error={!_.isEmpty(userSearchError)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MDIcon path={mdiAccountSearch} size={1} color={colors.blue[500]} />
                                            </InputAdornment>
                                        )
                                    }}
                                    style={{
                                        marginTop: theme.spacing.unit / 2,
                                        marginRight: theme.spacing.unit
                                    }}
                                    onChange={e => {
                                        setUserSearchError('');
                                        setUserSearchString(e.target.value);
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                                <Button
                                    data-cy={`link-account-customer-id-search-submit`}
                                    onClick={() => handleAddAccountAccess()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Add
                                </Button>
                            </div>

                            <CustomFormTitle
                                titleText={`${_.get(customer, 'name.first', 'This user')} can view:`}
                                iconName="people_alt"
                                style={{ marginBottom: 0 }}
                            />
                            <List
                                style={{
                                    margin: 0
                                }}
                            >
                                {multipleAccountAccessList &&
                                    multipleAccountAccessList.map((user, index) => {
                                        return (
                                            <ListItem
                                                style={{
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    opacity: user.disabled ? 0.7 : 1
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        width: 40,
                                                        height: 40,
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: 'white'
                                                    }}
                                                >
                                                    {_.get(user, 'name.first[0]', '?')}
                                                </Avatar>
                                                <ListItemText
                                                    primary={
                                                        <span
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                                display: 'block',
                                                                paddingRight: 'inherit'
                                                            }}
                                                        >
                                                            {`${_.get(user, 'name.first', 'N/A')} ${_.get(
                                                                user,
                                                                'name.last',
                                                                ''
                                                            )}`}
                                                        </span>
                                                    }
                                                    style={{
                                                        whiteSpace: 'normal',
                                                        paddingRight: theme.spacing.unit * 3,
                                                        position: 'relative'
                                                    }}
                                                />
                                                <ListItemSecondaryAction>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            paddingTop: theme.spacing.unit
                                                        }}
                                                    >
                                                        <IconButton onClick={handleViewUser(user._id)}>
                                                            <Icon>visibility</Icon>
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() =>
                                                                warnAction(() => {
                                                                    return handleRemoveAccountAccess(user._id);
                                                                }, `Are you sure you want to remove ${customer.name.first} ${customer.name.last}'s access to ${user.name.first} ${user.name.last}'s account?`)
                                                            }
                                                        >
                                                            <Icon>clear</Icon>
                                                        </IconButton>
                                                    </div>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                            </List>
                            {isAUSRegion() && (
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        data-cy="profile-setIsSchemeUser-checkbox"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    data-cy="set-is-schemeId-user"
                                                    onChange={e => setIsSchemeIDUser(e.target.checked)}
                                                    checked={isSchemeIDUser}
                                                />
                                            }
                                            label="Set as schemeID user"
                                        />
                                    </FormControl>
                                    <Collapse in={isSchemeIDUser}>
                                        <TextField
                                            data-cy="textfield-schemeId"
                                            label="SchemeID"
                                            value={schemeID}
                                            margin="normal"
                                            variant="outlined"
                                            autoComplete="off"
                                            fullWidth
                                            helperText={!_.isEmpty(schemeIdError) ? schemeIdError : null}
                                            error={!_.isEmpty(schemeIdError)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MDIcon
                                                            path={mdiAccountOutline}
                                                            size={1}
                                                            color={colors.blue[500]}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                            style={{
                                                marginTop: theme.spacing.unit / 2,
                                                marginRight: theme.spacing.unit
                                            }}
                                            onChange={e => {
                                                setSchemeIdError('');
                                                setSchemeID(e.target.value);
                                            }}
                                            // onKeyPress={handleKeyPress}
                                        />
                                    </Collapse>
                                </Grid>
                            )}
                        </div>
                    )}
                    {tab === 'pickup' && (
                        <div>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ margin: `${theme.spacing.unit}px 0`, width: '100%' }}
                                    >
                                        <InputLabel htmlFor="allowedDaysOfTheWeek">allowedDaysOfTheWeek</InputLabel>
                                        <Select
                                            data-cy="user-preferences-dialog-day-select"
                                            multiple
                                            input={
                                                <OutlinedInput
                                                    id="allowedDaysOfTheWeek"
                                                    name="allowedDaysOfTheWeek"
                                                    labelWidth={175}
                                                />
                                            }
                                            value={prefs.allowedDaysOfTheWeek}
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            toggleInfoDialog({
                                                                content:
                                                                    'Days of the week available for pickups (No preference will default zone day)',
                                                                title: 'Allowed days of week'
                                                            })
                                                        }
                                                    >
                                                        <Icon>help</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        >
                                            {DAYS_OF_THE_WEEK.map(day => (
                                                <MenuItem
                                                    key={day}
                                                    value={day}
                                                    data-cy={`user-preferences-dialog-day-select-${day}`}
                                                >
                                                    {day}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: theme.spacing.unit,
                                            width: '100%'
                                        }}
                                    >
                                        <CollectorsSelectDropdown
                                            showAll={true}
                                            label={'Override Transporter'}
                                            id="collector-dropdown"
                                            value={overrideTransporter}
                                            collectors={collectors}
                                            cypress_id="trip-list-transporter-dropdown"
                                            onChange={handleUpdateOverrideTransporter}
                                            style={{
                                                marginRight: theme.spacing.unit / 2,
                                                width: '100%'
                                            }}
                                            fullWidth={true}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            toggleInfoDialog({
                                                                content:
                                                                    'Set the designated transporter to have all pickups for this customer collected by that transporter only, no matter the location',
                                                                title: 'Override Transporter'
                                                            })
                                                        }
                                                    >
                                                        <Icon>help</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: theme.spacing.unit,
                                            width: '100%'
                                        }}
                                    >
                                        <CollectorsSelectDropdown
                                            showAll={true}
                                            label={'Override Processor'}
                                            id="collector-dropdown"
                                            value={overrideProcessor}
                                            collectors={collectors}
                                            cypress_id="trip-list-collector-dropdown"
                                            onChange={handleUpdateOverrideProcessor}
                                            style={{
                                                marginLeft: theme.spacing.unit / 2,
                                                width: '100%'
                                            }}
                                            fullWidth={true}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            toggleInfoDialog({
                                                                content:
                                                                    'Set the designated processor to have all pickups for this customer routed through that processor only, no matter the location',
                                                                title: 'Override Processor'
                                                            })
                                                        }
                                                    >
                                                        <Icon>help</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                </Grid>

                                <PickupSwitchOption
                                    title={'Enable Multiple Bookings:'}
                                    name="multiplePickups.enabled"
                                    dataCy="user-preferences-multisite-enable"
                                    isChecked={_.get(prefs, 'multiplePickups.enabled', false)}
                                    onChange={handleMultiplePickupsChecked}
                                />
                                {_.get(prefs, 'multiplePickups.enabled', false) && (
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            data-cy="user-preferences-multisite-limit"
                                            fullWidth
                                            name="multiplePickups.limit"
                                            label={'Pickup Limit'}
                                            style={{ margin: `${theme.spacing.unit}px 0` }}
                                            value={_.get(prefs, 'multiplePickups.limit', 3)}
                                            variant="outlined"
                                            onChange={handleMultiplePickupsChanged}
                                            error={pickupLimitError.fail}
                                            helperText={pickupLimitError.reason}
                                        />
                                    </Grid>
                                )}

                                <PickupSwitchOption
                                    title={'Carry Over Non-Beverage:'}
                                    name="carryOverNonBeverage"
                                    dataCy="user-preferences-carry-over-non-beverage"
                                    isChecked={_.get(prefs, 'carryOverNonBeverage', false)}
                                    onChange={handleChecked}
                                />
                                <PickupSwitchOption
                                    title={'Preserve Priority:'}
                                    name="preservePriority"
                                    dataCy="user-preferences-preserve-priority"
                                    isChecked={_.get(prefs, 'preservePriority', false)}
                                    onChange={handleChecked}
                                />
                                <PickupSwitchOption
                                    title={'Bypass Abort & Postpone Threshold:'}
                                    name="bypassPickupThreshold"
                                    dataCy="user-preferences-bypass-pickup-threshold"
                                    isChecked={_.get(prefs, 'bypassPickupThreshold', false)}
                                    onChange={handleChecked}
                                />
                                <PickupSwitchOption
                                    title={'Bypass Customer Type Zone Booking Restrictions:'}
                                    name="bypassZoneTypeRestriction"
                                    dataCy="user-preferences-bypass-zone-type-restriction"
                                    isChecked={_.get(prefs, 'bypassZoneTypeRestriction', false)}
                                    onChange={handleChecked}
                                />
                                <PickupSwitchOption
                                    title={'Always Confirmed:'}
                                    name="alwaysConfirmedOverride"
                                    dataCy="user-preferences-always-confirmed"
                                    isChecked={_.get(prefs, 'alwaysConfirmedOverride', false)}
                                    onChange={handleChecked}
                                />
                            </Grid>
                        </div>
                    )}
                    {tab === 'redemption' && (
                        <div style={{ marginTop: theme.spacing.unit }}>
                            <FormControlLabel
                                style={{ marginLeft: 0, marginRight: 0 }}
                                control={
                                    <Switch
                                        name="bypassRedemptionMinimum"
                                        checked={prefs.bypassRedemptionMinimum}
                                        onChange={handleChecked}
                                        data-cy="user-preferences-bypass-redemption-minimum"
                                    />
                                }
                                labelPlacement="start"
                                label={<DialogContentText>Bypass Redemption Minimum:</DialogContentText>}
                            />
                            <FormControlLabel
                                style={{ marginLeft: 0, marginRight: 0 }}
                                control={
                                    <Switch
                                        name="overridePaymentMethods"
                                        checked={prefs.overridePaymentMethods}
                                        onChange={handleOverridePaymentMethods}
                                        data-cy="user-preferences-override-payment-methods"
                                    />
                                }
                                labelPlacement="start"
                                label={<DialogContentText>Override Payment Methods:</DialogContentText>}
                            />
                            <Grid container>
                                <Grid item sm="2" xs="6">
                                    <FormControlLabel
                                        style={{ marginLeft: 0, marginRight: 0 }}
                                        control={
                                            <Checkbox
                                                name="allowCashRedemption"
                                                disabled={!prefs.overridePaymentMethods}
                                                checked={prefs.allowCashRedemption}
                                                onChange={handleChecked}
                                                data-cy="user-preferences-allow-cash-redemption"
                                            />
                                        }
                                        labelPlacement="end"
                                        label={<DialogContentText>{loc('redemption7', lang)}</DialogContentText>}
                                    />
                                </Grid>
                                <Grid item sm="3" xs="6">
                                    <FormControlLabel
                                        style={{ marginLeft: 0, marginRight: 0 }}
                                        control={
                                            <Checkbox
                                                name="allowChequeRedemption"
                                                disabled={!prefs.overridePaymentMethods}
                                                checked={prefs.allowChequeRedemption}
                                                onChange={handleChecked}
                                                data-cy="user-preferences-allow-cheque-redemption"
                                            />
                                        }
                                        labelPlacement="end"
                                        label={<DialogContentText>{loc('redemption5', lang)}</DialogContentText>}
                                    />
                                </Grid>
                                <Grid item sm="3" xs="6">
                                    <FormControlLabel
                                        style={{ marginLeft: 0, marginRight: 0 }}
                                        control={
                                            <Checkbox
                                                name="allowETransferRedemption"
                                                disabled={!prefs.overridePaymentMethods}
                                                checked={prefs.allowETransferRedemption}
                                                onChange={handleChecked}
                                                data-cy="user-preferences-allow-etransfer-redemption"
                                            />
                                        }
                                        labelPlacement="end"
                                        label={<DialogContentText>e-Transfer</DialogContentText>}
                                    />
                                </Grid>
                                <Grid item sm="4" xs="6">
                                    <FormControlLabel
                                        style={{ marginLeft: 0, marginRight: 0 }}
                                        control={
                                            <Checkbox
                                                name="allowBankTransferRedemption"
                                                disabled={!prefs.overridePaymentMethods}
                                                checked={prefs.allowBankTransferRedemption}
                                                onChange={handleChecked}
                                                data-cy="user-preferences-allow-bank-transfer-redemption"
                                            />
                                        }
                                        labelPlacement="end"
                                        label={<DialogContentText>{loc('redemption6', lang)}</DialogContentText>}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    {tab === 'audit' && (
                        <div style={{ marginTop: theme.spacing.unit }}>
                            <CustomFormTitle titleText="Disable Audit Checks" iconName="policy" />
                            <Grid container>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>Pickup Bag Count:</DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassBagCountReview"
                                            checked={_.get(prefs, 'bypassReviewSettings.bypassBagCountReview', false)}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassBagCountReview"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>Driver Complaint:</DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassDriverComplaintReview"
                                            checked={_.get(
                                                prefs,
                                                'bypassReviewSettings.bypassDriverComplaintReview',
                                                false
                                            )}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassDriverComplaintReview"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>
                                        Per Bag Amount Difference:
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassAmountDiffReview"
                                            checked={_.get(prefs, 'bypassReviewSettings.bypassAmountDiffReview', false)}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassAmountDiffReview"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>Counter Complaint:</DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassCounterComplaintReview"
                                            checked={_.get(
                                                prefs,
                                                'bypassReviewSettings.bypassCounterComplaintReview',
                                                false
                                            )}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassCounterComplaintReview"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>
                                        Large Redemption Amount:
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassRedemptionAmountReview"
                                            checked={_.get(
                                                prefs,
                                                'bypassReviewSettings.bypassRedemptionAmountReview',
                                                false
                                            )}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassRedemptionAmountReview"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>
                                        Hazardous Complaint:
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassHazardousComplaintReview"
                                            checked={_.get(
                                                prefs,
                                                'bypassReviewSettings.bypassHazardousComplaintReview',
                                                false
                                            )}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassHazardousComplaintReview"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>
                                        Redemption Email Mismatch:
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassRedemptionEmailReview"
                                            checked={_.get(
                                                prefs,
                                                'bypassReviewSettings.bypassRedemptionEmailReview',
                                                false
                                            )}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassRedemptionEmailReview"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>Garbage Complaint:</DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassGarbageComplaintReview"
                                            checked={_.get(
                                                prefs,
                                                'bypassReviewSettings.bypassGarbageComplaintReview',
                                                false
                                            )}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassGarbageComplaintReview"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={5}>
                                    <DialogContentText style={{ marginTop: 10 }}>Same IP Address:</DialogContentText>
                                </Grid>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <Switch
                                            name="bypassReviewSettings.bypassSameIPAddressReview"
                                            checked={_.get(
                                                prefs,
                                                'bypassReviewSettings.bypassSameIPAddressReview',
                                                false
                                            )}
                                            onChange={handleChecked}
                                            data-cy="user-preferences-bypassSameIPAddressReview"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleSave}
                        data-cy="user-preferences-dialog-save"
                        disabled={_.get(prefs, 'multiplePickups.enabled', false) && pickupLimitError.fail}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <HelpDialog
                open={infoDialogOpen}
                title={infoDialogTitle}
                body={<Typography variant="body1">{infoDialogContent}</Typography>}
                onClose={() => setInfoDialogOpen(false)}
            />
        </>
    );
};

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(UserPreferencesDialog));

const CustomTabs = ({ headers = [], theme, tabIndex, onTabClick }) => {
    return (
        <div
            style={{
                display: 'flex',
                marginBottom: theme.spacing.unit * 2,
                width: '100%'
            }}
        >
            {headers.map((header, idx) => (
                <span
                    key={idx}
                    style={{
                        display: 'flex',
                        cursor: 'pointer',
                        border: '1px solid',
                        borderRadius: 4,
                        borderColor: colors.grey[100],
                        backgroundColor: idx === tabIndex ? colors.grey[300] : colors.grey[100],
                        padding: theme.spacing.unit / 2,
                        marginLeft: idx === 0 ? 0 : theme.spacing.unit / 4,
                        marginRight: idx === 3 ? 0 : theme.spacing.unit / 4,
                        flex: idx === tabIndex ? 1 : 0,
                        tranistion: 'all 0.3s linear'
                    }}
                    onClick={() => onTabClick(idx)}
                >
                    <Icon
                        data-cy={`user-preferences-${header.type}-tab`}
                        color="primary"
                        style={{ paddingLeft: 0, marginRight: idx === tabIndex ? theme.spacing.unit : 0 }}
                    >
                        {header.iconName}
                    </Icon>

                    {idx === tabIndex && (
                        <Typography
                            variant="subtitle2"
                            style={{
                                color: colors.grey[700],
                                fontWeight: 'bold',
                                marginTop: 2
                            }}
                        >
                            {header.titleText}
                        </Typography>
                    )}
                </span>
            ))}
        </div>
    );
};

function PickupSwitchOption({ title, name, isChecked, onChange, dataCy }) {
    return (
        <>
            <Grid item xs={10} sm={5}>
                <Typography style={{ marginTop: 10 }}>{title} </Typography>
            </Grid>
            <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                    <Switch data-cy={dataCy} name={name} checked={isChecked} onChange={onChange} />
                </div>
            </Grid>
        </>
    );
}
