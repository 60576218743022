import React, { useState } from 'react';
import _ from 'lodash';
import { _time, _user } from 'std';

import {
    Icon,
    Grid,
    Paper,
    Button,
    FormControl,
    Checkbox,
    Select,
    MenuItem,
    ListItemText,
    Divider,
    Typography,
    TextField,
    InputAdornment,
    DialogTitle,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    FormControlLabel,
    CircularProgress,
    colors,
    Collapse,
    Switch
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import useTrends from './hooks/useTrends';

import StatisticsChart from './StatisticsChart';
import DatePicker from 'components/DateTimePickersTz/DatePicker';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import { RESIDENTIAL, COMMERCIAL, CONDO, COMMISSION_TYPES } from '../../../constants';
import { DROPNGO, ADJUSTMENT, PICKUP, WALKIN } from '../../../constants';
import MDIcon from '@mdi/react';
import { mdiAccountOff, mdiWalk, mdiMapMarkerOff } from '@mdi/js';

import FilterSidePanel from 'components/FilterSidePanel/FilterSidePanel';
import useFilterSidePanel from 'components/FilterSidePanel/hooks/useFilterSidePanel';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import { useEffect } from 'react';
import moment from 'moment-timezone';

const allPickupTypes = [RESIDENTIAL, COMMERCIAL, CONDO];

const allBulkTypes = [
    { type: DROPNGO, label: 'Drop & Go' },
    { type: ADJUSTMENT, label: 'Adjustment' },
    { type: PICKUP, label: 'Pickup' },
    { type: WALKIN, label: 'Walk In' }
];

const iconColors = [
    'pink',
    'red',
    'deepOrange',
    'orange',
    'amber',
    'yellow',
    'lightGreen',
    'green',
    'teal',
    'blue',
    'indigo',
    'purple',
    'grey',
    'black'
];
const timezone = process.env.REACT_APP_REGION_TIMEZONE;
const Trends = ({ http, collectors, regions, theme, onSnackbar }) => {
    const [addressQueryDialogOpen, setAddressQueryDialogOpen] = useState(false);
    const [createNewChartDialogOpen, setCreateNewChartDialogOpen] = useState(false);
    const [selectedChartId, setSelectedChartId] = useState(null);
    const [selectedChart, setSelectedChart] = useState(null);
    const [chartKeyValue, setChartKeyValue] = useState(0);
    const [loadingIcons, setLoadingIcons] = useState([]);

    // const [dateWindow, setDateWindow] = useState('thisIsoWeek');

    const {
        customerAddressKeys,
        visibleTrendKeys,
        trendLines,
        collectorsSelected,
        handleTrend,
        fetchTrends,
        chartStatsList,
        chartInterval,
        zonesWithRegion,
        provinces,
        cities,
        customerProvincesSelected,
        customerCitiesSelected,
        zonesSelected,
        regionsSelected,
        allDrivers,
        clerks,
        driversSelected,
        clerksSelected,
        allCollectorAdmins,
        collectorAdminsSelected,
        handleCollectorsSelected,
        handleCustomerProvincesSelected,
        handleOrderProvincesSelected,
        handlePickupProvincesSelected,
        setChartInterval,
        setPickupTypesSelected,
        setZonesSelected,
        setCustomerCitiesSelected,
        handleRegionsSelected,
        setDriversSelected,
        setClerksSelected,
        setCollectorAdminsSelected,
        handleVisibleTrendKeys,
        handleDownload,
        handleRemoveChart,
        setCustomerAddressKeys,
        chartTitle,
        setChartTitle,
        handleFiltersForm,
        handleRefresh,
        markerTitle,
        setMarkerTitle,
        markerColor,
        setMarkerColor,
        markerDate,
        setMarkerDate,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        useToday,
        handleUseTodayChange,
        dateRangeOption,
        setDateRangeOption,
        isCustomerFilterActive,
        setCustomerFilterActive,
        isPickupFilterActive,
        setPickupFilterActive,
        pickupProvincesSelected,
        setPickupProvincesSelected,
        setCustomerProvincesSelected,
        pickupCitiesSelected,
        setPickupCitiesSelected,
        pickupAddressKeys,
        setPickupAddressKeys,
        isOrderFilterActive,
        setOrderFilterActive,
        orderProvincesSelected,
        setOrderProvincesSelected,
        orderCitiesSelected,
        setOrderCitiesSelected,
        orderAddressKeys,
        setOrderAddressKeys,
        showOrderSystemFilters,
        setShowOrderSystemFilters,
        showPickupSystemFilters,
        setShowPickupSystemFilters,
        orderCollectorsSelected,
        orderCollectorAdminsSelected,
        setOrderCollectorAdminsSelected,
        orderDriversSelected,
        orderZonesSelected,
        setOrderZonesSelected,
        setOrderDriversSelected,
        orderClerksSelected,
        setOrderClerksSelected,
        handleOrderCollectorsSelected,
        showMarkerSettings,
        setShowMarkerSettings,
        updateDatesBasedOnOption
    } = useTrends({ http, collectors, onSnackbar, regions });

    const closeCustomerFilter = () => setCustomerFilterActive(false);
    const toggleCustomerFilter = () => setCustomerFilterActive(!isCustomerFilterActive);
    const closePickupFilter = () => setPickupFilterActive(false);
    const togglePickupFilter = () => setPickupFilterActive(!isPickupFilterActive);
    const closeOrderFilter = () => setOrderFilterActive(false);
    const toggleOrderFilter = () => setOrderFilterActive(!isOrderFilterActive);

    const togglePickupSystemFilters = () => setShowPickupSystemFilters(!showPickupSystemFilters);
    const toggleOrderSystemFilters = () => setShowOrderSystemFilters(!showOrderSystemFilters);
    const getColor = color => {
        try {
            if (_.isNil(color) || color === '') {
                return '';
            }
            return color === 'black' ? colors['grey'][900] : colors[color][600];
        } catch (e) {
            return '';
        }
    };

    const handleDateRangeChange = event => {
        const selectedOption = event.target.value;
        setDateRangeOption(selectedOption);
        updateDatesBasedOnOption(selectedOption);
    };

    const handleSubmit = async () => {
        addLoadingIcon();
        await handleTrend(selectedChartId);
        setChartKeyValue((chartKeyValue + 1) % 2);
        removeLoadingIcon();
        setSelectedChart(null);
        setSelectedChartId(null);
    };

    const addLoadingIcon = () => {
        setLoadingIcons([
            ...loadingIcons,
            <CircularProgress
                size={30}
                thickness={4.8}
                style={{ marginTop: theme.spacing.unit, marginRight: theme.spacing.unit / 2 }}
            />
        ]);
    };
    const removeLoadingIcon = () => {
        let loadingIconsCopy = _.clone(loadingIcons);
        setLoadingIcons([loadingIconsCopy.splice(0, 1)]);
    };

    const handleDelete = index => {
        handleRemoveChart(index);
        setChartKeyValue((chartKeyValue + 1) % 2);
    };

    // change both date window and x-axis interval
    const handleDateWindowChange = e => {
        setChartInterval(e.target.value);
    };

    const { open: filterPanelOpen, overlay, toggleOverlay, handleFilter, handleFilterPanelOpen } = useFilterSidePanel({
        onFilter: handleSubmit
    });

    const regionsSorted = _.sortBy(regions, 'name');
    const zonesFiltered = _.filter(zonesWithRegion, zone =>
        _.some(zone.regions, region => regionsSelected.includes(region._id))
    );

    const filterTypes = new Set();
    trendLines.forEach(line => filterTypes.add(line.type));

    useEffect(() => {
        // switch (chartInterval) {
        //     case 'today':
        //         setChartInterval('DAY');
        //         break;
        //     case 'thisIsoWeek':
        //         setChartInterval('WEEK');
        //         break;
        //     case 'thisMonth':
        //         setChartInterval('MONTH');
        //         break;
        //     default:
        //         setChartInterval('MONTH');
        // }

        fetchTrends();
    }, []);

    const handleSetFormValues = _id => {
        setSelectedChartId(_id);
        let chart = chartStatsList.find(trend => trend._id.toString() === _id.toString());
        handleFiltersForm(chart);
        // switch (chart.chartInterval) {
        //     case 'DAY':
        //         handleWindowSelect({ target: { value: 'today' } });
        //         break;
        //     case 'WEEK':
        //         handleWindowSelect({ target: { value: 'thisIsoWeek' } });
        //         break;
        //     case 'MONTH':
        //         handleWindowSelect({ target: { value: 'thisMonth' } });
        //         break;
        //     default:
        //         handleWindowSelect({ target: { value: 'thisMonth' } });
        // }
        setStartDate(moment(chart.startDate));
        setEndDate(moment(chart.endDate));
        handleVisibleTrendKeys(chart.visibleTrendKeys);
        setCreateNewChartDialogOpen(true);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'relative',
                    marginTop: theme.spacing.unit * 2
                }}
            >
                <Paper
                    style={{
                        minHeight: '80px',
                        margin: theme.spacing.unit * 2,
                        padding: theme.spacing.unit * 2,
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                        overflow: 'auto'
                    }}
                >
                    {loadingIcons}
                    <Button
                        style={{ margin: theme.spacing.unit }}
                        variant="outlined"
                        onClick={() => setCreateNewChartDialogOpen(true)}
                        color="primary"
                        data-cy="new-chart"
                    >
                        <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                        New Chart
                    </Button>
                </Paper>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container spacing={theme.spacing.unit * 2} style={{ margin: theme.spacing.unit }}>
                        {!_.isEmpty(chartStatsList) &&
                            chartStatsList.map((chartStats, index) => {
                                return (
                                    <StatisticsChart
                                        key={index}
                                        title={chartStats.title}
                                        data={chartStats.data}
                                        visibleTrendKeys={chartStats.visibleTrendKeys}
                                        trendLines={trendLines}
                                        totalUsers={chartStats.totalUsers}
                                        totalPickups={chartStats.totalPickups}
                                        totalOrders={chartStats.totalOrders}
                                        dataInterval={chartStats.chartInterval}
                                        setPickupTypesSelected={setPickupTypesSelected}
                                        setZonesSelected={setZonesSelected}
                                        onRegionsSelected={handleRegionsSelected}
                                        handleDownload={() => handleDownload(chartStats._id)}
                                        handleRemoveChart={() => handleDelete(chartStats._id)}
                                        handleRefresh={() => handleRefresh(chartStats._id)}
                                        handleFilters={() => handleSetFormValues(chartStats._id)}
                                        customHeight={chartStats.height}
                                        keyVal={chartKeyValue + index}
                                        showMarker={chartStats.showMarkerSettings}
                                        markerDate={chartStats.markerDate}
                                        markerTitle={chartStats.markerTitle}
                                        markerColor={chartStats.markerColor}
                                        lastUpdated={chartStats.updatedAt}
                                        dateRangeOption={chartStats.dateRangeOption}
                                        chartInterval={chartStats.chartInterval}
                                        useToday={chartStats.useToday}
                                    />
                                );
                            })}
                    </Grid>
                </div>
                <Dialog
                    open={createNewChartDialogOpen}
                    fullWidth
                    onClose={() => {
                        setSelectedChartId(null);
                        setSelectedChart(null);
                        setChartTitle('');
                        setCreateNewChartDialogOpen(false);
                    }}
                >
                    <DialogTitle>
                        {_.isNil(selectedChartId)
                            ? 'Create New Statistics Chart'
                            : `Edit ${_.get(selectedChart, 'title', 'Chart')}`}
                    </DialogTitle>
                    <DialogContent>
                        <CustomFormTitle titleText={'Trend Info'} iconName={'timeline'} />
                        <div style={{ display: 'flex', marginBottom: theme.spacing.unit * 2 }}>
                            <TextField
                                label="Chart Title"
                                value={chartTitle}
                                name="Chart Title"
                                onChange={e => setChartTitle(e.target.value)}
                                style={{ margin: theme.spacing.unit, marginTop: -theme.spacing.unit, flexGrow: 1 }}
                            />
                        </div>
                        <CustomFormTitle titleText={'Group By'} iconName={'preview'} />
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: theme.spacing.unit * 2 }}>
                            <Select
                                value={dateRangeOption}
                                onChange={handleDateRangeChange}
                                style={{ marginBottom: theme.spacing.unit * 2 }}
                            >
                                <MenuItem value="custom">Custom</MenuItem>
                                {/* <MenuItem value="today">Start Date to Today</MenuItem> */}
                                <MenuItem value="last15Days">Last 15 Days</MenuItem>
                                <MenuItem value="last30Days">Last 30 Days</MenuItem>
                                <MenuItem value="last90Days">Last 90 Days</MenuItem>
                                <MenuItem value="all">All Time</MenuItem>
                            </Select>
                            {dateRangeOption === 'custom' && (
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <DatePicker
                                        timezone={timezone}
                                        label="Start Date"
                                        disableFuture
                                        format="DD MMM YYYY"
                                        value={startDate}
                                        inputStyle={{ paddingRight: theme.spacing.unit * 2, flex: 1 }}
                                        onChange={date => {
                                            setStartDate(moment(date));
                                        }}
                                        input
                                        disabled={!['custom', 'today'].includes(dateRangeOption)}
                                    />
                                    {!useToday ? (
                                        <DatePicker
                                            timezone={timezone}
                                            type="end"
                                            label="End Date"
                                            disableFuture
                                            format="DD MMM YYYY"
                                            value={endDate}
                                            inputStyle={{ flex: 1 }}
                                            onChange={date => {
                                                setEndDate(moment(date));
                                            }}
                                            disabled={!['custom', 'today'].includes(dateRangeOption)}
                                        />
                                    ) : (
                                        <TextField
                                            variant="standard"
                                            label="End Date"
                                            value="Today"
                                            disabled
                                            style={{ flex: 1 }}
                                        />
                                    )}
                                    <FormControlLabel
                                        control={<Checkbox checked={useToday} onChange={handleUseTodayChange} />}
                                        label="Use Today"
                                    />
                                </div>
                            )}
                        </div>
                        <CustomFormTitle titleText={'Location Info'} iconName={'location_on'} />
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                            <Button
                                onClick={toggleCustomerFilter}
                                variant={isCustomerFilterActive ? 'contained' : 'outlined'}
                                style={{ marginBottom: theme.spacing.unit }}
                            >
                                {isCustomerFilterActive
                                    ? 'Close Customer Address Filter'
                                    : 'Add Customer Address Filter'}
                            </Button>

                            <Collapse in={isCustomerFilterActive}>
                                <FilterRow
                                    onClose={closeCustomerFilter}
                                    provinces={provinces}
                                    provincesSelected={customerProvincesSelected}
                                    setProvincesSelected={setCustomerProvincesSelected}
                                    handleProvincesSelected={handleCustomerProvincesSelected}
                                    cities={cities}
                                    citiesSelected={customerCitiesSelected}
                                    setCitiesSelected={setCustomerCitiesSelected}
                                    addressKeys={customerAddressKeys}
                                    setAddressKeys={setCustomerAddressKeys}
                                />
                            </Collapse>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                            <Button
                                onClick={togglePickupFilter}
                                variant={isPickupFilterActive ? 'contained' : 'outlined'}
                                style={{ marginBottom: theme.spacing.unit }}
                            >
                                {isPickupFilterActive ? 'Close Pickup Address Filter' : 'Add Pickup Address Filter'}
                            </Button>

                            <Collapse in={isPickupFilterActive}>
                                <FilterRow
                                    onClose={closePickupFilter}
                                    provinces={provinces}
                                    provincesSelected={pickupProvincesSelected}
                                    handleProvincesSelected={handlePickupProvincesSelected}
                                    cities={cities}
                                    citiesSelected={pickupCitiesSelected}
                                    addressKeys={pickupAddressKeys}
                                    setAddressKeys={setPickupAddressKeys}
                                    setProvincesSelected={setPickupProvincesSelected}
                                    setCitiesSelected={setPickupCitiesSelected}
                                />
                            </Collapse>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                            <Button
                                onClick={toggleOrderFilter}
                                variant={isOrderFilterActive ? 'contained' : 'outlined'}
                                style={{ marginBottom: theme.spacing.unit }}
                            >
                                {isOrderFilterActive ? 'Close Order Address Filter' : 'Add Order Address Filter'}
                            </Button>

                            <Collapse in={isOrderFilterActive}>
                                <FilterRow
                                    onClose={closeOrderFilter}
                                    provinces={provinces}
                                    provincesSelected={orderProvincesSelected}
                                    handleProvincesSelected={handleOrderProvincesSelected}
                                    cities={cities}
                                    citiesSelected={orderCitiesSelected}
                                    addressKeys={orderAddressKeys}
                                    setAddressKeys={setOrderAddressKeys}
                                    setProvincesSelected={setOrderProvincesSelected}
                                    setCitiesSelected={setOrderCitiesSelected}
                                />
                            </Collapse>
                        </div>
                        <CustomFormTitle titleText={'System Filter'} iconName={'tune'} />
                        <div style={{ display: 'flex', marginBottom: theme.spacing.unit * 2, flexWrap: 'wrap' }}>
                            <Button
                                onClick={togglePickupSystemFilters}
                                variant={showPickupSystemFilters ? 'contained' : 'outlined'}
                                style={{ marginBottom: theme.spacing.unit, width: '100%' }}
                                data-cy="trends-pickup-system-filter"
                            >
                                {showPickupSystemFilters ? 'Close Pickup System Filter' : 'Add Pickup System Filter'}
                            </Button>
                            <Collapse in={showPickupSystemFilters}>
                                <Select
                                    multiple
                                    onChange={handleCollectorsSelected}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    value={collectorsSelected}
                                    renderValue={s => (s.length > 0 ? `${s.length} Collectors` : 'No Collectors')}
                                    displayEmpty
                                    data-cy="trends-pickup-system-collector-select"
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleCollectorsSelected(collectors.map(c => c._id));
                                            }}
                                            data-cy="trends-pickup-system-collector-select-all"
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleCollectorsSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {collectors.map(({ _id, name }) => (
                                        <MenuItem key={_id} value={_id}>
                                            <Checkbox checked={collectorsSelected.includes(_id)} />
                                            <ListItemText>{name}</ListItemText>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    multiple
                                    renderValue={val =>
                                        val.length > 0
                                            ? `${_.isNull(val) ? 0 : val.length} Collector Admin${
                                                  val.length !== 1 ? 's' : ''
                                              }`
                                            : 'No Collector Admins'
                                    }
                                    value={collectorAdminsSelected}
                                    onChange={e => setCollectorAdminsSelected(e.target.value)}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    displayEmpty
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setCollectorAdminsSelected(
                                                    _(allCollectorAdmins)
                                                        .filter(user => collectorsSelected.includes(user.collector._id))
                                                        .map(user => user._id)
                                                        .value()
                                                );
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setCollectorAdminsSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    <MenuItem value={''}>
                                        Empty Collector Admin
                                        <span
                                            style={{
                                                marginLeft: theme.spacing.unit,
                                                display: 'grid',
                                                placeItems: 'center'
                                            }}
                                        >
                                            <MDIcon path={mdiWalk} size={0.85} color={theme.palette.text.secondary} />
                                        </span>
                                    </MenuItem>
                                    {_.orderBy(
                                        allCollectorAdmins,
                                        [d => !d.banned, d => d.name.first.toLowerCase()],
                                        ['desc', 'asc']
                                    ).map(user => {
                                        const { _id, collector, banned } = user;
                                        if (!collectorsSelected.includes(collector._id)) return null;
                                        return (
                                            <MenuItem
                                                key={_id}
                                                value={_id}
                                                style={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {_user.getNameFull(user).toLowerCase()}
                                                {banned && (
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            display: 'grid',
                                                            placeItems: 'center'
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiAccountOff}
                                                            size={0.85}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                <Select
                                    multiple
                                    renderValue={val =>
                                        val.length > 0
                                            ? `${val.length} Driver${val.length !== 1 ? 's' : ''}`
                                            : 'No Drivers'
                                    }
                                    value={driversSelected}
                                    onChange={e => setDriversSelected(e.target.value)}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    displayEmpty
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setDriversSelected(
                                                    _(allDrivers)
                                                        .filter(driver =>
                                                            collectorsSelected.includes(driver.collector._id)
                                                        )
                                                        .map(driver => driver._id)
                                                        .value()
                                                );
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setDriversSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    <MenuItem value={''}>
                                        Empty Driver
                                        <span
                                            style={{
                                                marginLeft: theme.spacing.unit,
                                                display: 'grid',
                                                placeItems: 'center'
                                            }}
                                        >
                                            <MDIcon path={mdiWalk} size={0.85} color={theme.palette.text.secondary} />
                                        </span>
                                    </MenuItem>
                                    {_.orderBy(
                                        allDrivers,
                                        [d => !d.banned, d => d.name.first.toLowerCase()],
                                        ['desc', 'asc']
                                    ).map(driver => {
                                        const { _id, collector, banned } = driver;
                                        if (!collectorsSelected.includes(collector._id)) return null;
                                        return (
                                            <MenuItem
                                                key={_id}
                                                value={_id}
                                                style={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {_user.getNameFull(driver).toLowerCase()}
                                                {banned && (
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            display: 'grid',
                                                            placeItems: 'center'
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiAccountOff}
                                                            size={0.85}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Select
                                    multiple
                                    renderValue={val =>
                                        val.length > 0
                                            ? `${val.length} Clerk${val.length !== 1 ? 's' : ''}`
                                            : 'No Clerks'
                                    }
                                    value={clerksSelected}
                                    onChange={e => setClerksSelected(e.target.value)}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    displayEmpty
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setClerksSelected(
                                                    _(clerks)
                                                        .filter(clerk =>
                                                            collectorsSelected.includes(clerk.collector._id)
                                                        )
                                                        .map(clerk => clerk._id)
                                                        .value()
                                                );
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setClerksSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {_.orderBy(
                                        clerks,
                                        [c => !c.banned, c => c.name.first.toLowerCase()],
                                        ['desc', 'asc']
                                    ).map(clerk => {
                                        const { _id, collector, banned } = clerk;
                                        if (!collectorsSelected.includes(collector._id)) return null;
                                        return (
                                            <MenuItem
                                                key={_id}
                                                value={_id}
                                                style={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {_user.getNameFull(clerk).toLowerCase()}
                                                {banned && (
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            display: 'grid',
                                                            placeItems: 'center'
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiAccountOff}
                                                            size={0.85}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Select
                                    multiple
                                    onChange={e => setZonesSelected(e.target.value)}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    value={zonesSelected}
                                    renderValue={s => (s.length > 0 ? `${s.length} Zones` : 'No Zones')}
                                    displayEmpty
                                    data-cy="trends-pickup-system-zone-select"
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setZonesSelected(zonesWithRegion.map(zone => zone._id));
                                            }}
                                            data-cy="trends-pickup-system-zone-select-all"
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setZonesSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {zonesFiltered.map(zone => (
                                        <MenuItem key={zone._id} value={zone._id}>
                                            <Checkbox checked={zonesSelected.includes(zone._id)} />
                                            <ListItemText>{zone.name}</ListItemText>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Collapse>
                        </div>
                        <div style={{ display: 'flex', marginBottom: theme.spacing.unit * 2, flexWrap: 'wrap' }}>
                            <Button
                                onClick={toggleOrderSystemFilters}
                                variant={showOrderSystemFilters ? 'contained' : 'outlined'}
                                style={{ marginBottom: theme.spacing.unit, width: '100%' }}
                            >
                                {showOrderSystemFilters ? 'Close Order System Filter' : 'Add Order System Filter'}
                            </Button>
                            <Collapse in={showOrderSystemFilters}>
                                <Select
                                    multiple
                                    onChange={handleOrderCollectorsSelected}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    value={orderCollectorsSelected}
                                    renderValue={s => (s.length > 0 ? `${s.length} Collectors` : 'No Collectors')}
                                    displayEmpty
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOrderCollectorsSelected(collectors.map(o => o._id));
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOrderCollectorsSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {collectors.map(({ _id, name }) => (
                                        <MenuItem key={_id} value={_id}>
                                            <Checkbox checked={orderCollectorsSelected.includes(_id)} />
                                            <ListItemText>{name}</ListItemText>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    multiple
                                    renderValue={val =>
                                        val.length > 0
                                            ? `${_.isNull(val) ? 0 : val.length} Collector Admin${
                                                  val.length !== 1 ? 's' : ''
                                              }`
                                            : 'No Collector Admins'
                                    }
                                    value={orderCollectorAdminsSelected}
                                    onChange={e => setOrderCollectorAdminsSelected(e.target.value)}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    displayEmpty
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setOrderCollectorAdminsSelected(
                                                    _(allCollectorAdmins)
                                                        .filter(user =>
                                                            orderCollectorsSelected.includes(user.collector._id)
                                                        )
                                                        .map(user => user._id)
                                                        .value()
                                                );
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setOrderCollectorAdminsSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    <MenuItem value={''}>
                                        Empty Order Admin
                                        <span
                                            style={{
                                                marginLeft: theme.spacing.unit,
                                                display: 'grid',
                                                placeItems: 'center'
                                            }}
                                        >
                                            <MDIcon path={mdiWalk} size={0.85} color={theme.palette.text.secondary} />
                                        </span>
                                    </MenuItem>
                                    {_.orderBy(
                                        allCollectorAdmins,
                                        [d => !d.banned, d => d.name.first.toLowerCase()],
                                        ['desc', 'asc']
                                    ).map(user => {
                                        const { _id, collector, banned } = user;
                                        if (!orderCollectorsSelected.includes(collector._id)) return null;
                                        return (
                                            <MenuItem
                                                key={_id}
                                                value={_id}
                                                style={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {_user.getNameFull(user).toLowerCase()}
                                                {banned && (
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            display: 'grid',
                                                            placeItems: 'center'
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiAccountOff}
                                                            size={0.85}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                <Select
                                    multiple
                                    renderValue={val =>
                                        val.length > 0
                                            ? `${val.length} Driver${val.length !== 1 ? 's' : ''}`
                                            : 'No Drivers'
                                    }
                                    value={orderDriversSelected}
                                    onChange={e => setOrderDriversSelected(e.target.value)}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    displayEmpty
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setOrderDriversSelected(
                                                    _(allDrivers)
                                                        .filter(driver =>
                                                            orderCollectorsSelected.includes(driver.collector._id)
                                                        )
                                                        .map(driver => driver._id)
                                                        .value()
                                                );
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setOrderDriversSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    <MenuItem value={''}>
                                        Empty Driver
                                        <span
                                            style={{
                                                marginLeft: theme.spacing.unit,
                                                display: 'grid',
                                                placeItems: 'center'
                                            }}
                                        >
                                            <MDIcon path={mdiWalk} size={0.85} color={theme.palette.text.secondary} />
                                        </span>
                                    </MenuItem>
                                    {_.orderBy(
                                        allDrivers,
                                        [d => !d.banned, d => d.name.first.toLowerCase()],
                                        ['desc', 'asc']
                                    ).map(driver => {
                                        const { _id, collector, banned } = driver;
                                        if (!orderCollectorsSelected.includes(collector._id)) return null;
                                        return (
                                            <MenuItem
                                                key={_id}
                                                value={_id}
                                                style={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {_user.getNameFull(driver).toLowerCase()}
                                                {banned && (
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            display: 'grid',
                                                            placeItems: 'center'
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiAccountOff}
                                                            size={0.85}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Select
                                    multiple
                                    renderValue={val =>
                                        val.length > 0
                                            ? `${val.length} Clerk${val.length !== 1 ? 's' : ''}`
                                            : 'No Clerks'
                                    }
                                    value={orderClerksSelected}
                                    onChange={e => setOrderClerksSelected(e.target.value)}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    displayEmpty
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setOrderClerksSelected(
                                                    _(clerks)
                                                        .filter(clerk =>
                                                            orderCollectorsSelected.includes(clerk.collector._id)
                                                        )
                                                        .map(clerk => clerk._id)
                                                        .value()
                                                );
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setOrderClerksSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {_.orderBy(
                                        clerks,
                                        [c => !c.banned, c => c.name.first.toLowerCase()],
                                        ['desc', 'asc']
                                    ).map(clerk => {
                                        const { _id, collector, banned } = clerk;
                                        if (!orderCollectorsSelected.includes(collector._id)) return null;
                                        return (
                                            <MenuItem
                                                key={_id}
                                                value={_id}
                                                style={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {_user.getNameFull(clerk).toLowerCase()}
                                                {banned && (
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            display: 'grid',
                                                            placeItems: 'center'
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiAccountOff}
                                                            size={0.85}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Select
                                    multiple
                                    onChange={e => setOrderZonesSelected(e.target.value)}
                                    style={{
                                        margin: theme.spacing.unit
                                    }}
                                    value={orderZonesSelected}
                                    renderValue={s => (s.length > 0 ? `${s.length} Zones` : 'No Zones')}
                                    displayEmpty
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setOrderZonesSelected(zonesWithRegion.map(zone => zone._id));
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setOrderZonesSelected([]);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {zonesFiltered.map(zone => (
                                        <MenuItem key={zone._id} value={zone._id}>
                                            <Checkbox checked={orderZonesSelected.includes(zone._id)} />
                                            <ListItemText>{zone.name}</ListItemText>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Collapse>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Grid
                                container
                                spacing={theme.spacing.unit}
                                style={{ paddingBottom: theme.spacing.unit * 2 }}
                            >
                                <Grid item xs={12} md={12}>
                                    <Select
                                        value={chartInterval}
                                        onChange={handleDateWindowChange}
                                        style={{
                                            width: '100%',
                                            paddingBottom: theme.spacing.unit
                                        }}
                                        data-cy="date-range-select"
                                    >
                                        <MenuItem value="DAY" data-cy="date-range-select-today">
                                            Daily
                                        </MenuItem>
                                        <MenuItem value="WEEK" data-cy="date-range-select-thisIsoWeek">
                                            Weekly
                                        </MenuItem>
                                        <MenuItem value="MONTH" data-cy="date-range-select-thisMonth">
                                            Monthly
                                        </MenuItem>
                                        <MenuItem value="QUARTER" data-cy="date-range-select-thisQuarter">
                                            Quarterly
                                        </MenuItem>
                                        <MenuItem value="ALL" data-cy="date-range-select-allTime">
                                            All Time
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={showMarkerSettings ? 3 : 5} md={showMarkerSettings ? 3 : 5}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showMarkerSettings}
                                                onChange={() => setShowMarkerSettings(!showMarkerSettings)} // Toggle state
                                                name="showMarkerSettings"
                                                color="primary"
                                            />
                                        }
                                        label="Show Marker"
                                    />
                                </Grid>
                                {showMarkerSettings && (
                                    <>
                                        <Grid item xs={3} md={3}>
                                            <DatePicker
                                                timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                                label="Marker Date"
                                                disableFuture
                                                format="DD MMM YYYY"
                                                value={markerDate}
                                                onChange={date => setMarkerDate(moment(date).startOf('day'))}
                                            />
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                value={markerTitle}
                                                fullWidth
                                                name="markerTitle"
                                                label="Marker Title"
                                                variant="outlined"
                                                onChange={e => setMarkerTitle(e.target.value)}
                                                error={
                                                    showMarkerSettings &&
                                                    (_.isNil(markerTitle) || _.isEmpty(markerTitle))
                                                }
                                                helperText={
                                                    showMarkerSettings &&
                                                    (_.isNil(markerTitle) || _.isEmpty(markerTitle)) &&
                                                    'Marker Title is required'
                                                }
                                                FormHelperTextProps={{ error: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                value={markerColor}
                                                select
                                                fullWidth
                                                name="markerColor"
                                                label="Marker Color"
                                                variant="outlined"
                                                onChange={e => setMarkerColor(e.target.value)}
                                                error={
                                                    showMarkerSettings &&
                                                    (_.isNil(markerColor) || _.isEmpty(markerColor))
                                                }
                                                helperText={
                                                    showMarkerSettings &&
                                                    (_.isNil(markerColor) || _.isEmpty(markerColor)) &&
                                                    'Marker Colour is required'
                                                }
                                                FormHelperTextProps={{ error: true }}
                                            >
                                                {iconColors.map(color => {
                                                    return (
                                                        <MenuItem key={color} value={color}>
                                                            <div
                                                                style={{
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    flexDirection: 'row'
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginRight: theme.spacing.unit,
                                                                        height: 12,
                                                                        width: 12,
                                                                        borderRadius: '100%',
                                                                        backgroundColor: getColor(color)
                                                                    }}
                                                                />
                                                                {_.startCase(color)}
                                                            </div>
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid container spacing={theme.spacing.unit}>
                                {[...filterTypes].map(filterType => {
                                    const trendLinesByType = _.filter(trendLines, { type: filterType });
                                    const trendLinesShown = _.filter(trendLines, line =>
                                        visibleTrendKeys.includes(line.dataKey)
                                    );

                                    const filteredTrendLines = _.filter(trendLinesByType, line =>
                                        visibleTrendKeys.includes(line.dataKey)
                                    );

                                    const showingAll = trendLinesByType.length === filteredTrendLines.length;

                                    const handleShowAll = () => {
                                        let updatedTrendKeys = _.clone(visibleTrendKeys);

                                        for (let line of trendLinesByType) {
                                            if (!updatedTrendKeys.includes(line.dataKey)) {
                                                updatedTrendKeys.push(line.dataKey);
                                            }
                                        }

                                        handleVisibleTrendKeys(updatedTrendKeys);
                                    };

                                    const handleShowNone = () => {
                                        let updatedTrendKeys = _.clone(visibleTrendKeys);

                                        const shownTrendsWithoutType = _.filter(
                                            trendLinesShown,
                                            line => line.type !== filterType
                                        );
                                        updatedTrendKeys = shownTrendsWithoutType.map(line => line.dataKey);

                                        handleVisibleTrendKeys(updatedTrendKeys);
                                    };

                                    const handleIndividual = key => {
                                        let updatedTrendKeys = _.clone(visibleTrendKeys);
                                        if (updatedTrendKeys.includes(key)) {
                                            handleVisibleTrendKeys(_.filter(updatedTrendKeys, k => k !== key));
                                        } else {
                                            updatedTrendKeys.push(key);
                                            handleVisibleTrendKeys(updatedTrendKeys);
                                        }
                                    };

                                    const typeCapitalized = _.startCase(filterType);

                                    return (
                                        <Grid
                                            item
                                            xs={6}
                                            sm={4}
                                            style={{
                                                border: '1px solid',
                                                borderColor: theme.palette.grey[400],
                                                borderRadius: 4
                                            }}
                                        >
                                            <div />
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Typography
                                                    variant="body1"
                                                    style={{ paddingBottom: 0, marginBottom: 0 }}
                                                >
                                                    {typeCapitalized}
                                                </Typography>
                                                {/* <Typography
                                                    variant="body1"
                                                    style={{ float: 'right', paddingBottom: 0, marginBottom: 0 }}
                                                >
                                                    Shown: {filteredTrendLines.length}
                                                </Typography> */}
                                            </div>
                                            <div
                                                style={{
                                                    paddingTop: theme.spacing.unit / 2,
                                                    paddingBottom: theme.spacing.unit / 2,
                                                    paddingLeft: theme.spacing.unit / 2,
                                                    paddingRight: theme.spacing.unit / 2,
                                                    marginBottom: theme.spacing.unit * 2,
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around'
                                                    }}
                                                >
                                                    <Button onClick={handleShowAll}>All</Button>
                                                    <Button onClick={handleShowNone}>None</Button>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    {trendLinesByType.map(line => {
                                                        const { dataKey, displayName } = line;
                                                        return (
                                                            <FormControlLabel
                                                                key={dataKey}
                                                                control={
                                                                    <Checkbox
                                                                        style={{ padding: 0, margin: 2 }}
                                                                        onChange={e => handleIndividual(e.target.value)}
                                                                        value={dataKey}
                                                                        checked={visibleTrendKeys.includes(dataKey)}
                                                                    />
                                                                }
                                                                style={{ padding: 0, margin: 2 }}
                                                                label={
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: '90%',
                                                                            padding: 0,
                                                                            margin: 2
                                                                        }}
                                                                    >
                                                                        {displayName}
                                                                    </Typography>
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={() => {
                                setSelectedChart(null);
                                setSelectedChartId(null);
                                setChartTitle('');
                                setCreateNewChartDialogOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            color="primary"
                            //disabled={_.isEmpty(collectorsSelected)}
                            variant="contained"
                            data-cy="dashboard-search-button"
                            type="submit"
                            onClick={() => {
                                setCreateNewChartDialogOpen(false);
                                setChartTitle('');
                                handleSubmit();
                            }}
                            disabled={
                                (showMarkerSettings && (_.isNil(markerColor) || _.isEmpty(markerColor))) ||
                                (showMarkerSettings && (_.isNil(markerTitle) || _.isEmpty(markerTitle)))
                            }
                        >
                            <Icon>search</Icon>
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={addressQueryDialogOpen} fullWidth onClose={() => setAddressQueryDialogOpen(false)}>
                    <DialogTitle>Address Search</DialogTitle>
                    <DialogContent>
                        <Typography>
                            This field is comma delimited to allow for multiple search queries. Search queries are case
                            insensitive. This field will only affect trends for which there is relevant pickup location
                            data.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => setAddressQueryDialogOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

const FilterRow = ({
    onClose,
    provinces,
    provincesSelected,
    setProvincesSelected,
    handleProvincesSelected,
    cities,
    citiesSelected,
    setCitiesSelected,
    addressKeys,
    setAddressKeys
}) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', position: 'relative' }}>
            <Select
                multiple
                value={provincesSelected}
                onChange={handleProvincesSelected}
                style={{ margin: '8px' }}
                renderValue={selected => `${selected.length > 0 ? selected.length : 'No'} Provinces`}
                displayEmpty
            >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            setProvincesSelected(provinces);
                        }}
                    >
                        All
                    </Button>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            setProvincesSelected([]);
                        }}
                    >
                        None
                    </Button>
                </div>

                {provinces.map(province => (
                    <MenuItem key={province} value={province}>
                        <Checkbox checked={!_.isEmpty(provincesSelected) && provincesSelected.includes(province)} />
                        <ListItemText primary={province} />
                    </MenuItem>
                ))}
            </Select>

            <Select
                multiple
                value={citiesSelected}
                onChange={e => setCitiesSelected(e.target.value)}
                style={{ margin: '8px' }}
                renderValue={selected => `${selected.length > 0 ? selected.length : 'No'} Cities`}
                displayEmpty
            >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            setCitiesSelected(
                                _.filter(cities, c => provincesSelected.includes(c.province)).map(c => c.name)
                            );
                        }}
                    >
                        All
                    </Button>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            setCitiesSelected([]);
                        }}
                    >
                        None
                    </Button>
                </div>

                {_.filter(cities, city => provincesSelected.includes(city.province)).map(city => (
                    <MenuItem key={city.name} value={city.name}>
                        <Checkbox checked={!_.isEmpty(citiesSelected) && citiesSelected.includes(city.name)} />
                        <ListItemText primary={`${city.name}, ${city.province}`} />
                    </MenuItem>
                ))}
            </Select>

            <TextField
                label="Address search (comma delimited)"
                value={addressKeys}
                onChange={e => setAddressKeys(e.target.value)}
                multiline
                fullWidth
                style={{ margin: '8px', marginTop: '-8px' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon style={{ color: 'gray', cursor: 'pointer' }} onClick={() => alert('Info Clicked')}>
                                info
                            </Icon>
                        </InputAdornment>
                    )
                }}
            />

            {/* Close Button */}
            {/* <IconButton onClick={onClose} style={{ position: 'absolute', right: 0, top: 0 }}>
                <Icon>close</Icon>
            </IconButton> */}
        </div>
    );
};

export default withTheme()(Trends);
