import _ from 'lodash';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';

/**
 * Function to handle calling the camera plugin
 */
export async function takePhoto(uploadExistingPhoto = false) {
    try {
        const image = await Camera.getPhoto({
            quality: 70,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: uploadExistingPhoto ? CameraSource.Photos : CameraSource.Camera
        });

        const { data, modifiedImage } = await convertImageObj(image);
        return {
            data,
            image: modifiedImage
        };
    } catch (err) {
        console.error(err);
    }
}

/**
 *
 * This version throws an error so that error messages can be displayed to the user
 */
export async function getPhoto(uploadExistingPhoto = false) {
    const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: uploadExistingPhoto ? CameraSource.Photos : CameraSource.Camera
    });
    const { data, modifiedImage } = await convertImageObj(image);
    console.log(modifiedImage);
    return {
        data,
        image: modifiedImage
    };
}

async function convertImageObj(image) {
    //This occurs on IOS, fetching this path results in cors issues
    if (image.webPath.startsWith('capacitor://')) {
        const imageCopy = _.cloneDeep(image);
        // Read the file as base64 using the Filesystem plugin
        const file = await Filesystem.readFile({
            path: image.path || image.webPath
        });

        // Convert the base64 string to a Blob
        const byteCharacters = atob(file.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: `image/${image.format}` });

        imageCopy.webPath = URL.createObjectURL(blob);

        return {
            data: blob,
            modifiedImage: imageCopy
        };
    } else {
        const data = await fetch(image.webPath).then(r => r.blob());
        return {
            data,
            modifiedImage: image
        };
    }
}
