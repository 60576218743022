import { locDate } from 'localizations/localizationHandler';
import _ from 'lodash';
import moment from 'moment-timezone';

export const getTimeUntilRedemptionProcessed = (redemption, lang, redemptionOptions) => {
    let minProcessingTime = 1;
    let unit = 'days';
    if (redemption.paymentModel === 'AUS_DIRECT_BANKING') {
        const directDepositProcessingTimeUnit = _.get(redemptionOptions, 'directDepositProcessingTimeUnit');
        if (!_.isNil(directDepositProcessingTimeUnit) && !_.isEmpty(directDepositProcessingTimeUnit)) {
            unit = directDepositProcessingTimeUnit.toLowerCase();
        }
        minProcessingTime = _.get(redemptionOptions, 'directDepositProcessingTimeMin', 1);
    } else if (redemption.paymentModel === 'INTERAC_ETRANSFERS') {
        const eTransferProcessingTimeUnit = _.get(redemptionOptions, 'eTransferProcessingTimeUnit');
        if (!_.isNil(eTransferProcessingTimeUnit) && !_.isEmpty(eTransferProcessingTimeUnit)) {
            unit = eTransferProcessingTimeUnit.toLowerCase();
        }
        minProcessingTime = _.get(redemptionOptions, 'eTransferProcessingTimeMin', 1);
    } else if (redemption.paymentModel === 'CHEQUE') {
        const chequeProcessingTimeUnit = _.get(redemptionOptions, 'chequeProcessingTimeUnit');
        if (!_.isNil(chequeProcessingTimeUnit) && !_.isEmpty(chequeProcessingTimeUnit)) {
            unit = chequeProcessingTimeUnit.toLowerCase();
        }
        minProcessingTime = _.get(redemptionOptions, 'chequeProcessingTimeMin', 1);
    } else if (redemption.paymentModel === 'BANK_TRANSFER') {
        const bankTransferProcessingTimeUnit = _.get(redemptionOptions, 'bankTransferProcessingTimeUnit');
        if (!_.isNil(bankTransferProcessingTimeUnit) && !_.isEmpty(bankTransferProcessingTimeUnit)) {
            unit = bankTransferProcessingTimeUnit.toLowerCase();
        }
        minProcessingTime = _.get(redemptionOptions, 'bankTransferProcessingTimeMin', 1);
    }
    return locDate(
        moment(_.get(redemption, 'createdAt', new Date())).add(minProcessingTime, unit),
        'DD MMM YYYY',
        lang
    );
};

export const canRedeemWithoutDonation = (customer, balance, redemptionMinimum, redemptionFee) => {
    return (
        balance >= redemptionMinimum || (_.get(customer, 'bypassRedemptionMinimum', false) && balance > redemptionFee)
    );
};
