import { useState, useReducer, useMemo, useEffect, useContext } from 'react';

import _ from 'lodash';

import { validate } from '../../../utils/validate';

import { _user } from 'std';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { loc } from 'localizations/localizationHandler';
import { INVALID_REDEMPTION_EMAILS } from '../../../constants';

function useRedemptionDialog({
    http,
    customer,
    operator,
    redemptionFee,
    redemptionType,
    redemptionOptions,
    charity_id,
    charities,
    redemptions,
    redemptionPending,
    email,
    authorizedEmails,
    accountName,
    charityPreferred,
    onDonateSuccess,
    onRedeemSuccess,
    reloadCharity,
    reloadCustomer,
    subdivisionPreferred,
    banking,
    open,
    deletingOrDisablingAccount,
    emailVerificationRequired,
    emailVerificationRequiredForETransfer,
    handleVerifyEmailDialog,
    charityEnabled,
    autoRedemption = false
}) {
    const initialFormErrors = {
        bsbNumber: {
            fail: false,
            reason: ''
        },
        accountNumber: {
            fail: false,
            reason: ''
        },
        accountName: {
            fail: false,
            reason: ''
        },
        email: {
            fail: false,
            reason: ''
        },
        question: {
            fail: false,
            reason: ''
        },
        passphrase: {
            fail: false,
            reason: ''
        },
        street: {
            fail: false,
            reason: ''
        },
        city: {
            fail: false,
            reason: ''
        },
        postalCode: {
            fail: false,
            reason: ''
        },
        province: {
            fail: false,
            reason: ''
        },
        country: {
            fail: false,
            reason: ''
        },
        institutionNumber: {
            fail: false,
            reason: ''
        },
        transitNumber: {
            fail: false,
            reason: ''
        }
    };

    const initialRedemptionForm = useMemo(
        () =>
            generateInitialRedemptionForm({
                customer,
                email,
                redemptions,
                accountName,
                redemptionType,
                authorizedEmails
            }),
        [email, redemptions, accountName, redemptionType, authorizedEmails, customer]
    );

    const [paymentModel, setPaymentModel] = useState();
    const [cashedOutBy, setCashedOutBy] = useState(_.get(customer, 'collector._id', ''));
    const [inProgress, setInProgress] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [donate, setDonate] = useState(!_.isEmpty(charityPreferred));
    const [emailForAPIRedemption, setEmailForAPIRedemption] = useState('');
    const [redemptionForm, dispatchRedemptionForm] = useReducer(redemptionFormReducer, initialRedemptionForm);
    const [formErrors, setFormErrors] = useState({});
    const [charityPref, setCharityPref] = useState(charityPreferred);
    const [subdivisionPref, setSubdivisionPref] = useState(subdivisionPreferred);
    const [customerLocation, setCustomerLocation] = useState(_.get(customer, 'location', null));
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [securityAnswerDialogOpen, setSecurityAnswerDialogOpen] = useState(false);
    const [askForPreviousBankInfo, setAskForPreviousBankInfo] = useState(!_.isNil(banking));
    const [usePreviousBankingInfo, setUsePreviousBankingInfo] = useState(!_.isNil(banking));
    const [modifiedRedemptionFee, setModifiedRedemptionFee] = useState(redemptionFee);
    const [securityQuestions, setSecurityQuestions] = useState({});
    const [loading, setLoading] = useState(false);
    const { lang } = useContext(LocalizationContext);
    const onSnackbar = useContext(SnackbarContext);
    const [taxReceiptRequested, setTaxReceiptRequested] = useState(_.get(customer, 'receiveTaxReceipts', true));

    useEffect(() => {
        setTaxReceiptRequested(_.get(customer, 'receiveTaxReceipts', true));
    }, [customer.receiveTaxReceipts]);

    useEffect(() => {
        setCustomerLocation(_.get(customer, 'location', null));
    }, [customer.location]);

    let amountPending = 0;
    if (redemptionPending) {
        const latestRed = _.last(redemptions);
        amountPending = latestRed.amount;
    }

    useEffect(() => {
        switch (process.env.REACT_APP_REGION_EXT) {
            case 'STD':
            case 'EXP':
            case 'MXD':
            case 'CON':
                setPaymentModel('INTERAC_ETRANSFERS');
                break;
            case 'AUS':
                setPaymentModel('AUS_DIRECT_BANKING');
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        const fetchSecurityQuestions = async () => {
            let res = await http.getJSON('/system/configuration/securityQuestions');
            if (res.ok) {
                setSecurityQuestions(res.data.securityQuestions);
            }
        };
        fetchSecurityQuestions();
    }, []);

    useEffect(() => {
        validateForm();
    }, [redemptionForm]);

    useEffect(() => {
        (async () => {
            //reload payment methods available for the charity/customer
            if (open) {
                setLoading(true);
                if (redemptionType === 'customer') {
                    await reloadCustomer();
                    if (![email, ...authorizedEmails].includes(redemptionForm.email)) {
                        handleResetForm();
                    }
                } else if (redemptionType === 'charity') {
                    await reloadCharity();
                }
                setLoading(false);
            } else {
                handleResetForm();
            }
        })();
    }, [open]);

    const handleResetForm = state => {
        if (state) {
            dispatchRedemptionForm({ type: 'reset', initialState: state });
        } else {
            dispatchRedemptionForm({ type: 'reset', initialState: initialRedemptionForm });
        }
        setActiveStep(0);
    };

    useEffect(() => {
        if (!open) handleResetForm();
    }, [customer, open]);

    const validateForm = () => {
        let errors;
        switch (paymentModel) {
            case 'AUS_DIRECT_BANKING':
                errors = {
                    bsbNumber: ['bsbNumber'],
                    accountNumber: ['accountNumber'],
                    accountName: ['required', 'accountName']
                };
                break;
            case 'INTERAC_ETRANSFERS':
                errors = { email: ['email', 'e-transfer-email'], question: ['required'], passphrase: ['passphrase'] };
                break;
            case 'CHEQUE':
                errors = {
                    accountName: ['required'],
                    street: ['required'],
                    city: ['required'],
                    postalCode: ['required'],
                    province: ['required'],
                    country: ['required']
                };
                break;
            case 'BANK_TRANSFER':
                errors = {
                    accountNumber: ['bankTransferAccountNumber'],
                    institutionNumber: ['bankTransferInstitutionNumber'],
                    transitNumber: ['bankTransferTransitNumber']
                };
                break;
            default:
                return;
        }

        const keys = _.keys(errors);

        let formErrorsUpdated = _.cloneDeep(formErrors);
        for (let key of keys) {
            formErrorsUpdated[key] = validate(errors[key], redemptionForm[key], lang);
        }

        setFormErrors(formErrorsUpdated);
    };

    const handleNext = () => {
        validateForm();
        if (
            paymentModel === 'CASH' &&
            ((activeStep === 1 && redemptionType === 'customer') ||
                (activeStep === 0 && (redemptionType === 'charity' || autoRedemption)))
        ) {
            setActiveStep(activeStep + 2);
        } else if (
            paymentModel === 'INTERAC_ETRANSFERS' &&
            redemptionType === 'customer' &&
            activeStep === (charityEnabled ? 1 : 0) &&
            !_.get(customer, 'verification.email.verified', false) &&
            emailVerificationRequired &&
            emailVerificationRequiredForETransfer
        ) {
            handleVerifyEmailDialog();
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleSetPaymentModel = paymentModel => {
        if (paymentModel === 'CASH') {
            setModifiedRedemptionFee(0);
        } else {
            setModifiedRedemptionFee(redemptionFee);
        }

        setFormErrors(initialFormErrors);
        setPaymentModel(paymentModel);
    };

    const handleBack = () => {
        if (
            paymentModel === 'CASH' &&
            ((activeStep === 3 && redemptionType === 'customer') ||
                (activeStep === 2 && (redemptionType === 'charity' || autoRedemption)))
        ) {
            setActiveStep(activeStep - 2);
        } else {
            setActiveStep(activeStep - 1);
        }
    };

    const toggleDonate = () => {
        setDonate(!donate);
    };

    const handlePaymentModelChange = e => {
        if (e.target.value === 'CASH') {
            setModifiedRedemptionFee(0);
        } else {
            setModifiedRedemptionFee(redemptionFee);
        }

        setFormErrors(initialFormErrors);
        setPaymentModel(e.target.value);
    };

    const handleChangeCashedOutBy = e => {
        setCashedOutBy(e.target.value);
    };

    const handleChange = e => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'bsbNumber') {
            value = value.replace(/\D+/g, '');
        }
        dispatchRedemptionForm({ type: 'set', name, value });
    };

    const handleCharityPreferred = (_id, subdivision) => () => {
        setCharityPref(_id);
        setSubdivisionPref(subdivision);
    };

    const handleSubdivisionPreferred = e => {
        setSubdivisionPref(e.target.value);
    };

    const toggleTaxReceiptRequested = () => {
        setTaxReceiptRequested(!taxReceiptRequested);
    };

    const handleCustomerLocationUnit = newUnitNumber => {
        setCustomerLocation({ ...customerLocation, unitNumber: newUnitNumber });
    };

    const handleCustomerLocation = ({ suggestion, place }) => {
        setCustomerLocation({
            description: suggestion.description,
            place_id: suggestion.place_id,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
    };

    const handleEmailForAPIRedemption = e => {
        setEmailForAPIRedemption(e.target.value);
    };

    const handleInfoDialog = state => {
        setInfoDialogOpen(state);
    };

    const handleConfirmationDialog = state => {
        setConfirmationDialogOpen(state);
    };

    const handleSecurityAnswerDialog = state => {
        setSecurityAnswerDialogOpen(state);
    };

    const handleUsePreviousBankingInformation = state => {
        setAskForPreviousBankInfo(state);
        setUsePreviousBankingInfo(state);
        if (state) {
            handleNext();
        }
    };

    const handleRedeem = async () => {
        if (donate) {
            return await handleDonate();
        }

        let {
            email,
            question,
            passphrase,
            bsbNumber,
            accountNumber,
            accountName,
            street,
            city,
            province,
            country,
            postalCode,
            institutionNumber,
            transitNumber
        } = redemptionForm;
        setInProgress(true);

        if (paymentModel === 'CHEQUE') {
            passphrase = `${accountName} - ${street} ${city} ${province} ${country} ${postalCode}`;
        }

        let res;
        switch (redemptionType) {
            case 'customer':
                setLoading(true);
                res = await http.post(
                    '/users/redeem',
                    {
                        paymentModel,
                        customer,
                        email,
                        question,
                        passphrase,
                        bsbNumber,
                        accountNumber,
                        institutionNumber,
                        transitNumber,
                        accountName,
                        street,
                        city,
                        province,
                        country,
                        postalCode,
                        usePreviousBankingInfo,
                        cashedOutBy: _.isEmpty(cashedOutBy) ? null : cashedOutBy,
                        deletingOrDisablingAccount,
                        emailForAPIRedemption
                    },
                    true
                );

                setLoading(false);
                break;
            case 'charity':
                setLoading(true);

                res = await http.post(
                    `/charities/${charity_id}/redeem`,
                    {
                        paymentModel,
                        customer,
                        redemptionEmail: email,
                        question,
                        passphrase,
                        bsbNumber,
                        accountNumber,
                        institutionNumber,
                        transitNumber,
                        accountName,
                        street,
                        city,
                        province,
                        country,
                        postalCode,
                        usePreviousBankingInfo,
                        cashedOutBy: _.isEmpty(cashedOutBy) ? null : cashedOutBy,
                        deletingOrDisablingAccount,
                        emailForAPIRedemption
                    },
                    true
                );
                setLoading(false);

                break;
            default:
                throw new Error(loc('redeemError', lang));
        }

        if (res.ok) {
            setActiveStep(0);
            // setConfirmationDialogOpen(false);
            setSecurityAnswerDialogOpen(false);
            await onRedeemSuccess();
            await reloadCharity();
            await reloadCustomer();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setInProgress(false);
    };

    const handleSetDonation = state => {
        setDonate(state);
    };

    const handleDonate = async () => {
        setInProgress(true);

        let charitySelected = _.find(charities, charity => charity._id === charityPref);
        let taxReceiptRequestedAdjusted = charitySelected.taxReceiptsIssued ? taxReceiptRequested : false;
        let res = await http.post('/users/donate', {
            customer,
            charity_id: charityPref,
            subdivision: subdivisionPref,
            taxReceiptRequested: taxReceiptRequestedAdjusted,
            customerLocation
        });
        if (res.ok) {
            let charityName = charitySelected.name;
            setActiveStep(0);
            setConfirmationDialogOpen(false);
            setSecurityAnswerDialogOpen(false);
            await onDonateSuccess(charityName);
            await reloadCharity();
            await reloadCustomer();
        }

        setInProgress(false);
    };

    return {
        amountPending,
        paymentModel,
        emailForAPIRedemption,
        inProgress,
        activeStep,
        donate,
        modifiedRedemptionFee,
        redemptionForm,
        formErrors,
        charityPref,
        subdivisionPref,
        taxReceiptRequested,
        customerLocation,
        infoDialogOpen,
        cashedOutBy,
        confirmationDialogOpen,
        securityAnswerDialogOpen,
        askForPreviousBankInfo,
        usePreviousBankingInfo,
        loading,
        handlePaymentModelChange,
        handleChangeCashedOutBy,
        handleNext,
        handleSetPaymentModel,
        handleEmailForAPIRedemption,
        handleBack,
        toggleDonate,
        handleChange,
        handleCharityPreferred,
        handleSubdivisionPreferred,
        toggleTaxReceiptRequested,
        handleInfoDialog,
        handleConfirmationDialog,
        handleSecurityAnswerDialog,
        handleUsePreviousBankingInformation,
        handleRedeem,
        handleSetDonation,
        handleDonate,
        handleCustomerLocation,
        handleCustomerLocationUnit,
        securityQuestions,
        handleResetForm
    };
}

export default useRedemptionDialog;

function generateInitialRedemptionForm({
    customer,
    redemptions,
    email,
    accountName,
    redemptionType,
    authorizedEmails
}) {
    let initialEmail;
    let location = _.get(customer, 'location', undefined);

    function safeSplitAndGetValue(inputString, index) {
        if (!inputString) return '';
        const splitValues = inputString.split(',');
        if (index === -1) {
            return !_.isEmpty(splitValues) ? splitValues[splitValues.length - 1] : '';
        }
        return !_.isEmpty(splitValues) && index < splitValues.length ? splitValues[index] : '';
    }

    let locationDesc = _.get(location, 'description', undefined);

    let locationUnit = _.get(location, 'unitNumber', undefined);

    let parsedStreet = safeSplitAndGetValue(locationDesc, 0),
        parsedCity = _.get(location, 'city') || safeSplitAndGetValue(locationDesc, 1) || '',
        parsedProvince = _.get(location, 'province') || '',
        parsedPostalCode = _.get(location, 'postalCode') || '',
        parsedCountry = _.get(location, 'country') || safeSplitAndGetValue(locationDesc, -1) || '';
    const emailOptions = [email, ...authorizedEmails].filter(
        address => !_.some(INVALID_REDEMPTION_EMAILS, invalidEmail => address.includes(invalidEmail))
    );
    if (redemptionType === 'charity') {
        initialEmail = email;
    } else {
        initialEmail = _.isEmpty(emailOptions)
            ? ''
            : _.isEmpty(redemptions) || !emailOptions.includes(_.get(_.last(redemptions), 'email', email))
            ? _.first(emailOptions)
            : _.get(_.last(redemptions), 'email', email);
    }
    return {
        email: initialEmail.toLowerCase(),
        question: '',
        passphrase: '',
        bsbNumber: '',
        accountNumber: '',
        accountName: accountName || '',
        street: _.isNil(locationUnit) || _.isEmpty(locationUnit) ? parsedStreet : `${locationUnit}-${parsedStreet}`,
        city: parsedCity,
        province: parsedProvince,
        country: parsedCountry,
        postalCode: parsedPostalCode,
        institutionNumber: '',
        transitNumber: ''
    };
}

function redemptionFormReducer(state, action) {
    const { type } = action;

    switch (type) {
        case 'set':
            let updatedState = _.cloneDeep(state);
            _.set(updatedState, action.name, action.value);
            return {
                ...updatedState
            };
        case 'reset':
            return action.initialState;
        default:
            return state;
    }
}
